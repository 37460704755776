import React, { Component } from 'react';

import Router from './Router';

import { logOutUser } from './Actions/userActions'
import { connect } from 'react-redux'

class App extends Component {
    componentDidMount() {
        if (this.props.loggedIn) {
            let now = new Date()
            let loggedIn = parseInt(now.getDate())
            let last = parseInt(this.props.loggedIn)
            if (loggedIn - last > 6 || loggedIn - last < 0) {
                this.props.logOutUser()
            }
        }
    }

    render() {
        return (
            <>
                <Router />
            </>
        );
    }
}

export default connect(
    null,
    { logOutUser }
)(App);
