import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Scrollbars } from "react-custom-scrollbars";
import "../../Assets/CSS/Hourlog.css";
import TextareaAutosize from "react-textarea-autosize";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import ReplayOutlinedIcon from "@material-ui/icons/ReplayOutlined";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Collapse from "@material-ui/core/Collapse";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import Timer from "react-compound-timer";
import moment from "moment";
import { connect } from "react-redux";
import HeaderHour from "./HeaderHour";

import {
  startTimer,
  pauseTimer,
  updateEstimate,
  updateSpent,
} from "../../Actions/hourLogActions";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles({
  root: {
    marginTop: 48,
    marginLeft: 32,
    marginRight: 32,
  },
  thisweek: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32,
  },
  lastweek: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 64,
  },
});

let spentHour = (spent) => {
  let final = spent / (60 * 60 * 1000);
  return final.toFixed(2);
};

let msToTime = (duration) => {
  let milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
};

let weekTotal = (week) => {
  let total = 0;
  for (let i = 0; i < 7; i++) {
    let len = week[i].logs.length;
    let log = week[i].logs;
    for (let j = 0; j < len; j++) {
      total += log[j].spent;
    }
  }
  return total;
};

let dayTotal = (logs) => {
  let total = 0;
  for (let j = 0; j < logs.length; j++) {
    total += logs[j].spent;
  }
  return total;
};

function Hourlog(props) {
  let {
    token,
    thisWeekData,
    nextWeekData,
    prevWeekData,
    activeTask,
    startTimer,
    pauseTimer,
  } = props;

  console.log(props)

  let classes = useStyles();
  let [openNow, setOpenNow] = useState(true);
  let [openNext, setOpenNext] = useState(false);
  let [open, setOpen] = useState(false);

  let [runningTask, setRunningTask] = useState(activeTask ? activeTask : {});
  let [reset, setReset] = useState(false);
  let [stop, setStop] = useState(false);

  let handleStart = (log) => {
    setRunningTask(log);
    if (token) {
      log.startTime = new Date();
      startTimer(log, token).then((res) => {
        if (res) {
          socket.emit("getLogs", res);
        }
      });
    }
  };

  let handleSpentChange = (event) => {
    if (event.key === "Enter") {
      return event.preventDefault();
    }
  };

  let handlePrev = (prevLog, newLog) => {
    handleStop({});
    setReset(true);
    setStop(true);
    if (token) {
      let endData = {
        _id: prevLog._id,
        endTime: new Date(),
      };
      pauseTimer(endData, token).then((res) => {
        if (res) {
          socket.emit("notify", token);
          setReset(false);
          setStop(false);
          handleStart(newLog);
        }
      });
    }
  };

  let handleStop = (log) => {
    setRunningTask(log);
  };

  return (
    <Scrollbars className="scroller" style={{ height: window.innerHeight }}>
      {/*Top-header editor*/}
      <HeaderHour
        token={token}
        runningTask={runningTask}
        handleStop={handleStop}
        resetFlag={reset}
        stopFlag={stop}
      />

      {/*Current week*/}
      <div className="this-week" onClick={() => setOpenNow(!openNow)}>
        <div>This week</div>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpenNow(!openNow)}
        >
          {openNow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <div className="week-total">Week Total:</div>
        <div className="total">{msToTime(weekTotal(thisWeekData))}</div>
      </div>

      <Collapse in={openNow} timeout="auto" unmountOnExit>
        <Box>
          {thisWeekData.map((dayData, idx) => {
            if (dayData.logs.length) {
              let isCurrentDate = false;
              let tomorrow = false;
              let yesterday = false;
              let datetime = moment(dayData.date);
              tomorrow = datetime.isSame(
                new Date().setDate(new Date().getDate() + 1),
                "day"
              );
              yesterday = datetime.isSame(
                new Date().setDate(new Date().getDate() - 1),
                "day"
              );
              isCurrentDate = datetime.isSame(new Date(), "day");

              console.log("DayData", dayData);

              return (
                <Card key={idx} className={classes.thisweek}>
                  <CardContent>
                    {isCurrentDate ? (
                      <div
                        className="date-name"
                        style={{ background: "#C0EFEF" }}
                      >
                        <span>Today</span>
                        <div className="spent">S</div>
                        <div className="estimate">E</div>
                        <div className="day-total">
                          <span>Total:</span>
                          <span>{msToTime(dayTotal(dayData.logs))}</span>
                        </div>
                      </div>
                    ) : (
                      <div className="date-name">
                        {yesterday ? (
                          <span>Yesterday</span>
                        ) : (
                          <span>
                            {tomorrow
                              ? "Tomorrow"
                              : moment(dayData.date).format("ddd, MMM D")}
                          </span>
                        )}

                        <div className="spent">S</div>
                        <div className="estimate">E</div>
                        <div className="day-total">
                          <span>Total:</span>
                          <span>{msToTime(dayTotal(dayData.logs))}</span>
                        </div>
                      </div>
                    )}

                    {dayData.logs.map((log, i) => {
                      return (
                        <div key={i} className="week-row">
                          <TextareaAutosize
                            value={
                              log.taskName ? log.taskName : "Untitled Task"
                            }
                            placeholder="What are you working now?"
                            disabled
                          />
                          <div
                            style={{ color: log.projectColor }}
                            className="header-project"
                          >
                            <FiberManualRecordIcon />
                            <span>
                              {log.projectTitle
                                ? log.projectTitle
                                : "Untitled Project"}
                            </span>
                          </div>

                          <div className="header-estimate">
                            <TextareaAutosize
                              value={spentHour(log.spent)}
                              placeholder="S: 0"
                              onKeyPress={(e) => handleSpentChange(e)}
                              disabled
                            />
                            <span>-</span>
                            <TextareaAutosize
                              value={log.estimated}
                              placeholder="E: 00:00"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  return event.preventDefault();
                                }
                              }}
                              disabled
                            />
                          </div>

                          <div>
                            <Timer
                              initialTime={log.spent}
                              startImmediately={false}
                              formatValue={(value) =>
                                `${value < 10 ? `0${value}` : value}`
                              }
                            >
                              {({
                                start,
                                resume,
                                pause,
                                stop,
                                reset,
                                timerState,
                              }) => (
                                <React.Fragment>
                                  <div className="header-timer">
                                    <div>{msToTime(log.spent)}</div>
                                    <div
                                      className="play-pause"
                                      onClick={
                                        runningTask._id
                                          ? () => handlePrev(runningTask, log)
                                          : () => handleStart(log)
                                      }
                                    >
                                      {runningTask._id === log._id ? (
                                        <ReplayOutlinedIcon />
                                      ) : (
                                        <PlayCircleOutlineIcon />
                                      )}
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </Timer>
                          </div>
                        </div>
                      );
                    })}
                  </CardContent>
                </Card>
              );
            }
          })}
        </Box>
      </Collapse>

      {/*Next Week*/}
      <div className="this-week" onClick={() => setOpenNext(!openNext)}>
        <div>Next week</div>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpenNext(!openNext)}
        >
          {openNext ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <div className="week-total">Week Total:</div>
        <div className="total">{msToTime(weekTotal(nextWeekData))}</div>
      </div>

      <Collapse in={openNext} timeout="auto" unmountOnExit>
        <Box>
          {nextWeekData.map((dayData, idx) => {
            if (dayData.logs.length) {
              return (
                <Card key={idx} className={classes.thisweek}>
                  <CardContent>
                    <div className="date-name">
                      <span>{moment(dayData.date).format("ddd, MMM D")}</span>
                      <div className="spent">S</div>
                      <div className="estimate">E</div>
                      <div className="day-total">
                        <span>Total:</span>
                        <span>{msToTime(dayTotal(dayData.logs))}</span>
                      </div>
                    </div>
                    {dayData.logs.map((log, key) => {
                      return (
                        <div className="week-row">
                          <TextareaAutosize
                            value={log.taskName}
                            placeholder="What are you working now?"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                return event.preventDefault();
                              }
                            }}
                          />
                          <div
                            style={{ color: log.projectColor }}
                            className="header-project"
                          >
                            <FiberManualRecordIcon />
                            <span>{log.projectTitle}</span>
                          </div>

                          <div className="header-estimate">
                            <TextareaAutosize
                              value={spentHour(log.spent)}
                              placeholder="S: 0"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  return event.preventDefault();
                                }
                              }}
                            />
                            <span>-</span>
                            <TextareaAutosize
                              value={log.estimated}
                              placeholder="E: 00:00"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  return event.preventDefault();
                                }
                              }}
                            />
                          </div>

                          <div>
                            <Timer
                              initialTime={log.spent}
                              startImmediately={false}
                              formatValue={(value) =>
                                `${value < 10 ? `0${value}` : value}`
                              }
                            >
                              {({
                                start,
                                resume,
                                pause,
                                stop,
                                reset,
                                timerState,
                              }) => (
                                <React.Fragment>
                                  <div className="header-timer">
                                    <div>{msToTime(log.spent)}</div>
                                    <div
                                      className="play-pause"
                                      onClick={
                                        runningTask._id
                                          ? () =>
                                              alert(
                                                "Please pause the active task first"
                                              )
                                          : () => handleStart(log)
                                      }
                                    >
                                      <PlayCircleOutlineIcon />
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </Timer>
                          </div>
                        </div>
                      );
                    })}
                  </CardContent>
                </Card>
              );
            }
          })}
        </Box>
      </Collapse>

      {/*Last Week*/}
      <div
        style={{ marginBottom: 48 }}
        className="this-week"
        onClick={() => setOpen(!open)}
      >
        <div>Last week</div>
        <IconButton
          aria-label="expand row"
          size="small"
          onClick={() => setOpen(!open)}
        >
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
        <div className="week-total">Week Total:</div>
        <div className="total">{msToTime(weekTotal(prevWeekData))}</div>
      </div>

      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        style={{ marginTop: -48 }}
      >
        <Box>
          {prevWeekData.map((dayData, idx) => {
            if (dayData.logs.length) {
              return (
                <Card key={idx} className={classes.lastweek}>
                  <CardContent>
                    <div className="date-name">
                      <span>{moment(dayData.date).format("ddd, MMM D")}</span>
                      <div className="spent">S</div>
                      <div className="estimate">E</div>
                      <div className="day-total">
                        <span>Total:</span>
                        <span>{msToTime(dayTotal(dayData.logs))}</span>
                      </div>
                    </div>
                    {dayData.logs.map((log, key) => {
                      return (
                        <div className="week-row">
                          <TextareaAutosize
                            value={log.taskName}
                            placeholder="What are you working now?"
                            onKeyPress={(event) => {
                              if (event.key === "Enter") {
                                return event.preventDefault();
                              }
                            }}
                          />
                          <div
                            style={{ color: log.projectColor }}
                            className="header-project"
                          >
                            <FiberManualRecordIcon />
                            <span>{log.projectTitle}</span>
                          </div>

                          <div className="header-estimate">
                            <TextareaAutosize
                              value={spentHour(log.spent)}
                              placeholder="S: 0"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  return event.preventDefault();
                                }
                              }}
                            />
                            <span>-</span>
                            <TextareaAutosize
                              value={log.estimated}
                              placeholder="E: 00:00"
                              onKeyPress={(event) => {
                                if (event.key === "Enter") {
                                  return event.preventDefault();
                                }
                              }}
                            />
                          </div>

                          <div>
                            <Timer
                              initialTime={log.spent}
                              startImmediately={false}
                              formatValue={(value) =>
                                `${value < 10 ? `0${value}` : value}`
                              }
                            >
                              {({
                                start,
                                resume,
                                pause,
                                stop,
                                reset,
                                timerState,
                              }) => (
                                <React.Fragment>
                                  <div className="header-timer">
                                    <div>{msToTime(log.spent)}</div>
                                    <div
                                      className="play-pause"
                                      onClick={
                                        runningTask._id
                                          ? () =>
                                              alert(
                                                "Please pause the active task first"
                                              )
                                          : () => handleStart(log)
                                      }
                                    >
                                      <PlayCircleOutlineIcon />
                                    </div>
                                  </div>
                                </React.Fragment>
                              )}
                            </Timer>
                          </div>
                        </div>
                      );
                    })}
                  </CardContent>
                </Card>
              );
            }
          })}
        </Box>
      </Collapse>
    </Scrollbars>
  );
}

export default connect(null, {
  startTimer,
  pauseTimer,
  updateEstimate,
  updateSpent,
})(Hourlog);
