import axios from 'axios'

import {
  ADD_WORKSPACE,
  REMOVE_WORKSPACE,
  SET_WORKSPACE,
  GET_WORKSPACE,
  SET_WORKSPACE_LOCAL,
  GET_ALL_WORKSPACES,
  SET_UPDATED_WORKSPACE
} from "../Constants";

import { setLoginError } from './userActions'

import { serverURI } from './config'
// import { func } from 'prop-types';

//const serverURI = "http://192.168.2.3:5001/api/";
//Must add 'JWT ' string before the token

export function addWorkspace(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  };

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/add-new-workspace', data)
        .then(res => {
          if (res.status === 200) {
            console.log('workspaceUserRelation', res)
            response.status = res.status
            let teamTrekkingSettings = {
              workspace: [res.data._id]
            }
            if (!data.noLocal) {
              localStorage.setItem(
                'teamTrekkingSettings',
                JSON.stringify(teamTrekkingSettings)
              )
              dispatch(newWorkspace([res.data._id]))
            }
            response.data = res.data
            response.message = 'Workspace Successfully Added'
          } else {
            response.token = ''
            response.status = 302
            response.message = res.data.message
          }
          return response
        })
        .catch(err => {
          response.status = 400
          response.message = 'err'
          return response
        })
    }
  }
}

export function getWorkspace(data, token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/get-workspace', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            dispatch(getWorkspaceDetails([res.data]))
            response.message = 'Workspace found'
          } else {
            response.token = ''
            response.status = 302
            response.message = res.data.message
          }
          return response
        })
        .catch(err => {
          response.status = 400
          response.message = err
          return response
        })
    }
  }
}

export function getAllWorkspacesUser(token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios.get(serverURI + 'get/get-workspaces-by-user').then(res => {
        if (res.status === 200) {
          response.status = res.status
          response.token = token
          dispatch(getAllWorkspaces(res.data))
          response.message = 'Workspace found'
        } else {
          response.token = ''
          response.status = 302
          response.message = res.data.message
        }
      })
    }
  }
}

export function updateWorkspace(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/update-workspace', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            response.token = token
            response.data = res.data
            dispatch(setUpdated(res.data))
            response.message = "Workspace updated successfully"
          } else {
            response.token = "";
            response.status = 302;
            response.message = res.data.message;
          }
        })
    }
  }
}

export function updateWorkspaceStatus(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/update-workspace-status', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            response.token = token
            response.data = res.data
            dispatch(setUpdated(res.data))
            response.message = "Workspace status updated successfully"
          } else {
            response.token = "";
            response.status = 302;
            response.message = res.data.message;
          }
        })
    }
  }
}

export function newWorkspace(workspaceData) {
  return {
    type: ADD_WORKSPACE,
    data: workspaceData
  }
}

export function setUpdated(workspaceData) {
  return {
    type: SET_UPDATED_WORKSPACE,
    data: workspaceData
  }
}

export function setWorkspace(workspaceData) {
  return {
    type: SET_WORKSPACE,
    data: workspaceData
  }
}

export function getWorkspaceDetails(workspaceData) {
  return {
    type: GET_WORKSPACE,
    data: workspaceData
  }
}

export function removeWorkspace(workspaceData) {
  return {
    type: REMOVE_WORKSPACE,
    data: workspaceData
  }
}

export function getAllWorkspaces(workspaces) {
  localStorage.setItem('ttkWorkspaces', JSON.stringify(workspaces))
  return {
    type: GET_ALL_WORKSPACES,
    data: workspaces
  }
}

export function setSettingsLocal() {
  if (localStorage.getItem('teamTrekkingSettings')) {
    return {
      type: SET_WORKSPACE_LOCAL,
      data: JSON.parse(localStorage.getItem('teamTrekkingSettings'))
    }
  } else {
    return {
      type: SET_WORKSPACE_LOCAL,
      data: ''
    }
  }
}
