import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import Avatar from "@material-ui/core/Avatar";
import { Link, withRouter } from "react-router-dom";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import Badge from '@material-ui/core/Badge';

import { connect } from 'react-redux'
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles((theme) => ({
    orange: {
        color: 'rgba(0,0,0,0.7)',
        backgroundColor: 'rgba(123,123,123,0.2)',
        fontSize: 12,
        marginTop: 5,
        marginLeft: 10,
        fontFamily: 'Sofia Pro',
        width: theme.spacing(4),
        height: theme.spacing(4),
    },
}));

let getRole = (roleId, roleList) => {
    let role = roleList ? roleList.find(item => item._id === roleId) : null

    if (role) return role.title
    else return 'No roles Defined'
}

function UserList(props) {
    let { user, workspace, activeTeam, teamMembers, teams, roles } = props
    const classes = useStyles();

    let userId = user ? user.userDetails ? user.userDetails.id : '' : ''

    let team = teams ? teams.find(item => item._id === activeTeam) : null

    let memberRoleId = teamMembers ? teamMembers.length ? teamMembers.find(
        item => item.userId === userId && item.teamId === activeTeam
    ) : null : null

    memberRoleId = memberRoleId ? memberRoleId.userRole : ''

    let workspaceId = workspace.workspaceDetails ? workspace.workspaceDetails[0]._id : null

    let users = activeTeam ? teamMembers ? teamMembers.length ?
        teamMembers.filter(member => member.teamId.toString() === activeTeam.toString())
        : [] : [] : []

    let workspaceUser = workspace.workspaceDetails ?
        workspace.workspaceDetails.length ? workspace.workspaceDetails[0].createdBy : null : null

    let workspaceOwner = workspaceUser ? workspaceUser : null

    let memberRole = roles ? roles.length ? roles.find(
        role => role._id === memberRoleId
    ) : null : null

    let permission = workspaceOwner === userId ? 1 : (
        memberRole ? memberRole.priority : team ? (team.createdBy === userId ? 1 : 3) : 0
    )

    let permitted = permission > 0 && permission <= 2

    // console.log('roles', roles)

    function handleSubmit() {
        props.history.push('/')
    }

    return (
        <Scrollbars style={{ height: (window.innerHeight - 100), backgroundColor: 'rgba(0,0,0,0)' }}>
            <div className="body">
                <div className="display-top" style={{ marginBottom: 24 }}>
                    <div className="title-text">Users ({users.length})</div>
                    <div className="save-button"
                        onClick={() => handleSubmit()}
                    >
                        Close
                    </div>
                </div>
                {
                    users.length ? users.map(member =>
                        <div onClick={permitted ? () => props.handleMember(member._id) : null}>
                            <div className="display-user-list editHoverBGColor">
                                {
                                    member.avatar ?
                                        <Avatar
                                            className={classes.orange}
                                            src={member.avatar}
                                        />
                                        : <Avatar className={classes.orange}>{member.shortName}</Avatar>
                                }
                                <div className="user-field">
                                    <div>{member.name}</div>
                                </div>
                                <div className="divider-line">|</div>
                                <div className="user-field2">
                                    <div>{member.email}</div>
                                </div>
                                <div className="edit-user-workspace">
                                    {workspaceOwner === member.userId ? (
                                        <Badge
                                            badgeContent={'Workspace Owner'}
                                            color='primary'
                                        />
                                    ) : null}
                                </div>
                                {workspaceOwner === member.userId ? null : (
                                    <div className="edit-user-role">
                                        <Badge
                                            badgeContent={getRole(member.userRole, roles)}
                                            color='primary'
                                        />
                                    </div>
                                )}
                                <div className="edit-user">
                                    <div style={{ marginRight: 8 }}>Edit user</div>
                                    <ArrowRightAltIcon />
                                </div>
                            </div>
                            <Divider />
                        </div>
                    ) : null
                }
            </div>
        </Scrollbars>
    )

}

function mapStateToProps(state) {
    return {
        workspace: state.settings,
        user: state.user,
        teams: state.teams ? state.teams.allTeams : [],
        activeTeam: state.teams ? state.teams.activeTeam : '',
        teamMembers: state.teamMembers ? state.teamMembers.allTeamMember : [],
        roles: state.teamMembers ? state.teamMembers.roles : []
    }
}

export default withRouter(connect(
    mapStateToProps
)(UserList));