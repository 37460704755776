import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { addUserLeave } from "../../Actions/leaveActions";
import { getWeekLog, setWeekLogs } from "../../Actions/hourLogActions";
import { allTaskUser } from "../../Actions/taskActions";
import LeaveCalander from "./LeaveCalander";
import moment from "moment";
import AvailableTasks from "./AvailableTasks";

let getDates = (start, end) => {
  let startDate = moment(start);
  let endDate = moment(end);

  let dif = endDate.diff(startDate);
  let diff = moment.duration(dif);
  let days = diff.days();

  let retDates = [];

  for (let i = 0; i <= days; i++) {
    let cur = moment(startDate).add(i, "d").format("YYYY-MM-DD");
    retDates.push(cur + "T00:00:00.000Z");
  }
  return retDates;
};

let getDetails = (taskData, logData, projectList, days) => {
  let dayLogs = [];
  let dayLen = days.length;
  for (let i = 0; i < dayLen; i++) {
    let filteredTasks = logData.filter((log) => log.date === days[i]);

    let len = filteredTasks.length;
    let taskLogs = [];

    for (let j = 0; j < len; j++) {
      let found = taskData.find(
        (task) => task.taskId === filteredTasks[j].taskId
      );
      //console.log('found', found, filteredTasks[j].taskId)
      let projectData = filteredTasks[j].projectId
        ? projectList.find(
            (project) => project._id === filteredTasks[j].projectId
          )
        : null;
      if (found) {
        let details = {
          _id: filteredTasks[j]._id,
          taskName: found.taskName,
          taskId: found.taskId,
          userId: filteredTasks[j].userId,
          spent: filteredTasks[j].spent,
          estimated: filteredTasks[j].estimated,
          run: filteredTasks[j].run,
          pause: filteredTasks[j].pause,
          projectId: filteredTasks[j].projectId,
          teamId: filteredTasks[j].teamId,
          workspaceId: filteredTasks[j].workspaceId,
          projectTitle: projectData ? projectData.projectTitle : "",
          projectColor: projectData ? projectData.color : "",
          startTime: filteredTasks[j].startTime,
          endTime: filteredTasks[j].endTime,
        };
        taskLogs.push(details);
      }
    }

    let pushData = {
      date: days[i].slice(0, 10),
      logs: taskLogs,
    };

    dayLogs.push(pushData);
  }

  return dayLogs;
};

let availableLeaves = (allLeaves, history) => {
  let leaveDetails = [];

  let len = allLeaves.length;

  for (let i = 0; i < len; i++) {
    let leaveMain = allLeaves[i];

    let found = history.filter((item) => item.leaveId === leaveMain._id);

    let leaveCount = 0;

    let flen = found.length;

    for (let j = 0; j < flen; j++) {
      leaveCount += found[j].days.length;
    }

    let pushData = {
      title: allLeaves[i].title,
      id: allLeaves[i]._id,
      available:
        allLeaves[i].count - leaveCount ? allLeaves[i].count - leaveCount : 0,
      total: allLeaves[i].count,
    };
    leaveDetails.push(pushData);
  }
  return leaveDetails;
};

let ApplyLeave = (props) => {
  let {
    addUserLeave,
    getWeekLog,
    allTaskUser,
    user,
    workspace,
    workspaceDetails,
    tasks,
    projects,
    hourLogs,
    leaves,
    disabledDays,
    allLeavesList,
    history,
  } = props;

  let token = user ? (user.token ? user.token : null) : null;
  let userId = user ? (user.userDetails ? user.userDetails.id : null) : null;
  let workspaceId = workspace ? workspace : "";
  let ownerEmail = workspaceDetails
    ? workspaceDetails.ownerEmail
      ? workspaceDetails.ownerEmail
      : ""
    : "";
  let userEmail = user ? (user.userDetails ? user.userDetails.email : "") : "";
  let allLeaves = leaves ? (leaves.allLeaves ? leaves.allLeaves : []) : [];
  let userPendings = leaves
    ? leaves.userPendings
      ? leaves.userPendings
      : []
    : [];

  let available = availableLeaves(allLeavesList, history);

  let [details, setDetails] = useState(false);
  let [description, setDescription] = useState("");
  let [leaveId, setLeaveId] = useState("");
  let [dates, setDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  let handleLeaveId = (value) => {
    setLeaveId(value);
  };

  let handleDateChange = (value) => {
    setDates(value);
  };

  let handleDescription = (value) => {
    setDescription(value);
  };

  let handleSearch = () => {
    if (token) {
      let query = {
        userId: userId,
        start: moment(dates[0].startDate).format("YYYY-MM-DD"),
        end: moment(dates[0].endDate).format("YYYY-MM-DD"),
      };

      let allTaskData = {
        workspaceId: workspaceId,
        startDate: query.start,
        endDate: query.end,
      };
      getWeekLog(query, token);
      allTaskUser(allTaskData, token).then((res) => {
        if (res) {
          if (res.data) {
            setDetails(true);
          }
        }
      });
    }
  };

  let clearAll = () => {
    setDetails(false);
    setDescription("");
    setDates([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  let cancelLeave = () => {
    setDetails(false);
    setDates([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
    ]);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <LeaveCalander
        available={available}
        handleChange={handleDateChange}
        dates={dates}
        handleLeaveId={handleLeaveId}
        allLeaves={allLeaves}
        disabledDays={disabledDays}
        desc={description}
        handleDescription={handleDescription}
        leaveId={leaveId}
      />
      <Button
        variant="contained"
        style={{ backgroundColor: "#4A9AFF", color: "white" }}
        onClick={
          leaveId.length
            ? () => handleSearch()
            : () => alert("Please select leave type")
        }
      >
        Submit
      </Button>
      <Button
        variant="outlined"
        style={{ color: "#4A9AFF", marginLeft: 5 }}
        onClick={clearAll}
      >
        Cancel
      </Button>
      <AvailableTasks
        tasks={tasks}
        ownerEmail={ownerEmail}
        userEmail={userEmail}
        history={hourLogs}
        token={token}
        projects={projects}
        dates={{
          start: moment(dates[0].startDate),
          end: moment(dates[0].endDate),
        }}
        getDetails={getDetails}
        getDates={getDates}
        workspaceId={workspaceId}
        leaveId={leaveId}
        addUserLeave={addUserLeave}
        handleLeaveId={handleLeaveId}
        setDetails={setDetails}
        userPendings={userPendings}
        open={details}
        handleOpen={setDetails}
        setDescription={setDescription}
        description={description}
        handleClose={cancelLeave}
      />
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.user ? state.user : null,
    workspace: state.settings
      ? state.settings.workspace
        ? state.settings.workspace[0]
        : null
      : null,
    workspaceDetails: state.settings
      ? state.settings.workspaceDetails
        ? state.settings.workspaceDetails[0]
        : null
      : null,
    tasks: state.tasks
      ? state.tasks.taskDetails
        ? state.tasks.taskDetails
        : []
      : [],
    projects: state.projects
      ? state.projects.allProjects
        ? state.projects.allProjects
        : []
      : [],
    hourLogs: state.hourLogs
      ? state.hourLogs.weekLogs
        ? state.hourLogs.weekLogs
        : []
      : [],
    leaves: state.leaves ? state.leaves : null,
  };
};

export default connect(mapStateToProps, {
  addUserLeave,
  getWeekLog,
  allTaskUser,
})(ApplyLeave);
