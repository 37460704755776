import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import { addProjectType } from "../../Actions/projectActions";
import { connect } from "react-redux";

import "../../Assets/CSS/EventSidebar.css";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const filter = createFilterOptions();

function ProjectManagerAC(props) {
  const { projects, handleChange, activeType } = props;

  let projectType = projects.projectTypes ? projects.projectTypes : [];

  let active = projectType.find((item) => item._id == activeType);

  const [value, setValue] = React.useState(
    active ? { title: active.projectTypeTitle } : {}
  );

  return (
    <Autocomplete
      className="customSingleSelect editHoverBGColor"
      value={value ? value.title : "Type any Project Type Name"}
      onChange={(event, newValue) => {
        if (newValue._id && newValue.status) {
          handleChange(newValue._id);
          if (newValue) {
            setValue({
              title: newValue.projectTypeTitle,
            });
            return;
          }
          setValue(newValue);
        } else {
          let data = {
            projectTypeTitle: newValue.inputValue,
            workspaceId: props.settings.workspaceDetails[0]._id,
          };
          props.addProjectType(data, props.user.token).then((res) => {
            socket.emit("updateProjectType", res.data);
            setValue({
              title: newValue.inputValue,
            });
            handleChange(res.data._id);
          });
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      options={projectType}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.projectTypeTitle) {
          return option.projectTypeTitle;
        }
        return option.projectTypeTitle;
      }}
      renderOption={(option) =>
        option.projectTypeTitle && option.status ? (
          <Typography
            style={{
              width: "100%",
              fontSize: 14,
              fontFamily: "Sofia Pro",
              fontWeight: 300,
            }}
          >
            {option.projectTypeTitle}
          </Typography>
        ) : (
          "+ Add new Project Type"
        )
      }
      style={{ width: "100%", marginRight: "1%" }}
      freeSolo
      disableClearable
      disableOpenOnFocus={false}
      forcePopupIcon={true}
      renderInput={(params) => (
        <span className="sidebarProjectName">
          <TextField
            {...params}
            style={{ width: "100%", fontSize: 12 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        </span>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : "",
    projects: state.projects,
    settings: state.settings ? state.settings : "",
  };
}

export default connect(mapStateToProps, {
  addProjectType,
})(ProjectManagerAC);
