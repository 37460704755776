import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment'
import Pagination from '@material-ui/lab/Pagination';
import { connect } from 'react-redux'
import { search } from "ss-search"
import Search from './Search'

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#5765B3',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 700
  },
});

let LeaveHistory = props => {
  const classes = useStyles();
  let { leaves } = props

  let [page, setPage] = useState(1)
  let [rowsPerPage, setRowsPerPage] = useState(10)
  let [searchText, setSearchKey] = useState('')

  let searchKey = ["userId.firstName", "userId.lastName", "userId.email"]

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  let rowData = leaves ? leaves.confirmedLeaves ? leaves.confirmedLeaves : [] : []

  let filtered = search(rowData, searchKey, searchText)

  filtered = filtered.reverse()

  return (
    <div>
      {filtered.length ? (
        <TableContainer align='center'>
          <div
            style={{
              fontSize: 18,
              textAlign: 'center',
              margin: '10px 0px'
            }}
          >
            Leaves Approved
          </div>
          <Search onSearch={setSearchKey} />
          <div style={{ marginBottom: 10 }} />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">User</StyledTableCell>
                <StyledTableCell align="center">Date(s)</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Days</StyledTableCell>
                <StyledTableCell align="center">Approved By</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.length ? (rowsPerPage > 0
                ? filtered && filtered.slice(
                  (page - 1) * rowsPerPage,
                  (page - 1) * rowsPerPage + rowsPerPage
                )
              : filtered
              ).map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell align="center">
                    {row.userId.firstName + ' ' + row.userId.lastName}
                  </StyledTableCell>
                  <StyledTableCell align="center" scope="row">
                    {row.days.map(day => {
                      return <div>{moment(day).format('DD/MM/YYYY')}</div>
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.leaveId.title}</StyledTableCell>
                  <StyledTableCell align="center">{row.days.length}</StyledTableCell>
                  <StyledTableCell align="center">
                    {row.operatedBy.firstName + ' ' + row.operatedBy.lastName}
                  </StyledTableCell>
                </StyledTableRow>
              )) : null}
            </TableBody>
            <div>
              <Pagination
                style={{height: 48, paddingTop: 8}}
                count={filtered ? filtered.length ?
                  Math.ceil(filtered.length / 10) : 0 : 0
                }
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChange={handleChangePage}
              />
            </div>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
}

let mapStateToProps = state => {
  return {
    leaves: state.leaves ? state.leaves : null
  }
}

export default connect(
  mapStateToProps
)(LeaveHistory)