import axios from 'axios'

import { setAllTeamMembers } from './teamMemberActions'

import {
  ADD_NEW_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  GET_ALL_TEAMS,
  REMOVE_TEAM_STORE,
  SET_TEAM_LOCAL, SET_USERS_LOCAL
} from '../Constants'
// import { func } from 'prop-types';

import { serverURI } from './config';
//const serverURI = "http://192.168.2.3:5001/api/";
//Must add 'JWT ' string before the token

let teamData = JSON.parse(localStorage.getItem('teamTrekkingTeams'))

export function addNewTeam(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const addTeam = await axios.post(serverURI + 'post/add-new-team', data)
      try {
        if (addTeam.status === 200) {
          response.status = addTeam.status
          response.token = token
          response.data = addTeam.data
          dispatch(setTeam(addTeam.data.team))

          if (!data.noSelf) {
            let workspaceId = { workspaceId: addTeam.data.team.workspaceId }
            const fetchMember = await axios.post(serverURI + 'post/find-all-team-members', workspaceId)
            if (fetchMember.status === 200) {
              dispatch(setAllTeamMembers(fetchMember.data))
            }
          }

          response.message = 'Team Successfully Added'
        } else {
          response.status = 302
          response.message = addTeam.data.message
        }
        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function updateTeam(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/update-team', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            response.token = token
            response.data = res.data
            dispatch(setUpdate(res.data))
            response.message = 'Team Successfully Added'
          } else {
            response.status = 302
            response.message = res.data.message
          }
          return response
        })
        .catch(err => {
          response.status = 400
          return response
        })
    }
  }
}

export function deleteTeam(data, token) {
  //data = { teamId }
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/delete-team', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            response.token = token
            dispatch(removeTeam())
            response.message = 'Team Successfully Added'
          } else {
            response.status = 302
            response.message = res.data.message
          }
          return response
        })
        .catch(err => {
          response.status = 400
          response.message = err
          return response
        })
    }
  }
}

export function getAllTeam(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      return axios
        .post(serverURI + 'post/get-all-teams', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            response.token = token
            response.data = res.data
            dispatch(getAllTeams(res.data))
            if (teamData.length) {
              dispatch(setTeamToLocal(teamData))
            } else {
              let teamTrekkingTeam = res.data[0]._id
              dispatch(setTeamToLocal(teamTrekkingTeam))
            }
            response.message = 'Teams Successfully fetched'
          } else {
            response.status = 302
            response.message = res.data.message
          }
          return response
        })
        .catch(err => {
          response.status = 400
          response.message = err
          return response
        })
    }
  }
}

export function setTeamToStore(value) {
  // console.log('Hocche', value)
  return dispatch => {
    dispatch(setTeamToLocal(value))
  }
}

export function setTeam(teamData) {
  return {
    type: ADD_NEW_TEAM,
    data: teamData
  }
}

export function setUpdate(teamData) {
  return {
    type: UPDATE_TEAM,
    data: teamData
  }
}

export function removeTeam() {
  return {
    type: DELETE_TEAM,
    data: ''
  }
}

export function getAllTeams(teamData) {
  localStorage.setItem('ttkAllTeams', JSON.stringify(teamData))
  return {
    type: GET_ALL_TEAMS,
    data: teamData
  }
}

export function setTeamToLocal(teamData) {
  localStorage.setItem('teamTrekkingTeams', JSON.stringify(teamData))
  return {
    type: SET_TEAM_LOCAL,
    data: teamData
  }
}
export function setUserToLocal(teamData) {
  localStorage.setItem('teamTrekkingUsers', JSON.stringify(teamData))
  return {
    type: SET_USERS_LOCAL,
    data: teamData
  }
}

export function removeTeamStore() {
  return {
    type: REMOVE_TEAM_STORE,
    data: ''
  }
}
