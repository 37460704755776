import {
  ADD_LEAVE_LIST,
  UPDATE_LEAVE_LIST,
  GET_LEAVE_LIST,
  INSERT_USER_LEAVE,
  UPDATE_USER_LEAVE,
  CONFIRM_USER_LEAVE,
  REJECT_USER_LEAVE,
  GET_PENDING_LEAVES,
  GET_CONFIRMED_LEAVES,
  GET_USER_HISTORY,
  GET_USER_PENDING,
  GET_USER_REJECTED
} from '../Constants/index'

let initialState = {}

let leaveReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_LEAVE_LIST:
      return {
        ...state,
        newLeaveEntry: action.data
      }
    case UPDATE_LEAVE_LIST:
      return {
        ...state,
        updatedLeave: action.data
      }
    case GET_LEAVE_LIST:
      return {
        ...state,
        allLeaves: action.data
      }
    case INSERT_USER_LEAVE:
      return {
        ...state,
        newUserLeave: action.data
      }
    case UPDATE_USER_LEAVE:
      return {
        ...state,
        updatedUserLeave: action.data
      }
    case CONFIRM_USER_LEAVE:
      return {
        ...state,
        leaveConfirmed: action.data
      }
    case REJECT_USER_LEAVE:
      return {
        ...state,
        rejectedLeave: action.data
      }
    case GET_PENDING_LEAVES:
      return {
        ...state,
        pendingLeaves: action.data
      }
    case GET_CONFIRMED_LEAVES:
      return {
        ...state,
        confirmedLeaves: action.data
      }
    case GET_USER_HISTORY:
      return {
        ...state,
        leaveHistory: action.data
      }
    case GET_USER_PENDING:
      return {
        ...state,
        userPendings: action.data
      }
    case GET_USER_REJECTED:
      return {
        ...state,
        rejectedHistory: action.data
      }

    default:
      return state
  }
}

export default leaveReducer