import React, { useState } from "react";
import { Input, Checkbox } from "@material-ui/core";
import Row from "antd/lib/row";
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { connect } from 'react-redux'
import withStyles from "@material-ui/core/styles/withStyles";

const GreenCheckbox = withStyles({
    root: {
        color: '#A0A0A0',
        '&$checked': {
            color: '#38F8C4',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

let MilestoneTasklList = (props) => {
    return(
        <>
            <GreenCheckbox
                className="subtaskCheckbox"
            />
        </>
    )
}

export default MilestoneTasklList;