/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { addProject } from "../../Actions/projectActions";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const filter = createFilterOptions();

function FreeSoloCreateOption(props) {
  const { active, projectLists, onProjectChange, handleUpdate } = props;
  let projectValue = projectLists.find((item) => item._id === active);
  let valueTitle = projectValue ? projectValue.projectTitle : "";
  const [value, setValue] = React.useState({ title: valueTitle });

  // console.log('ProjectLists', projectLists)

  let handleChange = (projectId) => {
    onProjectChange(projectId);
    if (handleUpdate) {
      handleUpdate(true);
    }
  };

  let filteredProjects = projectLists.filter(
    (project) => project.projectStatus === true
  );

  return (
    <Autocomplete
      className="customSingleSelect editHoverBGColor"
      value={value ? value.title : value}
      disableClearable
      disableOpenOnFocus={false}
      forcePopupIcon={true}
      onChange={(event, newValue) => {
        if (newValue) {
          if (newValue._id && newValue.projectStatus) {
            handleChange(newValue._id);
            if (newValue) {
              setValue({
                title: newValue.projectTitle,
              });
              return;
            }
            setValue(newValue);
          } else {
            setValue({
              title: newValue.inputValue,
            });
            let data = {
              projectTitle: newValue.inputValue,
              color: "#BDE1FA",
              workspaceId: props.settings.workspaceDetails[0]._id,
              createdBy: props.user.userDetails.id,
            };
            props.addProject(data, props.user.token).then((res) => {
              socket.emit("addProject", res.data);
              setValue({
                title: newValue.inputValue,
              });
              handleChange(res.data._id);
            });
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      disabled={props.disabled}
      options={filteredProjects}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.projectTitle && option.projectStatus) {
          return option.projectTitle;
        }
        return option.projectTitle;
      }}
      renderOption={(option) =>
        option.projectTitle && option.projectStatus ? (
          // <Typography style={{ color: `${option.color}` , width: '80%', fontSize: 14, fontFamily: 'Sofia Pro', fontWeight: 300}}>{option.projectTitle}</Typography>
          <Typography
            style={{
              width: "100%",
              fontSize: 14,
              fontFamily: "Sofia Pro",
              fontWeight: 300,
            }}
          >
            {option.projectTitle}
          </Typography>
        ) : (
          "+ Add new project"
        )
      }
      style={{ width: "100%", marginRight: "1%" }}
      freeSolo
      renderInput={(params) => (
        <span className="sidebarProjectName">
          <TextField
            {...params}
            style={{ width: "100%", fontSize: 12 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        </span>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : "",
    workspace: state.settings.workspace ? state.settings.workspace : "",
    settings: state.settings ? state.settings : "",
  };
}

export default connect(mapStateToProps, {
  addProject,
})(FreeSoloCreateOption);
