import {
  ADD_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER,
  GET_TEAM_USER,
  SET_ALL_TEAM_MEMBERS,
  SET_ALL_ROLES,
  SET_ROLE_PRIORITY
} from "../Constants";
// import { act } from "@testing-library/react";

const initialState = {};

const teamMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TEAM_MEMBER:
      return {
        ...state,
        teamMemberDetails: action.data,
      };

    case REMOVE_TEAM_MEMBER:
      return {
        ...state,
        teamMemberDetails: action.data,
      };

    case GET_TEAM_USER:
      return {
        ...state,
        addMember: action.data,
      };
    case SET_ALL_TEAM_MEMBERS:
      return {
        ...state,
        allTeamMember: action.data,
      };

    case SET_ALL_ROLES:
      return {
        ...state,
        roles: action.data,
      };
    
    case SET_ROLE_PRIORITY:
      return {
        ...state,
        permission: action.data
      }

    default:
      return state;
  }
};

export default teamMemberReducer;
