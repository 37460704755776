import {
  ADD_WORKSPACE,
  REMOVE_WORKSPACE,
  SET_WORKSPACE,
  SET_WORKSPACE_LOCAL,
  GET_WORKSPACE,
  GET_ALL_WORKSPACES,
  SET_UPDATED_WORKSPACE
} from "../Constants";
// import { act } from "@testing-library/react";

const initialState = {}

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_WORKSPACE:
      return {
        ...state,
        workspace: action.data
      };
    case SET_WORKSPACE:
      return {
        ...state,
        workspace: action.data
      };
    case REMOVE_WORKSPACE:
      return {
        ...state,
        workspace: action.data,
        workspaceDetails: action.data,

      };
    case SET_WORKSPACE_LOCAL:
      return {
        ...state,
        workspace: action.data.workspace
      }

    case GET_WORKSPACE:
      return {
        ...state,
        workspaceDetails: action.data
      }

    case GET_ALL_WORKSPACES:
      return {
        ...state,
        allWorkspaces: action.data
      }

    case SET_UPDATED_WORKSPACE:
      return {
        ...state,
        updatedWorkspace: action.data
      }

    default:
      return state;
  }
}

export default settingsReducer;