import React from "react";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const BorderLinearProgress = withStyles({
  root: {
    height: 30,
    backgroundColor: lighten("#38f0bc", 0.5),
  },
  bar: {
    borderRadius: 0,
    backgroundColor: "#38f0bc",
  },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  margin: {
    marginTop: 1,
  },
}));

export default function CustomizedProgressBars(props) {
  let classes = useStyles();

  let { value } = props;

  let completed = 0;

  let len = value.length;

  for (let i = 0; i < len; i++) {
    if (value[i].checked) completed += value[i].percentage;
  }

  return (
    <div className={classes.root}>
      <BorderLinearProgress
        className={classes.margin}
        variant="determinate"
        color="secondary"
        value={completed}
      />
    </div>
  );
}
