import React, { forwardRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import CheckIcon from "@material-ui/icons/CheckOutlined";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import AvailableLeaves from "./AvailableLeaves";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import UserTaskDetails from "./UserTaskDetails";
import { connect } from "react-redux";
import { confirmUserLeave, rejectUserLeave } from "../../Actions/leaveActions";
import Typography from "@material-ui/core/Typography";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

let getDates = (start, end) => {
  let startDate = moment(start);
  let endDate = moment(end);

  let dif = endDate.diff(startDate);
  let diff = moment.duration(dif);
  let days = diff.days();

  let retDates = [];

  for (let i = 0; i <= days; i++) {
    let cur = moment(startDate).add(i, "d").format("YYYY-MM-DD");
    retDates.push(cur + "T00:00:00.000Z");
  }
  return retDates;
};

let getDetails = (taskData, logData, projectList, days) => {
  let dayLogs = [];
  let dayLen = days.length;
  for (let i = 0; i < dayLen; i++) {
    let filteredTasks = logData.filter((log) => log.date === days[i]);

    let len = filteredTasks.length;
    let taskLogs = [];

    for (let j = 0; j < len; j++) {
      let found = taskData.find(
        (task) => task.taskId === filteredTasks[j].taskId
      );
      //console.log('found', found, filteredTasks[j].taskId)
      let projectData = filteredTasks[j].projectId
        ? projectList.find(
            (project) => project._id === filteredTasks[j].projectId
          )
        : null;
      if (found) {
        let details = {
          _id: filteredTasks[j]._id,
          taskName: found.taskName,
          taskId: found.taskId,
          userId: filteredTasks[j].userId,
          spent: filteredTasks[j].spent,
          estimated: filteredTasks[j].estimated,
          run: filteredTasks[j].run,
          pause: filteredTasks[j].pause,
          projectId: filteredTasks[j].projectId,
          teamId: filteredTasks[j].teamId,
          workspaceId: filteredTasks[j].workspaceId,
          projectTitle: projectData ? projectData.projectTitle : "",
          projectId: projectData ? projectData._id : "",
          projectColor: projectData ? projectData.color : "",
          startTime: filteredTasks[j].startTime,
          endTime: filteredTasks[j].endTime,
        };
        taskLogs.push(details);
      }
    }

    let pushData = {
      date: days[i].slice(0, 10),
      logs: taskLogs,
    };

    dayLogs.push(pushData);
  }

  return dayLogs;
};

const useStyles = makeStyles((theme) => ({
  paper: { minWidth: "900px" },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  close: {
    position: "absolute",
    right: 5,
    top: 3,
  },
  check: {
    height: 28,
    width: 28,
  },
}));

let LeaveDialog = (props) => {
  let {
    open,
    setOpen,
    available,
    tasks,
    history,
    token,
    projects,
    dates,
    leaveId,
    handleLeaveId,
    confirmUserLeave,
    rejectUserLeave,
    description,
  } = props;

  const classes = useStyles();

  let dateArray = getDates(dates.start, dates.end);

  let taskData = getDetails(tasks, history, projects, dateArray);

  let handleClose = () => {
    setOpen(false);
    handleLeaveId("");
  };

  let handleAccept = () => {
    if (token) {
      let sendData = {
        _id: leaveId,
      };
      confirmUserLeave(sendData, token).then((res) => {
        socket.emit("updateLeaves", res.data);
        setOpen(false);
        handleLeaveId("");
      });
    }
  };

  let handleReject = () => {
    if (token) {
      let sendData = {
        _id: leaveId,
      };
      rejectUserLeave(sendData, token).then((res) => {
        socket.emit("updateLeaves", res.data);
        setOpen(false);
        handleLeaveId("");
      });
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">User Leave Details</DialogTitle>
      <IconButton
        color="inherit"
        className={classes.close}
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <AvailableLeaves rowData={available} />
        {description ? (
          <div
            style={{
              marginTop: 10,
              marginLeft: "3%",
            }}
          >
            <Typography>Description: {description}</Typography>
          </div>
        ) : null}
        <div style={{ marginTop: 10 }} />
        <UserTaskDetails taskData={taskData} />
      </DialogContent>
      <DialogActions>
        <div>
          <IconButton color="primary" onClick={() => handleAccept()}>
            <CheckIcon className={classes.check} />
          </IconButton>
          <IconButton color="secondary" onClick={() => handleReject()}>
            <DeleteIcon className={classes.check} />
          </IconButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, { confirmUserLeave, rejectUserLeave })(
  LeaveDialog
);
