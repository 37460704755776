import React, { useState } from "react";
import { GithubPicker } from "react-color";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Scrollbars } from "react-custom-scrollbars";
import TextareaAutosize from "react-textarea-autosize";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import "../../Assets/CSS/ProjectDialog.css";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import SingleSelect from "../../Components/SingleSelect";
import StatusAutoComplete from "./StatusAutoComplete";
import ProjectManagers from "./ProjectManagerAC";
import ProjectTypes from "./ProjectTypeAC";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-date-picker";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LayersOutlinedIcon from "@material-ui/icons/LayersOutlined";
import InboxOutlinedIcon from "@material-ui/icons/InboxOutlined";
import MoodIcon from "@material-ui/icons/Mood";
import CustomizedProgressBars from "../../Components/CustomizedProgressBars";
import BorderLinearProgress from "../../Components/BorderLinearProgress";
import DialogActions from "@material-ui/core/DialogActions";

import { connect } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import Milestone from "./Milestone";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function AddProjectForm(props) {
  const classes = useStyles();
  let { onAdd, createdBy, workspaceId, token, open, handleClose } = props;

  let [name, setName] = useState("");
  let [backgroundColor, setColor] = useState("#BDE1FA");
  let [status, colorPikerHandler] = useState(false);
  let [isMilestone, setMilestone] = useState(false);
  let [startDate, setStart] = useState(new Date());
  let [endDate, setEnd] = useState(new Date());
  let [projectCode, setProjectCode] = useState("");
  let [projectManager, setManager] = useState("");
  let [projectType, setType] = useState("");
  let [projectStatus, setStatus] = useState("");
  let [estimated, setEstimated] = useState(4);
  let [description, setDescription] = useState("");

  let [milestones, setMilestoneState] = useState({});

  let handleStartDate = (date) => {
    setStart(date);
  };

  let handleEndDate = (date) => {
    setEnd(date);
  };

  let handleProjectCode = (event) => {
    setProjectCode(event.target.value);
  };

  let handleMilestone = (values) => {
    setMilestone(values);
  };

  let handleName = (event) => {
    setName(event.target.value);
  };

  let handleChangeComplete = (color) => {
    setColor(color.hex);
    colorPikerHandler(false);
  };

  let handleManager = (value) => {
    setManager(value);
  };

  let handleType = (value) => {
    setType(value);
  };

  let handleProjectStatus = (value) => {
    setStatus(value);
  };

  let handleEstimated = (event) => {
    setEstimated(event.target.value);
  };

  let handleDesciption = (event) => {
    setDescription(event.target.value);
  };

  let handleMilestoneState = (value) => {
    setMilestoneState(value);
  };

  let onSubmit = () => {
    let data = {
      projectTitle: name,
      color: backgroundColor,
      workspaceId: workspaceId,
      createdBy: createdBy,
      projectCode: projectCode,
      projectManager: projectManager.length ? projectManager : null,
      projectTypeId: projectType.length ? projectType : null,
      phase: projectStatus.length
        ? projectStatus
        : allStatus.length
        ? allStatus[0]._id
        : null,
      startTime: startDate,
      endTime: endDate,
      estimatedTime: estimated,
      description: description.length ? description : null,
      milestone: milestones,
    };
    onAdd(data, token).then((res) => {
      socket.emit("addProject", res.data);
      setName("");
      handleClose();
    });
  };

  let colorPikerStyles = {
    width: "327px",
    height: "32px",
    borderRadius: "0%",
    backgroundColor: backgroundColor + "80",
  };

  let colors = [
    "#f4433680",
    "#e91e6380",
    "#9c27b080",
    "#673ab780",
    "#3f51b580",
    "#2196f380",
    "#03a9f480",
    "#00bcd480",
    "#00968880",
    "#4caf5080",
    "#8bc34a80",
    "#cddc3980",
    "#ffeb3b80",
    "#ffc10780",
    "#ff980080",
    "#ff572280",
    "#79554880",
    "#607d8b80",
  ];

  let activeMembers = props.members.filter(
    (item) => item.teamId === props.activeTeam
  );

  // console.log('Task Status', props.taskStatus)

  let allStatus = props.taskStatus.allStatus ? props.taskStatus.allStatus : [];

  return (
    <React.Fragment>
      <Dialog
        className="customDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        aria-describedby="form-dialog-description"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <TextareaAutosize
              defaultValue=""
              value={name}
              onChange={handleName}
              placeholder="Add New Project"
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  return event.preventDefault();
                }
              }}
              autoFocus
            />
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 10, overflow: "auto" }}>
          <Scrollbars>
            <div className="sidebarControllerWrapper">
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <Col span={10}>
                  <span className="sidebarIcon">
                    <SlowMotionVideoIcon />
                  </span>
                  <span className="sidebarTitle">Status</span>
                </Col>
                <Col span={14}>
                  <StatusAutoComplete
                    active={allStatus.length ? allStatus[0]._id : null}
                    allStatus={allStatus}
                    statusChange={handleProjectStatus}
                    handleUpdate={null}
                  />
                  {/* {<SingleSelect
                                        defaultValue={
                                            { title: 'Ongoing', valueId: 'ongoing' }
                                        }
                                        values={[
                                            { title: 'Urgent', valueId: 'urgent' },
                                            { title: 'Paused', valueId: 'paused' },
                                            { title: 'Completed', valueId: 'completed' }
                                        ]}
                                    />} */}
                </Col>
              </Row>
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <Col span={10}>
                  <span className="sidebarIcon">
                    <InboxOutlinedIcon />
                  </span>
                  <span className="sidebarTitle">Project Code</span>
                </Col>
                <Col span={14}>
                  {
                    <TextareaAutosize
                      value={projectCode}
                      onChange={handleProjectCode}
                      placeholder="Add project code"
                      onKeyPress={(event) => {
                        if (event.key === "Enter") {
                          return event.preventDefault();
                        }
                      }}
                    />
                  }
                </Col>
              </Row>
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <Col span={10}>
                  <span className="sidebarIcon">
                    <MoodIcon />
                  </span>
                  <span className="sidebarTitle">Color</span>
                </Col>
                <Col span={14}>
                  <div
                    className="colorPikerWrapper"
                    style={colorPikerStyles}
                    onClick={() => colorPikerHandler(!status)}
                  ></div>
                  {status === true ? (
                    <GithubPicker
                      colors={colors}
                      color={backgroundColor}
                      onChangeComplete={handleChangeComplete}
                      width={327}
                    />
                  ) : null}
                </Col>
              </Row>
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <Col span={10}>
                  <span className="sidebarIcon">
                    <EventAvailableIcon />
                  </span>
                  <span className="sidebarTitle">Start - End</span>
                </Col>
                <Col span={14}>
                  <span>
                    <div style={{ display: "flex" }}>
                      <DatePicker
                        onChange={handleStartDate}
                        value={startDate}
                        clearIcon={null}
                        calendarIcon={null}
                        format="MMM dd, y"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        dayPlaceholder="DD"
                        className="sidebarCustomCalender hoverBGColor"
                        calendarClassName="sidebarCustomCalenderView"
                      />
                      <div className="highPen">-</div>
                      <DatePicker
                        onChange={handleEndDate}
                        value={endDate}
                        clearIcon={null}
                        calendarIcon={null}
                        format="MMM dd, y"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        dayPlaceholder="DD"
                        className="sidebarCustomCalender hoverBGColor"
                        calendarClassName="sidebarCustomCalenderView"
                      />
                    </div>
                  </span>
                </Col>
              </Row>
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <Col span={10}>
                  <span className="sidebarIcon">
                    <AccessTimeIcon />
                  </span>
                  <span className="sidebarTitle">Estimated Time</span>
                </Col>
                <Col span={14}>
                  {
                    <TextField
                      id="standard-number"
                      className="timePerDay editHoverBGColor"
                      value={estimated}
                      InputProps={{ inputProps: { min: 1, max: 10 } }}
                      placeholder="Estimated time"
                      onChange={handleEstimated}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disableUnderline={true}
                      autoFocus={false}
                    />
                  }
                </Col>
              </Row>
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <Col span={10}>
                  <span className="sidebarIcon">
                    <LayersOutlinedIcon />
                  </span>
                  <span className="sidebarTitle">Project Type</span>
                </Col>
                <Col span={14}>
                  {/*<StatusAutoComplete*/}
                  {/*    active={props.taskStatus[0] ? props.taskStatus[0]._id : null}*/}
                  {/*    allStatus={props.taskStatus}*/}
                  {/*    statusChange={props.handleTaskStatus}*/}
                  {/*    handleUpdate={null}*/}
                  {/*/>*/}
                  {/* {<SingleSelect
                                        defaultValue={
                                            { title: 'Website Design', valueId: 'web' }
                                        }
                                        values={[
                                            { title: 'Web Development', valueId: 'web_dev' },
                                            { title: 'Mobile Application', valueId: 'app' },
                                            { title: 'Digital Marketing', valueId: 'marketing' }
                                        ]}
                                    />} */}
                  <ProjectTypes handleChange={handleType} />
                </Col>
              </Row>
              <Row
                className="sidebarControllerRow"
                type="flex"
                align="middle"
                style={{ marginTop: 12 }}
              >
                <Col span={10}>
                  <span className="sidebarIcon">
                    <LayersOutlinedIcon />
                  </span>
                  <span className="sidebarTitle">Project Manager</span>
                </Col>
                <Col span={14}>
                  {/*<StatusAutoComplete*/}
                  {/*    active={props.taskStatus[0] ? props.taskStatus[0]._id : null}*/}
                  {/*    allStatus={props.taskStatus}*/}
                  {/*    statusChange={props.handleTaskStatus}*/}
                  {/*    handleUpdate={null}*/}
                  {/*/>*/}
                  {/* {<SingleSelect
                                        defaultValue={
                                            { title: 'Sorwar Turzo', valueId: 'm1' }
                                        }
                                        values={[
                                            { title: 'Anjan Das', valueId: 'm2' },
                                            { title: 'Masud Kaisar', valueId: 'm3' },
                                            { title: 'Taposhi Rabeya', valueId: 'm4' }
                                        ]}
                                        onChange={(e) => handleManager(e)}
                                    />} */}
                  <ProjectManagers
                    allMembers={activeMembers}
                    handleChange={handleManager}
                  />
                </Col>
              </Row>
            </div>
            <div className="sidebarDescriptionWrapper">
              <Row className="sidebarControllerRow" type="flex" align="middle">
                <TextareaAutosize
                  value={description}
                  onChange={handleDesciption}
                  placeholder="Project description"
                  //value={this.props.value}
                  //onBlur={this.props.detailsText}
                  // maxRows={10}
                  minRows={5}
                  style={{
                    backgroundColor: "#fff",
                    width: "100%",
                    fontFamily: "Sofia Pro",
                    fontSize: 14,
                    color: "#585858",
                  }}
                />
              </Row>
            </div>
            <div className="sidebarMilestoneWrapper">
              <Row className="sidebarControllerRow">
                <Col span={9} className="milestoneTop">
                  <div className="column-div">
                    <div>
                      <span>Milestone Progress</span>
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <text>Completed</text>
                    </div>
                    <div style={{ width: 210 }}>
                      <CustomizedProgressBars value={0} />
                    </div>
                    <div style={{ marginTop: 5 }}>
                      <text>20 tasks in 2 milestones</text>
                    </div>
                  </div>
                </Col>
                <Col span={9} className="milestoneProgress">
                  {
                    <div style={{ marginTop: 13 }}>
                      <div>
                        <span>Remaining</span>
                      </div>
                      <div>
                        <BorderLinearProgress value={0} />
                      </div>
                      <div style={{ marginTop: 5 }}>
                        <span>50 tasks in 5 milestones</span>
                      </div>
                    </div>
                  }
                </Col>
                <Col span={4} className="milestone">
                  {isMilestone === false ? (
                    <span
                      style={{ marginLeft: 30 }}
                      onClick={() => handleMilestone(true)}
                    >
                      Milestone
                    </span>
                  ) : (
                    <span onClick={() => handleMilestone(false)}>Hide</span>
                  )}
                </Col>
              </Row>
            </div>
            {isMilestone === true ? (
              <div>
                <Milestone
                  projectId={null}
                  milestoneState={handleMilestoneState}
                />
              </div>
            ) : (
              ""
            )}
          </Scrollbars>
        </DialogContent>
        <DialogActions>
          <div style={{ display: "flex", width: "100%", margin: 12 }}>
            <div className="text_button" onClick={onSubmit}>
              Save
            </div>
            <div className="text_button" onClick={handleClose}>
              Discard
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    taskStatus: state.taskStatus,
  };
}

export default connect(mapStateToProps)(AddProjectForm);
