import React from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { sidebar } from "./Sidebar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import UserProfile from "../../Components/UserProfile";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo_profile.svg";
import "../../Assets/CSS/MaterialAppbar.css";
import Users from "../../Components/Users";
import List from "@material-ui/core/List";
import UserList from "../../Components/Userlist";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: " #fff !important",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  title: {
    display: "none",
    marginLeft: -6,
    marginTop: 5,
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let [selected, setSelected] = React.useState(0);
  let [memberId, setMember] = React.useState(null);

  let handleSelected = (value) => {
    setSelected(value);
    if (value === 1) setMember(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let handleMember = (value) => {
    setMember(value);
  };

  const drawer = (
    <div className="nav-list" style={{ height: "100%" }}>
     <div style={{ display: 'flex', padding: 24 }}>
       <Link to="/">
         <ArrowBackIcon style={{ marginTop: 12, marginRight: 48}}/>
       </Link>
       <Typography className={classes.title} variant="h6" noWrap>
         <Link to="/">
           <img src={Logo} alt="Team Trekking Logo" />
         </Link>
       </Typography>
     </div>
      <div className={classes.toolbar} />
      <List>
        {sidebar.map((item, index) => (
          <ListItem
            button
            key={index}
            selected={selected == index}
            onClick={() => handleSelected(index)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {selected === 0 ? <UserProfile /> : null}
        {selected === 1 ? (
          memberId ? (
            <Users memberId={memberId} handleMember={handleMember} />
          ) : (
            <UserList handleMember={handleMember} />
          )
        ) : null}
        {selected === 2 ? null : null}
        {selected === 3 ? null : null}
        {selected === 4 ? null : null}
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
