import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Dashboard from "../AnotherView/AnotherView";
import MaterialAppBar from "../Header/MaterialAppBarOtherView";
import { connect } from "react-redux";
import { logOutUser, inviteUser } from "../../Actions/userActions";
import { addNewTeamMember, findUserByMail, setUserRolePriority } from "../../Actions/teamMemberActions";
import { getAllWorkspacesUser, getWorkspace } from "../../Actions/settingsActions";
import { getAllTeam } from "../../Actions/teamActions";
import { getAllProjects } from "../../Actions/projectActions";
import { getAllSubTasks } from "../../Actions/subTaskActions";
import SettingsSidebar from "../Settings/SettingsSidebar";
import Fab from "@material-ui/core/Fab";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import AddTeamMember from "../Workspace/AddTeamMember";
import Tooltip from "@material-ui/core/Tooltip";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        height: '48px !important',
        minHeight: '48px !important',
        padding: theme.spacing(0, 0),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(0),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));

function PersistentDrawerLeft(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [modalStatus, handleProjectModal] = useState(
        [
            {
                addTeamMemberModal: false,
            }
        ]
    );

    function showTeamMemberModal() {
        handleProjectModal({
            addTeamMemberModal: !modalStatus.addTeamMemberModal
        })
    }

    let permission = props.teamMembers ?
        props.teamMembers.permission ? props.teamMembers.permission : 0
        : 0

    let permitted = permission ? permission > 0 && permission <=2 : null

    const [open, setOpen] = React.useState({
        team: false,
        teamMember: false,
        project: false,
        workspace: false
    });

    useEffect(() => {
        props.setUserRolePriority(props.permission)
    }, [props.permission])

    let handleDrawerClose = () => {
        props.onOpenDrawer()
        setOpen({
            team: false,
            teamMember: false,
            project: false,
            workspace: false
        })
    }

    return (
        <div className={classes.root}>
            {props.loggedIn ? (
                <MaterialAppBar
                    state={props.state}
                    workspace={props.status}
                    drawerOpenHandel={props.onOpenDrawer}
                    workspaceList={props.userData.settings}
                    refreshPage={props.refreshPage}
                />
            ) : null}
            <Drawer
                className={classes.drawer}
                variant="persistent"
                anchor="left"
                open={props.state}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.drawerHeader}>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </div>
                <Divider />
                <SettingsSidebar
                    open={open}
                    setOpen={setOpen}
                    userData={props.userData}
                />
            </Drawer>
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: props.state,
                })}
            >
                <div style={{ height: 48 }} />
                {props.loading === false ? (
                    <Dashboard
                        status={props.status}
                        permission={permission}
                        holidays={props.holidays}
                    />
                ) : null}
                {
                    permitted ? (
                        <Tooltip title="Add member" aria-label="add">
                            <Fab size="medium"
                                 color="primary"
                                 aria-label="edit"
                                 className="fab-icon"
                                 onClick={showTeamMemberModal}
                            >
                                <PersonAddIcon />
                            </Fab>
                        </Tooltip>
                    ) : null
                }
                {
                    props.team.activeTeam ? (
                        <AddTeamMember
                            activeTeam={props.team.activeTeam}
                            allTeams={props.team.allTeams ? props.team.allTeams : []}
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            onAdd={props.addNewTeamMember}
                            search={props.findUserByMail}
                            open={modalStatus.addTeamMemberModal}
                            handleClose={showTeamMemberModal}
                            addInvited={props.inviteUser}
                        />
                    ) : null
                }
            </main>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user ? state.user : '',
        workspace: state.settings.workspace ? state.settings.workspace : '',
        team: state.teams,
        teamMembers: state.teamMembers,
        tasks: state.tasks,
        projects: state.projects,
        settings: state.settings ? state.settings : ''
    }
}

export default connect(mapStateToProps, {
    logOutUser,
    findUserByMail,
    getWorkspace,
    getAllWorkspacesUser,
    getAllTeam,
    addNewTeamMember,
    getAllProjects,
    getAllSubTasks,
    inviteUser,
    setUserRolePriority
})(PersistentDrawerLeft)
