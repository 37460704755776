import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import {sidebar} from "./Sidebarlog";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import {Link} from "react-router-dom";
import Logo from "../../Assets/images/logo_profile.svg";
import "../../Assets/CSS/MaterialAppbar.css";
import List from "@material-ui/core/List";
import moment from "moment";
import Hourlog from "./Hourlog";

import {connect} from "react-redux";
import {getWeekLog} from "../../Actions/hourLogActions";
import {allTaskUser} from "../../Actions/taskActions";
import socketIOClient from "socket.io-client";
import {serverSocket} from "../../Actions/config";

import TimerMixin from "react-timer-mixin";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import LogReport from "./LogReport";

let socket = socketIOClient(serverSocket);

let drawerWidth = 240;

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        backgroundColor: " #fff !important",
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    title: {
        display: "none",
        marginLeft: -6,
        marginTop: 5,
        color: "#fff",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: 0,
    },
}));

let getWeekRange = () => {
    let curr = new Date();
    let first = curr.getDate() - curr.getDay();

    let firstday = new Date(curr.setDate(first));
    let lastday = moment(firstday).add(7, "d").format("YYYY-MM-DD");

    return {
        start: moment(firstday).subtract(7, "d").format("YYYY-MM-DD"),
        end: moment(lastday).add(7, "d").format("YYYY-MM-DD"),
    };
};

let getWeekDates = (w) => {
    let curr = new Date();
    let first = curr.getDate() - curr.getDay();

    let today = moment(new Date()).format("YYYY-MM-DD");

    let firstday = new Date(curr.setDate(first));

    let start = moment(firstday).add(w, "d").format("YYYY-MM-DD");

    let weekDays = [];

    for (let i = 0; i < 7; i++) {
        let currentDay = moment(start).add(i, "d").format("YYYY-MM-DD");
        if (currentDay === today) {
            continue;
        } else weekDays.push(currentDay + "T00:00:00.000Z");
    }

    if (weekDays.length !== 7) {
        weekDays.unshift(today + "T00:00:00.000Z");
    }

    return weekDays;
};

let getDetails = (taskData, logData, projectList, days) => {
    let dayLogs = [];
    for (let i = 0; i < 7; i++) {
        let filteredTasks = logData.filter((log) => log.date === days[i]);

        let len = filteredTasks.length;
        let taskLogs = [];

        for (let j = 0; j < len; j++) {
            let found = taskData.find(
                (task) => task.taskId === filteredTasks[j].taskId
            );
            //console.log('found', found, filteredTasks[j].taskId)
            let projectData = filteredTasks[j].projectId
                ? projectList.find(
                    (project) => project._id === filteredTasks[j].projectId
                )
                : null;
            if (found) {
                let details = {
                    _id: filteredTasks[j]._id,
                    taskName: found.taskName,
                    taskId: found.taskId,
                    userId: filteredTasks[j].userId,
                    spent: filteredTasks[j].spent,
                    estimated: filteredTasks[j].estimated,
                    run: filteredTasks[j].run,
                    pause: filteredTasks[j].pause,
                    projectId: filteredTasks[j].projectId,
                    teamId: filteredTasks[j].teamId,
                    workspaceId: filteredTasks[j].workspaceId,
                    projectTitle: projectData ? projectData.projectTitle : "",
                    projectColor: projectData ? projectData.color : "",
                    startTime: filteredTasks[j].startTime,
                    endTime: filteredTasks[j].endTime,
                };
                taskLogs.push(details);
            }
        }

        let pushData = {
            date: days[i].slice(0, 10),
            logs: taskLogs,
        };

        dayLogs.push(pushData);
    }

    return dayLogs;
};

function HourlogNav(props) {
    let {
        window,
        getWeekLog,
        allTaskUser,
        user,
        workspace,
        projects,
        tasks,
        hourLogs,
        runningLog,
        permission
    } = props;

    let classes = useStyles();
    let theme = useTheme();
    let [mobileOpen, setMobileOpen] = useState(false);
    let [selected, setSelected] = useState(0);

    let [notify, setNotify] = useState("");

    useEffect(() => {
        let query = getWeekRange();
        if (user) {
            if (user.userDetails) {
                let token = user.token;
                query.userId = user.userDetails.id;
                let allTaskData = {
                    workspaceId: workspaceId,
                    startDate: query.start,
                    endDate: query.end,
                };
                getWeekLog(query, token);
                allTaskUser(allTaskData, token);

                socket.on("getLogs", (data) => {
                    let querySocket = getWeekRange();
                    querySocket.userId = user.userDetails.id;
                    getWeekLog(querySocket, token);
                });

                socket.on("notify", (data) => {
                    if (data === token) {
                        setNotify("Timer log has been created");
                        TimerMixin.setTimeout(() => {
                            setNotify("");
                        }, 5000);
                    }
                });
            }
        }
    }, []);

    let thisWeek = getWeekDates(0);
    let prevWeek = getWeekDates(-7);
    let nextWeek = getWeekDates(7);

    let taskList = tasks ? (tasks.taskDetails ? tasks.taskDetails : []) : [];

    let thisWeekData = hourLogs
        ? getDetails(taskList, hourLogs, projects, thisWeek)
        : [];
    let prevWeekData = hourLogs
        ? getDetails(taskList, hourLogs, projects, prevWeek)
        : [];
    let nextWeekData = hourLogs
        ? getDetails(taskList, hourLogs, projects, nextWeek)
        : [];

    let allData = prevWeekData.concat(thisWeekData.concat(nextWeekData));

    let running = [];

    allData.forEach((e) => {
        running = running.concat(
            e.logs.filter((c) => {
                return c.run === true;
            })
        );
    });

    let runningId = running[0] ? running[0]._id : "";

    let loaded = runningLog === runningId;

    let workspaceId = workspace ? workspace : "";

    let handleSelected = (value) => {
        setSelected(value);
    };

    let handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let drawer = (
        <div className="nav-list" style={{height: "100%"}}>
            <div style={{display: "flex", padding: 24}}>
                <Link to="/">
                    <ArrowBackIcon style={{marginTop: 12, marginRight: 48}}/>
                </Link>
                <Typography className={classes.title} variant="h6" noWrap>
                    <Link to="/">
                        <img src={Logo} alt="Team Trekking Logo"/>
                    </Link>
                </Typography>
            </div>
            <div className={classes.toolbar}/>
            <List>
                {sidebar.map((item, index) => (
                    <ListItem
                        button
                        key={index}
                        selected={selected === index}
                        onClick={() => handleSelected(index)}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    let container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            {/*{loaded ? (*/}
            {/*    */}
            {/*) : null}*/}
            <main className={classes.content}>
                {notify.length ? (
                    <div
                        style={{
                            width: "32%",
                            marginLeft: "50%",
                            zIndex: 100,
                            position: "fixed",
                            marginRight: 10,
                        }}
                    >
                        <Alert
                            variant="filled"
                            severity="success"
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setNotify("");
                                    }}
                                >
                                    <CloseIcon fontSize="inherit"/>
                                </IconButton>
                            }
                        >
                            {notify}
                        </Alert>
                    </div>
                ) : null}
                {selected === 0 ? (
                    <Hourlog
                        token={user ? user.token : null}
                        thisWeekData={thisWeekData}
                        nextWeekData={nextWeekData}
                        prevWeekData={prevWeekData}
                        activeTask={running[0]}
                    />
                ) : null}
                {selected === 1 && permission === 1 ?
                    <LogReport/> :
                    <div>
                        <h1>You don't have permission</h1>
                    </div>
                }
            </main>
        </div>
    );
}

HourlogNav.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

let mapStateToProps = (state) => {
    return {
        user: state.user ? state.user : null,
        workspace: state.settings
            ? state.settings.workspace
                ? state.settings.workspace[0]
                : null
            : null,
        tasks: state.tasks ? state.tasks : [],
        projects: state.projects
            ? state.projects.allProjects
                ? state.projects.allProjects
                : []
            : [],
        hourLogs: state.hourLogs
            ? state.hourLogs.weekLogs
                ? state.hourLogs.weekLogs
                : []
            : [],
        runningLog: state.hourLogs.running,
    };
};

export default connect(mapStateToProps, {getWeekLog, allTaskUser})(HourlogNav);
