import { GET_ALL_STATUS } from "../Constants";

const initialState = {}

const taskStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_STATUS:
      return {
        ...state,
        allStatus: action.data
      }

    default:
      return state
  }
}

export default taskStatusReducer