import axios from 'axios'

import { SET_ALL_ROLES, SET_USER_ROLE } from '../Constants'

import { serverURI } from './config';

export function addRole(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let addRole = await axios.post(serverURI + 'post/add-new-role', data)

      try {
        if (addRole.status === 200) {
          response.status = addRole.status
          response.token = token
          response.data = addRole.data

          response.message = 'Role Successfully Added'
        } else {
          response.status = 302
          response.message = addRole.data.message
        }

        return response
      } catch (err) {
        response.status = 500;
        response.message = err;
        return response;
      }
    }
  }
}

export function getAllRoles(token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    try {
      return async dispatch => {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let roles = await axios.get(serverURI + 'get/all-roles')
        if (roles.status === 200) {
          response.status = 200
          response.token = token
          response.data = roles.data
          response.message = 'Role fetched'
          localStorage.setItem('ttkRoles', JSON.stringify(roles.data))
          dispatch(setRoles(roles.data))
        } else {
          response.status = roles.status
          response.message = 'Error Occured'
        }

        return response
      }
    } catch (err) {
      response.status = 500
      response.message = 'Internal Server Error'

      return response
    }
  }
}

export function addNewRoleUser(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    try {
      return async dispatch => {
        console.log('Data', data)
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let addRole = await axios.post(serverURI + 'post/add-new-role-user', data)
        if (addRole.status === 200) {
          response.status = 200
          response.token = token
          response.data = addRole.data
          response.message = 'Role fetched'
          dispatch(setUserRole(addRole.data))
        } else {
          response.status = addRole.status
          response.message = 'Error Occured'
        }

        return response
      }
    } catch (err) {
      response.status = 500
      response.message = 'Internal Server Error'

      return response
    }
  }
}

export function setRoles(roleData) {
  return {
    type: SET_ALL_ROLES,
    data: roleData
  }
}

export function setUserRole(roleData) {
  return {
    type: SET_USER_ROLE,
    data: roleData
  }
}
