import React from 'react'
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import ListIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import LeaveIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';

export let sidebar = [
  {
    title: 'User Leave History',
    icon: <ListIcon />,
    id: 0
  },
  {
    title: 'Apply For Leave',
    icon: <LeaveIcon />,
    id: 1
  },
  {
    title: 'Workspace Leave and Vacation Management',
    icon: <SettingsIcon />,
    id: 2
  }
]