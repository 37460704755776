import React, { Component } from "react";
import TimerMixin from "react-timer-mixin";

import "antd/lib/style/index.less";
import "antd/lib/style/index.css";
import "antd/lib/modal/style/index.css";
import "antd/lib/button/style/index.css";
import "antd/lib/form/style/index.css";
import "antd/lib/input/style/index.css";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import { withRouter } from "react-router-dom";
// material Components
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import IconButton from "@material-ui/core/IconButton";
import TimeIcon from "@material-ui/icons/AccessTimeOutlined";
import Progress from "../../Components/Progress";
import ScrollerProgress from "../../Components/ScrollerProgressbar";
import EditProject from "./EditProject";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import AddTask from "./AddTask";
import ShowTaskDetails from "./ShowTaskDetails";
import "moment/locale/en-au";

import Scheduler, {
  SchedulerData,
  ViewTypes,
  CellUnits,
  DATE_FORMAT,
  SummaryPos,
} from "casta-scheduler";

import withDragDropContext from "./withDnDContext";
import { connect } from "react-redux";
import { setActiveUser } from "../../Actions/userActions";
import {
  addNewTeam,
  getAllTeam,
  updateTeam,
  setTeamToStore,
} from "../../Actions/teamActions";
import {
  findUserByMail,
  addNewTeamMember,
  findAllTeamMembers,
  updateTeamMember,
} from "../../Actions/teamMemberActions";
import {
  addNewTask,
  allTaskUser,
  updateTask,
  removeUserTask,
  getAllSplitTask,
} from "../../Actions/taskActions";
import {
  addProject,
  getAllProjects,
  getAllProjectTypes,
  addTaskToMilestone,
  updateProject,
} from "../../Actions/projectActions";
import { getAllStatus } from "../../Actions/taskStatusActions";
import {
  getAllWorkspacesUser,
  getWorkspace,
} from "../../Actions/settingsActions";
import {
  addLittleSubTaskMultiple,
  addSubTaskMultiple, getAllLittleSubTasks,
  getAllSubTasks,
  setToState,
} from "../../Actions/subTaskActions";
import { getAllRoles } from "../../Actions/roleActions";
import {
  addInitialLog,
  updateUserDate,
  updateEstimate,
} from "../../Actions/hourLogActions";

// Popover css
import "../../Assets/CSS/Popover.css";

// timeline Css
import "../../Assets/CSS/Timeline.css";
import "../../Assets/CSS/jannatCss.css";

// socketIO

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
let socket = socketIOClient(serverSocket);

// Temporary Component

// let settingsData = JSON.parse(localStorage.getItem('teamTrekkingSettings'))
let teamData = JSON.parse(localStorage.getItem("teamTrekkingTeams"));
let allTeamsLocal = JSON.parse(localStorage.getItem("ttkAllTeams"));
let allTeamMemberLocal = JSON.parse(localStorage.getItem("ttkTeamMembers"));
let allTaskLocal = JSON.parse(localStorage.getItem("ttkAllTask"));
let allProjectsLocal = JSON.parse(localStorage.getItem("ttkAllProjects"));
let allRolesLocal = JSON.parse(localStorage.getItem("ttkRoles"));
let allSplitsLocal = JSON.parse(localStorage.getItem("ttkSplitTasks"));

// function viewportToPixels(value) {
//     var parts = value.match(/([0-9\.]+)(vh|vw)/)
//     var q = Number(parts[1])
//     var side = window[['innerHeight', 'innerWidth'][['vh', 'vw'].indexOf(parts[2])]]
//     return side * (q / 100)
// }

class Dashboard extends Component {
  constructor(props) {
    super(props);
    let allTeams = this.props.team.allTeams
      ? this.props.team.allTeams
      : allTeamsLocal;
    let len = allTeams ? allTeams.length : 0;
    let stateList = [];
    let activeTeam = teamData
      ? teamData
      : this.props.team.activeTeam
      ? this.props.team.activeTeam
      : allTeams
      ? allTeams.length
        ? allTeams[0]._id
        : null
      : null;
    for (let i = 0; i < len; i++) {
      if (activeTeam === allTeams[i]._id) {
        if (
          allTeams[i].teamStatus &&
          allTeams[i].workspaceId === this.props.status[0]
        ) {
          let item = {
            id: allTeams[i]._id,
            name: allTeams[i].teamTitle,
            groupOnly: true,
          };
          stateList.push(item);
        }
      } else continue;
    }
    let allMembers = allTeamMemberLocal
      ? allTeamMemberLocal
      : this.props.teamMembers.allTeamMember;
    let len1 = allMembers ? allMembers.length : 0;
    let members = [];
    for (let i = 0; i < len1; i++) {
      if (allMembers[i] && activeTeam === allMembers[i].teamId) {
        if (allMembers[i].status) {
          let item = {
            id: allMembers[i]._id,
            name: allMembers[i].name,
            parentId: allMembers[i].teamId,
          };
          members.push(item);
        }
      } else continue;
    }
    stateList = stateList.concat(members);
    let schedulerData = new SchedulerData(
      moment().format(DATE_FORMAT),
      ViewTypes.Custom1,
      false,
      false,
      {
        //checkConflict: true,
        //crossResourceMove: false,
        headerEnabled: true,
        nonWorkingTimeHeadColor: "#000000",
        nonWorkingTimeHeadBgColor: "#f0f0f0",
        nonWorkingTimeBodyBgColor: "#f0f0f0",
        nonWorkingTimeBodyBgClass: "weekEnd",
        schedulerMaxHeight: window.innerHeight - 85,
        schedulerWidth: "100%",
        displayWeekend: true,
        monthCellWidth: 55,
        yearCellWidth: 55,
        weekCellWidth: 250,
        dayCellWidth: 75,
        customCellWidth: 55,
        tableHeaderHeight: 50,
        agendaResourceTableWidth: 130,
        dayResourceTableWidth: 130,
        weekResourceTableWidth: 130,
        monthResourceTableWidth: 130,
        customResourceTableWidth: 130,
        quarterResourceTableWidth: 130,
        yearResourceTableWidth: 130,
        eventItemHeight: 42,
        eventItemLineHeight: 45,
        summeryItemHeight: 20,
        summaryPos: SummaryPos.Bottom,
        resourceName: "",
        nonAgendaDayCellHeaderFormat: "M/D|HH:mm",
        nonAgendaOtherCellHeaderFormat: "D|ddd",
        scrollToSpecialMoment: false,
        views: [
          {
            viewName: "Week",
            viewType: ViewTypes.Custom,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Month",
            viewType: ViewTypes.Custom1,
            showAgenda: false,
            isEventPerspective: false,
          },
          {
            viewName: "Year",
            viewType: ViewTypes.Custom2,
            showAgenda: false,
            isEventPerspective: false,
          }
        ],
      },
      {
        getCustomDateFunc: this.getCustomDate,
        isNonWorkingTimeFunc: this.isNonWorkingTime,
        calendarPopoverEnabled: false,
        eventItemPopoverEnabled: true,
        getSummaryFunc: this.getSummary,
        getNonAgendaViewBodyCellBgColorFunc: this.getNonAgendaViewBodyCellBgColor,
        getScrollSpecialMomentFunc: this.getScrollSpecialMoment,
      }
    );
    moment.locale("en-US");
    schedulerData.setLocaleMoment(moment);
    schedulerData.setResources(stateList);
    schedulerData.config.dayStartFrom = 9;
    schedulerData.config.dayStopTo = 20;
    schedulerData.config.minuteStep = 30;
    schedulerData.config.nonAgendaSlotMinHeight = 150;
    schedulerData.config.customCellWidth = 55;
    schedulerData.config.scrollToSpecialMomentEnabled = true;

    this.state = {
      loading: true,
      eventClicked: false,
      viewModel: schedulerData,
      visible: false,
      addTeamModal: false,
      removeTeamModal: false,
      removeMember: false,
      open: false,
      addMember: false,
      newOpen: false,
      showDetails: false,
      createEvents: false,
      updateEvent: false,
      isProgress: false,
      taskStatus: "",
      id: "",
      taskId: "",
      status: "",
      title: "",
      description: "",
      taskColor: "",
      start: new Date(),
      end: new Date(),
      item: "",
      resource: "",
      resourceID: "",
      resources: [],
      shortName: "",
      parentID: "",
      milestoneId: "",
      checkedItems: new Map(),
      projectId: "",
      helloCheck: this.props.user.token,
      timePerDay: 4,
      history: {},
      viewRemaining: false,
      activeTeam: teamData ? teamData : this.props.team.activeTeam,
      subTasks: [],
      currentMonth: "",
      startDate: new Date(),
      endDate: new Date(),
      assignedTo: "",
      splitStatus: false,
      permitted: false,
      updateLog: false,
      updateEstimate: false,
      projectUpdateId: "",
      projectPopUp: false,
      onSideScroll: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    let schedulerData = this.state.viewModel;
    let workspaceSetttingsData = this.props.status[0];
    let workspaceData = { workspaceId: workspaceSetttingsData };

    this.props.getWorkspace(workspaceData, this.props.user.token);

    this.props.getAllSplitTask(workspaceData, this.props.user.token);

    this.props.getAllRoles(this.props.user.token);

    this.props.getAllTeam(workspaceData, this.props.user.token).then((res) => {
      let allTeams = this.props.team.allTeams ? this.props.team.allTeams : [];
      let len = allTeams ? allTeams.length : 0;
      let activeTeam = this.props.team.activeTeam
        ? this.props.team.activeTeam
        : res.data.length
        ? res.data[0]._id
        : allTeams.length
        ? allTeams[0]._id
        : null;
      for (let i = 0; i < len; i++) {
        if (activeTeam === allTeams[i]._id) {
          if (
            allTeams[i].teamStatus &&
            allTeams[i].workspaceId === workspaceSetttingsData
          ) {
            let item = {
              id: allTeams[i]._id,
              name: allTeams[i].teamTitle,
              groupOnly: true,
            };
            schedulerData.addResource(item);
          }
        } else continue;
      }

      this.setState({
        viewModel: schedulerData,
      });
    });

    this.props
      .findAllTeamMembers(workspaceData, this.props.user.token)
      .then((res) => {
        let allMembers = res.data;
        let len1 = allMembers ? allMembers.length : 0;
        for (let i = 0; i < len1; i++) {
          let item = {
            id: allMembers[i]._id,
            name: allMembers[i].name,
            parentId: allMembers[i].teamId,
          };
          if (allMembers[i].status) {
            schedulerData.addResource(item);
          }
        }

        this.setState({
          viewModel: schedulerData,
          loading: false,
        });
      });

    let allTaskData = {
      workspaceId: workspaceSetttingsData,
      startDate: schedulerData.startDate,
      endDate: schedulerData.endDate,
    };

    let userWorkTok = {
      //userId: this.props.user.userDetails.id,
      workspaceId: this.props.status[0],
    };

    this.props.getAllSubTasks(userWorkTok, this.props.user.token);

    this.props.getAllProjects(userWorkTok, this.props.user.token);

    this.props.getAllStatus(this.props.user.token).then((res) => {
      this.setState({ taskStatus: res.data[0]._id });
    });

    let taskLen = allTaskLocal ? allTaskLocal.length : 0;

    if (taskLen) {
      let taskData = allTaskLocal ? allTaskLocal : [];
      if (allProjectsLocal) {
        let taskLists = taskData.map((item) => {
          let group =
            item.projectId && allProjectsLocal
              ? allProjectsLocal.find(
                  (project) => project._id === item.projectId
                )
              : null;
          let groupName = group ? group.projectTitle : "";
          let color = group ? (group.color ? group.color : null) : null;
          let taskDataDet = {
            id: item._id,
            title: item.taskName,
            start: item.startingDate,
            end: item.deadLine,
            bgColor: color ? color : item.colorData,
            taskId: item.taskId,
            projectId: item.projectId ? item.projectId : "",
            groupName: groupName,
            showPopover: true,
            resourceId: item.resourceId ? item.resourceId : item.assignedTo,
            groupId: item.assignedTo,
            timePerDay: item.timePerDay,
            splitStatus: item.splitStatus,
            descriptionData: item.descriptionData,
            statusId: item.statusId,
          };
          return taskDataDet;
        });
        if (taskData.length) {
          schedulerData.setEvents(taskLists);
        } else {
          schedulerData.setEvents(taskData);
        }
        this.setState({
          viewModel: schedulerData,
          loading: false,
        });
      }
    } else {
      this.props.allTaskUser(allTaskData, this.props.user.token).then((res) => {
        let taskData = res.data;
        let taskLists = taskData.map((item) => {
          let group =
            item.projectId && this.props.projects.allProjects
              ? this.props.projects.allProjects.find(
                  (project) => project._id === item.projectId
                )
              : null;
          let groupName = group ? group.projectTitle : "";
          let color = group ? (group.color ? group.color : null) : null;
          let taskDataDet = {
            id: item._id,
            title: item.taskName,
            start: item.startingDate,
            end: item.deadLine,
            bgColor: color ? color : item.colorData,
            taskId: item.taskId,
            projectId: item.projectId ? item.projectId : "",
            groupName: groupName,
            showPopover: true,
            resourceId: item.resourceId ? item.resourceId : item.assignedTo,
            groupId: item.assignedTo,
            timePerDay: item.timePerDay,
            splitStatus: item.splitStatus,
            descriptionData: item.descriptionData,
            statusId: item.statusId,
          };
          return taskDataDet;
        });

        if (taskData.length) {
          schedulerData.setEvents(taskLists);
        } else {
          schedulerData.setEvents(taskData);
        }
        this.setState({
          viewModel: schedulerData,
          loading: false,
        });
      });
    }

    this.props.findAllTeamMembers(workspaceData, this.props.user.token);

    this.props.allTaskUser(allTaskData, this.props.user.token);

    socket.on("taskDataSOCK", (data) => {
      // schedulerData.events = schedulerData.events.filter(
      //   (item) => item.taskId !== 2 && item.id !== 1
      // );

      this.props.allTaskUser(allTaskData, this.props.user.token);
      schedulerData.addEvent(data);

      this.setState({
        viewModel: schedulerData,
      });
      if (data.token === this.props.user.token) {
        this.setState({ newOpen: false });
      }
    });

    socket.on("updateTask", (data) => {
      this.props.allTaskUser(allTaskData, this.props.user.token);
      if (data.token !== this.props.user.token) {
        // schedulerData.events = schedulerData.events.filter(
        //   (item) => item.taskId !== data.taskId && item.id !== data.id
        // );
        // console.log(data, "=====SchedularData====")
        schedulerData.events = schedulerData.events.filter(
          (item) => item.taskId !== data.taskId
        );

        schedulerData.addEvent(data);
        this.setState({
          viewModel: schedulerData,
        });
      }
    });

    socket.on("removeTask", (data) => {
      this.props.allTaskUser(allTaskData, this.props.user.token);
      let events = schedulerData.events.filter(
        (item) => item.taskId !== data.taskId
      );
      // console.log('Event', data, events)
      schedulerData.setEvents(events);
      this.setState({ viewModel: schedulerData });
    });

    socket.on("updateStart", (data) => {
      if (data.token !== this.props.user.token) {
        schedulerData.events = schedulerData.events.filter(
          (item) => item.taskId !== data.event.taskId
        );
        schedulerData.updateEventStart(data.event, data.newStart);

        this.setState({
          viewModel: schedulerData,
          showDetails: false,
        });
        this.props.allTaskUser(allTaskData, this.props.user.token);
      }
      // this.updateStartSocket(data.event, schedulerData, data.newStart)
    });

    socket.on("updateEnd", (data) => {
      //console.log('Update End Data', data)
      if (data.token !== this.props.user.token) {
        schedulerData.events = schedulerData.events.filter(
          (item) => item.id !== data.event.id
        );
        let pushEvent = {
          id: data.event.id,
          title: data.event.title,
          start: data.newStart,
          end: data.newEnd,
          projectId: data.event.projectId,
          groupName: data.event.groupName,
          descriptionData: data.event.descriptionData,
          bgColor: data.event.bgColor,
          showPopover: data.event.showPopover,
          resourceId: data.event.resourceId,
          timePerDay: data.event.timePerDay,
          taskId: data.event.taskId,
          splitStatus: data.splitStatus,
        };
        schedulerData.addEvent(pushEvent);

        this.setState({
          viewModel: schedulerData,
          showDetails: false,
        });
        this.props.allTaskUser(allTaskData, this.props.user.token);
      }
      // this.props.allTaskUser(allTaskData, this.props.user.token).then(res => {
      //     let taskData = res.data
      //     let taskLists = taskData.map(item => {
      //         let group = item.projectId && this.props.projects.allProjects ?
      //             this.props.projects.allProjects.find(project => project._id === item.projectId) : null
      //         let groupName = group ? group.projectTitle : ''
      //         let color = group ? (group.color ? group.color : null) : null
      //         let taskDataDet = {
      //             id: item._id,
      //             title: item.taskName,
      //             start: item.startingDate,
      //             end: item.deadLine,
      //             bgColor: color ? color : item.colorData,
      //             taskId: item.taskId,
      //             projectId: item.projectId ? item.projectId : '',
      //             groupName: groupName,
      //             showPopover: true,
      //             resourceId: item.resourceId ? item.resourceId : item.assignedTo,
      //             groupId: item.assignedTo,
      //             timePerDay: item.timePerDay
      //         }
      //         return taskDataDet;
      //     })

      //     if (taskData.length) {
      //         schedulerData.setEvents(taskLists);
      //     } else {
      //         schedulerData.setEvents(taskData)
      //     }
      //     this.setState({
      //         viewModel: schedulerData,
      //         loading: false
      //     })

      // })
    });

    socket.on("moveEvent", (data) => {
      if (data.token !== this.props.user.token) {
        schedulerData.events = schedulerData.events.filter(
          (item) => item.id !== data.event.id
        );
        schedulerData.moveEvent(
          data.event,
          data.slotId,
          data.slotName,
          data.start,
          data.end
        );
        this.setState({
          viewModel: schedulerData,
          showDetails: false,
        });
        this.props.allTaskUser(allTaskData, this.props.user.token);
      }
    });

    socket.on("addTeam", (data) => {
      // this.props.team.allTeams.push(data.team)
      if (workspaceData.workspaceId === data.workspaceId) {
        // console.log('addTeamSocket', data.workspaceId, workspaceData.workspaceId)
        this.props.getAllTeam(workspaceData, this.props.user.token);
      }
      // let allTeams = this.props.team.allTeams ? this.props.team.allTeams : []
      // let activeTeam = this.props.team.activeTeam
      // if (activeTeam === data.team._id) {
      //     let item = {
      //         id: data.team._id,
      //         name: data.team.teamTitle,
      //         groupOnly: true
      //     }
      //     schedulerData.addResource(item)
      //     let member = {
      //         id: data.member._id,
      //         name: data.user.firstName + ' ' + data.user.lastName,
      //         parentId: data.member.teamId
      //     }
      //     schedulerData.addResource(member)
      //     this.setState({ viewModel: schedulerData })
      // }
    });

    socket.on("removeTeam", (data) => {
      localStorage.removeItem("ttkAllTeams");
      this.props.getAllTeam(workspaceData, this.props.user.token);
    });

    socket.on("addWorkspace", (data) => {
      localStorage.removeItem("ttkWorkspaces");
      this.props.getAllWorkspacesUser(this.props.user.token);
    });

    socket.on("addMember", (data) => {
      // console.log('Data', data)
      this.props.findAllTeamMembers(workspaceData, this.props.user.token);
      let activeTeam = this.props.team.activeTeam;
      // console.log('Active team', activeTeam, data.member.teamId, allTeams[0]._id)
      if (activeTeam === data.member.teamId) {
        let member = {
          id: data.member._id,
          name: data.user.firstName + " " + data.user.lastName,
          parentId: data.member.teamId,
        };
        schedulerData.addResource(member);
        this.setState({ viewModel: schedulerData });
      }
    });

    socket.on("removeMember", (data) => {
      this.props.findAllTeamMembers(workspaceData, this.props.user.token);
      let newMembers = schedulerData.resources.filter(
        (item) => item.id !== data.id
      );

      schedulerData.setResources([]);

      schedulerData.setResources(newMembers);

      this.setState({
        viewModel: schedulerData,
      });
    });

    socket.on("addProject", (data) => {
      this.props.projects.allProjects.push(data);
      this.props.getAllProjects(userWorkTok, this.props.user.token);
    });

    socket.on("clicked", (data) => {
      let events = schedulerData.events;
      schedulerData.setEvents(events);
      this.setState({
        viewModel: schedulerData,
      });
    });

    socket.on("updateProject", (data) => {
      this.props.getAllProjects(userWorkTok, this.props.user.token);

      this.props.allTaskUser(allTaskData, this.props.user.token).then((res) => {
        let taskData = res.data;
        let taskLists = taskData.map((item) => {
          let group =
            item.projectId && this.props.projects.allProjects
              ? this.props.projects.allProjects.find(
                  (project) => project._id === item.projectId
                )
              : null;
          let groupName = group ? group.projectTitle : "";
          let color = group ? (group.color ? group.color : null) : null;
          let taskDataDet = {
            id: item._id,
            title: item.taskName,
            start: item.startingDate,
            end: item.deadLine,
            bgColor: color ? color : item.colorData,
            taskId: item.taskId,
            projectId: item.projectId ? item.projectId : "",
            groupName: groupName,
            showPopover: true,
            resourceId: item.resourceId,
            groupId: item.assignedTo,
            timePerDay: item.timePerDay,
            descriptionData: item.descriptionData,
            statusId: item.statusId,
          };
          return taskDataDet;
        });
        if (this.props.projects.selectProject) {
          let filteredList = taskLists.filter(
            (item) => item.projectId === this.props.projects.selectProject
          );
          schedulerData.setEvents(filteredList);
          this.setState({
            viewModel: schedulerData,
          });
        } else {
          schedulerData.setEvents(taskLists);
          this.setState({
            viewModel: schedulerData,
          });
        }
      });
    });

    socket.on("updateProjectType", (data) => {
      this.props.getAllProjectTypes(userWorkTok, this.props.user.token);
    });

    socket.on("switchTeam", (data) => {
      this.props.findAllTeamMembers(workspaceData, this.props.user.token);
      let allTeams = this.props.team.allTeams ? this.props.team.allTeams : [];
      let len = allTeams ? allTeams.length : 0;
      let stateList = [];
      let activeTeam = data;
      for (let i = 0; i < len; i++) {
        if (activeTeam === allTeams[i]._id) {
          if (
            allTeams[i].teamStatus &&
            allTeams[i].workspaceId === this.props.status[0]
          ) {
            let item = {
              id: allTeams[i]._id,
              name: allTeams[i].teamTitle,
              groupOnly: true,
            };
            stateList.push(item);
          }
        } else continue;
      }
      let allMembers = this.props.teamMembers.allTeamMember;
      let len1 = allMembers ? allMembers.length : 0;
      let members = [];
      for (let i = 0; i < len1; i++) {
        if (activeTeam === allMembers[i].teamId) {
          if (allMembers[i].status) {
            let item = {
              id: allMembers[i]._id,
              name: allMembers[i].name,
              parentId: allMembers[i].teamId,
            };
            members.push(item);
          }
        } else continue;
      }
      stateList = stateList.concat(members);
      schedulerData.setResources(stateList);

      let taskLists = this.props.tasks.taskDetails.map((item) => {
        let group =
          item.projectId && this.props.projects.allProjects
            ? this.props.projects.allProjects.find(
                (project) => project._id === item.projectId
              )
            : null;
        let groupName = group ? group.projectTitle : "";
        let color = group ? (group.color ? group.color : null) : null;
        let taskDataDet = {
          id: item._id,
          title: item.taskName,
          start: item.startingDate,
          end: item.deadLine,
          bgColor: color ? color : item.colorData,
          taskId: item.taskId,
          projectId: item.projectId ? item.projectId : "",
          groupName: groupName,
          showPopover: true,
          resourceId: item.resourceId,
          groupId: item.assignedTo,
          timePerDay: item.timePerDay,
          splitStatus: item.splitStatus,
          statusId: item.statusId,
        };
        return taskDataDet;
      });

      if (this.props.projects.selectProject) {
        let filteredList = taskLists.filter(
          (item) => item.projectId === this.props.projects.selectProject
        );
        schedulerData.setEvents(filteredList);
        this.setState({
          viewModel: schedulerData,
        });
      } else {
        schedulerData.setEvents(taskLists);
        this.setState({
          viewModel: schedulerData,
        });
      }
    });

    socket.on("switchProject", (data) => {
      let taskLists = this.props.tasks.taskDetails.map((item) => {
        let group =
          item.projectId && this.props.projects.allProjects
            ? this.props.projects.allProjects.find(
                (project) => project._id === item.projectId
              )
            : null;
        let groupName = group ? group.projectTitle : "";
        let color = group ? (group.color ? group.color : null) : null;
        let taskDataDet = {
          id: item._id,
          title: item.taskName,
          start: item.startingDate,
          end: item.deadLine,
          bgColor: color ? color : item.colorData,
          taskId: item.taskId,
          projectId: item.projectId ? item.projectId : "",
          groupName: groupName,
          showPopover: true,
          resourceId: item.resourceId,
          groupId: item.assignedTo,
          timePerDay: item.timePerDay,
          splitStatus: item.splitStatus,
          descriptionData: item.descriptionData,
          statusId: item.statusId,
        };
        return taskDataDet;
      });

      if (this.props.projects.selectProject) {
        let filteredList = taskLists.filter(
          (item) => item.projectId === this.props.projects.selectProject
        );
        schedulerData.setEvents(filteredList);
        this.setState({
          viewModel: schedulerData,
        });
      } else {
        schedulerData.setEvents(taskLists);
        this.setState({
          viewModel: schedulerData,
        });
      }
    });

    socket.on("updateSubtask", (data) => {
      this.props.getAllSubTasks(userWorkTok, this.props.user.token);
    });

    socket.on("taskUpdating", (data) => {
      this.props.setActiveUser(data);
    });
  }

  getSummary = (
    schedulerData,
    headerEvents,
    slotId,
    slotName,
    headerStart,
    headerEnd
  ) => {
    let text = 0;
    let color = "#d9d9d9";

    if (this.state) {
      if (this.state.viewRemaining) {
        if (headerEvents.length > 0) {
          let len = headerEvents.length;
          for (let i = 0; i < len; i++) {
            let item = headerEvents[i];
            text += parseInt(item.timePerDay);
          }
          let checkCol = text - 9;
          text = text - 9 + "h";
          // text = headerEvents.id;
          color = checkCol <= 0 ? "green" : "red";
          return { text: text, color: color, fontSize: "12px" };
        } else return;
      }
    } else return;
  };

  getScrollSpecialMoment(schedulerData, startMoment, endMoment) {
    var localeMoment = schedulerData.localeMoment;
    //console.log('LocaleMoment', localeMoment())
    return localeMoment();
  }

  handleProjectUpdateId = (value) => {
    this.setState({ projectUpdateId: value, projectPopUp: true });
  };

  handleProjectClose = () => {
    this.setState({ projectUpdateId: "", projectPopUp: false });
  };

  getCustomDate = (schedulerData, num, date = undefined) => {
    const { viewType } = schedulerData;
    let selectDate = schedulerData.startDate;

    if (date !== undefined) selectDate = date;

    let startDate =
        num === 0
          ? selectDate
          : schedulerData
              .localeMoment(selectDate)
              .add(2 * num, "days")
              .format(DATE_FORMAT),
      endDate = schedulerData
        .localeMoment(startDate)
        .add(1, "days")
        .format(DATE_FORMAT),
      cellUnit = CellUnits.Hour;
    if (viewType === ViewTypes.Custom1) {
      let firstDayOfMonth = schedulerData
        .localeMoment(selectDate)
        .startOf("month")
        .subtract(1, "months")
        .format(DATE_FORMAT);
      startDate =
        num === 0
          ? firstDayOfMonth
          : num > 0
          ? schedulerData
              .localeMoment(firstDayOfMonth)
              .startOf("month")
              .add(5, "months")
              .format(DATE_FORMAT)
          : schedulerData
              .localeMoment(firstDayOfMonth)
              .endOf("month")
              .subtract(21, "days")
              .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(3, "months")
        .endOf("month")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    } else if (viewType === ViewTypes.Custom2) {
      let firstDayOfMonth = schedulerData
        .localeMoment(selectDate)
        .startOf("month")
        .subtract(1, "months")
        .format(DATE_FORMAT);
      startDate =
        num === 0
          ? firstDayOfMonth
          : schedulerData
              .localeMoment(firstDayOfMonth)
              .add(1 * num, "months")
              .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(10, "months")
        .endOf("month")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    } else if (viewType === ViewTypes.Custom) {
      let monday = schedulerData
        .localeMoment(selectDate)
        .startOf("week")
        .format(DATE_FORMAT);
      startDate =
        num === 0
          ? monday
          : schedulerData
              .localeMoment(monday)
              .add(1 * num, "weeks")
              .format(DATE_FORMAT);
      endDate = schedulerData
        .localeMoment(startDate)
        .add(12, "weeks")
        .endOf("week")
        .format(DATE_FORMAT);
      cellUnit = CellUnits.Day;
    }
    // console.log('CellUnit', cellUnit)

    this.setState({ startDate: startDate, endDate: endDate });

    return {
      startDate,
      endDate,
      cellUnit,
    };
  };

  isNonWorkingTime = (schedulerData, time) => {
    const { localeMoment } = schedulerData;
    let t = localeMoment(time).format("YYYY-MM-DD");
    let isHoliday = this.props.holidays.find(
      (item) => localeMoment(item.day).format("YYYY-MM-DD") === t
    );

    if (schedulerData.cellUnit === CellUnits.Hour) {
      let hour = localeMoment(time).hour();
      if (hour < 1) return true;
    } else {
      let dayOfWeek = localeMoment(time).weekday();
      if (dayOfWeek === 5 || dayOfWeek === 6) return true;
    }

    if (isHoliday) return true;

    return false;
  };

  getNonAgendaViewBodyCellBgColor = (schedulerData, slotId, header) => {
    let now = new Date();
    now = moment(now).format("YYYY-MM-DD");
    let headerTime = header.time.split(" ");
    if (headerTime[0] === now) {
      return "#C0EFEF";
    }
    return undefined;
  };

  handleViewRemaining = (schedulerData) => {
    this.setState({ viewRemaining: !this.state.viewRemaining }, () => {
      let events = schedulerData.events;
      schedulerData.setEvents(events);
      this.setState({
        viewModel: schedulerData,
      });
    });
  };

  handleChange = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    this.setState((prevState) => ({
      checkedItems: prevState.checkedItems.set(item, isChecked),
    }));
  };

  labelClickHandler = (e) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    // console.log("Item, checked", item, isChecked);
  };

  // Active date function

  nonAgendaCellHeaderTemplateResolver = (
    schedulerData,
    item,
    formattedDateItems,
    style,
    isCurrent
  ) => {
    let datetime = schedulerData.localeMoment(item.time);
    let isCurrentDate = false;

    if (schedulerData.viewType === ViewTypes.Day) {
      isCurrentDate = datetime.isSame(new Date(), "hour");
    } else {
      isCurrentDate = datetime.isSame(new Date(), "day");
    }

    if (isCurrentDate) {
      style.backgroundColor = "#C0EFEF";
      style.color = "black";
    } else {
      style.color = "rgba(40, 44, 52, 0.56)";
    }

    // if(isCurrent) {
    //   console.log('formatted Items', formattedDateItems)
    // }

    return (
      <th key={item.time} className={`header3-text`} style={style}>
        {isCurrent === true
          ? formattedDateItems.map((formattedItem, index) => (
              <div
                key={index}
                style={{ color: "red", fontSize: 12 }}
                dangerouslySetInnerHTML={{
                  __html: formattedItem
                    .toUpperCase()
                    .replace(/[0-9]/g, "<b>$&</b>"),
                }}
              />
            ))
          : formattedDateItems.map((formattedItem, index) => (
              <div
                key={index}
                dangerouslySetInnerHTML={{
                  __html: formattedItem
                    .toUpperCase()
                    .replace(/[0-9]/g, "<b>$&</b>"),
                }}
              />
            ))}
      </th>
    );
  };

  // Summery

  handleOpsClick = (schedulerData, event) => {
    let index = schedulerData.resources.findIndex((p) => {
      return p.id === event.resourceId;
    });
    let person = { ...schedulerData.resources[index] };

    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;

    this.setState({
      open: true,
      id: event.id,
      taskId: event.taskId,
      status: event.status,
      title: event.title,
      projectId: event.projectId ? event.projectId : "",
      description: event.descriptionData,
      start: "",
      end: "",
      item: event,
      resource: person.name,
      resourceID: event.resourceId,
      permitted: permitted,
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleNewAddClose = (token) => {
    if (token === this.props.user.token) {
      this.setState({ newOpen: false });
    }
  };

  handleNewAddClickOpen = (schedulerData, slotId, slotName, start, end) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({
      newOpen: permitted,
      start: start,
      end: end,
      resource: slotName,
      resourceID: slotId,
      projectId: "",
      item: {},
      taskColor: "",
      title: "",
      id: "",
      taskId: "",
      permitted: permitted,
    });
  };

  handleTitleChange = (event, value) => {
    this.setState({ title: event.target.value });
    if (value !== event.target.value) {
      this.setState({ updateEvent: true });
    }
    this.handleUpdateConfirm(
      this.state.start,
      this.state.end,
      this.state.resourceID,
      this.state.resource
    );
  };

  handleUpdateEventChange = (value) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({ updateEvent: value });
    }
  };

  handleDescriptionChange = (event) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({
        description: event.target.value,
        updateEvent: true,
      });
      this.handleUpdateConfirm(
        this.state.start,
        this.state.end,
        this.state.resourceID,
        this.state.resource
      );
    }
  };

  handleStartChange = (date) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({ start: date });
      this.handleUpdateConfirm(
        date,
        this.state.end,
        this.state.resourceID,
        this.state.resource
      );
    }
  };

  handleEndChange = (date) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({ end: date });
      this.handleUpdateConfirm(
        this.state.start,
        date,
        this.state.resourceID,
        this.state.resource
      );
    }
  };

  handleUpdateConfirm = (start, end, resourceID, resource) => {
    const startDate = moment(start).format("YYYY-MM-DD HH:mm:ss");
    const endDate = moment(end).format("YYYY-MM-DD HH:mm:ss");
    let schedulerData = this.state.viewModel;
    schedulerData.moveEvent(
      this.state.item,
      resourceID,
      resource,
      startDate,
      endDate
    );
    this.setState({
      viewModel: schedulerData,
    });
  };

  handleTitleCreate = (event) => {
    this.setState({
      title: event.target.value,
      createEvents: true,
    });
  };

  handleDescriptionCreate = (event) => {
    this.setState({
      description: event.target.value,
      createEvents: true,
    });
  };

  handleStartCreate = (date) => {
    this.setState({ start: date, createEvents: true, updateLog: true });
    // console.log("Start DateTime:", date);
  };

  handleStartUpdate = (date) => {
    this.setState({ startUpdate: date });
  };

  handleEndCreate = (date) => {
    this.setState({ end: date, createEvents: true, updateLog: true });
    // console.log("End DateTime:", date);
  };

  handleEndUpdate = (date) => {
    this.setState({ endUpdate: date });
  };

  handleProjectId = (value) => {
    this.setState({
      projectId: value,
      createEvents: true,
      updateLog: true,
    });
  };

  handleMilestoneId = (value) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({
        milestoneId: value,
        createEvents: true,
      });
    }
  };

  handleTaskStatus = (value) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({ taskStatus: value });
    }
  };

  handleDiscard = () => {
    this.setState({ newOpen: false });
  };

  handleMember = (resource, assigned) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({
        resourceID: resource,
        assignedTo: assigned,
        updateLog: true,
      });
    }
  };

  handleCreateConfirm = () => {
    if (this.state.createEvents === true || this.state.eventClicked) {
      let schedulerData = this.state.viewModel;
      let newFreshId = 0;

      let permission = this.props.teamMembers
        ? this.props.teamMembers.permission
          ? this.props.teamMembers.permission
          : 0
        : 0;

      let permitted = permission > 0 && permission <= 3;
      this.setState({ permitted: permitted });

      schedulerData.events.forEach((item) => {
        if (item.id >= newFreshId) newFreshId = item.id + 1;
      });

      const startDate = moment(this.state.start).format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment(this.state.end).format("YYYY-MM-DD HH:mm:ss");

      let assigned = this.props.teamMembers
        ? this.props.teamMembers.allTeamMember
          ? this.props.teamMembers.allTeamMember.find(
              (item) => item._id === this.state.resourceID
            )
          : null
        : null;

      let group =
        this.state.projectId.length && this.props.projects.allProjects
          ? this.props.projects.allProjects.find(
              (project) => project._id === this.state.projectId
            )
          : null;
      let groupName = group ? group.projectTitle : "";
      let color = group ? (group.color ? group.color : null) : null;

      let team = this.props.team
        ? this.props.team.activeTeam
          ? this.props.team.allTeams
            ? this.props.team.allTeams.find(
                (team) =>
                  team._id.toString() === this.props.team.activeTeam.toString()
              )
            : null
          : null
        : null;

      let newTask = {
        taskName: this.state.title,
        startTime: startDate,
        deadLine: endDate,
        assignedTo: assigned ? assigned.userId : null,
        colorData: color ? color : "rgba(190, 222, 248, 0.45)",
        projectId: this.state.projectId,
        createdBy: this.props.user.userDetails.id,
        groupName: groupName,
        descriptionData: this.state.description,
        email: assigned ? assigned.email : null,
        name: assigned ? assigned.name : null,
        workspaceId: this.props.workspace[0],
        workspaceName: this.props.workspaceDetails
          ? this.props.workspaceDetails
            ? this.props.workspaceDetails[0].workspace
            : ""
          : "",
        resourceId: this.state.resourceID,
        timePerDay: this.state.timePerDay,
        team: team ? team.teamTitle : "",
        statusId: this.state.taskStatus
          ? this.state.taskStatus.length
            ? this.state.taskStatus
            : this.props.taskStatus.allStatus[0]._id
          : this.props.taskStatus.allStatus[0]._id,
        userTaskStatus: 1,
        splitStatus: this.state.splitStatus,
      };

      if (permitted) {
        let frontendTaskData = {
          id: 1,
          title: newTask.taskName,
          start: startDate,
          end: endDate,
          projectId: newTask.projectId ? newTask.projectId : "",
          bgColor: color ? color : undefined,
          descriptionData: newTask.descriptionData,
          groupName: groupName,
          showPopover: true,
          resourceId: this.state.resourceID,
          assignedTo: this.state.assignedTo,
          taskId: 2,
          timePerDay: newTask.timePerDay,
          splitStatus: this.state.splitStatus,
          token: this.props.user.token,
        };

        // console.log("NewTask Color", frontendTaskData);

        // schedulerData.addEvent(frontendTaskData);

        // this.setState({
        //   viewModel: schedulerData,
        //   newOpen: false,
        // });
        this.props.addNewTask(newTask, this.props.user.token).then((res) => {
          // console.log('New task', res.data)
          // let group = newTask.projectId.length && this.props.projects.allProjects ?
          //     this.props.projects.allProjects.find(project => project._id === newTask.projectId) : null
          // let groupName = group ? group.projectTitle : ''
          // let color = group ? (group.color ? group.color : null) : null
          let customizeTaskData = {
            id: res.data._id,
            title: res.data.taskName,
            start: res.data.startingDate,
            end: res.data.deadLine,
            projectId: newTask.projectId ? newTask.projectId : "",
            bgColor: color ? color : res.data.colorData,
            descriptionData: res.data.descriptionData,
            groupName: groupName,
            showPopover: true,
            resourceId: this.state.resourceID,
            assignedTo: this.state.assignedTo,
            taskId: res.data.taskId,
            timePerDay: res.data.timePerDay,
            splitStatus: res.data.splitStatus,
            statusId: res.data.statusId,
            token: this.props.user.token,
          };

          // console.log('Customize taskData', customizeTaskData)

          let hourLogData = {
            taskId: res.data.taskId,
            userId: newTask.assignedTo,
            projectId: newTask.projectId,
            workspaceId: this.props.workspace[0],
            teamId: this.props.team.activeTeam,
            startTime: customizeTaskData.start,
            endTime: customizeTaskData.end,
            timePerDay: res.data.timePerDay,
          };

          this.props.addInitialLog(hourLogData, this.props.user.token);

          socket.emit("taskDataSOCK", customizeTaskData);

          if (this.state.milestoneId.length) {
            let sendMileTask = {
              projectId: frontendTaskData.projectId,
              id: this.state.milestoneId,
              taskId: customizeTaskData.taskId,
            };
            this.props
              .addTaskToMilestone(sendMileTask, this.props.user.token)
              .then((res) => {
                socket.emit("updateProject", res.data);
              });
          }

          if (this.props.subTasks.setSubtasks) {
            let filtered = this.props.subTasks.setSubtasks.filter(
              (item) => item.value !== null || item.value !== undefined
            );
            let sendData = [];
            let len = filtered.length;

            for (let i = 0; i < len; i++) {
              if (filtered[i].value) {
                let setData = {
                  subTaskTitle: filtered[i].value,
                  parentTaskId: res.data.taskId,
                  completion: filtered[i].status,
                  sortOrder: filtered[i].id,
                  assignedUserId: this.state.resourceID,
                  workspaceId: this.props.workspace[0],
                };
                sendData.push(setData);
              }
            }
            // console.log('Subtasks', sendData)
            this.props
              .addSubTaskMultiple(sendData, this.props.user.token)
              .then((res) => {
                this.props.setToState(null);
                socket.emit("updateSubtask", []);
              });
          }

          this.setState({
            newOpen: false,
            projectId: "",
            timePerDay: 4,
            milestoneId: "",
            createEvents: false,
            updateEvent: false,
            start: new Date(),
            end: new Date(),
            splitStatus: false,
            updateLog: false,
            updateEstimate: false,
          });
        });
      } else {
        alert("User has no permission to add tasks");
      }
    }
  };

  handleSplit = (value) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({ splitStatus: value, updateEvent: true });
    }
  };

  handleRemoveTask = (schedulerData) => {
    let data = { taskId: this.state.taskId };

    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });

    if (permitted) {
      this.props.removeUserTask(data, this.props.user.token).then((res) => {
        this.setState({ showDetails: false, eventClicked: false });
        socket.emit("removeTask", res.data);
      });
    } else {
      alert("User has no permission to do this action");
    }

    this.clickedEventsClose(schedulerData);
  };

  handleTimePerDay = (value) => {
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });
    if (permitted) {
      this.setState({
        timePerDay: value,
        updateEvent: true,
        createEvents: true,
        updateEstimate: true,
      });
    }
  };

  render() {
    const { viewModel } = this.state;
    const currentDate = moment(new Date());
    let leftCustomHeader = (
      <div className="scheduler-width-top">
        <div
          style={{ background: "rgb(232,232,232)", width: 20, height: 30 }}
        />
        <div
          color="primary"
          style={{
            fontFamily: "Sofia Pro",
            cursor: "pointer",
            paddingLeft: 10,
            paddingTop: 5,
          }}
          onClick={() => this.onSelectDate(viewModel, currentDate)}
        >
          Today: {currentDate.format("D MMMM")}
        </div>
      </div>
    );

    let rightCustomHeader = (
      <IconButton
        color="primary"
        onClick={() => this.handleViewRemaining(viewModel)}
      >
        <TimeIcon />
      </IconButton>
    );

    let createProgress = <Progress />;

    let createScrollerProgress = <ScrollerProgress />;

    const startDate = moment(this.state.start);
    const endDate = moment(this.state.end);
    const diff = endDate.diff(startDate);
    const diffDuration = moment.duration(diff);
    let schedulerData = this.state.viewModel;
    const titleChange = this.state.title;

    let addTask = (
      <AddTask
        token={this.props.user.token}
        taskID={this.state.taskId}
        workspaceId={this.props.status[0]}
        getAllLittleSubTasks={this.props.getAllLittleSubTasks}
        taskColor={this.state.taskColor}
        userId={this.state.resourceID}
        showDetails={this.state.showDetails}
        projectId={this.state.projectId}
        milestones={this.props.milestones}
        changeProjectId={this.handleProjectId}
        changeMilesId={this.handleMilestoneId}
        projectLists={
          this.props.projects.allProjects ? this.props.projects.allProjects : []
        }
        handleSplit={this.handleSplit}
        splitStatus={this.state.splitStatus}
        title={this.state.title}
        start={this.state.start}
        timePerDay={this.state.timePerDay}
        handleTimePerDay={this.handleTimePerDay}
        end={this.state.end}
        newOpen={this.state.newOpen}
        changed={(event) => this.handleTitleCreate(event)}
        inputStartValue={this.handleStartCreate}
        resource={this.state.resource}
        taskStatus={this.props.taskStatus.allStatus}
        handleTaskStatus={this.handleTaskStatus}
        inputEndValue={this.handleEndCreate}
        detailsText={(event) => this.handleDescriptionCreate(event)}
        canceled={this.handleCreateConfirm}
        discard={this.handleDiscard}
        checkedBoxes={(event) => this.state.checkedItems.get(event)}
        checkboxChange={this.handleChange}
        checkboxlabelClick={this.labelClickHandler}
        clickEdit={() => this.ops1(schedulerData, this.state.item)}
        clickDelete={() => this.ops2(schedulerData, this.state.item)}
        closed={this.handleNewAddClose}
        handleMember={this.handleMember}
        permitted={this.state.permitted}
      />
    );

    let taskDetails = (
      <ShowTaskDetails
        token={this.props.user.token}
        getAllLittleSubTasks={this.props.getAllLittleSubTasks}
        activeUser={this.props.user.activeUser}
        loggedIn={this.props.user.userDetails}
        taskID={this.state.taskId}
        workspaceId={this.props.status[0]}
        taskColor={this.state.taskColor}
        userId={this.state.resourceID}
        showDetails={this.state.showDetails}
        changeProjectId={this.handleProjectId}
        changeMilesId={this.handleMilestoneId}
        projectId={this.state.projectId}
        milestones={this.props.milestones}
        projectLists={
          this.props.projects.allProjects ? this.props.projects.allProjects : []
        }
        handleSplit={this.handleSplit}
        splitStatus={this.state.splitStatus}
        title={this.state.title}
        start={this.state.start}
        end={this.state.end}
        timePerDay={this.state.timePerDay}
        handleTimePerDay={this.handleTimePerDay}
        days={diffDuration.days()}
        hours={diffDuration.hours()}
        minutes={diffDuration.minutes()}
        resource={this.state.resource}
        history={this.state.history}
        description={this.state.description}
        detailsText={(event) => this.handleDescriptionChange(event)}
        changed={(event) => this.handleTitleChange(event, titleChange)}
        inputStartValue={this.handleStartCreate}
        inputEndValue={this.handleEndCreate}
        taskStatus={this.props.taskStatus.allStatus}
        handleTaskStatus={this.handleTaskStatus}
        taskStatusActive={this.state.taskStatus}
        checkedBoxes={(event) => this.state.checkedItems.get(event)}
        checkboxChange={this.handleChange}
        checkboxlabelClick={this.labelClickHandler}
        clickEdit={() => this.ops1(schedulerData, this.state.item)}
        clickDelete={() => this.ops2(schedulerData, this.state.item)}
        closed={() => this.clickedEventsClose(schedulerData)}
        cancel={this.handleCancel}
        handleUpdate={this.handleUpdateEventChange}
        handleDuplicate={this.handleCreateConfirm}
        handleRemoveTask={() => this.handleRemoveTask(schedulerData)}
        handleMember={this.handleMember}
        permitted={this.state.permitted}
      />
    );

    // console.log('ViewModel', viewModel)
    return (
      <>
        {this.state.loading === true ? (
          createProgress
        ) : (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="tt__timelineWrapper">
              {this.state.newOpen ? addTask : ""}
              {this.state.showDetails ? taskDetails : null}
              {this.state.isProgress === true ? (
                createScrollerProgress
              ) : (
                <div style={{ height: 2 }} />
              )}
              {this.state.projectPopUp && this.state.projectUpdateId ? (
                <EditProject
                  token={this.props.user.token}
                  allProjects={this.props.projects.allProjects}
                  workspaceId={this.props.workspace[0]}
                  onAdd={this.props.updateProject}
                  updateId={this.state.projectUpdateId}
                  createdBy={this.props.user.userDetails.id}
                  handleClose={this.handleProjectClose}
                  open={this.state.projectPopUp}
                  members={
                    this.props.teamMembers.allTeamMember
                      ? this.props.teamMembers.allTeamMember
                      : []
                  }
                  activeTeam={this.props.team.activeTeam}
                />
              ) : null}
              <Scheduler
                schedulerData={viewModel}
                showAgenda={true}
                isEventPerspective={true}
                prevClick={this.prevClick}
                nextClick={this.nextClick}
                onSelectDate={this.onSelectDate}
                onViewChange={this.onViewChange}
                eventItemClick={this.eventClicked}
                viewEventClick={this.ops1}
                viewEventText="Edit"
                viewEvent2Text="Delete"
                viewEvent2Click={this.ops2}
                updateEventStart={this.updateEventStart}
                updateEventEnd={this.updateEventEnd}
                moveEvent={this.moveEvent}
                newEvent={this.newEvent}
                onScrollLeft={this.onScrollLeft}
                onScrollRight={this.onScrollRight}
                onScrollTop={this.onScrollTop}
                onScrollBottom={this.onScrollBottom}
                slotClickedFunc={this.slotClickedFunc}
                nonAgendaCellHeaderTemplateResolver={
                  this.nonAgendaCellHeaderTemplateResolver
                }
                eventItemTemplateResolver={this.eventItemTemplateResolver}
                eventItemPopoverTemplateResolver={
                  this.eventItemPopoverTemplateResolver
                }
                toggleExpandFunc={this.toggleExpandFunc}
                conflictOccurred={this.conflictOccurred}
                rightCustomHeader={rightCustomHeader}
                leftCustomHeader={leftCustomHeader}
              />
            </div>
          </MuiPickersUtilsProvider>
        )}
      </>
    );
  }

  prevClick = (schedulerData) => {
    schedulerData.prev();
    let taskLists = this.props.tasks.taskDetails.map((item) => {
      let group =
        item.projectId && this.props.projects.allProjects
          ? this.props.projects.allProjects.find(
              (project) => project._id === item.projectId
            )
          : null;
      let groupName = group ? group.projectTitle : "";
      let color = group ? (group.color ? group.color : null) : null;
      let taskDataDet = {
        id: item._id,
        title: item.taskName,
        start: item.startingDate,
        end: item.deadLine,
        bgColor: color ? color : item.colorData,
        taskId: item.taskId,
        projectId: item.projectId ? item.projectId : "",
        groupName: groupName,
        showPopover: true,
        resourceId: item.resourceId,
        groupId: item.assignedTo,
        timePerDay: item.timePerDay,
        descriptionData: item.descriptionData,
        splitStatus: item.splitStatus,
        statusId: item.statusId,
      };
      return taskDataDet;
    });

    if (this.props.projects.selectProject) {
      let filteredList = taskLists.filter(
        (item) => item.projectId === this.props.projects.selectProject
      );
      schedulerData.setEvents(filteredList);
      this.setState({
        viewModel: schedulerData,
      });
    } else {
      schedulerData.setEvents(taskLists);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  nextClick = (schedulerData) => {
    schedulerData.next();
    let taskLists = this.props.tasks.taskDetails.map((item) => {
      let group =
        item.projectId && this.props.projects.allProjects
          ? this.props.projects.allProjects.find(
              (project) => project._id === item.projectId
            )
          : null;
      let groupName = group ? group.projectTitle : "";
      let color = group ? (group.color ? group.color : null) : null;
      let taskDataDet = {
        id: item._id,
        title: item.taskName,
        start: item.startingDate,
        end: item.deadLine,
        bgColor: color ? color : item.colorData,
        taskId: item.taskId,
        projectId: item.projectId ? item.projectId : "",
        groupName: groupName,
        showPopover: true,
        resourceId: item.resourceId,
        groupId: item.assignedTo,
        timePerDay: item.timePerDay,
        descriptionData: item.descriptionData,
        splitStatus: item.splitStatus,
        statusId: item.statusId,
      };
      return taskDataDet;
    });
    if (this.props.projects.selectProject) {
      let filteredList = taskLists.filter(
        (item) => item.projectId === this.props.projects.selectProject
      );
      schedulerData.setEvents(filteredList);
      this.setState({
        viewModel: schedulerData,
      });
    } else {
      schedulerData.setEvents(taskLists);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  onViewChange = (schedulerData, view) => {
    if (view.viewType === 7) {
      schedulerData.config.customCellWidth = 55;
      schedulerData.config.scrollToSpecialMomentEnabled = true;
    } else if (view.viewType === 5) {
      schedulerData.config.customCellWidth = 150;
      schedulerData.config.scrollToSpecialMomentEnabled = true;
    } else {
      schedulerData.config.customCellWidth = 55;
      schedulerData.config.scrollToSpecialMomentEnabled = true;
    }

    schedulerData.setViewType(
      view.viewType,
      view.showAgenda,
      view.isEventPerspective
    );
    let taskLists = this.props.tasks
      ? this.props.tasks.taskDetails
        ? this.props.tasks.taskDetails.map((item) => {
            let group =
              item.projectId && this.props.projects.allProjects
                ? this.props.projects.allProjects.find(
                    (project) => project._id === item.projectId
                  )
                : null;
            let groupName = group ? group.projectTitle : "";
            let color = group ? (group.color ? group.color : null) : null;
            let taskDataDet = {
              id: item._id,
              title: item.taskName,
              start: item.startingDate,
              end: item.deadLine,
              bgColor: color ? color : item.colorData,
              taskId: item.taskId,
              projectId: item.projectId ? item.projectId : "",
              groupName: groupName,
              showPopover: true,
              resourceId: item.resourceId,
              groupId: item.assignedTo,
              timePerDay: item.timePerDay,
              descriptionData: item.descriptionData,
              splitStatus: item.splitStatus,
              statusId: item.statusId,
            };
            return taskDataDet;
          })
        : []
      : [];
    if (this.props.projects.selectProject) {
      let filteredList = taskLists.filter(
        (item) => item.projectId === this.props.projects.selectProject
      );
      schedulerData.setEvents(filteredList);
      this.setState({
        viewModel: schedulerData,
      });
    } else {
      schedulerData.setEvents(taskLists);
      this.setState({
        viewModel: schedulerData,
      });
    }
  };

  onSelectDate = (schedulerData, date) => {
    window.location.reload();
    // schedulerData.setDate(date);

    // let allTask = allTaskLocal ? allTaskLocal : this.props.tasks.taskDetails;
    // let taskLists = allTask.map((item) => {
    //   let group =
    //     item.projectId && this.props.projects.allProjects
    //       ? this.props.projects.allProjects.find(
    //         (project) => project._id === item.projectId
    //       )
    //       : null;
    //   let groupName = group ? group.projectTitle : "";
    //   let color = group ? (group.color ? group.color : null) : null;
    //   let taskDataDet = {
    //     id: item._id,
    //     title: item.taskName,
    //     start: item.startingDate,
    //     end: item.deadLine,
    //     bgColor: color ? color : item.colorData,
    //     taskId: item.taskId,
    //     projectId: item.projectId ? item.projectId : "",
    //     groupName: groupName,
    //     showPopover: true,
    //     resourceId: item.resourceId,
    //     groupId: item.assignedTo,
    //     timePerDay: item.timePerDay,
    //   };
    //   return taskDataDet;
    // });
    // if (this.props.projects.selectProject) {
    //   let filteredList = taskLists.filter(
    //     (item) => item.projectId === this.props.projects.selectProject
    //   );
    //   schedulerData.setEvents(filteredList);
    //   this.setState({
    //     viewModel: schedulerData,
    //   });
    // } else {
    //   schedulerData.setEvents(taskLists);
    //   this.setState({
    //     viewModel: schedulerData,
    //   });
    // }
  };

  eventClicked = (schedulerData, event) => {
    let index = schedulerData.resources.findIndex((p) => {
      return p.id === event.resourceId;
    });

    let activeUser = this.props.user.activeUser;

    let activeUserArr = activeUser ? activeUser : [];

    let find = activeUserArr.find(
      (item) => item.taskId.toString() === event.taskId.toString()
    );

    if (!find) {
      let data = {
        user: this.props.user.userDetails,
        taskId: event.taskId,
      };

      activeUserArr.push(data);
      socket.emit("taskUpdating", activeUserArr);
    }

    let person = { ...schedulerData.resources[index] };
    // const names = person.name;

    let updated = this.props.tasks.taskLogs
      ? this.props.tasks.taskLogs.find((item) => item.taskId === event.taskId)
      : null;

    let userData = updated
      ? this.props.teamMembers.allTeamMember.find(
          (member) => member.userId === updated.createdBy
        )
      : null;

    let history = updated
      ? {
          name: userData.name,
          updatedAt: updated.createdAt,
        }
      : {};

    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;

    this.setState({
      item: event,
      id: event.id,
      taskId: event.taskId,
      status: event.status,
      title: event.title,
      description: event.descriptionData,
      taskColor: event.bgColor ? event.bgColor : "",
      taskStatus: event.statusId ? event.statusId : "",
      start: event.start,
      end: event.end,
      projectId: event.projectId ? event.projectId : "",
      timePerDay: event.timePerDay,
      resource: person.name,
      resourceID: event.resourceId,
      history: history,
      splitStatus: event.splitStatus,
      permitted: permitted,
      showDetails: true,
      eventClicked: true,
    });
  };

  handleCancel = (token, taskId) => {
    let activeUser = this.props.user.activeUser;

    let find = activeUser
      ? activeUser.find((item) => item.taskId.toString() === taskId.toString())
      : null;

    let setUser = find
      ? find.user
        ? find.user.email === this.props.user.userDetails.email
          ? find.user
          : null
        : null
      : null;

    if (setUser) {
      activeUser = activeUser.filter(
        (item) => item.taskId.toString() !== taskId.toString()
      );

      socket.emit("taskUpdating", activeUser);
    }

    this.setState({
      showDetails: false,
      eventClicked: false,
      splitStatus: false,
      id: "",
      taskId: "",
      status: "",
      title: "",
      description: "",
      taskColor: "",
      taskStatus: "",
      projectId: "",
      timePerDay: 4,
      resource: "",
      resourceID: "",
      milestoneId: "",
    });
  };

  // update event
  clickedEventsClose = (schedulerData) => {
    const startDate = moment(this.state.start).format("YYYY-MM-DD HH:mm:ss");
    const endDate = moment(this.state.end).format("YYYY-MM-DD HH:mm:ss");

    let assigned = this.props.teamMembers
      ? this.props.teamMembers.allTeamMember
        ? this.props.teamMembers.allTeamMember.find(
            (item) => item._id === this.state.resourceID
          )
        : null
      : null;

    let team = this.props.team
      ? this.props.team.activeTeam
        ? this.props.team.allTeams
          ? this.props.team.allTeams.find(
              (team) =>
                team._id.toString() === this.props.team.activeTeam.toString()
            )
          : null
        : null
      : null;

    let updatedTask = {
      taskId: this.state.taskId,
      taskName: this.state.title,
      startTime: startDate,
      deadLine: endDate,
      projectId: this.state.projectId,
      email: assigned ? assigned.email : null,
      name: assigned ? assigned.name : null,
      createdBy: this.props.user.userDetails.id,
      descriptionData: this.state.description,
      team: team ? team.teamTitle : "",
      workspaceId: this.props.workspace[0],
      resourceId: this.state.resourceID,
      assignedTo: this.state.assignedTo,
      timePerDay: this.state.timePerDay,
      statusId: this.state.taskStatus,
      userTaskStatus: 1,
      splitStatus: this.state.splitStatus,
      workspaceName: this.props.workspaceDetails
        ? this.props.workspaceDetails
          ? this.props.workspaceDetails[0].workspace
          : ""
        : "",
    };

    let group =
      updatedTask.projectId && this.props.projects.allProjects
        ? this.props.projects.allProjects.find(
            (project) => project._id === updatedTask.projectId
          )
        : null;
    let groupName = group ? group.projectTitle : "";
    let color = group ? (group.color ? group.color : null) : null;

    updatedTask.groupName = groupName;

    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({ permitted: permitted });

    if (permitted) {
      if (this.state.updateEvent) {
        let frontendTaskData = {
          id: this.state.id,
          title: updatedTask.taskName,
          start: updatedTask.startTime,
          end: updatedTask.deadLine,
          projectId: updatedTask.projectId
            ? updatedTask.projectId
            : this.state.projectId,
          groupName: groupName,
          descriptionData: updatedTask.descriptionData,
          bgColor: color ? color : undefined,
          showPopover: true,
          resourceId: this.state.resourceID,
          taskId: updatedTask.taskId,
          timePerDay: updatedTask.timePerDay,
          statusId: updatedTask.statusId,
          splitStatus: updatedTask.splitStatus,
          token: this.props.user.token,
        };
        if (this.state.milestoneId.length) {
          let sendMileTask = {
            projectId: frontendTaskData.projectId,
            id: this.state.milestoneId,
            taskId: frontendTaskData.taskId,
          };
          this.props
            .addTaskToMilestone(sendMileTask, this.props.user.token)
            .then((res) => {
              socket.emit("updateProject", res.data);
            });
        }

        // schedulerData.events = schedulerData.events.filter(
        //   (item) => item.taskId !== updatedTask.taskId && item.id !== updatedTask.id
        // );
        // console.log(data, "=====SchedularData====")
        schedulerData.events = schedulerData.events.filter(
          (item) => item.taskId !== updatedTask.taskId
        );

        schedulerData.addEvent(frontendTaskData);
        this.setState({
          viewModel: schedulerData,
          showDetails: false,
        });

        this.props
          .updateTask(updatedTask, this.props.user.token)
          .then((res) => {
            // let group = updatedTask.projectId && this.props.projects.allProjects ?
            //     this.props.projects.allProjects.find(project => project._id === updatedTask.projectId) : null
            // let groupName = group ? group.projectTitle : ''
            // let color = group ? (group.color ? group.color : null) : null
            let customizeTaskData = {
              id: res.data._id,
              title: updatedTask.taskName,
              start: res.data.startingDate,
              end: res.data.deadLine,
              projectId: res.data.projectId
                ? res.data.projectId
                : this.state.projectId,
              groupName: groupName,
              descriptionData: res.data.descriptionData,
              bgColor: color ? color : undefined,
              showPopover: true,
              resourceId: this.state.resourceID,
              taskId: res.data.taskId,
              timePerDay: res.data.timePerDay,
              token: this.props.user.token,
              statusId: res.data.statusId,
              splitStatus: updatedTask.splitStatus,
              assignedTo: res.data.assignedTo,
            };

            let hourLogData = {
              taskId: res.data.taskId,
              userId: customizeTaskData.assignedTo,
              projectId: customizeTaskData.projectId,
              workspaceId: this.props.workspace[0],
              teamId: this.props.team.activeTeam,
              startTime: customizeTaskData.start,
              endTime: customizeTaskData.end,
              timePerDay: res.data.timePerDay,
            };

            if (this.state.updateEstimate) {
              this.props
                .updateEstimate(hourLogData, this.props.user.token)
                .then((logRes) => {
                  if (logRes) {
                    socket.emit("getLogs", hourLogData);
                  }
                });
            }

            if (this.state.updateLog) {
              this.props
                .updateUserDate(hourLogData, this.props.user.token)
                .then((logRes) => {
                  if (logRes) {
                    socket.emit("getLogs", hourLogData);
                  }
                });
            }
            socket.emit("updateTask", customizeTaskData);
            // schedulerData.addEvent(customizeTaskData)
            // this.setState({
            //     viewModel: schedulerData
            // });
            this.setState({
              showDetails: false,
              id: "",
              taskId: "",
              status: "",
              title: "",
              description: "",
              taskColor: "",
              taskStatus: "",
              projectId: "",
              timePerDay: 4,
              resource: "",
              resourceID: "",
              milestoneId: "",
              splitStatus: false,
              updateEvent: false,
              createEvents: false,
              start: new Date(),
              end: new Date(),
              assignedTo: "",
              updateLog: false,
              updateEstimate: false,
            });
          });
      }
    } else {
      alert("User is not permitted to do the action");
    }

    let activeUser = this.props.user.activeUser;
    let find = activeUser
      ? activeUser.find((item) => item.taskId === updateTask.taskId)
      : null;

    let setUser = find
      ? find.user
        ? find.user.email === this.props.user.userDetails.email
          ? find.user
          : null
        : null
      : null;

    if (setUser) {
      activeUser = activeUser.filter(
        (item) => item.taskId.toString() !== updateTask.taskId.toString()
      );

      socket.emit("taskUpdating", activeUser);
    }

    this.setState({
      showDetails: false,
      eventClicked: false,
      history: {},
    });
  };

  ops1 = (schedulerData, event) => {
    this.handleOpsClick(schedulerData, event);
    // console.log('------EVENT-------', event.id)
    // this.clickedEventsClose(schedulerData);
    this.setState({
      showDetails: false,
    });
  };

  ops2 = (schedulerData, event) => {
    schedulerData.removeEvent(event);
    this.setState({
      viewModel: schedulerData,
      showDetails: false,
    });
    // this.clickedEventsClose(schedulerData);
  };

  newEvent = (schedulerData, slotId, slotName, start, end, type, item) => {
    //this.handleNewAddClickOpen(schedulerData, slotId, slotName, start, end);
    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;

    let permitted = permission > 0 && permission <= 3;
    this.setState({
      newOpen: permitted,
      start: start,
      end: end,
      resource: slotName,
      resourceID: slotId,
      projectId: "",
      item: {},
      taskColor: "",
      title: "",
      id: "",
      taskId: "",
      permitted: permitted,
    });
  };

  updateStartSocket = (event, schedulerData, newStart) => {
    schedulerData.events = schedulerData.events.filter(
      (item) => item.taskId !== event.taskId
    );
    schedulerData.updateEventStart(event, newStart);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventStart = (schedulerData, event, newStart) => {
    // eslint-disable-next-line no-restricted-globals

    //console.log('time', event.start, event.end)
    if (event.id) {
      const startDate = moment(newStart).format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment(event.end).format("YYYY-MM-DD HH:mm:ss");

      let assigned = this.props.teamMembers
        ? this.props.teamMembers.allTeamMember
          ? this.props.teamMembers.allTeamMember.find(
              (item) => item._id === event.resourceId
            )
          : null
        : null;

      let team = this.props.team
        ? this.props.team.activeTeam
          ? this.props.team.allTeams
            ? this.props.team.allTeams.find(
                (team) =>
                  team._id.toString() === this.props.team.activeTeam.toString()
              )
            : null
          : null
        : null;

      let updatedEvent = {
        taskId: event.taskId,
        taskName: event.title,
        startTime: startDate,
        deadLine: endDate,
        email: assigned ? assigned.email : null,
        name: assigned ? assigned.name : null,
        workspaceId: this.props.workspace[0],
        workspaceName: this.props.workspaceDetails
          ? this.props.workspaceDetails
            ? this.props.workspaceDetails[0].workspace
            : ""
          : "",
        assignedTo: assigned ? assigned.userId : null,
        team: team ? team.teamTitle : "",
        resourceId: event.resourceId,
        timePerDay: event.timePerDay,
        userTaskStatus: 1,
        groupName: event.groupName,
      };
      let data = {
        event: event,
        newStart: newStart,
        newEnd: endDate,
        token: this.props.user.token,
      };
      let permission = this.props.teamMembers
        ? this.props.teamMembers.permission
          ? this.props.teamMembers.permission
          : 0
        : 0;

      let permitted = permission > 0 && permission <= 3;
      this.setState({ permitted: permitted });
      if (permitted) {
        this.setState({ eventClicked: false, showDetails: false });
        schedulerData.updateEventStart(data.event, data.newStart);
        this.setState({
          viewModel: schedulerData,
        });
        this.props
          .updateTask(updatedEvent, this.props.user.token)
          .then((res) => {
            // console.log('SUCCESS', res.data)
            // schedulerData.events = schedulerData.events.filter(
            //     item => item.taskId !== res.data.taskId
            // )
            let backData = {
              event: event,
              newStart: newStart,
              newEnd: endDate,
              token: this.props.user.token,
            };

            let hourLogData = {
              taskId: res.data.taskId,
              userId: updatedEvent.assignedTo,
              projectId: res.data.projectId,
              workspaceId: this.props.workspace[0],
              teamId: this.props.team.activeTeam,
              startTime: backData.newStart,
              endTime: backData.newEnd,
              timePerDay: res.data.timePerDay,
            };

            this.props
              .updateUserDate(hourLogData, this.props.user.token)
              .then((logRes) => {
                if (logRes) {
                  socket.emit("getLogs", hourLogData);
                }
              });

            socket.emit("updateStart", backData);
          });
      }
    }
  };

  updateEndSocket = (event, schedulerData, newEnd) => {
    schedulerData.updateEventEnd(event, newEnd);
    this.setState({
      viewModel: schedulerData,
    });
  };

  updateEventEnd = (schedulerData, event, newEnd) => {
    // eslint-disable-next-line no-restricted-globals

    //console.log('time', event.start, event.end)
    if (event.id) {
      // console.log('====EVENT====', event)
      const startDate = moment(event.start).format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment(newEnd).format("YYYY-MM-DD HH:mm:ss");

      let assigned = this.props.teamMembers
        ? this.props.teamMembers.allTeamMember
          ? this.props.teamMembers.allTeamMember.find(
              (item) => item._id === event.resourceId
            )
          : null
        : null;

      let team = this.props.team
        ? this.props.team.activeTeam
          ? this.props.team.allTeams
            ? this.props.team.allTeams.find(
                (team) => team._id === this.props.team.activeTeam
              )
            : null
          : null
        : null;

      let updatedEvent = {
        taskId: event.taskId,
        taskName: event.title,
        startTime: startDate,
        deadLine: endDate,
        email: assigned ? assigned.email : null,
        name: assigned ? assigned.name : null,
        workspaceId: this.props.workspace[0],
        assignedTo: assigned ? assigned.userId : null,
        resourceId: event.resourceId,
        descriptionData: event.descriptionData,
        timePerDay: event.timePerDay,
        userTaskStatus: 1,
        team: team ? team.teamTitle : "",
        groupName: event.groupName,
        workspaceName: this.props.workspaceDetails
          ? this.props.workspaceDetails
            ? this.props.workspaceDetails[0].workspace
            : ""
          : "",
      };
      let data = {
        newEnd: endDate,
        event: event,
        newStart: startDate,
        token: this.props.user.token,
      };
      let permission = this.props.teamMembers
        ? this.props.teamMembers.permission
          ? this.props.teamMembers.permission
          : 0
        : 0;

      let permitted = permission > 0 && permission <= 3;
      this.setState({ permitted: permitted });
      if (permitted) {
        this.setState({ eventClicked: false, showDetails: false });
        schedulerData.updateEventEnd(event, newEnd);
        this.setState({
          viewModel: schedulerData,
        });
        this.props
          .updateTask(updatedEvent, this.props.user.token)
          .then((res) => {
            let backData = {
              newEnd: endDate,
              event: event,
              newStart: startDate,
              token: this.props.user.token,
            };

            let hourLogData = {
              taskId: res.data.taskId,
              userId: updatedEvent.assignedTo,
              projectId: res.data.projectId,
              workspaceId: this.props.workspace[0],
              teamId: this.props.team.activeTeam,
              startTime: backData.newStart,
              endTime: backData.newEnd,
              timePerDay: res.data.timePerDay,
            };

            this.props
              .updateUserDate(hourLogData, this.props.user.token)
              .then((logRes) => {
                if (logRes) {
                  socket.emit("getLogs", hourLogData);
                }
              });

            socket.emit("updateEnd", backData);
          });
      }
    }
  };

  moveEventSocket = (schedulerData, event, slotId, slotName, start, end) => {
    schedulerData.events = schedulerData.events.filter(
      (item) => item.taskId !== event.taskId
    );
    schedulerData.moveEvent(event, slotId, slotName, start, end);
    this.setState({
      viewModel: schedulerData,
    });
  };

  moveEvent = (schedulerData, event, slotId, slotName, start, end) => {
    // eslint-disable-next-line no-restricted-globals
    //console.log('Show', show, startArr, eventStart)
    if (event.id) {
      const startDate = moment(start).format("YYYY-MM-DD HH:mm:ss");
      const endDate = moment(end).format("YYYY-MM-DD HH:mm:ss");

      let assigned = this.props.teamMembers
        ? this.props.teamMembers.allTeamMember
          ? this.props.teamMembers.allTeamMember.find(
              (item) => item._id === slotId
            )
          : null
        : null;

      let team = this.props.team
        ? this.props.team.activeTeam
          ? this.props.team.allTeams
            ? this.props.team.allTeams.find(
                (team) => team._id === this.props.team.activeTeam
              )
            : null
          : null
        : null;

      let updatedEvent = {
        taskId: event.taskId,
        taskName: event.title,
        startTime: startDate,
        deadLine: endDate,
        email: assigned ? assigned.email : null,
        name: assigned ? assigned.name : null,
        workspaceId: this.props.workspace[0],
        assignedTo: assigned ? assigned.userId : null,
        resourceId: slotId,
        timePerDay: event.timePerDay,
        descriptionData: event.descriptionData,
        userTaskStatus: 1,
        team: team ? team.teamTitle : "",
        groupName: event.groupName,
        workspaceName: this.props.workspaceDetails
          ? this.props.workspaceDetails
            ? this.props.workspaceDetails[0].workspace
            : ""
          : "",
      };
      let permission = this.props.teamMembers
        ? this.props.teamMembers.permission
          ? this.props.teamMembers.permission
          : 0
        : 0;

      let permitted = permission > 0 && permission <= 3;
      this.setState({ permitted: permitted });
      if (permitted) {
        let data = {
          event: event,
          slotId: slotId,
          slotName: slotName,
          start: start,
          end: end,
          token: this.props.user.token,
        };
        schedulerData.moveEvent(event, slotId, slotName, start, end);
        this.setState({
          viewModel: schedulerData,
        });
        this.props
          .updateTask(updatedEvent, this.props.user.token)
          .then((res) => {
            let backData = {
              event: event,
              slotId: slotId,
              slotName: slotName,
              start: start,
              end: end,
              token: this.props.user.token,
            };

            let hourLogData = {
              taskId: res.data.taskId,
              userId: updatedEvent.assignedTo,
              projectId: res.data.projectId,
              workspaceId: this.props.workspace[0],
              teamId: this.props.team.activeTeam,
              startTime: backData.start,
              endTime: backData.end,
              timePerDay: res.data.timePerDay,
            };

            this.props
              .updateUserDate(hourLogData, this.props.user.token)
              .then((logRes) => {
                if (logRes) {
                  socket.emit("getLogs", hourLogData);
                }
              });

            socket.emit("moveEvent", backData);
          });
      }
    }
  };

  onScrollRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    if(!this.state.onSideScroll) {
      this.setState({
        isProgress: true,
      });

      this.onScrollCustomRight(schedulerData, schedulerContent, maxScrollLeft);
    }
  };

  onScrollCustomRight = (schedulerData, schedulerContent, maxScrollLeft) => {
    TimerMixin.setTimeout(() => {
      this.setState({ onSideScroll: true })
      schedulerData.next();
      let allTaskData = {
        workspaceId: this.props.status[0],
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      this.props.allTaskUser(allTaskData, this.props.user.token).then((res) => {
        let taskLists = res.data.map((item) => {
          let group =
            item.projectId && this.props.projects.allProjects
              ? this.props.projects.allProjects.find(
                  (project) => project._id === item.projectId
                )
              : null;
          let groupName = group ? group.projectTitle : "";
          let color = group ? (group.color ? group.color : null) : null;
          let taskDataDet = {
            id: item._id,
            title: item.taskName,
            start: item.startingDate,
            end: item.deadLine,
            bgColor: color ? color : item.colorData,
            taskId: item.taskId,
            projectId: item.projectId ? item.projectId : "",
            groupName: groupName,
            showPopover: true,
            resourceId: item.resourceId,
            groupId: item.assignedTo,
            timePerDay: item.timePerDay,
            descriptionData: item.descriptionData,
            splitStatus: item.splitStatus,
            statusId: item.statusId,
          };
          return taskDataDet;
        });
        if (this.props.projects.selectProject) {
          let filteredList = taskLists.filter(
            (item) => item.projectId === this.props.projects.selectProject
          );
          schedulerData.setEvents(filteredList);
          this.setState({
            viewModel: schedulerData,
            isProgress: false,
          });
        } else {
          schedulerData.setEvents(taskLists);
          this.setState({
            viewModel: schedulerData,
            isProgress: false,
            onSideScroll: false
          });
        }

        schedulerContent.scrollLeft = maxScrollLeft - (maxScrollLeft - 50);
      });
    }, 1000);
  };

  onScrollLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    if(!this.state.onSideScroll) {
      this.setState({
        isProgress: true,
      });

      this.onScrollCustomLeft(schedulerData, schedulerContent, maxScrollLeft);
    }
  };

  onScrollCustomLeft = (schedulerData, schedulerContent, maxScrollLeft) => {
    TimerMixin.setTimeout(() => {
      this.setState({ onSideScroll: true })
      schedulerData.prev();
      let allTaskData = {
        workspaceId: this.props.status[0],
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      this.props.allTaskUser(allTaskData, this.props.user.token).then((res) => {
        let taskLists = res.data.map((item) => {
          let group =
            item.projectId && this.props.projects.allProjects
              ? this.props.projects.allProjects.find(
                  (project) => project._id === item.projectId
                )
              : null;
          let groupName = group ? group.projectTitle : "";
          let color = group ? (group.color ? group.color : null) : null;
          let taskDataDet = {
            id: item._id,
            title: item.taskName,
            start: item.startingDate,
            end: item.deadLine,
            bgColor: color ? color : item.colorData,
            taskId: item.taskId,
            projectId: item.projectId ? item.projectId : "",
            groupName: groupName,
            showPopover: true,
            resourceId: item.resourceId,
            groupId: item.assignedTo,
            timePerDay: item.timePerDay,
            descriptionData: item.descriptionData,
            splitStatus: item.splitStatus,
            statusId: item.statusId,
          };
          return taskDataDet;
        });
        // console.log('TaskLists', schedulerData.events, this.props.tasks.taskDetails)
        if (this.props.projects.selectProject) {
          let filteredList = taskLists.filter(
            (item) => item.projectId === this.props.projects.selectProject
          );
          schedulerData.setEvents(filteredList);
          this.setState({
            viewModel: schedulerData,
            isProgress: false,
          });
        } else {
          schedulerData.setEvents(taskLists);
          this.setState({
            viewModel: schedulerData,
            isProgress: false,
            onSideScroll: false
          });
        }

        schedulerContent.scrollLeft = 500;
      });
    }, 1000);
  };

  onScrollTop = (schedulerData, schedulerContent, maxScrollTop) => {
    //console.log("onScrollTop", schedulerContent);
  };

  onScrollBottom = (schedulerData, schedulerContent, maxScrollTop) => {
    //console.log("onScrollBottom", schedulerContent);
  };

  addResource = (resourceName) => {
    let schedulerData = this.state.viewModel;
    let newFreshId = schedulerData.resources.length + 1;
    let newFreshName = resourceName;
    schedulerData.addResource({ id: newFreshId, name: newFreshName });
    this.setState({
      viewModel: schedulerData,
    });
  };

  slotClickedFunc = (schedulerData, slot) => {
    console.log("Slot Click", slot);
    this.props.history.push("/status?slot="+slot.slotId);
  };

  toggleExpandFunc = (schedulerData, slotId) => {
    schedulerData.toggleExpandStatus(slotId);
    this.setState({
      viewModel: schedulerData,
    });
  };

  conflictOccurred = (
    schedulerData,
    action,
    event,
    type,
    slotId,
    slotName,
    start,
    end
  ) => {
    alert(`Conflict occurred. {action: ${action}, event: ${event}`);
  };

  eventItemTemplateResolver = (
    schedulerData,
    event,
    dates,
    width,
    bgColor,
    isStart,
    isEnd,
    mustAddCssClass,
    mustBeHeight,
    agendaMaxEventWidth
  ) => {
    let titleText = schedulerData.behaviors.getEventTextFunc(
      schedulerData,
      event
    );

    let backgroundColor = bgColor;
    let backgroundColorT = "rgba(71,71,71,0)";
    let divStyle = {
      borderLeft: "none",
      backgroundColor: backgroundColor + "99",
      height: mustBeHeight,
    };

    let dateRange = dates ? dates : [];

    let divFadeStyle = {
      background: `linear-gradient(90deg, ${backgroundColor + "15"} 0%, ${
        backgroundColor + "50"
      } 31%, ${backgroundColor + "99"} 100%)`,
      fontSize: 13,
    };

    if (titleText.trim() === "") {
      titleText = "Untitled";
    }

    if (!!agendaMaxEventWidth)
      divStyle = { ...divStyle, maxWidth: agendaMaxEventWidth };

    return (
      <div
        key={event.id}
        className={mustAddCssClass + " tt__event-item"}
        style={divStyle}
      >
        <div
          style={{
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: -3,
              marginLeft: -6,
              zIndex: 3,
            }}
          >
            {dateRange.map((day, idx) => {
              let dow = moment(day).day();
              let formatted = moment(day).format("YYYY-MM-DD");
              let isHoliday = this.props.holidays.find(
                (item) => moment(item.day).format("YYYY-MM-DD") === formatted
              );
              let dayColor = "";
              let nonClass = "";
              if (event.splitStatus === true) {
                if (dow === 5 || dow === 6) {
                  dayColor = "rgb(255, 255, 255, .4)";
                  nonClass = "weekEndDay";
                } else {
                  dayColor = "rgba(255,255,255,0.2)";
                  nonClass = "";
                }
              } else {
                dayColor = "rgba(255,255,255,0.2)";
                nonClass = "";
              }

              if (isHoliday) {
                dayColor = "rgb(255, 255, 255, .4)";
                nonClass = "weekEndDay";
              }

              let dayStyle = {
                borderLeft: "none",
                backgroundColor: dayColor,
                width: width / dates.length,
                height: mustBeHeight,
              };

              return <div style={dayStyle} className={nonClass} />;
            })}
          </div>
          <div>
            <div className="tt__event-item__fade">
              <div className="tt__event-item__task-time" style={divFadeStyle}>
                {event.timePerDay}h
              </div>
            </div>
            <span
              className="tt__event-item__task-title"
              style={{ fontSize: 14 }}
            >
              {titleText}
            </span>
            <div style={{ overflow: "hidden" }}>
              <span
                className="tt__event-item__project-title"
                style={{ fontSize: 14 }}
              >
                {event.groupName}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  eventItemPopoverTemplateResolver = (
    schedulerData,
    eventItem,
    title,
    start,
    end,
    statusColor
  ) => {
    const startDate = moment(start);
    const timeEnd = moment(end);
    const diff = timeEnd.diff(startDate, "days") + 1;

    if (title.trim() === "") {
      title = "Untitled";
    }

    let active = 0;

    for (let i = 0; i < diff; i++) {
      let now = moment(startDate).add(i, "d");
      let dow = moment(now).day();
      let isHoliday = this.props.holidays.find(
        (item) =>
          moment(item.day).format("YYYY-MM-DD") ===
          moment(now).format("YYYY-MM-DD")
      );
      if (eventItem.splitStatus && (dow === 5 || dow === 6)) {
        continue;
      } else if (isHoliday) {
        continue;
      } else {
        active++;
      }
    }

    // console.log('EventItem', eventItem)

    let statusId = eventItem.statusId ? eventItem.statusId : "";

    let statusValue = this.props.taskStatus.allStatus
      ? this.props.taskStatus.allStatus.find((item) => item._id == statusId)
      : null;
    let valueColor = statusValue ? statusValue.statusColor : "";
    let statusTitle = statusValue ? statusValue.statusTitle : "";

    let permission = this.props.teamMembers
      ? this.props.teamMembers.permission
        ? this.props.teamMembers.permission
        : 0
      : 0;
    let permitted = permission > 0 && permission <= 2;

    return (
      <div className="customPopover" style={{ width: "260px" }}>
        <Row
          className="customPopoverTask customPopoverItemRow"
          type="flex"
          align="middle"
        >
          <Col span={22}>
            <span
              onClick={() => {
                this.eventClicked(schedulerData, eventItem);
              }}
              className="header-text"
              style={{ cursor: "pointer", fontSize: 14 }}
              title={title}
            >
              {title}
            </span>
          </Col>
        </Row>
        <Row
          className="customPopoverItemRow customPopoverProject"
          type="flex"
          align="middle"
        >
          <Col span={24} className="tooltip-overflow-text">
            <div style={{ display: "flex" }}>
              <div style={{ width: "220px", overflow: "hidden" }}>
                <span
                  className="header4-text"
                  title={eventItem.groupName}
                  style={
                    permitted ? { cursor: "pointer" } : { cursor: "alias" }
                  }
                  onClick={
                    permitted
                      ? () => this.handleProjectUpdateId(eventItem.projectId)
                      : () => {}
                  }
                >
                  {eventItem.groupName}
                </span>
              </div>
              <div className="tooltipDot" style={{ background: valueColor }}>
                .
              </div>
            </div>
          </Col>
        </Row>
        <hr className="line" />
        <Row
          className="customPopoverItemRow customPopoverTime"
          type="flex"
          align="middle"
        >
          <Col span={22}>
            <div className="customPopoverTime__Date">
              <span className="header5-text" style={{ marginLeft: "0px" }}>
                {start.format("D MMM")}
              </span>
              <span className="header5-text" style={{ marginLeft: "8px" }}>
                -
              </span>
              <span className="header5-text" style={{ marginLeft: "8px" }}>
                {end.format("D MMM")}
              </span>
            </div>
            <div className="customPopoverTime__Time">
              <span
                className="header2-text"
                style={{
                  color: "#7b8099",
                  cursor: "pointer",
                  textAlign: "right",
                }}
              >
                {active * eventItem.timePerDay} h
              </span>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  demoButtonClicked = (schedulerData, eventItem) => {
    alert(`You just clicked demo button. event title: ${eventItem.title}`);
  };
}

function mapStateToProps(state) {
  return {
    workspace: state.settings.workspace,
    workspaceDetails: state.settings.workspaceDetails,
    user: state.user ? state.user : null,
    team: state.teams,
    teamMembers: state.teamMembers,
    tasks: state.tasks,
    projects: state.projects,
    taskStatus: state.taskStatus,
    subTasks: state.subTasks,
    roles: state.teamMembers ? state.teamMembers.roles : [],
    milestones: state.projects
      ? state.projects.allMilestones
        ? state.projects.allMilestones
        : []
      : [],
  };
}

export default withRouter(
  withDragDropContext(
    connect(mapStateToProps, {
      addNewTeam,
      getAllTeam,
      addNewTask,
      getAllLittleSubTasks,
      findUserByMail,
      findAllTeamMembers,
      addNewTeamMember,
      setTeamToStore,
      allTaskUser,
      updateTeam,
      updateTeamMember,
      addProject,
      updateTask,
      getAllProjects,
      getAllStatus,
      removeUserTask,
      getAllWorkspacesUser,
      addSubTaskMultiple,
      addLittleSubTaskMultiple,
      getAllSubTasks,
      setToState,
      getAllProjectTypes,
      addTaskToMilestone,
      getAllRoles,
      getWorkspace,
      getAllSplitTask,
      addInitialLog,
      updateUserDate,
      updateEstimate,
      updateProject,
      setActiveUser,
    })(Dashboard)
  )
);
