import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/EditOutlined';
import IconButton from '@material-ui/core/IconButton';
import UpdateLeave from './UpdateLeave'
import AddLeave from './AddLeave'
import Button from '@material-ui/core/Button';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#5765B3',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 700
  },
});

let AllLeaves = props => {
  const classes = useStyles();
  let { rowData, token, workspaceId } = props

  let [editOpen, setEdit] = useState(false)
  let [addOpen, setAdd] = useState(false)
  let [active, setActive] = useState({})

  let handleActive = data => {
    setActive(data)
    handleEditDialog(true)
  }

  let handleEditDialog = (value) => {
    setEdit(value)
    setAdd(false)
  }

  let handleAddDialog = (value) => {
    setEdit(false)
    setAdd(value)
  }

  return (
    <div>
      <UpdateLeave 
        open={editOpen}
        setOpen={handleEditDialog}
        data={active}
        token={token}
      />
      <AddLeave 
        open={addOpen}
        setOpen={handleAddDialog}
        workspaceId={workspaceId}
        token={token}
      />
      <TableContainer align='center'>
        <div
          style={{
            fontSize: 18,
            textAlign: 'center',
            margin: '10px 0px'
          }}
        >
          Workspace Leave List
        </div>
        <Button variant="outlined" color="primary" onClick={() => handleAddDialog(true)}>
          Add New +
        </Button>
        <div style={{ marginTop: 10 }} />
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Leave Count</StyledTableCell>
              <StyledTableCell align="center">Edit</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length ? rowData.map((row, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell align="center" scope="row">{row.title}</StyledTableCell>
                <StyledTableCell align="center">{row.count}</StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton
                    color='primary'
                    onClick={() => handleActive(row)}
                  >
                    <EditIcon />
                  </IconButton>
                </StyledTableCell>
              </StyledTableRow>
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AllLeaves