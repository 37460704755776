import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import { sidebar } from "./SidebarLeave";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import Logo from "../../Assets/images/logo_profile.svg";
import "../../Assets/CSS/MaterialAppbar.css";
import List from "@material-ui/core/List";
import moment from "moment";
import Badge from "@material-ui/core/Badge";
import { connect } from "react-redux";
import { getWeekLog } from "../../Actions/hourLogActions";
import { allTaskUser } from "../../Actions/taskActions";
import {
  getUserLeaveHistory,
  getAllLeaves,
  getUserPending,
  getPendingLeaves,
  getConfirmedLeaves,
  getUserRejectedHistory,
} from "../../Actions/leaveActions";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import LeaveHistory from "./LeaveHistory";
import LeaveApply from "./LeaveApply";
import SettingsTab from "./SettingsTab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

let socket = socketIOClient(serverSocket);

let drawerWidth = 240;

let useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    backgroundColor: " #fff !important",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  title: {
    display: "none",
    marginLeft: -6,
    marginTop: 5,
    color: "#fff",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: 0,
  },
}));

let disabledDays = (total) => {
  let retArr = [];
  let len = total.length;

  for (let i = 0; i < len; i++) {
    let arr = total[i].days;
    retArr = retArr.concat(arr);
  }

  return retArr;
};

function LeaveNav(props) {
  let {
    window,
    getAllLeaves,
    getUserLeaveHistory,
    getUserPending,
    getPendingLeaves,
    getConfirmedLeaves,
    getUserRejectedHistory,
    user,
    leaves,
    workspace,
    workspaceDetails,
  } = props;

  let classes = useStyles();
  let theme = useTheme();
  let [mobileOpen, setMobileOpen] = useState(false);
  let [selected, setSelected] = useState(0);

  let [notify, setNotify] = useState("");

  let token = user ? (user.token ? user.token : null) : null;
  let userId = user ? (user.userDetails ? user.userDetails.id : null) : null;

  let allLeaves = leaves ? (leaves.allLeaves ? leaves.allLeaves : []) : [];
  let history = leaves ? (leaves.leaveHistory ? leaves.leaveHistory : []) : [];
  let pending = leaves ? (leaves.userPendings ? leaves.userPendings : []) : [];
  let rejected = leaves
    ? leaves.rejectedHistory
      ? leaves.rejectedHistory
      : []
    : [];
  let userDetails = user ? (user.userDetails ? user.userDetails : null) : null;

  let allPendings = leaves
    ? leaves.pendingLeaves
      ? leaves.pendingLeaves
      : []
    : [];
  let allConfirms = leaves
    ? leaves.confirmedLeaves
      ? leaves.confirmedLeaves
      : []
    : [];

  let userName = userDetails
    ? userDetails.firstName + " " + userDetails.lastName
    : "";
  let workspaceId = workspace ? workspace : "";

  let workspaceOwner = workspaceDetails ? workspaceDetails.createdBy : null;

  let total = history.concat(pending);

  let disabled = disabledDays(total);

  let sideBar =
    workspaceOwner === userId
      ? sidebar
      : sidebar.filter((item) => item.id !== 2);

  useEffect(() => {
    if (token) {
      let inputData = {
        workspaceId: workspaceId,
      };
      getAllLeaves(inputData, token);
      getUserLeaveHistory(inputData, token);
      getUserPending(inputData, token);
      getPendingLeaves(inputData, token);
      getConfirmedLeaves(inputData, token);
      getUserRejectedHistory(inputData, token);

      socket.on("updateLeaves", (data) => {
        getAllLeaves(inputData, token);
        getUserLeaveHistory(inputData, token);
        getUserPending(inputData, token);
        getPendingLeaves(inputData, token);
        getConfirmedLeaves(inputData, token);
        getUserRejectedHistory(inputData, token);
      });
    }
  }, []);

  let handleSelected = (value) => {
    setSelected(value);
  };

  let handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  let drawer = (
    <div className="nav-list" style={{ height: "100%" }}>
      <div style={{ display: "flex", padding: 24 }}>
        <Link to="/">
          <ArrowBackIcon style={{ marginTop: 12, marginRight: 48 }} />
        </Link>
        <Typography className={classes.title} variant="h6" noWrap>
          <Link to="/">
            <img src={Logo} alt="Team Trekking Logo" />
          </Link>
        </Typography>
      </div>
      <div className={classes.toolbar} />
      <List>
        {sideBar.map((item, index) => (
          <ListItem
            button
            key={index}
            selected={selected == index}
            onClick={() => handleSelected(index)}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
            {index === 2 && allPendings.length ? (
              <Badge badgeContent={allPendings.length} color="secondary" />
            ) : null}
          </ListItem>
        ))}
      </List>
    </div>
  );

  let container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        {/* {notify.length ? (
          <div
            style={{
              width: '32%',
              marginLeft: '50%',
              zIndex: 100,
              position: 'fixed',
              marginRight: 10
            }}
          >
            <Alert
              variant="filled"
              severity="success"
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setNotify('');
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {notify}
            </Alert>
          </div>
        ) : null} */}
        {selected == 0 ? (
          <LeaveHistory
            allLeaves={allLeaves}
            history={history}
            rejected={rejected}
            token={token}
            userName={userName}
            pending={pending}
          />
        ) : null}
        {selected === 1 ? (
          <LeaveApply
            disabledDays={disabled}
            allLeavesList={allLeaves}
            history={history}
            userName={userName}
            pending={pending}
          />
        ) : null}
        {selected == 2 ? (
          <SettingsTab
            token={token}
            workspaceId={workspaceId}
            allLeaves={allLeaves}
            allPendings={allPendings}
            allConfirms={allConfirms}
          />
        ) : null}
      </main>
    </div>
  );
}

LeaveNav.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

let mapStateToProps = (state) => {
  return {
    user: state.user ? state.user : null,
    workspace: state.settings
      ? state.settings.workspace
        ? state.settings.workspace[0]
        : null
      : null,
    workspaceDetails: state.settings
      ? state.settings.workspaceDetails
        ? state.settings.workspaceDetails[0]
        : []
      : [],
    tasks: state.tasks ? state.tasks : [],
    projects: state.projects
      ? state.projects.allProjects
        ? state.projects.allProjects
        : []
      : [],
    hourLogs: state.hourLogs
      ? state.hourLogs.weekLogs
        ? state.hourLogs.weekLogs
        : []
      : [],
    leaves: state.leaves ? state.leaves : null,
  };
};

export default connect(mapStateToProps, {
  getWeekLog,
  allTaskUser,
  getAllLeaves,
  getUserLeaveHistory,
  getUserPending,
  getPendingLeaves,
  getConfirmedLeaves,
  getUserRejectedHistory,
})(LeaveNav);
