import {
  ADD_NEW_TEAM,
  UPDATE_TEAM,
  DELETE_TEAM,
  GET_ALL_TEAMS,
  REMOVE_TEAM_STORE,
  SET_TEAM_LOCAL,
  SET_USERS_LOCAL
} from '../Constants/index'

const initialState = {}

const teamReducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEAMS:
      return {
        ...state,
        allTeams: action.data
      }

    case ADD_NEW_TEAM:
      return {
        ...state,
        teamDetails: action.data
      }

    case UPDATE_TEAM:
      return {
        ...state,
        teamDetails: action.data
      }

    case DELETE_TEAM:
      return {
        ...state,
        teamDetails: action.data
      }

    case REMOVE_TEAM_STORE:
      return {
        ...state,
        teamDetails: ''
      }

    case SET_TEAM_LOCAL:
      return {
        ...state,
        activeTeam: action.data
      }
    case SET_USERS_LOCAL:
      return {
        ...state,
        activeTeamUser: action.data
      }

    default:
      return state
  }
}

export default teamReducers