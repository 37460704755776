import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { getWeekLog } from "../../Actions/hourLogActions";
import { allTaskUser } from "../../Actions/taskActions";
import moment from "moment";
import Pagination from "@material-ui/lab/Pagination";
import LeaveDialog from "./LeaveDialog";
import { search } from "ss-search";
import Button from "@material-ui/core/Button";
import Search from "./Search";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#5765B3",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 700,
    textAlign: "center",
  },
});

let availableLeaves = (allLeaves, user, history) => {
  let leaveDetails = [];

  let userHistory = history.filter((item) => item.userId._id === user);

  let len = allLeaves.length;

  for (let i = 0; i < len; i++) {
    let leaveMain = allLeaves[i];

    let found = userHistory.filter(
      (item) => item.leaveId._id === leaveMain._id
    );

    let leaveCount = 0;

    let flen = found.length;

    for (let j = 0; j < flen; j++) {
      leaveCount += found[j].days.length;
    }

    let pushData = {
      title: allLeaves[i].title,
      id: allLeaves[i]._id,
      available: allLeaves[i].count - leaveCount,
      total: allLeaves[i].count,
    };
    leaveDetails.push(pushData);
  }
  return leaveDetails;
};

let LeaveController = (props) => {
  let classes = useStyles();

  let {
    allPendings,
    allConfirms,
    allLeaves,
    user,
    workspace,
    tasks,
    projects,
    hourLogs,
    getWeekLog,
    allTaskUser,
  } = props;

  let [open, setOpen] = useState(false);
  let [available, setAvailable] = useState([]);
  let [leaveId, setLeaveId] = useState("");
  let [description, setDescription] = useState("");
  let [page, setPage] = useState(1);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [searchText, setSearchKey] = useState("");

  let searchKey = ["userId.firstName", "userId.lastName", "userId.email"];

  let filtered = search(allPendings, searchKey, searchText);

  filtered = filtered.reverse();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let [dates, setDates] = useState({});

  let handleLeaveId = (value) => {
    setLeaveId(value);
  };

  let token = user ? (user.token ? user.token : null) : null;
  let workspaceId = workspace ? workspace : "";

  let handleRow = (user, dates, leaveId, description) => {
    if (token) {
      let rowData = availableLeaves(allLeaves, user, allConfirms);
      setDescription(description);
      setLeaveId(leaveId);
      let len = dates.length;
      let query = {
        userId: user,
        start: moment(dates[0]).format("YYYY-MM-DD"),
        end: moment(dates[len - 1]).format("YYYY-MM-DD"),
      };
      setDates(query);
      let allTaskData = {
        workspaceId: workspaceId,
        startDate: query.start,
        endDate: query.end,
      };
      getWeekLog(query, token);
      allTaskUser(allTaskData, token);
      setAvailable(rowData);
      setOpen(true);
    }
  };

  return (
    <div>
      <LeaveDialog
        open={open}
        description={description}
        setOpen={setOpen}
        available={available}
        tasks={tasks}
        history={hourLogs}
        token={token}
        projects={projects}
        dates={dates}
        leaveId={leaveId}
        handleLeaveId={handleLeaveId}
      />
      {allPendings.length ? (
        <TableContainer align="center">
          <div
            style={{
              fontSize: 18,
              textAlign: "center",
              margin: "10px 0px",
            }}
          >
            Leaves Pending
          </div>
          <Search onSearch={setSearchKey} />
          <div style={{ marginBottom: 10 }} />
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">User</StyledTableCell>
                <StyledTableCell align="center">Date(s)</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Days</StyledTableCell>
                <StyledTableCell align="center">Action</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filtered.length
                ? (rowsPerPage > 0
                    ? filtered &&
                      filtered.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                    : filtered
                  ).map((row, key) => (
                    <>
                      <StyledTableRow key={key}>
                        <StyledTableCell align="center">
                          {row.userId.firstName + " " + row.userId.lastName}
                        </StyledTableCell>
                        <StyledTableCell align="center" scope="row">
                          {row.days.map((day) => {
                            return (
                              <div>{moment(day).format("DD/MM/YYYY")}</div>
                            );
                          })}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.leaveId.title}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          {row.days.length}
                        </StyledTableCell>
                        <StyledTableCell align="center">
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() =>
                              handleRow(
                                row.userId._id,
                                row.days,
                                row._id,
                                row.description
                              )
                            }
                          >
                            Details
                          </Button>
                        </StyledTableCell>
                      </StyledTableRow>
                    </>
                  ))
                : null}
            </TableBody>
            <div>
              <Pagination
                style={{ height: 48, paddingTop: 8 }}
                count={
                  filtered
                    ? filtered.length
                      ? Math.ceil(filtered.length / 10)
                      : 0
                    : 0
                }
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChange={handleChangePage}
              />
            </div>
          </Table>
        </TableContainer>
      ) : (
        <div style={{ textAlign: "center", marginTop: "10%", fontSize: 18 }}>
          No leave requests
        </div>
      )}
    </div>
  );
};

let mapStateToProps = (state) => {
  return {
    user: state.user ? state.user : null,
    workspace: state.settings
      ? state.settings.workspace
        ? state.settings.workspace[0]
        : null
      : null,
    tasks: state.tasks
      ? state.tasks.taskDetails
        ? state.tasks.taskDetails
        : []
      : [],
    projects: state.projects
      ? state.projects.allProjects
        ? state.projects.allProjects
        : []
      : [],
    hourLogs: state.hourLogs
      ? state.hourLogs.weekLogs
        ? state.hourLogs.weekLogs
        : []
      : [],
  };
};

export default connect(mapStateToProps, {
  getWeekLog,
  allTaskUser,
})(LeaveController);
