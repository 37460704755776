import React from 'react';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

const BorderProgress = withStyles({
    root: {
        height: 30,
        backgroundColor: lighten('#ffd800', 0.5),
    },
    bar: {
        borderRadius: 0,
        backgroundColor: '#ffd800',
    },
})(LinearProgress);

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    margin: {
        marginTop: 1,
    },
}));

export default function BorderLinearProgress(props) {
    let classes = useStyles();
    let { value } = props
    let completed = 0

    let len = value.length

    for (let i = 0; i < len; i++) {
        if (value[i].checked) completed += value[i].percentage
    }
    return (
        <div className={classes.root}>
            <BorderProgress
                className={classes.margin}
                variant="determinate"
                color="secondary"
                value={100 - completed}
            />
        </div>
    );
}