import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import '../../Assets/CSS/ProjectDialog.css';
import ChangeWorkspace from './ChangeWorkspace'
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(theme => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 480,
    paddingBottom: 16
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  }
}));

function EditWorkspace(props) {
  const classes = useStyles();
  let { 
    allWorkspaces, 
    onUpdate, 
    token, 
    open, 
    handleClose,
    updateWorkspaceId
  } = props

  return (
    <React.Fragment>
      <Dialog className="customMemberDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ background: 'rgba(73,121,255,0.85)' }}>
          <div style={{ display: 'flex' }}>
            <text style={{ color: 'white', width: '100%', fontFamily: 'Sofia Pro', textAlign: 'center' }}>
              Update A Workspace
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            <ChangeWorkspace
              workspaceId={updateWorkspaceId}
              allWorkspaces={allWorkspaces}
              token={token}
              onUpdate={onUpdate}
              handleClose={handleClose}
            />
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default EditWorkspace
