import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import "../../Assets/CSS/ProjectDialog.css";
import ChangeWorkspace from "./ChangeWorkspace";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import socketIOClient from "socket.io-client";
import Button from "@material-ui/core/Button";
import { serverSocket } from "../../Actions/config";
import WorkspaceLists from "../../Components/WorkspaceLists";
import { search } from "ss-search";
import Search from "./ProjectSearch";
import { Scrollbars } from "react-custom-scrollbars";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ConfirmWorkspaceDelete from "./ConfirmWorkspaceDelete";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 16,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function EditWorkspace(props) {
  const classes = useStyles();
  let {
    allWorkspaces,
    onUpdate,
    token,
    open,
    handleClose,
    userId,
    handleWorkspace,
    addWorkspace,
    showUpdateWorkspace,
  } = props;

  let [value, setValue] = useState("");
  let [searchText, setSearchKey] = useState("");
  let [deletePop, setDeletePop] = useState(false);

  let handleWorkspaceChange = (id) => {
    setValue(id);
  };

  let showUpdateModal = (id) => {
    showUpdateWorkspace();
    handleWorkspace(id);
  };

  let onDeleteSubmit = () => {
    let data = {
      _id: value,
    };
    onUpdate(data, token).then((res) => {
      socket.emit("addWorkspace", []);
      setDeletePop(false);
    });
  };

  let openDeleteModal = (id) => {
    handleWorkspaceChange(id);
    setDeletePop(true);
  };

  let onClickCreate = () => {
    addWorkspace();
  };

  let cancelModal = () => {
    setDeletePop(false);
    setValue("");
  };

  let searchKey = ["workspace"];

  let filtered = search(allWorkspaces, searchKey, searchText);

  return (
    <React.Fragment>
      <Dialog
        className="customDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "left",
              }}
            >
              All Workspaces
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Search label={"Workspace"} onSearch={setSearchKey} />
          <div style={{ marginTop: 12 }}>
            <div
              style={{
                display: "flex",
                height: 56,
                background: "rgba(0,0,0,0.1)",
              }}
            >
              <div className="divHeaders">Workspace Name</div>
              <div className="divHeaders" style={{ paddingLeft: 350 }}>
                Action
              </div>
            </div>
            <Scrollbars style={{ height: (window.innerHeight / 5) * 3 }}>
              <div>
                {
                  <WorkspaceLists
                    allWorkspaces={filtered}
                    editWorkspace={showUpdateModal}
                    deleteWorkspace={openDeleteModal}
                    userId={userId}
                  />
                }
              </div>
            </Scrollbars>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="createProject" onClick={onClickCreate}>
              <AddCircleIcon />
              <div className="create">Create Workspace</div>
            </div>
          </div>
        </DialogContent>
        <ConfirmWorkspaceDelete
          open={deletePop}
          deleteItem={value}
          allWorkspaces={filtered}
          confirm={onDeleteSubmit}
          cancel={cancelModal}
        />
      </Dialog>
    </React.Fragment>
  );
}

export default EditWorkspace;
