import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import userReducer from "../Reducers/userReducer";
import settingsReducer from "../Reducers/settingsReducers";
import projectReducer from "../Reducers/projectReducers";
import teamReducer from "../Reducers/teamReducers";
import teamMemberReducer from "../Reducers/teamMemberReducers";
import taskReducer from "../Reducers/taskReducers";
import taskStatusReducer from "../Reducers/taskStatusReducers";
import subTaskReducer from "../Reducers/subTaskReducers";
import roleReducer from "../Reducers/roleReducers";
import milestoneDetailsReducer from "../Reducers/milestoneDetailsReducers";
import hourLogReducer from "../Reducers/hourLogReducers";
import leaveReducer from "../Reducers/leaveReducers";
import holidayReducer from "../Reducers/holidayReducer";

import { composeWithDevTools } from "redux-devtools-extension";

const rootReducer = combineReducers({
  user: userReducer,
  settings: settingsReducer,
  projects: projectReducer,
  teams: teamReducer,
  teamMembers: teamMemberReducer,
  tasks: taskReducer,
  taskStatus: taskStatusReducer,
  subTasks: subTaskReducer,
  roles: roleReducer,
  milestoneDetails: milestoneDetailsReducer,
  hourLogs: hourLogReducer,
  leaves: leaveReducer,
  holidays: holidayReducer,
});

export default function Store() {
  return createStore(
    rootReducer,
    applyMiddleware(thunkMiddleware)
    //composeWithDevTools()
  );
}
