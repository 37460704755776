import React, { useState,  useEffect } from 'react'
import { DateRange } from 'react-date-range';
import LeaveAutoComplete from './LeaveAutoComplete'
import TextField from '@material-ui/core/TextField'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

let LeaveCalander = props => {

  let {
    handleChange,
    dates,
    handleLeaveId,
    allLeaves,
    disabledDays,
    available,
    handleDescription,
    leaveId,
    desc
  } = props

  let [description, setDescription] = useState('')

  let onDescriptionChange = event => {
    setDescription(event.target.value)
    handleDescription(event.target.value)
  }

  let disabled = disabledDays.map(day => new Date(day))

  useEffect(() => {
    setDescription(desc ? desc : '')
  }, [desc])

  return (
    <div
      style={{
        marginTop: 25,
        marginBottom: 15,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <div style={{ fontSize: 16, marginBottom: 10 }}>
        Select Date
      </div>
      <DateRange
        style={{ width: 650 }}
        editableDateInputs={true}
        onChange={item => handleChange([item.selection])}
        moveRangeOnFirstSelection={false}
        ranges={dates}
        minDate={new Date()}
        disabledDates={disabled}
      />
      <div
        style={{ 
          marginBottom: 8, 
          display: 'flex', 
          flexDirection: 'row', 
        }}
      >
        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            paddingRight: 10
          }}
        >
          <div style={{ fontSize: 16, marginBottom: 10 }}>
            Select Leave Type
          </div>
          <LeaveAutoComplete 
            leaves={allLeaves} 
            handleChange={handleLeaveId} 
            available={available}
            leaveId={leaveId}
          />
        </div>
        <div 
          style={{ 
            display: 'flex', 
            flexDirection: 'column',
            paddingLeft: 10 
          }}
        >
          <div style={{ fontSize: 16, marginBottom: 10 }}> 
            Add Description
          </div>
          <TextField
            id="outlined-multiline-static"
            label="Description (optional)"
            multiline
            rows={4}
            style={{ width: 300 }}
            value={description}
            onChange={onDescriptionChange}
            variant="outlined"
          />
        </div>
      </div>
    </div>
  )
}

export default LeaveCalander