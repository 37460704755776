import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import Typography from '@material-ui/core/Typography'
import '../../Assets/CSS/EventSidebar.css';

const filter = createFilterOptions();

function ProjectManagerAC(props) {
  const { allMembers, handleChange, managerId } = props

  let manager = allMembers.find(item => item._id == managerId)

  const [value, setValue] = React.useState(
    manager ? { title: manager.name } : {}
  )

  return (
    <Autocomplete
      className="customSingleSelect editHoverBGColor"
      value={value ? value.title : value}
      onChange={(event, newValue) => {
        if (newValue) {
          handleChange(newValue._id);
          if (newValue) {
            setValue({
              title: newValue.name
            });
            return;
          }
          setValue(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      options={allMembers}
      disableClearable
      forcePopupIcon={true}
      disableOpenOnFocus={false}
      getOptionLabel={option => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.name) {
          return option.name;
        }
        return option.name;
      }}

      renderOption={option => option.name ?
        <Typography style={{ width: '100%', fontSize: 14, fontFamily: 'Sofia Pro', fontWeight: 300 }}>{option.name}</Typography>
        : ''
      }
      style={{ width: '100%', marginRight: '1%' }}
      freeSolo
      renderInput={params => (
        <span className="sidebarProjectName">
          <TextField
            {...params}
            style={{ width: '100%', fontSize: 12 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true
            }}
          />
        </span>
      )}
    />
  );
}

export default ProjectManagerAC