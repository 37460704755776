import {
  ADD_HOLIDAY,
  UPDATE_HOLIDAY,
  GET_ALL_HOLIDAY,
  SET_HOLIDAY_LOADING
} from '../Constants/index'

import axios from 'axios'
import { serverURI } from './config'

export function addHoliday(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let addNew = await axios.post(serverURI + 'post/add-holiday', inputData)

      if(addNew.status === 200) {
        response.status = 200
        response.token = token
        response.data = addNew.data
        dispatch(setNewHoliday(addNew.data))
        response.message = "Holiday Added Successfully"
      } else {
        response.status = addNew.status
        response.message = "Error from Add Holiday"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function updateHoliday(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let update = await axios.post(serverURI + 'post/update-holiday', inputData)

      if(update.status === 200) {
        response.status = 200
        response.token = token
        response.data = update.data
        dispatch(setUpdatedHoliday(update.data))
        response.message = "Holiday Update Successfully"
      } else {
        response.status = update.status
        response.message = "Error from Update Holiday"
      }
      
      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getAllHoliday(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      dispatch(setLoading(true))
      let all = await axios.post(serverURI + 'post/get-all-holiday', inputData)

      if(all.status === 200) {
        response.status = 200
        response.token = token
        response.data = all.data
        dispatch(setAllHoliday(all.data))
        dispatch(setLoading(false))
        response.message = "Holiday Update Successfully"
      } else {
        response.status = all.status
        dispatch(setLoading(false))
        response.message = "Error from Update Holiday"
      }
      
      return response
    } catch (err) {
      dispatch(setLoading(false))
      response.message = err
      response.status = 500

      return response
    }
  }
}

export const setLoading = data => {
  return {
    type: SET_HOLIDAY_LOADING,
    data: data
  }
}

export const setNewHoliday = data => {
  return {
    type: ADD_HOLIDAY,
    data: data
  }
}

export const setUpdatedHoliday = data => {
  return {
    type: UPDATE_HOLIDAY,
    data: data
  }
}

export const setAllHoliday = data => {
  return {
    type: GET_ALL_HOLIDAY,
    data: data
  }
}