/** USER ACTIONS */

export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_USER_LOCAL = "SET_USER_LOCAL";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SET_WORKSPACE = "SET_WORKSPACE";
export const SET_SIGNUP_ERROR = "SET_SIGNUP_ERROR";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";

/** SETTINGS ACTION */
export const ADD_WORKSPACE = "ADD_WORKSPACE";
export const GET_WORKSPACE = "GET_WORKSPACE";
export const SET_WORKSPACE_LOCAL = "SET_WORKSPACE_LOCAL";
export const REMOVE_WORKSPACE = "REMOVE_WORKSPACE";
export const GET_ALL_WORKSPACES = "GET_ALL_WORKSPACES";
export const SET_UPDATED_WORKSPACE = "SET_UPDATED_WORKSPACE";

/** PROJECTS ACTIONS */
export const ADD_PROJECT = "ADD_PROJECT";
export const SET_UPDATED_PROJECT = "SET_UPDATED_PROJECT";
export const GET_WORKSPACE_PROJECT = "GET_WORKSPACE_PROJECT";
export const SET_FILTER_PROJECT = "SET_FILTER_PROJECT";
export const SET_PROJECT_TYPES = "SET_PROJECT_TYPES";

/** TEAM ADD ACTIONS */
export const ADD_NEW_TEAM = "ADD_NEW_TEAM";
export const UPDATE_TEAM = "UPDATE_TEAM";
export const DELETE_TEAM = "DELETE_TEAM";
export const GET_ALL_TEAMS = "GET_ALL_TEAMS";
export const REMOVE_TEAM_STORE = "REMOVE_TEAM_STORE";
export const SET_TEAM_LOCAL = "SET_TEAM_LOCAL";
export const SET_USERS_LOCAL = "SET_USERS_LOCAL";

/** TEAM MEMBER ACTIONS */
export const ADD_TEAM_MEMBER = "ADD_TEAM_MEMBER";
export const REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER";
export const GET_TEAM_USER = "GET_TEAM_USER";
export const SET_ALL_TEAM_MEMBERS = "SET_ALL_TEAM_MEMBERS";
export const SET_ALL_ROLES = "SET_ALL_ROLES";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_ROLE_PRIORITY = "SET_ROLE_PRIORITY";

/** TASK ACTIONS */
export const ADD_TASK = "ADD_TASK";
export const UPDATE_TASK = "UPDATE_TASK";
export const DELETE_TASK = "DELETE_TASK";
export const ADD_TASK_USER = "ADD_TASK_USER";
export const ALL_TASK_USER = "ALL_TASK_USER";
export const TASK_LOADING = "TASK_LOADING ";
export const HANDLE_REMAINING = "HANDLE_REMAINING";
export const ALL_SPLIT_TASK = "ALL_SPLIT_TASK";
export const ALL_REPORT_TASK = "ALL_REPORT_TASK";

/** SUBTASK ACTIONS */
export const ADD_MULTIPLE_SUBTASK = "ADD_MULTIPLE_SUBTASK";
export const ADD_SINGLE_SUBTASK = "ADD_SINGLE_SUBTASK";
export const GET_ALL_SUBTASKS = "GET_ALL_SUBTASKS";
export const GET_ALL_LITTLE_SUBTASKS = "GET_ALL_LITTLE_SUBTASKS";
export const UPDATE_SUBTASK = "UPDATE_SUBTASK";
export const SET_SUBTASK_STORE = "SET_SUBTASK_STORE";

/** Milestone actions */
export const GET_ALL_MILESTONES = "GET_ALL_MILESTONES";
export const ADD_NEW_MILESTONE = "ADD_NEW_MILESTONE";

/** TASK STATUS ACTIONS */
export const GET_ALL_STATUS = "GET_ALL_STATUS";

/** TASK LOG ACTIONS */
export const SET_TASK_LOGS = "SET_TASK_LOGS";

/** Milestone details */
export const ADD_NEW_ACTIVITY_NAME = "ADD_NEW_ACTIVITY_NAME";
export const GET_ALL_ACTIVITY_NAME = "GET_ALL_ACTIVITY_NAME";
export const ADD_NEW_ACTIVITY = "ADD_NEW_ACTIVITY";
export const GET_ALL_ACTIVITIES = "GET_ALL_ACTIVITIES";
export const ADD_NEW_MILESTONE_HISTORY = "ADD_NEW_MILESTONE_HISTORY";
export const GET_ALL_MILESTONE_HISTORY = "GET_ALL_MILESTONE_HISTORY";

/** Time log */
export const ADD_INITIAL_LOG = "ADD_INITIAL_LOG";
export const UPDATE_ESTIMATE = "UPDATE_ESTIMATE";
export const UPDATE_DATE_USER = "UPDATE_DATE_USER";
export const GET_WEEK_LOGS = "GET_WEEK_LOGS";
export const START_TIMER = "START_TIMER";
export const PAUSE_TIMER = "PAUSE_TIMER";
export const SET_RUNNING = "SET_RUNNING";
export const UPDATE_SPENT = "UPDATE_SPENT";

/** Leave module */
export const ADD_LEAVE_LIST = "ADD_LEAVE_LIST";
export const UPDATE_LEAVE_LIST = "UPDATE_LEAVE_LIST";
export const GET_LEAVE_LIST = "GET_LEAVE_LIST";
export const INSERT_USER_LEAVE = "INSERT_USER_LIST";
export const UPDATE_USER_LEAVE = "UPDATE_USER_LEAVE";
export const CONFIRM_USER_LEAVE = "CONFIRM_USER_LEAVE";
export const REJECT_USER_LEAVE = "REJECT_USER_LEAVE";
export const GET_PENDING_LEAVES = "GET_PENDING_LEAVES";
export const GET_CONFIRMED_LEAVES = "GET_CONFIRMED_LEAVES";
export const GET_USER_HISTORY = "GET_USER_HISTORY";
export const GET_USER_PENDING = "GET_USER_PENDING";
export const GET_USER_REJECTED = "GET_USER_REJECTED";

/** Holiday Feature */
export const ADD_HOLIDAY = "ADD_HOLIDAY";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const GET_ALL_HOLIDAY = "GET_ALL_HOLIDAY";
export const SET_HOLIDAY_LOADING = "SET_HOLIDAY_LOADING";

/** Active User */
export const ACTIVE_TASK_USER = "ACTIVE_TASK_USER";
