/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";

const filter = createFilterOptions();

function FreeSoloCreateOption(props) {
  let {
    user,
    teamId,
    userId,
    roles,
    block,
    memberId,
    activeRole,
    handleChange,
  } = props;
  let roleValue = roles
    ? block
      ? roles.find((item) => item.title === "Workspace Owner")
      : roles.find((item) => item._id === activeRole)
    : null;
  let valueTitle = roleValue ? roleValue.title : "";
  const [value, setValue] = React.useState({ title: valueTitle });

  roles = block
    ? roles.filter((item) => item.title === "Workspace Owner")
    : roles.filter((item) => item.title !== "Workspace Owner");

  return (
    <Autocomplete
      value={value ? value.title : value}
      disableClearable
      disableOpenOnFocus={false}
      forcePopupIcon={true}
      onChange={(event, newValue) => {
        if (newValue) {
          if (newValue._id) {
            handleChange(newValue._id);
            if (newValue) {
              setValue({
                title: newValue.title,
              });
              return;
            }
            setValue(newValue);
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      options={roles}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.title) {
          return option.title;
        }
        return option.title;
      }}
      renderOption={(option) => (
        <Typography
          style={{
            width: "100%",
            fontSize: 14,
            fontFamily: "Sofia Pro",
            fontWeight: 300,
          }}
        >
          {option.title}
        </Typography>
      )}
      style={{ width: "100%", marginRight: "1%" }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          label="Permissions"
          id="outlined-size-small"
          size="small"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
    roles: state.roles ? state.roles.allRoles : [],
  };
}

export default connect(mapStateToProps)(FreeSoloCreateOption);
