import React, {useState} from 'react';
import {makeStyles, useTheme} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Typography from "@material-ui/core/Typography";
import Logo from "../../Assets/images/logo_profile.svg";
import List from "@material-ui/core/List";
import {sidebarStatus} from "../Hourlog/Sidebarlog";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import CssBaseline from "@material-ui/core/CssBaseline";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import PropTypes from "prop-types";
import TrelloBord from "./TrelloBord";

let drawerWidth = 240;

let useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        backgroundColor: " #fff !important",
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    title: {
        display: "none",
        marginLeft: -6,
        marginTop: 5,
        color: "#fff",
        [theme.breakpoints.up("sm")]: {
            display: "block",
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: 0,
        width: '100%'
    },
}));

const IndexStatus = ({window, permission}) => {
    let classes = useStyles();
    let theme = useTheme();
    let [mobileOpen, setMobileOpen] = useState(false);
    let [selected, setSelected] = useState(0);

    let handleSelected = (value) => {
        setSelected(value);
    };

    let handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    let container =
        window !== undefined ? () => window().document.body : undefined;

    let drawer = (
        <div className="nav-list" style={{height: "100%"}}>
            <div style={{display: "flex", padding: 24}}>
                <Link to="/">
                    <ArrowBackIcon style={{marginTop: 12, marginRight: 48}}/>
                </Link>
                <Typography className={classes.title} variant="h6" noWrap>
                    <Link to="/">
                        <img src={Logo} alt="Team Trekking Logo"/>
                    </Link>
                </Typography>
            </div>
            <div className={classes.toolbar}/>
            <List>
                {sidebarStatus.map((item, index) => (
                    <ListItem
                        button
                        key={index}
                        selected={selected === index}
                        onClick={() => handleSelected(index)}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.title}/>
                    </ListItem>
                ))}
            </List>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline/>
            <nav className={classes.drawer} aria-label="mailbox folders">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === "rtl" ? "right" : "left"}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                {selected === 0 ? (
                    <TrelloBord permission={permission}/>
                    ) : null}
            </main>

        </div>
    );
};

IndexStatus.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default IndexStatus;