import React, { forwardRef, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import CheckIcon from "@material-ui/icons/CheckOutlined";
import CloseIcon from "@material-ui/icons/CloseOutlined";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import { updateLeave } from "../../Actions/leaveActions";
import { connect } from "react-redux";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
    },
  },
  paper: { minWidth: "600px" },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  close: {
    position: "absolute",
    right: 5,
    top: 3,
  },
  check: {
    height: 28,
    width: 28,
  },
}));

let LeaveDialog = (props) => {
  let { open, setOpen, data, updateLeave, token } = props;

  const classes = useStyles();

  let [title, setTitle] = useState("");
  let [count, setCount] = useState(0);

  let handleClose = () => {
    setOpen(false);
  };

  let onTitleChange = (e) => {
    setTitle(e.target.value);
  };

  let onCountChange = (e) => {
    setCount(e.target.value);
  };

  let handleSubmit = async () => {
    let submit = {
      title: title,
      count: count,
      _id: data._id,
    };
    updateLeave(submit, token).then((res) => {
      if (res) {
        if (res.status === 200) {
          socket.emit("updateLeaves", res.data);
          handleClose();
        } else {
          alert("Error occures. Try again later");
        }
      } else {
        alert("Error occures. Try again later");
      }
    });
  };

  useEffect(() => {
    setTitle(data ? data.title : "");
    setCount(data ? data.count : 0);
  }, [data]);

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">
        Update Leave Details
      </DialogTitle>
      <IconButton
        color="inherit"
        className={classes.close}
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <form className={classes.root} noValidate autoComplete="off">
          <TextField
            required
            id="outlined-required"
            label="Title"
            value={title}
            onChange={onTitleChange}
            variant="outlined"
          />
          <TextField
            id="outlined-number"
            label="Count"
            type="number"
            value={count}
            onChange={onCountChange}
            variant="outlined"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <div>
          <IconButton color="primary" onClick={handleSubmit}>
            <CheckIcon className={classes.check} />
          </IconButton>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default connect(null, { updateLeave })(LeaveDialog);
