import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Scrollbars } from 'react-custom-scrollbars';
import '../../Assets/CSS/Hourlog.css';
import TextareaAutosize from "react-textarea-autosize";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Divider from '@material-ui/core/Divider';

import Box from "@material-ui/core/Box";
import Timer from 'react-compound-timer';
import moment from 'moment'

const useStyles = makeStyles({
  root: {
    marginTop: 48,
    marginLeft: 32,
    marginRight: 32
  },
  thisweek: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32
  },
  lastweek: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 64
  }
});

let spentHour = (spent) => {
  let final = spent / (60 * 60 * 1000)
  return final.toFixed(2)
}

let msToTime = (duration) => {
  let milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds
}

let dayTotal = logs => {
  let total = 0
  for (let j = 0; j < logs.length; j++) {
    total += logs[j].spent
  }
  return total
}


function Hourlog(props) {
  let classes = useStyles();
  let {
    taskData
  } = props

  return (
    <div className="scroller">
      {/*Next Week*/}
      <div className="this-week">
        <div style={{ marginBottom: 6 }}>
          {taskData.length ? 'Available Tasks:' : 'No tasks Assigned'}
        </div>
      </div>
      <Divider />
      <Scrollbars
        className="scroller"
        style={{ height: window.innerHeight / 2 }}
      >
        <Box>
          {
            taskData.map((dayData, idx) => {
              if (dayData.logs.length) {
                return (
                  <Card key={idx} className={classes.thisweek}>
                    <CardContent>
                      <div className="date-name">
                        <span>{moment(dayData.date).format('ddd, MMM D')}</span>
                        <div className="spent">S</div>
                        <div className="estimate">E</div>
                        <div className="day-total">
                          <span>Total:</span>
                          <span>{msToTime(dayTotal(dayData.logs))}</span>
                        </div>
                      </div>
                      {
                        dayData.logs.map((log, key) => {
                          return (
                            <div className="week-row">
                              <TextareaAutosize
                                value={log.taskName}
                                placeholder="What are you working now?"
                                onKeyPress={event => {
                                  if (event.key === 'Enter') {
                                    return event.preventDefault()
                                  }
                                }} />
                              <div style={{ color: log.projectColor }} className="header-project">
                                <FiberManualRecordIcon />
                                <span>{log.projectTitle}</span>
                              </div>

                              <div className="header-estimate">
                                <TextareaAutosize
                                  value={spentHour(log.spent)}
                                  placeholder="S: 0"
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      return event.preventDefault()
                                    }
                                  }} />
                                <span>-</span>
                                <TextareaAutosize
                                  value={log.estimated}
                                  placeholder="E: 00:00"
                                  onKeyPress={event => {
                                    if (event.key === 'Enter') {
                                      return event.preventDefault()
                                    }
                                  }} />
                              </div>

                              <div>
                                <Timer
                                  initialTime={log.spent}
                                  startImmediately={false}
                                  formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                >
                                  {({ start, resume, pause, stop, reset, timerState }) => (
                                    <React.Fragment>
                                      <div className="header-timer">
                                        <div>
                                          {msToTime(log.spent)}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )}
                                </Timer>
                              </div>
                            </div>
                          )
                        })
                      }

                    </CardContent>
                  </Card>
                )
              }
            })
          }
        </Box>
      </Scrollbars>
    </div>
  )
}

export default Hourlog