import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import ColorProgress from '../../Components/ColorProgress';
import TimerMixin from 'react-timer-mixin';

import SubTask from './SubTask';

// material Icons
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import InboxIcon from '@material-ui/icons/Inbox';
import MoodIcon from '@material-ui/icons/Mood';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LayersIcon from '@material-ui/icons/Layers';
import MilestoneIcon from '@material-ui/icons/BeenhereOutlined';
import TextField from '@material-ui/core/TextField';
import '../../Assets/CSS/EventSidebar.css';
import TextareaAutosize from "react-textarea-autosize";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import AutoComplete from './AutoComplete'
import StatusAutoComplete from './StatusAutoComplete'
import Milstone from './MilestoneAutocomplete'
import UserAutoComplete from './UserAutoComplete'

import DatePicker from "react-date-picker";

const AddTask = (props) => {
    let [isProgress, setProgress] = useState(false);

    let [start, setStart] = useState(new Date(props.start))
    let [end, setEnd] = useState(new Date(props.end))

    let [hours, setHours] = useState(props.timePerDay)
    let [isSplit, setSplit] = useState(false)
    let [hourMsg, setHourMsg] = useState('')

    let onHourChange = (event) => {
        let hourValue = 0
        if(event.target.value > 10) {
            hourValue = 10
            setHourMsg(`Your input of ${event.target.value} hours exceeding maximum 10 hours`)
        } else if(event.target.value < 0) {
            hourValue = 1
            setHourMsg(`Your input of ${event.target.value} hour is insufficient then minimum 1 hour`)
        } else {
            hourValue = event.target.value
            setHourMsg('')
        }
        setHours(hourValue)
        props.handleTimePerDay(hourValue)
        TimerMixin.setTimeout(
            () => {
                setHourMsg('')
            },
            4000
        );
    }

    let onFirstClick = () => {
        if(!hours || parseInt(hours) === 0) {
            setHourMsg('Time per day must be between 1 to 10')
            TimerMixin.setTimeout(
                () => {
                    setHourMsg('')
                },
                3500
            );
        } else {
            setProgress(true);
            TimerMixin.setTimeout(
                () => {
                    props.canceled();
                },
                50
            );
        }
    }

    let handleStartDate = date => {
        setStart(date)
        let modifyStartValue = convert(date) + ' 00:00:00';
        props.inputStartValue(modifyStartValue)
    }

    let handleEndDate = date => {
        setEnd(date)
        let modifyEndValue = convert(date) + ' 23:59:00';
        props.inputEndValue(modifyEndValue)
    }

    let onSplitChange = () => {
        let value = !isSplit
        setSplit(value)
        props.handleSplit(value)
    }

    function convert(str) {
        var date = new Date(str),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    let len = props.resource.length
    let idx = 0
    for (let i = 0; i < len; i++) {
        if (i >= 0 && i < len && props.resource[i] === ' ') idx = i
    }
    let shortName = props.resource[0] + props.resource[idx + 1]
    let createProgress = (
        <ColorProgress />
    );

    let milesTask = ''

    // console.log('permitted', props.permitted)
    return (
        <Dialog className="customDrawer"
            open={props.newOpen}
            onClose={() => props.discard(props.token)}
            aria-labelledby="form-dialog-title"
        >
            <DialogContent style={{ padding: 0 }}>
                <DialogTitle id="form-dialog-title" style={{
                    backgroundColor: props.taskColor,
                }}>
                    <div style={{ display: 'flex' }}>
                        <TextareaAutosize
                            autoFocus={true}
                            placeholder="Type task name here.."
                            value={props.value}
                            onBlur={props.changed}
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    return props.changed && event.preventDefault()
                                }
                            }} />
                        <CloseIcon 
                            onClick={() => props.discard(props.token)} 
                            className="customDrawerClose" 
                        />
                    </div>
                </DialogTitle>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <SlowMotionVideoIcon />
                        </span>
                        <span className="sidebarTitle">Status</span>
                    </Col>
                    <Col span={14}>
                        <StatusAutoComplete
                            active={props.taskStatus ? props.taskStatus[0] ? props.taskStatus[0]._id : null : null}
                            allStatus={props.taskStatus}
                            statusChange={props.handleTaskStatus}
                            handleUpdate={null}
                        />
                        {/* <SingleSelect
                            defaultValue={
                                { title: 'Ongoing', valueId: 'ongoing' }
                            }
                            values={[
                                { title: 'Completed', valueId: 'completed' },
                                { title: 'Urgent', valueId: 'urgent' },
                                { title: 'Paused', valueId: 'paused' }
                            ]}
                        /> */}
                    </Col>
                </Row>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <InboxIcon />
                        </span>
                        <span className="sidebarTitle">Project</span>
                    </Col>
                    <Col span={14}>
                        <AutoComplete
                            active={''}
                            projectLists={props.projectLists}
                            onProjectChange={props.changeProjectId}
                            handleUpdate={null}
                        />
                    </Col>
                </Row>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <MoodIcon />
                        </span>
                        <span className="sidebarTitle">Person</span>
                    </Col>
                    <Col span={14}>
                        <UserAutoComplete
                            active={props.userId}
                            handleUpdate={null}
                            onUserChange={props.handleMember}
                        />
                    </Col>
                </Row>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <EventAvailableIcon />
                        </span>
                        <span className="sidebarTitle">Start - End</span>
                    </Col>
                    <Col span={14}>
                        <span>
                            <div style={{ display: 'flex' }}>
                                <DatePicker
                                    onChange={handleStartDate}
                                    value={start}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    format="MMM dd, y"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YYYY"
                                    dayPlaceholder="DD"
                                    className="sidebarCustomCalender hoverBGColor"
                                    calendarClassName="sidebarCustomCalenderView"
                                />
                                <div className="highPen">-</div>
                                <DatePicker
                                    onChange={handleEndDate}
                                    value={end}
                                    minDate={start}
                                    clearIcon={null}
                                    calendarIcon={null}
                                    format="MMM dd, y"
                                    monthPlaceholder="MM"
                                    yearPlaceholder="YYYY"
                                    dayPlaceholder="DD"
                                    className="sidebarCustomCalender hoverBGColor"
                                    calendarClassName="sidebarCustomCalenderView"
                                />
                            </div>
                        </span>
                    </Col>
                </Row>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <ScheduleIcon />
                        </span>
                        <span className="sidebarTitle">Time per Day</span>
                    </Col>
                    <Col span={14}>
                        <TextField
                            id="standard-number"
                            className="timePerDay editHoverBGColor"
                            type="number"
                            InputProps={{ inputProps: { min: 1, max: 10 } }}
                            value={hours}
                            onChange={onHourChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            disableUnderline={true}
                            autoFocus={false}
                        />
                        { hourMsg.length ? (
                            <div className="error_message">**{hourMsg} </div>
                        ) : null}
                    </Col>
                </Row>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <MilestoneIcon />
                        </span>
                        <span className="sidebarTitle">Milestone</span>
                    </Col>
                    <Col span={14}>
                        <Milstone
                            active={milesTask}
                            projectId={props.projectId}
                            onMilestoneChange={props.changeMilesId}
                            handleUpdate={props.handleUpdate}
                        />
                    </Col>
                </Row>
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <Col span={10}>
                        <span className="sidebarIcon">
                            <LayersIcon />
                        </span>
                        <span className="sidebarTitle">Automatically Skip</span>
                    </Col>
                    <Col span={14}>
                        <div style={{ display: 'flex', marginTop: 5 }} >
                            {isSplit? (<div
                                className="weekendIcon"
                                onClick={() => onSplitChange()}
                            >
                                <CheckCircleIcon/>
                            </div>) : (
                                <div
                                    className="weekendIcon"
                                    onClick={() => onSplitChange()}
                                >
                                    <CheckCircleOutlineIcon/>
                                </div>
                            )}
                            <div className="weekendTitle"
                                 onClick={() => onSplitChange()}>Weekends</div>
                        </div>
                    </Col>
                </Row>
                <div className="sidebarDescriptionWrapper">
                    <Row className="sidebarControllerRow" type="flex" align="middle">
                        <TextareaAutosize
                            defaultValue=""
                            placeholder="Task description"
                            value={props.value}
                            onBlur={props.detailsText}
                            // maxRows={10}
                            minRows={5}
                            style={{ backgroundColor: '#fff', width: '100%', fontFamily: 'Sofia Pro', fontSize: 14, color: '#585858' }}
                        />
                    </Row>
                </div>
                <div className="sidebarSubtaskWrapper">
                    <Row className="sidebarControllerRow" type="flex" align="middle">
                        <SubTask
                            taskId={props.taskID}
                            userId={props.userId}
                            permitted={props.permitted}
                        />
                    </Row>
                </div>
            </DialogContent>
            <DialogActions>
                <div style={{ display: 'flex', width: '100%', margin: 12 }}>
                    <div className="text_button" onClick={() => onFirstClick()}>
                        Save
                    </div>
                    <div 
                        className="text_button" 
                        onClick={() => props.discard(props.token)}
                    >
                        Discard
                    </div>
                    {isProgress === true ? createProgress : ''}
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default AddTask;