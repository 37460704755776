import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    width: '95%'
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

export default function CustomizedInputBase(props) {
  const classes = useStyles();

  let { onSearch, label } = props

  let [search, setSearch] = useState('')

  let handleSearch = e => {
    setSearch(e.target.value)
    onSearch(e.target.value)
  }

  return (
    <InputBase
      className={classes.input}
      placeholder={`Search ${label}`}
      value={search}
      onChange={handleSearch}
    />
  );
}