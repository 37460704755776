import {
  ADD_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  ADD_TASK_USER,
  ALL_TASK_USER,
  TASK_LOADING,
  SET_TASK_LOGS,
  ALL_SPLIT_TASK,
  ALL_REPORT_TASK
} from '../Constants/index'

const initialState = {}

const taskReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TASK:
      return {
        ...state,
        task: action.data
      }

    case UPDATE_TASK:
      return {
        ...state,
        task: action.data
      }

    case DELETE_TASK:
      return {
        ...state,
        task: action.data
      }

    case ADD_TASK_USER:
      return {
        ...state,
        task: action.data
      }
    case ALL_TASK_USER:
      return {
        ...state,
        taskDetails: action.data
      }

    case TASK_LOADING:
      return {
        ...state,
        taskLoading: action.data
      }

    case SET_TASK_LOGS:
      return {
        ...state,
        taskLogs: action.data
      }
    case ALL_SPLIT_TASK:
      return {
        ...state,
        splitTasks: action.data
      }
    case ALL_REPORT_TASK:
      return {
        ...state,
        reportTask: action.data
      }

    default:
      return state
  }
}

export default taskReducers