import React from 'react'
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import AccessTimeIcon from '@material-ui/icons/AccessTime';

export const sidebar = [
    {
        title: 'Time Tracker',
        icon: <AccessTimeIcon />
    },
    {
        title: 'Log Report',
        icon: <LiveHelpIcon/>
    }
]

export const sidebarStatus = [
    {
        title: 'Task Status',
        icon: <AccessTimeIcon />
    }
]