import axios from "axios";

import { setLocalForage, getLocalForage } from "./useForage";

import {
  SET_USER_DETAILS,
  SET_USER_LOCAL,
  SET_USER_TOKEN,
  REMOVE_TOKEN,
  SET_SIGNUP_ERROR,
  SET_LOGIN_ERROR,
  ACTIVE_TASK_USER,
} from "../Constants";

import {
  setWorkspace,
  getWorkspaceDetails,
  removeWorkspace,
  getAllWorkspaces,
} from "../Actions/settingsActions";
import {
  getAllTeams,
  setTeamToLocal,
  removeTeamStore,
} from "../Actions/teamActions";
import { setAllTeamMembers } from "../Actions/teamMemberActions";
import { taskLoading } from "../Actions/taskActions";
import { setAllHoliday, setLoading } from "../Actions/holidayActions";

import { serverURI } from "./config";
// const serverURI = "http://192.168.2.3:5001/api/";
//Must add 'JWT ' string before the token

export function userRegister(userData) {
  return (dispatch) => {
    return axios
      .post(serverURI + "post/sign-up", userData)
      .then((res) => {
        console.log("Signup data response", res);
        return res;
      })
      .catch((error) => {
        if (error.response) {
          // console.log(error.response)
          dispatch(setSignupError(error.response.data.msg));
        }
      });
  };
}

export const inviteUser = (userData, token) => {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };

  return async () => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      let invite = await axios.post(
        serverURI + "post/add-invited-user",
        userData
      );

      if (invite.status === 200) {
        response.status = invite.status;
        response.message = "User invited";
        response.token = token;
        response.data = invite.data;
      }
      return response;
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
    }
  };
};

export const getInvitedUser = (userData, token) => {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };

  return async () => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      let invite = await axios.post(
        serverURI + "post/get-invited-user",
        userData
      );

      if (invite.status === 200) {
        response.status = invite.status;
        response.message = "User Fetched";
        response.token = token;
        response.data = invite.data;
      }
      return response;
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
    }
  };
};

export const removeInvitedUser = (userData, token) => {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };

  return async () => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      let invite = await axios.post(
        serverURI + "post/remove-invited-user",
        userData
      );

      if (invite.status === 200) {
        response.status = invite.status;
        response.message = "User removed";
        response.token = token;
        response.data = invite.data;
      }
      return response;
    } catch (err) {
      if (err.response) {
        console.log(err.response.data.msg);
        return;
      }
    }
  };
};

export function userLogin(userData) {
  let response = {
    status: "",
    message: "",
    token: "",
  };

  return async (dispatch) => {
    try {
      dispatch(taskLoading(true));
      dispatch(setLoading(true));
      const userLoginData = await axios.post(
        serverURI + "post/log-in",
        userData
      );
      if (userLoginData.status === 200) {
        response.token = userLoginData.data.token;
        response.status = userLoginData.status;
        let userWorkspace = [];

        if (
          userLoginData.data.workspace &&
          userLoginData.data.workspace.length > 0
        ) {
          userLoginData.data.workspace.map((data, index) => {
            userWorkspace[index] = data.workspaceId;
          });
        }

        let workspaceSettings = { workspace: userWorkspace };

        let userLocalData = {
          token: userLoginData.data.token,
          userDetails: userLoginData.data.user,
        };
        let workspaceData = {
          workspaceId: userWorkspace[0]
            ? userWorkspace[0]
            : userLoginData.data.workspace[0].workspaceId,
        };

        axios.defaults.headers.common["Authorization"] =
          "JWT " + userLoginData.data.token;

        const userWorkspaceData = await axios.post(
          serverURI + "post/get-workspace",
          workspaceData
        );

        let allHolidays = await axios.post(
          serverURI + "post/get-all-holiday",
          workspaceData
        );

        if (allHolidays.status === 200) {
          dispatch(setAllHoliday(allHolidays.data));
        }

        if (userWorkspaceData.status === 200) {
          localStorage.setItem("teamTrekking", JSON.stringify(userLocalData));
          localStorage.setItem(
            "teamTrekkingSettings",
            JSON.stringify(workspaceSettings)
          );
          localStorage.setItem("loggedIn", new Date().getDate());
          localStorage.setItem("userVerified", userLoginData.data.user.status);
          dispatch(setTeamToLocal(""));
          dispatch(setUser(userLoginData.data.user));
          dispatch(setToken(userLoginData.data.token));
          dispatch(setWorkspace(userWorkspace));
          dispatch(getWorkspaceDetails([userWorkspaceData.data]));
          dispatch(setLoading(false));

          const userWorkspaces = await axios.get(
            serverURI + "get/get-workspaces-by-user"
          );

          if (userWorkspaces.status === 200) {
            dispatch(getAllWorkspaces(userWorkspaces.data));
          }

          const userTeams = await axios.post(
            serverURI + "post/get-all-teams",
            workspaceData
          );

          dispatch(getAllTeams(userTeams.data));
          dispatch(setTeamToLocal(userTeams.data[0]._id));

          const userAllTeamMembers = await axios.post(
            serverURI + "post/find-all-team-members",
            workspaceData
          );

          if (userAllTeamMembers.status === 200) {
            dispatch(setAllTeamMembers(userAllTeamMembers.data));
          }

          let inviteData = {
            email: userLoginData.data.user.email,
          };

          let invite = await axios.post(
            serverURI + "post/get-invited-user",
            inviteData
          );

          if (invite.status === 200 && invite.data !== null) {
            let addData = {
              userId: userLoginData.data.user.id,
              workspaceId: invite.data.workspaceId,
              teamId: invite.data.teamId,
            };
            let addMember = await axios.post(
              serverURI + "post/add-team-member",
              addData
            );

            if (addMember.status === 200) {
              let removeInvited = await axios.post(
                serverURI + "post/remove-invited-user",
                inviteData
              );
              if (removeInvited.status === 200) {
                console.log("User removed from invited list");
              }
            }
          }

          response.message = "Successfully Login";
        }
        return response;
      }
    } catch (err) {
      if (err.response) {
        dispatch(setLoginError(err.response.data.msg));
        return;
      }
    }
  };
}

export function updateUser(data, token) {
  let response = {
    status: "",
    data: "",
    message: "",
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      let updated = await axios.post(serverURI + "post/update-user", data);

      if (updated.status === 200) {
        let userLocalData = {
          token: token,
          userDetails: updated.data,
        };
        localStorage.setItem("teamTrekking", JSON.stringify(userLocalData));
        dispatch(setUser(updated.data));
        response.data = updated.data;
        response.status = 200;
        response.message = "User updated";
      }

      return response;
    } catch (err) {
      if (err.response) {
        dispatch(setLoginError(err.response.data.msg));
        return;
      }
    }
  };
}

export function updateImage(data, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  // console.log('Update Imeage Data', data)
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      let updated = await axios({
        method: "post",
        url: serverURI + "post/update-image",
        data: data.bodyFormData,
        config: {
          headers: { "Content-Type": "multipart/form-data" },
        },
      });

      if (updated.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = updated.data;
        let userLocalData = {
          token: token,
          userDetails: updated.data,
        };
        localStorage.setItem("teamTrekking", JSON.stringify(userLocalData));
        dispatch(setUser(updated.data));
        response.message = "User Updated";
      }

      return response;
    } catch (err) {
      response.status = 500;
      response.message = "Server error";
    }
  };
}

export function verifyUser(data, token) {
  let response = {
    status: "",
    data: "",
    message: "",
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      let verified = await axios.post(serverURI + "post/verify-user", data);

      if (verified.status === 200) {
        let userLocalData = {
          token: token,
          userDetails: verified.data,
        };
        localStorage.setItem("teamTrekking", JSON.stringify(userLocalData));
        dispatch(setUser(verified.data));
        response.data = verified.data;
        response.status = 200;
        response.message = "User verified";
      }

      return response;
    } catch (err) {
      if (err.response) {
        dispatch(setLoginError(err.response.data.msg));
        return;
      }
    }
  };
}

export function forgetPassword(inputData) {
  let response = {
    status: "",
    data: "",
    message: "",
  };

  return async (dispatch) => {
    try {
      let user = await axios.post(
        serverURI + "post/forget-password",
        inputData
      );

      if (user.status === 200) {
        response.status = 200;
        response.data = user.data;
        localStorage.setItem("email", user.data.email);
        response.message = "User data fetched";
        dispatch(setUser(user.data));
      } else {
        response.status = 500;
        response.message = "Error from forget password method";
      }

      return response;
    } catch (err) {
      response.status = 500;
      response.message = "Error from forget password method";

      return response;
    }
  };
}

export function resendCode(inputData) {
  let response = {
    status: "",
    data: "",
    message: "",
  };

  return async (dispatch) => {
    try {
      let user = await axios.post(
        serverURI + "post/resend-code",
        inputData
      );

      if (user.status === 200) {
        response.status = 200;
        response.data = user.data;
        response.message = "Code resent on Registered Email";
        dispatch(setUser(user.data));
      } else {
        response.status = 500;
        response.message = "Error from forget password method";
      }

      return response;
    } catch (err) {
      response.status = 500;
      response.message = "Error from forget password method";

      return response;
    }
  };
}

export function verifyByMail(inputData) {
  let response = {
    status: "",
    data: "",
    message: "",
  };

  return async (dispatch) => {
    try {
      let user = await axios.post(
        serverURI + "post/verify-by-email",
        inputData
      );
      if (user.status === 200) {
        response.status = 200;
        response.data = user.data;
        localStorage.setItem("email", user.data.email);
        response.message = "User data fetched";
        dispatch(setUser(user.data));
        localStorage.setItem("userVerified", user.data.status);
      } else {
        response.status = 500;
        response.message = "Error from forget password method";
      }

      return response;
    } catch (err) {
      response.status = 500;
      response.message = "Error from forget password method";
      return response;
    }
  };
}

export function updatePassword(inputData) {
  let response = {
    status: "",
    data: "",
    message: "",
  };

  return async (dispatch) => {
    try {
      let user = await axios.post(
        serverURI + "post/change-password",
        inputData
      );

      if (user.status === 200) {
        response.status = 200;
        response.data = user.data;
        localStorage.clear();
        response.message = "User data fetched";
        dispatch(setUser(user.data));
      } else {
        response.status = 500;
        response.message = "Error from forget password method";
      }

      return response;
    } catch (err) {
      response.status = 500;
      response.message = "Error from forget password method";

      return response;
    }
  };
}

export const setActiveUser = (user) => {
  return (dispatch) => {
    dispatch(setActiveUserToStore(user));
  };
};

export const setActiveUserToStore = (data) => {
  return {
    type: ACTIVE_TASK_USER,
    data: data,
  };
};

export function setSignupError(msg) {
  return {
    type: SET_SIGNUP_ERROR,
    data: msg,
  };
}

export function setLoginError(msg) {
  return {
    type: SET_LOGIN_ERROR,
    data: msg,
  };
}

export function setUser(userData) {
  return {
    type: SET_USER_DETAILS,
    data: userData,
  };
}

export function setToken(token) {
  return {
    type: SET_USER_TOKEN,
    data: token,
  };
}

export function checkUser() {
  if (localStorage.getItem("teamTrekking")) {
    return {
      type: SET_USER_LOCAL,
      data: JSON.parse(localStorage.getItem("teamTrekking")),
    };
  } else {
    return {
      type: REMOVE_TOKEN,
      data: "",
    };
  }
}

export function logOutUser() {
  window.location.reload(false);
  // localStorage.removeItem('teamTrekking')
  // localStorage.removeItem('teamTrekkingSettings')
  // localStorage.removeItem('teamTrekkingTeams')
  // localStorage.removeItem('loggedIn')
  // localStorage.removeItem('userVerified')
  localStorage.clear();
  return (dispatch) => {
    dispatch(setLoginError(null));
    dispatch(setSignupError(null));
    dispatch(removeWorkspace());
    dispatch(removeTeamStore());
    dispatch(setLogOut());
  };
}

export function setLogOut() {
  return { type: REMOVE_TOKEN, data: "" };
}
