import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Scrollbars } from "react-custom-scrollbars";
import ProjectLists from "../../Components/ProjectLists";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import "../../Assets/CSS/ProjectDialog.css";
import DialogActions from "@material-ui/core/DialogActions";
import ConfirmDelete from "./ConfirmDelete";
import { updateProject } from "../../Actions/projectActions";
import { search } from "ss-search";
import Search from "./ProjectSearch";

import { connect } from "react-redux";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 16,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function EditProject(props) {
  const classes = useStyles();
  let {
    allProjects,
    onUpdate,
    token,
    open,
    handleClose,
    createClick,
    handleProjectId,
    taskStatus,
  } = props;

  let [value, setValue] = useState("");
  let [searchText, setSearchKey] = useState("");
  let [deletePop, setDeletePop] = useState(false);
  let [deleteItem, setDeleteItem] = useState({});

  let searchKey = ["projectTitle", "projectCode"];

  let handleProjectChange = (event) => {
    setValue(event.target.value);
  };

  let handleEditProject = () => {
    handleClose();
  };

  let onClickCreate = () => {
    createClick();
  };

  let deleteProjectItem = (value) => {
    setDeletePop(true);
    setDeleteItem(value);
  };

  let confirmDelete = () => {
    if (deleteItem._id) {
      handleDeleteProject(deleteItem._id);
    }
  };

  let cancelDelete = () => {
    setDeletePop(false);
    setDeleteItem({});
  };

  let handleDeleteProject = (value) => {
    let data = {
      projectId: value,
      projectStatus: false,
    };
    props.updateProject(data, token).then((res) => {
      socket.emit("updateProject", res.data);
      setDeletePop(false);
      setDeleteItem({});
    });
  };

  let filtered = search(allProjects, searchKey, searchText);

  return (
    <React.Fragment>
      <Dialog
        className="customDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "left",
              }}
            >
              All projects
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Search label={"Project"} onSearch={setSearchKey} />
          <div style={{ marginTop: 12 }}>
            <div
              style={{
                display: "flex",
                height: 56,
                background: "rgba(0,0,0,0.1)",
              }}
            >
              <div className="divHeaders">Project Name</div>
              <div className="divHeaders" style={{ paddingLeft: 250 }}>
                Status
              </div>
              <div className="divHeaders" style={{ paddingLeft: 100 }}>
                Action
              </div>
            </div>
            <Scrollbars style={{ height: (window.innerHeight / 5) * 3 }}>
              <div>
                {
                  <ProjectLists
                    allProjects={filtered}
                    editProject={handleProjectId}
                    deleteProject={deleteProjectItem}
                    taskStatus={taskStatus}
                  />
                }
              </div>
            </Scrollbars>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="createProject" onClick={onClickCreate}>
              <AddCircleIcon />
              <div className="create">Create Project</div>
            </div>
          </div>
        </DialogContent>
        <ConfirmDelete
          open={deletePop}
          deleteProject={deleteItem}
          confirm={confirmDelete}
          cancel={cancelDelete}
        />
      </Dialog>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    taskStatus: state.taskStatus ? state.taskStatus.allStatus : [],
  };
}

export default connect(mapStateToProps, {
  updateProject,
})(EditProject);
