/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import { addProject } from "../../Actions/projectActions";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const filter = createFilterOptions();

function FreeSoloCreateOption(props) {
  let {
    active,
    projectId,
    milestones,
    onMilestoneChange,
    handleUpdate,
  } = props;
  let activeMilestone = milestones.length
    ? milestones.find(
        (item) => item.projectId.toString() === projectId.toString()
      )
    : null;
  let milestoneValue = activeMilestone
    ? activeMilestone.milestoneList
      ? activeMilestone.milestoneList.find((item) => item._id === active)
      : null
    : null;

  let valueTitle = milestoneValue ? milestoneValue.title : "";
  let [value, setValue] = React.useState({ title: valueTitle });

  // console.log('ProjectLists', projectLists)

  let handleChange = (milestoneId) => {
    onMilestoneChange(milestoneId);
    if (handleUpdate) {
      handleUpdate(true);
    }
  };

  let filteredMilestones = activeMilestone ? activeMilestone.milestoneList : [];

  return (
    <Autocomplete
      className="customSingleSelect editHoverBGColor"
      value={value ? value.title : value}
      disableClearable
      disableOpenOnFocus={false}
      forcePopupIcon={true}
      onChange={(event, newValue) => {
        if (newValue) {
          if (newValue._id) {
            handleChange(newValue._id);
            if (newValue) {
              setValue({
                title: newValue.title,
              });
              return;
            }
            setValue(newValue);
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            // title: `Add "${params.inputValue}"`
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      options={filteredMilestones}
      disabled={props.disabled}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.title) {
          return option.title;
        }
        return option.title;
      }}
      renderOption={(option) => (
        <Typography
          style={{
            width: "100%",
            fontSize: 14,
            fontFamily: "Sofia Pro",
            fontWeight: 300,
          }}
        >
          {option.title}
        </Typography>
      )}
      style={{ width: "100%", marginRight: "1%" }}
      freeSolo
      renderInput={(params) => (
        <span className="sidebarProjectName">
          <TextField
            {...params}
            style={{ width: "100%", fontSize: 12 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        </span>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : "",
    workspace: state.settings.workspace ? state.settings.workspace : "",
    settings: state.settings ? state.settings : "",
    milestones: state.projects
      ? state.projects.allMilestones
        ? state.projects.allMilestones
        : []
      : [],
  };
}

export default connect(mapStateToProps, {
  addProject,
})(FreeSoloCreateOption);
