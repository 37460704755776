import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 16,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function EditProject(props) {
  const classes = useStyles();
  let { allProjects, onUpdate, token, open, handleClose } = props;

  let [value, setValue] = React.useState("");

  let handleProjectChange = (event) => {
    setValue(event.target.value);
  };

  let onSubmit = () => {
    let data = {
      projectId: value,
      projectStatus: false,
    };
    onUpdate(data, token).then((res) => {
      socket.emit("updateProject", []);
      setValue("");
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Remove a project"}</DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="max-width">Select Project</InputLabel>
              <Select autoFocus value={value} onChange={handleProjectChange}>
                {allProjects
                  ? allProjects.map((item, key) =>
                      item.projectStatus ? (
                        <MenuItem key={key} value={item._id}>
                          {item.projectTitle}
                        </MenuItem>
                      ) : null
                    )
                  : null}
              </Select>
            </FormControl>
            <Button onClick={onSubmit} color="primary">
              Submit
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default EditProject;
