import axios from 'axios'

import {
  ADD_TASK,
  UPDATE_TASK,
  DELETE_TASK,
  ADD_TASK_USER,
  ALL_TASK_USER,
  TASK_LOADING,
  SET_TASK_LOGS,
  ALL_SPLIT_TASK,
  ALL_REPORT_TASK
} from '../Constants'
// import { func } from 'prop-types';
import { serverURI } from './config';
//const serverURI = "http://192.168.2.3:5001/api/";
//Must add 'JWT ' string before the token

export function addNewDemoTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const addTaskData = await axios.post(
        serverURI + 'post/add-user-demo-task',
        data
      )
      if (addTaskData.status === 200) {
        response.status = addTaskData.status
        response.token = token
        response.data = addTaskData.data
        let customizeTaskdata = addTaskData.data
        dispatch(addTaskUser(customizeTaskdata))
        response.message = 'Task User Successfully Added'
      } else {
        response.status = 302
        response.message = addTaskData.data.message
      }
      console.log('Response from demotask', response)
      return response
    } catch(err) {
      console.log('=====ERR Return =====', err)
      response.status = 400
      response.message = err
      console.log('Error Response from demotask', response)
      return response
    }
  }
}

export function addNewTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const addTaskData = await axios.post(
        serverURI + 'post/add-new-task',
        data
      )
      try {
        if (addTaskData.status === 200) {
          response.status = addTaskData.status
          response.token = token
          response.data = addTaskData.data
          let customizeTaskdata = [addTaskData.data]
          dispatch(addTaskUser(customizeTaskdata))
          response.message = 'Task User Successfully Added'

          const taskLogs = await axios.post(
            serverURI + 'post/fetch-task-logs',
            data
          )

          if (taskLogs.status === 200) {
            dispatch(setTaskLog(taskLogs.data))
          }
        } else {
          response.status = 302
          response.message = addTaskData.data.message
        }
        return response
      } catch (err) {
        console.log('=====ERR Return =====', err)
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function updateTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updatedTask = await axios.post(serverURI + 'post/update-task', data)

      try {
        if (updatedTask.status === 200) {
          response.status = updatedTask.status
          response.token = token
          response.data = updatedTask.data
          dispatch(updateTaskData(updatedTask.data))
          response.message = 'Task Successfully updated'

          const taskLogs = await axios.post(
            serverURI + 'post/fetch-task-logs',
            data
          )

          if (taskLogs.status === 200) {
            dispatch(setTaskLog(taskLogs.data))
          }
        } else {
          response.status = 302
          response.message = updatedTask.data.message
        }
        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function deleteTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return dispatch => {
      axios.defaults.headers.common['Authorization'] = token
      return axios
        .post(serverURI + 'post/delete-task', data)
        .then(res => {
          if (res.status === 200) {
            response.status = res.status
            response.token = token
            dispatch(removeTask(res.data))
            response.message = 'Task Deleted'
          } else {
            response.status = 302
            response.message = res.data.message
          }
          return response
        })
        .catch(err => {
          response.status = 400
          response.message = err
          return response
        })
    }
  }
}

export function addNewTaskUser(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: ''
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      const addTaskData = await axios.post(
        serverURI + 'post/add-new-user-task',
        data
      )
      try {
        if (addTaskData.status === 200) {
          response.status = addTaskData.status
          response.token = token
          response.data = addTaskData.data
          let taskId = addTaskData.data.taskId
          let customizeTaskdata = { [taskId]: addTaskData.data }
          dispatch(addTaskUser(customizeTaskdata))
          response.message = 'Task User Successfully Added'
        } else {
          response.status = 302
          response.message = addTaskData.data.message
        }
        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function allTaskUser(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: ''
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      dispatch(taskLoading(true))
      const userTaskData = await axios.post(
        serverURI + 'post/get-all-user-task',
        data
      )
      try {
        if (userTaskData.status === 200) {
          response.status = userTaskData.status
          response.token = token
          response.data = userTaskData.data
          dispatch(allTaskUserSet(userTaskData.data))
          dispatch(taskLoading(false))
          response.message = 'Task User Successfully Stored'
          const taskLogs = await axios.post(
            serverURI + 'post/fetch-task-logs',
            data
          )

          if (taskLogs.status === 200) {
            dispatch(setTaskLog(taskLogs.data))
          }
        } else {
          response.status = 302
          response.message = userTaskData.data.message
          dispatch(taskLoading(false))
        }
        return response
      } catch (err) {
        response.status = 400
        dispatch(taskLoading(false))
        response.message = err
        return response
      }
    }
  }
}

export function removeUserTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const removedTask = await axios.post(
        serverURI + 'post/remove-user-task',
        data
      )
      try {
        if (removedTask.status === 200) {
          response.status = removedTask.status
          response.token = token
          response.data = removedTask.data
          dispatch(removeTask(removedTask.data))
          response.message = 'Task Removed Successfully'
        } else {
          response.status = 302
          response.message = removedTask.data.message
        }

        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function getAllDemoTask(token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async () => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let demoTask = await axios.get(
        serverURI + 'get/get-demo-task'
      )
      try {
        if (demoTask.status === 200) {
          response.status = demoTask.status
          response.token = token
          response.data = demoTask.data

          response.message = 'Demo Task Fetched Successfully'
        } else {
          response.status = 302
          response.message = demoTask.data.message
        }

        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function getAllSplitTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let splitTask = await axios.post(
        serverURI + 'post/fetch-split-list',
        data
      )
      try {
        if (splitTask.status === 200) {
          response.status = splitTask.status
          response.token = token
          response.data = splitTask.data
          dispatch(setSplitTask(splitTask.data))
          response.message = 'Task Removed Successfully'
        } else {
          response.status = 302
          response.message = splitTask.data.message
        }

        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function getAllReportTask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let reportTask = await axios.post(
        serverURI + 'post/get-monthly-logs',
        data
      )
      try {
        if (reportTask.status === 200) {
          response.status = reportTask.status
          response.token = token
          response.data = reportTask.data
          dispatch(setReportTask(reportTask.data))
          response.message = 'Task Removed Successfully'
        } else {
          response.status = 302
          response.message = reportTask.data.message
        }

        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function addTask(taskData) {
  return {
    type: ADD_TASK,
    data: taskData
  }
}

export function updateTaskData(taskData) {
  return {
    type: UPDATE_TASK,
    data: taskData
  }
}

export function removeTask(taskData) {
  return {
    type: DELETE_TASK,
    data: taskData
  }
}

export function setSplitTask(taskData) {
  // localStorage.setItem('ttkSplitTasks', JSON.stringify(taskData))
  return {
    type: ALL_SPLIT_TASK,
    data: taskData
  }
}


export function setReportTask(taskData) {
  return {
    type: ALL_REPORT_TASK,
    data: taskData
  }
}

export function addTaskUser(taskData) {
  return {
    type: ADD_TASK_USER,
    data: taskData
  }
}

export function allTaskUserSet(taskData) {
  return {
    type: ALL_TASK_USER,
    data: taskData //final
  }
}

export function taskLoading(data) {
  return {
    type: TASK_LOADING,
    data: data
  }
}

export function setTaskLog(data) {
  // localStorage.setItem('ttkTaskLogs', JSON.stringify(data))
  return {
    type: SET_TASK_LOGS,
    data: data
  }
}
