import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CancelDialog from './CancelLeave'
import Pagination from '@material-ui/lab/Pagination';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#5765B3',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 700
  },
});

let LeaveHistory = props => {
  const classes = useStyles();
  let { rowData, userName } = props
  let [page, setPage] = useState(1)
  let [rowsPerPage, setRowsPerPage] = useState(10)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  return (
    <div style={{ marginBottom: 15 }}>
      {rowData.length ? (
        <TableContainer align='center'>
          <div
            style={{
              fontSize: 18,
              textAlign: 'center',
              margin: '10px 0px'
            }}
          >
            Leaves Approved
          </div>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Date(s)</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Days</StyledTableCell>
                <StyledTableCell align="center">Approved By</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.length ? (rowsPerPage > 0
              ? rowData &&
              rowData.slice(
                (page - 1) * rowsPerPage,
                (page - 1) * rowsPerPage + rowsPerPage
              )
              : rowData
            ).map((row, key) => (
                <StyledTableRow key={key}>
                  <StyledTableCell align="center" scope="row">
                    {row.dates.map(day => {
                      return <div>{day}</div>
                    })}
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.type}</StyledTableCell>
                  <StyledTableCell align="center">{row.days}</StyledTableCell>
                  <StyledTableCell align="center">{row.operatedBy}</StyledTableCell>
                </StyledTableRow>
              )) : null}
            </TableBody>
            <div>
              <Pagination
                style={{height: 48, paddingTop: 8}}
                count={rowData ? rowData.length ?
                  Math.ceil(rowData.length / 10) : 0 : 0
                }
                page={page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true
                }}
                onChange={handleChangePage}
              />
            </div>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
}

export default LeaveHistory