import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import { Scrollbars } from 'react-custom-scrollbars';
import TextareaAutosize from 'react-textarea-autosize';
import '../../Assets/CSS/ProjectDialog.css';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import MilestoneTasklList from "./MilestoneTaskList";

function MilestoneTask(props) {
    let {
        open,
        handleClose,
    }=props

    return(
        <>
            <Dialog className="customDialog"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                    aria-describedby="form-dialog-description">

                <DialogTitle id="form-dialog-title" style={{ background: 'rgba(73,121,255,0.85)' }}>
                    <div style={{ display: 'flex' }}>
                        <TextareaAutosize
                            value={''}
                            placeholder="Milestone title"
                            onKeyPress={event => {
                                if (event.key === 'Enter') {
                                    return event.preventDefault()
                                }
                            }}
                            autoFocus
                        />
                        <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
                    </div>
                </DialogTitle>
                <DialogContent style={{ paddingTop: 10, overflow: 'auto' }}>
                    <Scrollbars>
                        <div className="sidebarControllerWrapper">
                            <Row className="sidebarControllerRow" type="flex" align="middle">
                                <MilestoneTasklList/>
                                <MilestoneTasklList/>
                                <MilestoneTasklList/>
                                <MilestoneTasklList/>
                                <MilestoneTasklList/>
                            </Row>
                        </div>
                    </Scrollbars>
                </DialogContent>

            </Dialog>
        </>
    );
}

export default MilestoneTask;