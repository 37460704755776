import React, {useEffect, useState} from 'react';
import Button from "@material-ui/core/Button";
import {Checkbox, Input, List, ListItem} from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import withStyles from "@material-ui/core/styles/withStyles";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import styled from "styled-components";
let socket = socketIOClient(serverSocket);

const GreenCheckbox = withStyles({
    root: {
        color: "#A0A0A0",
        "&$checked": {
            color: "#38F8C4",
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


const SubTaskLittle = (props) => {
    const [fields, setFields] = useState([]);
    const [state, setState] = useState([]);

    let subTaskState = props.littleSubTask.length
        ? props.littleSubTask.map((item) => {
            let retData = {
                id: item.sortOrder,
                value: item.subTaskTitle,
                subTaskId: item.subTaskId,
                assignedUserId: item.assignedUserId,
                status: item.completion,
                focus: true,
                dropDown: item.assignedUserId !== props.userId ? true : false,
                dbId: item._id,
            };

            return retData;
        })
        : [];

    let len = subTaskState.length;
    if (props.permitted && len > 0) {
        subTaskState.push({
            id: len,
            value: null,
            subTaskId: props.taskId,
            assignedUserId: props.userId,
            status: false,
            focus: false,
            dropDown: false,
            dbId: len,
        });
    }

    useEffect(()=>{
        if (props.littleSubTask.length > 0){
            setFields(subTaskState)
        }
    },[props.littleSubTask])

    const handleChangeMap = (id) => {
        const nextState = fields.map((a) => {
            if (a.dbId === id) a.status = !a.status;
            return a;
        });
        setState(nextState);
        if (props.taskId.length) {
            let filtered = nextState.filter((item) => item.dbId === id);
            if (filtered.length) {
                if (props.permitted) {
                    let sendData = {
                        subTaskId: filtered[0].dbId,
                        completion: filtered[0].status,
                    };
                    props.updateSubtask(sendData, props.user.token).then((res) => {
                        let dbData = res.data;
                        let values = [...fields];
                        values[dbData.sortOrder].dbId = dbData._id;
                        setFields(values);
                        socket.emit("updateSubtask", []);
                    });
                }
            }
        }
    };

    function handleAdd() {
        const values = [...fields];
        values.push({ id: 0, value: null, subTaskId: props.taskId, status: false, focus: false, dropDown: false, assignedUserId: props.userId });
        setFields(values);
    }

    function handleChange(i, event) {
        const values = [...fields];
        values[i].value = event.target.value;
        let txt = event.target.value;
        if (!txt.length) values[i].focus = false;
        if (txt.charAt(txt.length - 1) === '@') {
            values[i].dropDown = true;
        } else {
            values[i].dropDown = false;
        }
        setFields(values);
    }

    let onChangeSelect = (i, id, event) => {
        const values = [...fields];
        values[i].assignedUserId = event.target.value;
        let txt = event.target.value;
        if (!txt.length) values[i].focus = true;
        setFields(values);

        if (props.taskId.length && typeof id !== 'number') {
            let filtereds = fields.filter((item) => item.dbId === id);
            if (filtereds.length) {
                if (props.permitted) {
                    let sendData = {
                        subTaskId: filtereds[0].dbId,
                        assignedUserId: event.target.value,
                    };
                    props.updateSubtask(sendData, props.user.token).then((res) => {
                        let dbData = res.data;
                        let values = [...fields];
                        values[dbData.sortOrder].dbId = dbData._id;
                        setFields(values);
                        socket.emit("updateSubtask", []);
                    });
                }
            }
        }
    }

    function handleAddOnEnter(event) {
        let code = event.keyCode || event.which;
        if (code === 13 && props.permitted) {
            const values = [...fields];
            let lastItem = values.length - 1;
            values[lastItem].focus = true;
            setFields(values);
            if (props.taskId.length) {
                onSingleSubmit(values[lastItem]);
            }
        }
    }

    function onSingleSubmit(data) {
        let values = [...fields];
        let title = data?.value?.replaceAll('@', '')
        if (title !== '' && title !== undefined && title !== null){
            let sendData = {
                subTaskTitle: title,
                parentTaskId: props.parentTaskId,
                subTaskId: props.taskId,
                completion: data.status,
                sortOrder: data.id,
                assignedUserId: data.assignedUserId,
                workspaceId: props.workspaceId
            };
            if (props.permitted) {
                props.addSingleSubtask(sendData, props.user.token).then((res) => {
                    let dbData = res.data;
                    values[data.id].dbId = dbData._id;
                    setFields(values);
                    socket.emit("updateSubtask", []);
                });
            }

            let lastItem = values.length - 1;
            values[lastItem].focus = true;
            if (values[lastItem].value) {
                values.push({
                    id: lastItem + 1,
                    value: null,
                    subTaskId: props.taskId,
                    assignedUserId: props.userId,
                    status: false,
                    focus: false,
                    dropDown: false,
                    dbId: lastItem + 1,
                });
                setFields(values);
            }
        }
    }

    function handleRemove(i) {
        const values = [...fields];
        if (props.taskId.length) {
            if (props.permitted) {
                let sendData = {
                    subTaskId: values[i].dbId
                };
                values.splice(i, 1);
                props.deleteSubtaskLittle(sendData, props.user.token).then((res) => {
                    setFields(values);
                    socket.emit("updateSubtask", []);
                });
            }
        }
    }

    return (
        <div className="subTaskWrapper" style={{marginLeft: 24}}>
            {fields.length < 1 && props.permitted && props.taskId !== undefined && props.taskId !== null && props.taskId !== '' && isNaN(props.taskId) ? (
                <div style={{cursor: 'pointer', padding: 2, fontSize: 8}} onClick={() => handleAdd()}>+@Subtask</div>
            ) : (
                ""
            )}

            <List>
                {fields.map((field, idx) => {
                    return (
                        <StyledMain>
                            <ListItem key={`${field}-${idx}`}>
                                <GreenCheckbox
                                    key={idx}
                                    className="subtaskCheckbox"
                                    onChange={() => handleChangeMap(field?.dbId)}
                                    checked={field.status}
                                    disabled={props.permitted ? false : true}
                                />
                                {field.status ? (
                                    <>
                                        <Input
                                            placeholder={"+Type and press enter to add a new to-do.."}
                                            autoFocus={idx + 1}
                                            value={field.value || ""}
                                            disabled={field.status}
                                            className="subtaskInputChecked"
                                            onChange={(e) => handleChange(idx, e)}
                                            onKeyPress={handleAddOnEnter.bind(this)}
                                            disabled={props.permitted ? false : true}
                                        />
                                        {
                                            field?.dropDown ?
                                                <select
                                                    onChange={(e) => onChangeSelect(idx, field?.dbId, e)}
                                                    value={field?.assignedUserId}
                                                    className="form-control-select">
                                                    <option>Select user</option>
                                                    {
                                                        props.teamMembers && props.teamMembers?.allTeamMember && props.teamMembers?.allTeamMember.length > 0 ?
                                                            props.teamMembers?.allTeamMember.map((member, key) =>
                                                                <option key={key} value={member?.userId}>{member?.name}</option>
                                                            ) : null
                                                    }
                                                </select> : null
                                        }
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            placeholder={"+Type and press enter to add a new to-do.."}
                                            autoFocus={idx + 1}
                                            value={field.value || ""}
                                            disabled={field.status}
                                            className="subtaskInput"
                                            onChange={(e) => handleChange(idx, e)}
                                            onKeyPress={handleAddOnEnter.bind(this)}
                                            disabled={props.permitted ? false : true}
                                        />
                                        {
                                            field?.dropDown ?
                                                <select
                                                    onChange={(e) => onChangeSelect(idx, field?.dbId, e)}
                                                    value={field?.assignedUserId}
                                                    className="form-control-select">
                                                    <option>Select user</option>
                                                    {
                                                        props.teamMembers && props.teamMembers?.allTeamMember && props.teamMembers?.allTeamMember.length > 0 ?
                                                            props.teamMembers?.allTeamMember.map((member, key) =>
                                                                <option key={key} value={member?.userId}>{member?.name}</option>
                                                            ) : null
                                                    }
                                                </select> : null
                                        }
                                    </>
                                )}
                                {field.focus ? (
                                    <DeleteOutlineIcon
                                        className="subtaskRemove"
                                        onClick={() => handleRemove(idx)}
                                        disabled={props.permitted ? false : true}
                                    />
                                ) : null}
                            </ListItem>
                        </StyledMain>
                    );
                })}
            </List>
        </div>
    );
};

let StyledMain = styled.div`

    .form-control-select{
      border: 0px solid rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
`

export default SubTaskLittle;