import {
  ADD_INITIAL_LOG,
  UPDATE_ESTIMATE,
  GET_WEEK_LOGS,
  UPDATE_DATE_USER,
  START_TIMER,
  PAUSE_TIMER,
  SET_RUNNING,
  UPDATE_SPENT,
} from "../Constants/index";

import axios from "axios";
import { serverURI } from "./config";

export function addInitialLog(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      let addNew = await axios.post(
        serverURI + "post/add-initial-logs",
        inputData
      );

      if (addNew.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = addNew.data;
        dispatch(setInit(addNew.data));
        response.message = "New log(s) added";
      } else {
        response.status = addNew.status;
        response.message = "Error from add new log";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export function updateEstimate(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      let update = await axios.post(
        serverURI + "post/update-estimate",
        inputData
      );

      if (update.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = update.data;
        dispatch(setEstimateUpdate(update.data));
        response.message = "Estimation updated";
      } else {
        response.status = update.status;
        response.message = "Error from update estimation";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export function updateSpent(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      let update = await axios.post(serverURI + "post/update-spent", inputData);

      if (update.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = update.data;
        dispatch(setSpentUpdate(update.data));
        response.message = "Spent updated";
      } else {
        response.status = update.status;
        response.message = "Error from update spent";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export function updateUserDate(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      let update = await axios.post(
        serverURI + "post/update-date-user",
        inputData
      );

      if (update.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = update.data;
        dispatch(setUpdateUser(update.data));
        response.message = "Log updated";
      } else {
        response.status = update.status;
        response.message = "Error from update";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export function getWeekLog(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;

      setRunning(true);
      let getLogs = await axios.post(
        serverURI + "post/get-week-logs",
        inputData
      );

      if (getLogs.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = getLogs.data;
        dispatch(setWeekLogs(getLogs.data));

        let runningLog = getLogs.data.find((log) => log.run === true);

        if (runningLog) {
          dispatch(setRunning(runningLog._id));
        } else {
          dispatch(setRunning(""));
        }

        response.message = "Logs fetched";
      } else {
        response.status = getLogs.status;
        response.message = "Error from logs fetch";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export function startTimer(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      let start = await axios.post(serverURI + "post/start-timer", inputData);

      if (start.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = start.data;
        dispatch(setStart(start.data));
        response.message = "Timer started";
      } else {
        response.status = start.status;
        response.message = "Error from start timer";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export function pauseTimer(inputData, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };
  return async (dispatch) => {
    try {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      let pause = await axios.post(serverURI + "post/pause-timer", inputData);

      if (pause.status === 200) {
        response.status = 200;
        response.token = token;
        response.data = pause.data;
        dispatch(setPause(pause.data));
        response.message = "timer paused";
      } else {
        response.status = pause.status;
        response.message = "Error from pause timer";
      }

      return response;
    } catch (err) {
      response.message = err;
      response.status = 500;

      return response;
    }
  };
}

export let setInit = (data) => {
  return {
    type: ADD_INITIAL_LOG,
    data: data,
  };
};

export let setEstimateUpdate = (data) => {
  return {
    type: UPDATE_ESTIMATE,
    data: data,
  };
};

export let setSpentUpdate = (data) => {
  return {
    type: UPDATE_SPENT,
    data: data,
  };
};

export let setUpdateUser = (data) => {
  return {
    type: UPDATE_DATE_USER,
    data: data,
  };
};

export let setWeekLogs = (data) => {
  return {
    type: GET_WEEK_LOGS,
    data: data,
  };
};

export let setStart = (data) => {
  return {
    type: START_TIMER,
    data: data,
  };
};

export let setPause = (data) => {
  return {
    type: PAUSE_TIMER,
    data: data,
  };
};

export let setRunning = (data) => {
  return {
    type: SET_RUNNING,
    data: data,
  };
};
