import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ColorProgress from '../../Components/Signprogress';
import TimerMixin from 'react-timer-mixin';
import { withRouter } from 'react-router-dom';
import GoogleLogin from "react-google-login";
import google from "../../Assets/images/google-button.svg";
import moment from 'moment';
import 'moment/locale/en-au';

// React Router Link
import {
    Link
} from "react-router-dom";
import {
    userRegister,
    userLogin,
    getInvitedUser,
    removeInvitedUser,
    logOutUser
} from "../../Actions/userActions";
import { addWorkspace } from '../../Actions/settingsActions'
import { addNewTeamMember } from '../../Actions/teamMemberActions'
import { addNewTeam } from '../../Actions/teamActions'
import { addNewTask, getAllDemoTask, addNewDemoTask } from '../../Actions/taskActions'
import { connect } from "react-redux";

function Copyright() {
    return (
      <Typography style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Sofia Pro' }}
                  variant="body2" color="textSecondary" align="center">
          {'Copyright © '}
          <a href="https://dcastalia.com/">
              Team Trakking
          </a>{' '}
          {new Date().getFullYear()}
          {'.'}
      </Typography>
    );
}

const useStyles = theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        fontFamily: 'Sofia Pro',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontFamily: 'Sofia Pro',
        fontSize: 14
    },
});

class SignUp extends Component {
    state = {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        password: '',
        confirmPassword: '',
        isUserNameNumber: false,
        isPassword: false,
        isProgress: false,
    }


    validateRegForm() {
        return this.state.firstName.length > 0
          && this.state.lastName.length > 0
          && (this.state.email.length > 0 || this.state.phone.length > 0)
          && this.state.userName.length > 0 && this.state.userName.password > 0;
    }
    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
        if ((event.target.name === 'password' || event.target.name === 'confirmPassword') && (this.state.password !== this.state.confirmPassword)) {

            console.log("Password & Confirm Password doesn't match")
            this.setState({
                confirmPassword: event.target.value,
                isPassword: false,
                isProgress: false
            })

        }
    }

    handleRegisterSubmit = event => {
        event.preventDefault();

        const userData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            password: this.state.password,
            email: this.state.email,
            phone: this.state.phone,
            userName: this.state.email.length > 0 ? this.state.email : this.state.phone,

        }
        if (this.state.password === this.state.confirmPassword) {
            this.setState(
              {
                  isPassword: false,
                  isProgress: true
              }
            )

            TimerMixin.setTimeout(
              () => {
                  this.props.userRegister(userData)
                    .then(res => {
                        if (res) {
                            const loginData = {
                                userName: userData.userName,
                                password: userData.password
                            }

                            let workspace = { workspace: loginData.userName };
                            this.props.addWorkspace(workspace, res.data.token)
                              .then(resWork => {
                                  if (resWork) {
                                      if (resWork.status === 200) {
                                          // console.log('Workspace', resWork.data)
                                          let addTeamData = {
                                              teamTitle: 'Demo Team',
                                              workspaceId: resWork.data._id
                                          }
                                          this.props.addNewTeam(addTeamData, res.data.token)
                                            .then(resTeam => {
                                                if (resTeam) {
                                                    //console.log('Res', resTeam, this.props.teamMembers)
                                                    if (this.props.teamMembers.length) {
                                                        this.props.getAllDemoTask(res.data.token)
                                                          .then(resDemo => {
                                                              //console.log('ResDemo', resDemo)
                                                              if (resDemo) {
                                                                  let newTask = {
                                                                      demoTaskList: resDemo.data,
                                                                      assignedTo: this.props.teamMembers[0].userId,
                                                                      colorData: 'rgba(190, 222, 248, 0.45)',
                                                                      createdBy: this.props.teamMembers[0].userId,
                                                                      descriptionData: 'This task is automatically created by the system',
                                                                      workspaceId: resWork.data._id,
                                                                      resourceId: this.props.teamMembers[0]._id,
                                                                      timePerDay: 9,
                                                                      userTaskStatus: 1
                                                                  }
                                                                  this.props.addNewDemoTask(newTask, res.data.token)
                                                                    .then(demoRes => {
                                                                        console.log('DemoRes', demoRes)
                                                                        if (demoRes) {
                                                                            this.props.userLogin(loginData).then(response => {
                                                                                this.props.history.push('/verification');
                                                                            }).catch(err => {
                                                                                console.log('Error from', err)
                                                                            })
                                                                        }
                                                                    })
                                                                    .catch(err => {
                                                                        console.log('Error from add demotask', err)
                                                                    })
                                                              }
                                                          })
                                                          .catch(err => {
                                                              console.log('Error from getDemoTask', err)
                                                          })
                                                        // console.log('Newtask', newTask)
                                                    }
                                                }
                                            })
                                            .catch(err => {
                                                console.log('Error from new team set', err)
                                            })
                                      }
                                  }
                              })
                              .catch(err => {
                                  console.log('Error from workspace', err)
                              })

                        } else {
                            this.setState({ isProgress: false })
                        }
                    })
              },
              50
            );
        } else {
            this.setState(
              {
                  isPassword: true,
                  isProgress: false
              }
            )
        }
    }

    responseGoogle = (response) => {
        if (response && response.accessToken) {
            console.log("response", response)
        }
    }

    render() {
        let createProgress = (
          <ColorProgress />
        );
        const { classes } = this.props;
        // if (this.props.user.signupError) {
        //     console.log('Signup Error', this.props.user.signupError)
        // }
        return (
          <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className={classes.paper}>
                  <Avatar className={classes.avatar}>
                      <LockOutlinedIcon />
                  </Avatar>
                  <Typography style={{ color: 'black', fontSize: 22, fontWeight: 300, fontFamily: 'Sofia Pro' }} component="h1" variant="h5">
                      Sign up
                  </Typography>
                  <form className={classes.form} onSubmit={this.handleRegisterSubmit}>
                      <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                onChange={this.handleChange}
                                autoComplete="fname"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="First Name"
                                autoFocus
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                                id="lastName"
                                label="Last Name"
                                name="lastName"
                                autoComplete="lname"
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                required
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                required
                                name="phone"
                                label="Phone"
                                type="number"
                                id="phone"
                              />
                          </Grid>
                          {this.props.user.signupError ? <div style={{ marginLeft: 8 }} className="error_message">**{this.props.user.signupError} </div> : ''}
                          <Grid item xs={12} sm={6}>
                              <TextField
                                onChange={this.handleChange}
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Password"
                                name="password"
                                type="password"
                              />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                              <TextField
                                onChange={this.handleChange}
                                variant="outlined"
                                fullWidth
                                required
                                name="confirmPassword"
                                label="Confirm Password"
                                type="password"
                                id="confirmPassword"
                              />
                          </Grid>
                          {this.state.isPassword === true ? <div style={{ marginLeft: 8 }} className="error_message">**The given password isn't match. </div> : ''}
                          <Grid item xs={12}>
                              <FormControlLabel
                                control={<Checkbox value="allowExtraEmails" color="primary" />}
                                label="I want to receive inspiration, marketing promotions and updates via email."
                              />
                          </Grid>
                      </Grid>

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                      >
                          Sign Up {this.state.isProgress === true ? createProgress : ''}
                      </Button>
                      <Grid container justify="flex-end">
                          <Grid item>
                              <Link to="signin">
                                  Already have an account? Sign in
                              </Link>
                          </Grid>
                      </Grid>
                  </form>

                 <div style={{marginTop: 24}}>
                     <GoogleLogin
                         clientId="560189595125-00mh1u92ccuth98a63ahp0ra52p0q46i.apps.googleusercontent.com"
                         render={renderProps => (
                             <img src={google} onClick={renderProps.onClick} disabled={renderProps.disabled}/>
                         )}
                         buttonText="Login"
                         onSuccess={this.responseGoogle}
                         onFailure={this.responseGoogle}
                         cookiePolicy={'single_host_origin'}
                     />
                 </div>
              </div>
              <Box mt={5}>
                  <Copyright />
              </Box>
          </Container>
        );
    }

}

function mapStateToProps(state) {
    return {
        user: state.user,
        teamMembers: state.teamMembers ? state.teamMembers.allTeamMember : []
    };
}

export default withRouter(withStyles(useStyles)(connect(
  mapStateToProps,
  {
      userRegister,
      userLogin,
      addWorkspace,
      addNewTeam,
      addNewTask,
      getAllDemoTask,
      addNewDemoTask,
      addNewTeamMember,
      getInvitedUser,
      removeInvitedUser,
      logOutUser
  }
)(SignUp)))
