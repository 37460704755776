import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import "../../Assets/CSS/ProjectDialog.css";
import { connect } from "react-redux";
import clsx from "clsx";
import socketIOClient from "socket.io-client";
import TimerMixin from "react-timer-mixin";
import { serverSocket } from "../../Actions/config";
import CloseIcon from "@material-ui/icons/Close";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
  },
}));

function AddTeamMember(props) {
  const classes = useStyles();
  let {
    open,
    handleClose,
    allTeams,
    search,
    onAdd,
    workspaceId,
    token,
    activeTeam,
    roles,
    user,
    workspace,
    allMembers,
    addInvited,
    permission,
  } = props;

  let userDetails = user ? (user.userDetails ? user.userDetails : null) : null;

  let permitted = permission ? permission > 0 && permission <= 2 : null;

  let [value, setValue] = React.useState(activeTeam);
  let [email, setEmail] = React.useState("");
  let [check, setCheck] = React.useState(false);
  let [msg, setMsg] = React.useState("");
  let [userId, setUserId] = React.useState("");

  let handleTeamChange = (event) => {
    setValue(event.target.value);
  };

  let handleEmail = (event) => {
    setEmail(event.target.value);
  };

  let searchUser = (event) => {
    event.preventDefault();
    if (permitted) {
      let emailData = {
        email: email,
      };
      if (email) {
        search(emailData, token).then((res) => {
          if (res.status === 200) {
            setUserId(res.userId);
            let inputData = {
              userId: res.userId,
              teamId: value,
              workspaceId: workspaceId,
            };
            setCheck(true);
            onSubmit(inputData);
          } else {
            setCheck(false);
            let inviteData = {
              email: email,
              workspaceId: workspaceId,
              teamId: value,
            };
            addInvited(inviteData, token).then((res) => {
              if (res) {
                if (res.status === 200) {
                  setMsg("User Invited");
                } else {
                  setMsg("User not found");
                }
              } else {
                setMsg("User not found");
              }
            });
          }
        });
      } else {
        setMsg("Please enter email of user");
      }
    } else {
      setMsg("You are not permitted to do the action");
    }
  };

  let onSubmit = (data) => {
    onAdd(data, token).then((res) => {
      socket.emit("addMember", res.data);
      setCheck(true);
      setMsg("User added");
      // TimerMixin.setTimeout(
      //     () => {
      //         closeDialog()
      //     },
      //     2000
      // );
    });
  };

  let onClear = () => {
    setEmail("");
    setValue("");
    setCheck(false);
    setMsg("");
    setUserId("");
  };

  let closeDialog = () => {
    handleClose();
    onClear();
  };

  return (
    <React.Fragment>
      <Dialog
        className="customMemberDialog"
        open={open}
        onClose={closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "center",
              }}
            >
              Add new team member
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={closeDialog} />
          </div>
        </DialogTitle>
        <DialogContent>
          <form
            className={clsx(classes.form, classes.formControl)}
            onSubmit={searchUser}
          >
            <InputLabel htmlFor="max-width">Select Team</InputLabel>
            <Select
              autoFocus
              value={activeTeam}
              disableUnderline
              onChange={handleTeamChange}
            >
              {allTeams.map((item, key) =>
                item.teamStatus ? (
                  <MenuItem key={key} value={item._id}>
                    {item.teamTitle}
                  </MenuItem>
                ) : null
              )}
            </Select>
            <TextField
              value={email}
              onChange={handleEmail}
              margin="dense"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              fullWidth
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btn}
            >
              Add Member
            </Button>
          </form>
          {msg.length ? (
            check ? (
              <div onClick={onClear} className="msg-green">
                {msg}
              </div>
            ) : msg === "User Invited" ? (
              <div onClick={onClear} className="msg-green">
                {msg}
              </div>
            ) : (
              <div onClick={onClear} className="msg">
                {msg}
              </div>
            )
          ) : null}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    team: state.teams,
    user: state.user,
    permission: state.teamMembers
      ? state.teamMembers.permission
        ? state.teamMembers.permission
        : null
      : null,
  };
}

export default connect(mapStateToProps)(AddTeamMember);
