import React, { useState } from "react";
import { GithubPicker } from "react-color";

import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Scrollbars } from "react-custom-scrollbars";
import TextareaAutosize from "react-textarea-autosize";
import "../../Assets/CSS/ProjectDialog.css";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import StatusAutoComplete from "./StatusAutoComplete";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import DatePicker from "react-date-picker";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LayersOutlinedIcon from "@material-ui/icons/LayersOutlined";
import InboxOutlinedIcon from "@material-ui/icons/InboxOutlined";
import MoodIcon from "@material-ui/icons/Mood";
import CustomizedProgressBars from "../../Components/CustomizedProgressBars";
import BorderLinearProgress from "../../Components/BorderLinearProgress";
import MilestoneTask from "./MilestoneTask";
import DialogActions from "@material-ui/core/DialogActions";
import ProjectTypes from "./ProjectTypeAC";
import ProjectManagers from "./ProjectManagerAC";

import { connect } from "react-redux";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import Milestone from "./Milestone";
import { updateMilestone } from "../../Actions/projectActions";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

let activeMilestoneCount = (arr, type) => {
  let cnt = 0;
  let len = arr.length;
  if (type === "completed") {
    for (let i = 0; i < len; i++) {
      if (arr[i].checked) {
        cnt += arr[i].tasks.length;
      }
    }
    return cnt;
  } else {
    for (let i = 0; i < len; i++) {
      if (!arr[i].checked) {
        cnt += arr[i].tasks.length;
      }
    }

    return cnt;
  }
};

function EditProject(props) {
  const classes = useStyles();

  let {
    onAdd,
    createdBy,
    workspaceId,
    token,
    open,
    handleClose,
    allProjects,
    updateId,
    members,
    updateMilestone,
    milestone,
  } = props;

  let activeProject = allProjects.find((item) => item._id == updateId);

  let milestoneProp = milestone.length
    ? milestone.find(
        (item) => item.projectId.toString() === updateId.toString()
      )
    : null;

  let allMilestones = milestoneProp ? milestoneProp.milestoneList : [];

  let [name, setName] = useState("");
  let [backgroundColor, setColor] = useState(
    activeProject.color ? activeProject.color : "#BDE1FA"
  );
  let [status, colorPikerHandler] = useState(false);
  let [isMilestone, setMilestone] = useState(false);
  let [taskMile, settaskMile] = useState(false);
  let [startDate, setStart] = useState(
    activeProject ? activeProject.startTime : new Date()
  );
  let [endDate, setEnd] = useState(
    activeProject ? activeProject.endTime : new Date()
  );
  let [projectCode, setProjectCode] = useState("");
  let [projectManager, setManager] = useState("");
  let [projectType, setType] = useState("");
  let [projectPhase, setPhase] = useState("");
  let [estimated, setEstimated] = useState(
    activeProject ? activeProject.estimatedTime : 4
  );
  let [description, setDescription] = useState("");

  let [milestones, setMilestoneState] = useState(allMilestones);

  let MilestoneDetails = (value) => {
    console.log(value);
    if (value.length) {
      settaskMile(true);
    }
  };

  let handleOpenMileStone = () => {
    settaskMile(false);
  };

  let handleStartDate = (date) => {
    setStart(date);
  };

  let handleEndDate = (date) => {
    setEnd(date);
  };

  let handleProjectCode = (event) => {
    setProjectCode(event.target.value);
  };

  let handleMilestone = (values) => {
    setMilestone(values);
  };

  let handleName = (event) => {
    setName(event.target.value);
  };

  let handleChangeComplete = (color) => {
    setColor(color.hex);
    colorPikerHandler(false);
  };

  let handleManager = (value) => {
    setManager(value);
  };

  let handleType = (value) => {
    setType(value);
  };

  let handleProjectPhase = (value) => {
    setPhase(value);
  };

  let handleEstimated = (event) => {
    setEstimated(event.target.value);
  };

  let handleDesciption = (event) => {
    setDescription(event.target.value);
  };

  let handleMilestoneState = (value) => {
    setMilestoneState(value);
  };

  let onSubmit = () => {
    let data = {
      projectId: updateId,
      projectTitle: name.length ? name : null,
      color: backgroundColor.length ? backgroundColor : null,
      createdBy: createdBy,
      projectCode: projectCode.length ? projectCode : null,
      projectManager: projectManager.length ? projectManager : null,
      projectTypeId: projectType.length ? projectType : null,
      phase: projectPhase.length ? projectPhase : null,
      startTime: startDate,
      endTime: endDate,
      estimatedTime: estimated,
      description: description.length ? description : null,
    };
    let milestoneData = {
      projectId: updateId,
      workspaceId: workspaceId,
      milestoneList: milestones,
    };
    let check = milestones.filter((item) => item.dbId !== undefined);
    onAdd(data, token).then((res) => {
      socket.emit("updateProject", res.data);
      setName("");
      handleClose();
    });
    if (check.length) {
      updateMilestone(milestoneData, token).then((res) => {
        socket.emit("updateProject", []);
      });
    }
  };

  let colorPikerStyles = {
    width: "327px",
    height: "32px",
    borderRadius: "0%",
    backgroundColor: backgroundColor + "80",
  };

  let colors = [
    "#f4433680",
    "#e91e6380",
    "#9c27b080",
    "#673ab780",
    "#3f51b580",
    "#2196f380",
    "#03a9f480",
    "#00bcd480",
    "#00968880",
    "#4caf5080",
    "#8bc34a80",
    "#cddc3980",
    "#ffeb3b80",
    "#ffc10780",
    "#ff980080",
    "#ff572280",
    "#79554880",
    "#607d8b80",
    "#609B7D80",
    "#56211780",
    "#BE95CB80",
    "#5D3BC780",
    "#084B3080",
    "#33EFC780",
  ];

  let activeMembers = members.filter(
    (item) => item.teamId === props.activeTeam
  );

  let allStatus = props.taskStatus.allStatus ? props.taskStatus.allStatus : [];

  let doneList = milestones.length
    ? milestones.filter((item) => item.checked === true)
    : [];

  return (
    <React.Fragment>
      {activeProject ? (
        <Dialog
          className="customDialog"
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          aria-describedby="form-dialog-description"
        >
          <DialogTitle
            id="form-dialog-title"
            style={{ background: "rgba(73,121,255,0.85)" }}
          >
            <div style={{ display: "flex" }}>
              <TextareaAutosize
                value={name.length ? name : activeProject.projectTitle}
                onChange={handleName}
                placeholder="Update Project Name"
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    return event.preventDefault();
                  }
                }}
                autoFocus
              />
              <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
            </div>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 10, overflow: "auto" }}>
            <Scrollbars>
              <div className="sidebarControllerWrapper">
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <SlowMotionVideoIcon />
                    </span>
                    <span className="sidebarTitle">Status</span>
                  </Col>
                  <Col span={14}>
                    <StatusAutoComplete
                      active={
                        projectPhase.length ? projectPhase : activeProject.phase
                      }
                      allStatus={allStatus}
                      statusChange={handleProjectPhase}
                      handleUpdate={null}
                    />
                    {/* {<SingleSelect
                                        defaultValue={
                                            { title: 'Ongoing', valueId: 'ongoing' }
                                        }
                                        values={[
                                            { title: 'Urgent', valueId: 'urgent' },
                                            { title: 'Paused', valueId: 'paused' },
                                            { title: 'Completed', valueId: 'completed' }
                                        ]}
                                    />} */}
                  </Col>
                </Row>
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <InboxOutlinedIcon />
                    </span>
                    <span className="sidebarTitle">Project Code</span>
                  </Col>
                  <Col span={14}>
                    {
                      <TextareaAutosize
                        value={
                          projectCode.length
                            ? projectCode
                            : activeProject.projectCode
                        }
                        onChange={handleProjectCode}
                        placeholder="Add project code"
                        onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            return event.preventDefault();
                          }
                        }}
                      />
                    }
                  </Col>
                </Row>
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <MoodIcon />
                    </span>
                    <span className="sidebarTitle">Color</span>
                  </Col>
                  <Col span={14}>
                    <div
                      className="colorPikerWrapper"
                      style={colorPikerStyles}
                      onClick={() => colorPikerHandler(!status)}
                    ></div>
                    {status === true ? (
                      <GithubPicker
                        colors={colors}
                        color={
                          activeProject.color
                            ? activeProject.color
                            : backgroundColor
                        }
                        onChangeComplete={handleChangeComplete}
                        width={327}
                      />
                    ) : null}
                  </Col>
                </Row>
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <EventAvailableIcon />
                    </span>
                    <span className="sidebarTitle">Start - End</span>
                  </Col>
                  <Col span={14}>
                    <span>
                      <div style={{ display: "flex" }}>
                        <DatePicker
                          onChange={handleStartDate}
                          value={startDate}
                          clearIcon={null}
                          calendarIcon={null}
                          format="MMM dd, y"
                          monthPlaceholder="MM"
                          yearPlaceholder="YYYY"
                          dayPlaceholder="DD"
                          className="sidebarCustomCalender hoverBGColor"
                          calendarClassName="sidebarCustomCalenderView"
                        />
                        <div className="highPen">-</div>
                        <DatePicker
                          onChange={handleEndDate}
                          value={endDate}
                          clearIcon={null}
                          calendarIcon={null}
                          format="MMM dd, y"
                          monthPlaceholder="MM"
                          yearPlaceholder="YYYY"
                          dayPlaceholder="DD"
                          className="sidebarCustomCalender hoverBGColor"
                          calendarClassName="sidebarCustomCalenderView"
                        />
                      </div>
                    </span>
                  </Col>
                </Row>
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <AccessTimeIcon />
                    </span>
                    <span className="sidebarTitle">Estimated Time</span>
                  </Col>
                  <Col span={14}>
                    {
                      <TextField
                        id="standard-number"
                        className="timePerDay editHoverBGColor"
                        value={
                          estimated.length
                            ? estimated
                            : activeProject.estimatedTime
                        }
                        InputProps={{ inputProps: { min: 1, max: 10 } }}
                        placeholder="Estimated time"
                        onChange={handleEstimated}
                        type="number"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        disableUnderline={true}
                        autoFocus={false}
                      />
                    }
                  </Col>
                </Row>
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <LayersOutlinedIcon />
                    </span>
                    <span className="sidebarTitle">Project Type</span>
                  </Col>
                  <Col span={14}>
                    {/*<StatusAutoComplete*/}
                    {/*    active={props.taskStatus[0] ? props.taskStatus[0]._id : null}*/}
                    {/*    allStatus={props.taskStatus}*/}
                    {/*    statusChange={props.handleTaskStatus}*/}
                    {/*    handleUpdate={null}*/}
                    {/*/>*/}
                    {/* {<SingleSelect
                                        defaultValue={
                                            { title: 'Website Design', valueId: 'web' }
                                        }
                                        values={[
                                            { title: 'Web Development', valueId: 'web_dev' },
                                            { title: 'Mobile Application', valueId: 'app' },
                                            { title: 'Digital Marketing', valueId: 'marketing' }
                                        ]}
                                    />} */}
                    <ProjectTypes
                      activeType={
                        activeProject.projectTypeId
                          ? activeProject.projectTypeId
                          : null
                      }
                      handleChange={handleType}
                    />
                  </Col>
                </Row>
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                  style={{ marginTop: 12 }}
                >
                  <Col span={10}>
                    <span className="sidebarIcon">
                      <LayersOutlinedIcon />
                    </span>
                    <span className="sidebarTitle">Project Manager</span>
                  </Col>
                  <Col span={14}>
                    {/*<StatusAutoComplete*/}
                    {/*    active={props.taskStatus[0] ? props.taskStatus[0]._id : null}*/}
                    {/*    allStatus={props.taskStatus}*/}
                    {/*    statusChange={props.handleTaskStatus}*/}
                    {/*    handleUpdate={null}*/}
                    {/*/>*/}
                    {/* {<SingleSelect
                                        defaultValue={
                                            { title: 'Sorwar Turzo', valueId: 'm1' }
                                        }
                                        values={[
                                            { title: 'Anjan Das', valueId: 'm2' },
                                            { title: 'Masud Kaisar', valueId: 'm3' },
                                            { title: 'Taposhi Rabeya', valueId: 'm4' }
                                        ]}
                                        onChange={(e) => handleManager(e)}
                                    />} */}
                    <ProjectManagers
                      managerId={
                        activeProject.projectManager
                          ? activeProject.projectManager
                          : null
                      }
                      allMembers={activeMembers}
                      handleChange={handleManager}
                    />
                  </Col>
                </Row>
              </div>
              <div className="sidebarDescriptionWrapper">
                <Row
                  className="sidebarControllerRow"
                  type="flex"
                  align="middle"
                >
                  <TextareaAutosize
                    value={
                      description.length
                        ? description
                        : activeProject.description
                    }
                    onChange={handleDesciption}
                    placeholder="Project description"
                    //value={this.props.value}
                    //onBlur={this.props.detailsText}
                    // maxRows={10}
                    minRows={5}
                    style={{
                      backgroundColor: "#fff",
                      width: "100%",
                      fontFamily: "Sofia Pro",
                      fontSize: 14,
                      color: "#585858",
                    }}
                  />
                </Row>
              </div>
              <div className="sidebarMilestoneWrapper">
                <Row className="sidebarControllerRow">
                  <Col span={9} className="milestoneTop">
                    <div className="column-div">
                      <div>
                        <span>Milestone Progress</span>
                      </div>
                      <div style={{ marginTop: 5 }}>
                        <text>Completed</text>
                      </div>
                      <div style={{ width: 210 }}>
                        <CustomizedProgressBars value={doneList} />
                      </div>
                      <div style={{ marginTop: 5 }}>
                        <text>
                          {activeMilestoneCount(milestones, "completed")} tasks
                          in {milestones.length} milestones
                        </text>
                      </div>
                    </div>
                  </Col>
                  <Col span={9} className="milestoneProgress">
                    {
                      <div style={{ marginTop: 13 }}>
                        <div>
                          <span>Remaining</span>
                        </div>
                        <div>
                          <BorderLinearProgress value={doneList} />
                        </div>
                        <div style={{ marginTop: 5 }}>
                          <span>
                            {activeMilestoneCount(milestones, "remaining")}{" "}
                            tasks in {milestones.length} milestones
                          </span>
                        </div>
                      </div>
                    }
                  </Col>
                  <Col span={4} className="milestone">
                    {isMilestone === false ? (
                      <span
                        style={{ marginLeft: 30 }}
                        onClick={() => handleMilestone(true)}
                      >
                        Milestone
                      </span>
                    ) : (
                      <span onClick={() => handleMilestone(false)}>Hide</span>
                    )}
                  </Col>
                </Row>
              </div>
              {isMilestone === true ? (
                <div>
                  <Milestone
                    projectId={updateId}
                    MilestoneDetails={MilestoneDetails}
                    milestoneState={handleMilestoneState}
                  />
                </div>
              ) : (
                ""
              )}
              {
                <MilestoneTask
                  open={taskMile}
                  handleClose={handleOpenMileStone}
                />
              }
            </Scrollbars>

            {/*<form className={classes.form} noValidate >*/}
            {/*    <FormControl className={classes.formControl}>*/}
            {/*        <div className="colorPikerWrapper" style={colorPikerStyles}*/}
            {/*            onClick={() => colorPikerHandler(!status)}></div>*/}
            {/*        {*/}
            {/*            status === true ? (*/}
            {/*                <GithubPicker*/}
            {/*                    colors={colors}*/}
            {/*                    color={backgroundColor}*/}
            {/*                    onChangeComplete={handleChangeComplete}*/}
            {/*                    width={100}*/}
            {/*                />*/}
            {/*            ) : null*/}
            {/*        }*/}
            {/*        <TextField*/}
            {/*            autoFocus*/}
            {/*            value={name}*/}
            {/*            onChange={handleName}*/}
            {/*            margin="dense"*/}
            {/*            id="name"*/}
            {/*            label="Project Name"*/}
            {/*            fullWidth*/}
            {/*        />*/}
            {/*    </FormControl>*/}
            {/*    <Button onClick={onSubmit} color="primary">*/}
            {/*        Submit*/}
            {/*    </Button>*/}
            {/*</form>*/}
          </DialogContent>
          <DialogActions>
            <div style={{ display: "flex", width: "100%", margin: 12 }}>
              <div className="text_button" onClick={onSubmit}>
                Update
              </div>
              <div className="text_button" onClick={handleClose}>
                Discard
              </div>
            </div>
          </DialogActions>
        </Dialog>
      ) : null}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    taskStatus: state.taskStatus,
    milestone: state.projects
      ? state.projects.allMilestones
        ? state.projects.allMilestones
        : []
      : [],
  };
}

export default connect(mapStateToProps, { updateMilestone })(EditProject);
