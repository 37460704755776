import { SET_ALL_ROLES, SET_USER_ROLE } from '../Constants'

const initialState = {}

const roleReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_ROLES:
      return {
        ...state,
        allRoles: action.data
      };

    case SET_USER_ROLE:
      return {
        ...state,
        newUserRole: action.data
      }

    default:
      return state;
  }
}

export default roleReducer;