import React, { useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactCodeInput from "react-verification-code-input";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { updatePassword } from "../../Actions/userActions";
import ColorProgress from '../../Components/Signprogress';
import TimerMixin from 'react-timer-mixin';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux'
// React Router Link
import {
  withRouter
} from "react-router-dom";
import { AlternateEmailOutlined } from '@material-ui/icons';

function Copyright() {
  return (
    <Typography style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Sofia Pro' }}
      variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href="https://dcastalia.com/">
        Team Trakking
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(20, 1, 1, 1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    fontFamily: 'Sofia Pro',
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    fontFamily: 'Sofia Pro',
    fontSize: 14
  },
}));

let emailLocal = localStorage.getItem('email')

function SetPassword(props) {
  let { updatePassword, user } = props
  const classes = useStyles();
  let [isProgress, setProgress] = useState(false);
  const input = useRef(null);
  let [password, setPassword] = useState('')
  let [confirm, setConfirm] = useState('')
  let [msg, setMsg] = useState('')

  let email = emailLocal ? emailLocal : user ? user.userDetails ? user.userDetails.email : '' : ''

  let handlePassword = event => {
    setPassword(event.target.value)
  };

  let handleConfirm = event => {
    setConfirm(event.target.value)
  };

  let handleSubmit = (event) => {
    event.preventDefault()

    setProgress(true);

    if (password.length && confirm.length && password === confirm) {
      let data = {
        email: email,
        password: password
      }
      updatePassword(data)
        .then(res => {
          TimerMixin.setTimeout(
            () => {
              setProgress(false)
              props.history.push('/signin')
            },
            50
          );
        }).catch(err => {
          setProgress(false)
        })
    } else {
      setProgress(false)
      setMsg("Password's didn't matched")
    }
  }

  let createProgress = (
    <ColorProgress />
  );
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          style={{
            color: 'black',
            fontSize: 22,
            fontWeight: 300,
            fontFamily: 'Sofia Pro'
          }}
          component="h1"
          variant="h5"
        >
          Update Password
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              value={password}
              type='password'
              onChange={handlePassword}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Confirm Password"
              value={confirm}
              type='password'
              onChange={handleConfirm}
            />
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit Password {isProgress === true ? createProgress : ''}
          </Button>
          {msg ?
            <div className="error_message">**{msg} </div> : <div style={{ height: 5 }} />
          }
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

let mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { updatePassword }
  )(SetPassword)
)
