import React from 'react'
import PersonIcon from '@material-ui/icons/PersonOutlineOutlined'
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";


export const sidebar = [
    {
        title: 'My Profile',
        icon: <PersonIcon />
    },
    {
        title: 'Users',
        icon: <SupervisorAccountIcon/>
    },
    {
        title: 'Billing',
        icon: <AccountBalanceWalletIcon/>
    },
    {
        title: 'Help',
        icon: <LiveHelpIcon/>
    },
    {
        title: 'Achievements',
        icon: <EmojiEventsIcon/>
    }
]