import React, { useState } from "react";
import { Input, List, ListItem, Checkbox } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";

import {
  addSingleSubtask,
  updateSubtask,
  setToState, addSingleSubLittletask, addLittleSubTaskMultiple, updateSubtaskLittle, deleteSubtaskLittle,
} from "../../Actions/subTaskActions";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import SubTaskLittle from "./SubTaskLittle";

let socket = socketIOClient(serverSocket);

const GreenCheckbox = withStyles({
  root: {
    color: "#A0A0A0",
    "&$checked": {
      color: "#38F8C4",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const SubTask = (props) => {
  let subTasks = props.subTasks.allSubTasks ? props.subTasks.allSubTasks : [];
  let allLittleSubTasks = props.subTasks.allLittleSubTasks ? props.subTasks.allLittleSubTasks : [];
  let filtered = subTasks.filter((item) => item.parentTaskId == props.taskId);

  let subTaskState = filtered.length
    ? filtered.map((item) => {
        let retData = {
          id: item.sortOrder,
          value: item.subTaskTitle,
          status: item.completion,
          focus: true,
          dbId: item._id,
        };

        return retData;
      })
    : [];

  let len = subTaskState.length;

  if (props.permitted && len > 0) {
    subTaskState.push({
      id: len,
      value: null,
      status: false,
      focus: false,
      dbId: len,
    });
  }

  // console.log('Subtask State', subTaskState)
  // console.log('TaskId', props.taskId)
  // console.log('Subtasks', subTasks)

  const [fields, setFields] = useState(subTaskState);
  // const [checked, changeCheckStatus] = useState(false);

  const [state, setState] = React.useState([]);

  const handleChangeMap = (id) => {
    const nextState = fields.map((a) => {
      if (a.dbId === id) a.status = !a.status;
      return a;
    });
    setState(nextState);
    if (props.taskId.length) {
      let filtered = nextState.filter((item) => item.dbId === id);
      if (filtered.length) {
        if (props.permitted) {
          let sendData = {
            subTaskId: filtered[0].dbId,
            completion: filtered[0].status,
            subTaskStatus: 1,
          };
          props.updateSubtask(sendData, props.user.token).then((res) => {
            let dbData = res.data?.subTask;
            let values = [...fields];
            values[dbData.sortOrder].dbId = dbData._id;
            setFields(values);
            socket.emit("updateSubtask", []);
          });
        }
      }
    }
  };

  function handleChange(i, event) {
    const values = [...fields];
    values[i].value = event.target.value;
    let txt = event.target.value;
    if (!txt.length) values[i].focus = false;
    setFields(values);
  }

  function handleAdd() {
    const values = [...fields];
    values.push({ id: 0, value: null, status: false, focus: false });
    setFields(values);
  }

  function handleAddOnEnter(event) {
    var code = event.keyCode || event.which;
    if (code === 13 && props.permitted) {
      //13 is the enter keycode
      //Do stuff in here
      const values = [...fields];
      let lastItem = values.length - 1;
      values[lastItem].focus = true;
      setFields(values);
      if (props.taskId.length) {
        onSingleSubmit(values[lastItem]);
      } else {
        if (values[lastItem].value) {
          values.push({
            id: lastItem + 1,
            value: null,
            status: false,
            focus: false,
            dbId: lastItem + 1,
          });
          setFields(values);
        }
        props.setToState(values);
      }
    }
  }

  function handleRemove(i) {
    const values = [...fields];
    if (!props.taskId.length) {
      values.splice(i, 1);
      props.setToState(values);
      setFields(values);
    } else {
      if (props.permitted) {
        let sendData = {
          subTaskId: values[i].dbId,
          subTaskStatus: 0,
          completion: true
        };
        values.splice(i, 1);
        props.updateSubtask(sendData, props.user.token).then((res) => {
          setFields(values);
          socket.emit("updateSubtask", []);
        });
      }
    }
  }

  function onSingleSubmit(data) {
    let values = [...fields];
    let sendData = {
      subTaskTitle: data.value,
      parentTaskId: props.taskId,
      completion: data.status,
      sortOrder: data.id,
      assignedUserId: props.userId,
      workspaceId: props.workspace[0],
    };
    if (props.permitted) {
      props.addSingleSubtask(sendData, props.user.token).then((res) => {
        let dbData = res.data;
        values[data.id].dbId = dbData._id;
        setFields(values);
        socket.emit("updateSubtask", []);
      });
    }

    let lastItem = values.length - 1;
    values[lastItem].focus = true;
    if (values[lastItem].value) {
      values.push({
        id: lastItem + 1,
        value: null,
        status: false,
        focus: false,
        dbId: lastItem + 1,
      });
      setFields(values);
    }
  }

  return (
    <div className="subTaskWrapper">
      {fields.length < 1 && props.permitted ? (
        <Button variant="outlined" color="primary" onClick={() => handleAdd()}>
          Add Subtasks
        </Button>
      ) : (
        ""
      )}

      <List>
        {fields.map((field, idx) => {
          let littleSubTask = allLittleSubTasks.filter((p)=> p.subTaskId === field.dbId)
          return (
           <>
             <ListItem key={`${field}-${idx}`}>
               <GreenCheckbox
                   key={idx}
                   className="subtaskCheckbox"
                   onChange={() => handleChangeMap(field?.dbId)}
                   checked={field.status}
                   disabled={props.permitted ? false : true}
               />
               {field.status ? (
                   <Input
                       placeholder={"+Type and press enter to add a new to-do.."}
                       autoFocus={idx + 1}
                       value={field.value || ""}
                       disabled={field.status}
                       className="subtaskInputChecked"
                       onChange={(e) => handleChange(idx, e)}
                       onKeyPress={handleAddOnEnter.bind(this)}
                       disabled={props.permitted ? false : true}
                   />
               ) : (
                   <Input
                       placeholder={"+Type and press enter to add a new to-do.."}
                       autoFocus={idx + 1}
                       value={field.value || ""}
                       disabled={field.status}
                       className="subtaskInput"
                       onChange={(e) => handleChange(idx, e)}
                       onKeyPress={handleAddOnEnter.bind(this)}
                       disabled={props.permitted ? false : true}
                   />
               )}
               {field.focus && littleSubTask.length === 0 ? (
                   <DeleteOutlineIcon
                       className="subtaskRemove"
                       onClick={() => handleRemove(idx)}
                       disabled={props.permitted ? false : true}
                   />
               ) : null}
             </ListItem>
             {
               props.taskId ?
                   <SubTaskLittle
                       teamMembers={props.teamMembers}
                       deleteSubtaskLittle={props.deleteSubtaskLittle}
                       updateSubtask={props.updateSubtaskLittle}
                       addSingleSubtask={props.addSingleSubLittletask}
                       user={props.user}
                       workspaceId={props.workspace[0]}
                       parentTaskId={props.taskId}
                       taskId={field?.dbId}
                       userId={props.userId}
                       permitted={props.permitted}
                       littleSubTask={littleSubTask}
                   /> : null
             }

           </>
          );
        })}
      </List>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    workspace: state.settings.workspace,
    user: state.user,
    subTasks: state.subTasks,
    teamMembers: state.teamMembers ? state.teamMembers : null,
  };
}

export default connect(mapStateToProps, {
  addSingleSubtask,
  addSingleSubLittletask,
  updateSubtask,
  deleteSubtaskLittle,
  updateSubtaskLittle,
  setToState,
})(SubTask);
