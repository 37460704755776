import axios from "axios";

import {
  ADD_TEAM_MEMBER,
  REMOVE_TEAM_MEMBER,
  GET_TEAM_USER,
  SET_ALL_TEAM_MEMBERS,
  SET_ROLE_PRIORITY
} from "../Constants";
// import { func } from 'prop-types';

import { serverURI } from "./config";
//const serverURI = "http://192.168.2.3:5001/api/";
//Must add 'JWT ' string before the token

export function addNewTeamMember(data, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };

  if (token) {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      return axios
        .post(serverURI + "post/add-team-member", data)
        .then((res) => {
          if (res.status === 200) {
            response.status = res.status;
            response.token = token;
            response.data = res.data;
            dispatch(setTeamMember(res.data));
            response.message = "Team member Successfully Added";
          } else {
            response.status = 302;
            response.message = res.data.message;
          }
          return response;
        })
        .catch((err) => {
          response.status = 400;
          response.message = err;
          return response;
        });
    };
  }
}

export function updateTeamMember(data, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: {},
  };

  if (token) {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      return axios
        .post(serverURI + "post/update-team-member", data)
        .then((res) => {
          if (res.status === 200) {
            response.status = res.status;
            response.token = token;
            response.data = res.data;
            dispatch(removeTeamMember());
            response.message = "Team member Successfully removed";
          } else {
            response.status = 302;
            response.message = res.data.message;
          }
          return response;
        })
        .catch((err) => {
          response.status = 400;
          response.message = err;
          return response;
        });
    };
  }
}

export function deleteTeamMember(data, token) {
  let response = {
    status: "",
    message: "",
    token: "",
  };

  if (token) {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = token;
      return axios
        .post(serverURI + "post/remove-team-member", data)
        .then((res) => {
          if (res.status === 200) {
            response.status = res.status;
            response.token = token;
            dispatch(removeTeamMember());
            response.message = "Team Member Successfully Deleted";
          } else {
            response.status = 302;
            response.message = res.data.message;
          }
          return response;
        })
        .catch((err) => {
          response.status = 400;
          response.message = err;
          return response;
        });
    };
  }
}

export function findUserByMail(data, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    userId: "",
  };

  if (token) {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      return axios
        .post(serverURI + "post/find-user", data)
        .then((res) => {
          if (res.status === 200) {
            response.status = res.status;
            response.token = token;
            response.userId = res.data.userId;
            dispatch(getTeamUser(res.data));
            response.message = "Teams Successfully fetched";
          } else {
            response.status = 302;
            response.message = res.data.message;
          }
          return response;
        })
        .catch((err) => {
          response.status = 400;
          response.message = err;
          return response;
        });
    };
  }
}

// find-all-team-members

export function findAllTeamMembers(data, token) {
  let response = {
    status: "",
    message: "",
    token: "",
    data: [],
  };

  if (token) {
    return (dispatch) => {
      axios.defaults.headers.common["Authorization"] = "JWT " + token;
      return axios
        .post(serverURI + "post/find-all-team-members", data)
        .then((res) => {
          if (res.status === 200) {
            response.status = res.status;
            response.token = token;
            response.data = res.data;
            dispatch(setAllTeamMembers(res.data));

            response.message = "Teams Successfully fetched";
          } else {
            response.status = 302;
            response.message = res.data.message;
          }
          return response;
        })
        .catch((err) => {
          response.status = 400;
          response.message = err;
          return response;
        });
    };
  }
}

export function setTeamMember(teamData) {
  return {
    type: ADD_TEAM_MEMBER,
    data: teamData,
  };
}

export function removeTeamMember() {
  return {
    type: REMOVE_TEAM_MEMBER,
    data: "",
  };
}

export function setUserRolePriority(role) {
  return {
    type: SET_ROLE_PRIORITY,
    data: role
  }
}

export function getTeamUser(data) {
  return {
    type: GET_TEAM_USER,
    data: data,
  };
}

export function setAllTeamMembers(teamMemberData) {
  localStorage.setItem('ttkTeamMembers', JSON.stringify(teamMemberData))
  return {
    type: SET_ALL_TEAM_MEMBERS,
    data: teamMemberData,
  };
}
