import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import "../../Assets/CSS/ProjectDialog.css";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import TimerMixin from "react-timer-mixin";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
  },
}));

function AddWorkspaceForm(props) {
  const classes = useStyles();
  let { onAdd, token, open, handleClose, addTeam } = props;

  let [name, setName] = useState("");
  let [msg, setMsg] = useState("");

  let handleName = (event) => {
    setName(event.target.value);
  };

  // let handleChangeComplete = (color) => {
  //     setColor(color.hex);
  //     colorPikerHandler(false);
  // };

  let onSubmit = (event) => {
    event.preventDefault();

    let data = {
      workspace: name,
      noLocal: true,
    };
    onAdd(data, token).then((res) => {
      socket.emit("addWorkspace", res.data);
      setMsg("New Workspace Added!");
      let addTeamData = {
        teamTitle: "Team " + res.data.workspace,
        workspaceId: res.data._id,
        noSelf: true,
      };
      addTeam(addTeamData, token).then((team) => {
        let socketData = {
          workspaceId: res.data._id,
        };
        socket.emit("addTeam", socketData);
      });
      TimerMixin.setTimeout(() => {
        setName("");
        setMsg("");
        handleClose();
      }, 2000);
    });
  };

  return (
    <React.Fragment>
      <Dialog
        className="customMemberDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "center",
              }}
            >
              Add New Workspace
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <form
            className={clsx(classes.form, classes.formControl)}
            noValidate
            onSubmit={onSubmit}
          >
            <TextField
              autoFocus
              value={name}
              onChange={handleName}
              margin="dense"
              id="name"
              label="Workspace Name"
              fullWidth
              autoComplete
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btn}
            >
              Add Workspace
            </Button>
          </form>
          {msg.length ? <div className="msg-green">{msg}</div> : null}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default AddWorkspaceForm;
