import React, { useState } from "react";
import '../Assets/CSS/Users.css'
import { Scrollbars } from "react-custom-scrollbars";
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import SimpleDialog from "../Container/Settings/SimpleDialog";
import { updateImage, updateUser, updatePassword } from '../Actions/userActions'
import { connect } from 'react-redux'
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    orange: {
        color: 'rgba(0,0,0,0.7)',
        backgroundColor: 'rgba(123,123,123,0.2)',
        marginTop: 16,
        fontSize: 14,
        fontFamily: 'Sofia Pro'
    },
}));

function UserProfile(props) {
    let { user, updateUser, updateImage, updatePassword } = props
    let classes = useStyles();

    let userDetails = user ? user.userDetails ? user.userDetails : null : null

    let [open, setOpen] = useState(false);
    let [fname, setFname] = useState(userDetails ? userDetails.firstName : '')
    let [lname, setLname] = useState(userDetails ? userDetails.lastName : '')
    let [avatar, setAvatar] = useState('')
    let [password, setPassword] = useState('')
    let [confirmPass, setConfirm] = useState('')

    let handleFirstName = event => {
        setFname(event.target.value)
    }

    let handleLastName = event => {
        setLname(event.target.value)
    }

    let handleImage = event => {
        setAvatar(event.target.files[0])
    }

    let handleClickOpen = () => {
        setOpen(true);
    };

    let handleClose = () => {
        setOpen(false)
    };

    let handlePassword = event => {
        setPassword(event.target.value)
    }

    let handleConfirm = event => {
        setConfirm(event.target.value)
    }

    let handleImageSubmit = (token) => {
        if (avatar === '') {
            console.log('No Image Selected')
            props.history.push('/')
        } else {
            let bodyFormData = new FormData();
            bodyFormData.append("image", avatar);
            bodyFormData.append("upload", "true");

            let data = {
                bodyFormData: bodyFormData
            }
            // console.log('Data, formData', data, bodyFormData, avatar)
            updateImage(data, token)
            props.history.push('/')
        }
    }

    let handleSubmit = () => {
        let token = user ? user.token : null
        let data = {
            firstName: fname,
            lastName: lname
        }
        if (token) {
            updateUser(data, token)
            handleImageSubmit(token)
            if (password.length && confirmPass.length) {
                if (password === confirmPass) {
                    let passData = {
                        email: userDetails.email,
                        password: password
                    }
                    updatePassword(passData)
                } else {
                    alert("Password did not match")
                }
            }
        }
    }

    return userDetails ? (
        <Scrollbars style={{ height: (window.innerHeight - 100), backgroundColor: 'rgba(0,0,0,0)' }}>
            <div className="body">
                <div className="display-top">
                    <div className="title-text">My Profile</div>
                    <div
                        className="save-button"
                        onClick={() => handleSubmit()}
                    >
                        Save & Close
                    </div>
                </div>
                <div className="display-image">
                    {
                        avatar ?
                            <Avatar
                                className={classes.orange}
                                src={
                                    URL.createObjectURL(avatar)
                                }
                                alt="Display Picture"
                            /> : userDetails.avatar ? <Avatar
                                className={classes.orange}
                                src={
                                    userDetails.avatar
                                }
                                alt="Display Picture"
                            />
                                : <Avatar className={classes.orange}>{userDetails.shortName}</Avatar>
                    }
                    <div className="edit-image">
                        <input
                            accept="image/*"
                            style={{ display: 'none' }}
                            id='icon-button-file-pro'
                            type="file"
                            name='avatar'
                            onChange={handleImage}
                        />
                        <label
                            htmlFor="icon-button-file-pro"
                        >
                            <span>
                                Upload image
                            </span>
                        </label>

                    </div>
                </div>
                {/* {open === true ?
                    <SimpleDialog
                        open={open}
                        handleClickOpen={handleClickOpen}
                        handleClose={handleClose}
                    /> : null
                } */}
                <div className="display-field">
                    <div className="edit-field">
                        <TextField
                            label="First Name"
                            id="outlined-size-small"
                            fullWidth
                            value={fname}
                            variant="outlined"
                            size="small"
                            onChange={handleFirstName}
                        />
                    </div>
                    <div className="edit-field2">
                        <TextField
                            label="Last Name"
                            id="outlined-size-small"
                            fullWidth
                            value={lname}
                            variant="outlined"
                            size="small"
                            onChange={handleLastName}
                        />
                    </div>
                </div>
                <div className="display-field">
                    <div className="edit-field">
                        <TextField
                            label="Email"
                            id="outlined-size-small"
                            fullWidth
                            disabled
                            value={userDetails ? userDetails.email : ''}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div className="edit-field2">
                        <Autocomplete
                            id="combo-box-demo"
                            options={timeZone}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label="Timezone"
                                    id="outlined-size-small"
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                />}
                        />
                    </div>
                </div>
                <div className="display-field">
                    <div className="edit-field">
                        <TextField
                            label="Change Password"
                            id="outlined-size-small"
                            fullWidth
                            type='password'
                            onChange={handlePassword}
                            value={password}
                            variant="outlined"
                            size="small"
                        />
                    </div>
                    <div className="edit-field2">
                        <TextField
                            label="Confirm Password"
                            id="outlined-size-small"
                            fullWidth
                            onChange={handleConfirm}
                            value={confirmPass}
                            variant="outlined"
                            type='password'
                            size="small"
                        />
                    </div>
                </div>
            </div>
        </Scrollbars>
    ) : null
}

function mapStateToProps(state) {
    return {
        user: state.user
    };
}

export default withRouter(connect(
    mapStateToProps,
    {
        updateImage,
        updateUser,
        updatePassword
    }
)(UserProfile));

const timeZone = [
    { title: 'Dhaka, Bangladesh', gmt: '+6:00' }
];