import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import TextareaAutosize from "react-textarea-autosize";
import ColorProgress from "../../Components/ColorProgress";
import TimerMixin from "react-timer-mixin";

import AutoComplete from "./AutoComplete";
import StatusAutoComplete from "./StatusAutoComplete";
import Milstone from "./MilestoneAutocomplete";
import UserAutoComplete from "./UserAutoComplete";
import SlowMotionVideoIcon from "@material-ui/icons/SlowMotionVideo";
import InboxIcon from "@material-ui/icons/Inbox";
import MoodIcon from "@material-ui/icons/Mood";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LayersIcon from "@material-ui/icons/Layers";
import CloseIcon from "@material-ui/icons/Close";
import MilestoneIcon from "@material-ui/icons/BeenhereOutlined";
import Typography from "@material-ui/core/Typography";
import moment from "moment";

import "../../Assets/CSS/EventSidebar.css";
import SubTask from "./SubTask";
import MenuList from "./MenuList";
import DatePicker from "react-date-picker";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
let socket = socketIOClient(serverSocket);

function isEmpty(obj) {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

class ShowTaskDetails extends Component {
  state = {
    startDate: this.props.start,
    endDate: this.props.end,
    project: "",
    hours: this.props.timePerDay,
    isProgress: false,
    isSplit: this.props.splitStatus,
    hourMsg: "",
  };

  componentDidMount() {
    let datas = {
      workspaceId: this.props.workspaceId,
      parentTaskId: this.props.taskID
    }

    this.props.getAllLittleSubTasks(datas, this.props.token)

    socket.on("updateSubtask", (data) => {
      this.props.getAllLittleSubTasks(datas, this.props.token)
    });
  }



  onChangeStart = (date) => {
    this.setState({ startDate: date });
    this.props.inputStartValue(date);
    if (this.props.start !== date) {
      this.props.handleUpdate(true);
    }
  };

  onChangeEnd = (date) => {
    this.setState({ endDate: date });
    this.props.inputEndValue(date);
    if (this.props.end !== date) {
      this.props.handleUpdate(true);
    }
  };

  onHourChange = (event) => {
    let hourValue = 0;
    if (event.target.value > 10) {
      hourValue = 10;
      this.setState({
        hourMsg: `Your input of ${event.target.value} hours exceeding maximum 10 hours`,
      });
    } else if (event.target.value < 0) {
      hourValue = 1;
      this.setState({
        hourMsg: `Your input of ${event.target.value} hour is insufficient then minimum 1 hour`,
      });
    } else {
      hourValue = event.target.value;
      this.setState({ hourMsg: "" });
    }
    this.setState({ hours: hourValue });
    this.props.handleTimePerDay(hourValue);
    TimerMixin.setTimeout(() => {
      this.setState({ hourMsg: "" });
    }, 4000);
  };

  onFirstClick = () => {
    if (!this.state.hours || parseInt(this.state.hours) === 0) {
      this.setState({ hourMsg: "Time per day must be between 1 to 10" });
      TimerMixin.setTimeout(() => {
        this.setState({ hourMsg: "" });
      }, 3500);
    } else {
      this.setState({ isProgress: true });
      TimerMixin.setTimeout(() => {
        this.props.closed();
        this.setState({ isProgress: false });
      }, 50);
    }
  };

  onSplitChange = () => {
    let value = !this.state.isSplit;
    if (this.props.permitted) {
      this.setState({ isSplit: value });
      this.props.handleSplit(value);
    }
  };

  render() {
    let len = this.props.resource.length;
    let idx = 0;
    for (let i = 0; i < len; i++) {
      if (i >= 0 && i < len && this.props.resource[i] === " ") idx = i;
    }
    let shortName = this.props.resource[0] + this.props.resource[idx + 1];
    let createProgress = <ColorProgress />;

    let milestone = this.props.milestones.length
      ? this.props.milestones.find(
          (item) =>
            item.projectId.toString() === this.props.projectId.toString()
        )
      : null;

    let milestoneList = milestone ? milestone.milestoneList : [];

    let mileLen = milestoneList.length;

    let milesTask = "";

    for (let i = 0; i < mileLen; i++) {
      let fnd = milestoneList[i].tasks
        ? milestoneList[i].tasks.length
          ? milestone.milestoneList[i].tasks.find((id) =>
              id ? id.toString() === this.props.taskID.toString() : null
            )
          : null
        : null;

      if (fnd) {
        milesTask = milestoneList[i]._id;
        break;
      }
    }

    let activeUser = this.props.activeUser ? this.props.activeUser : [];

    let activeDetails = activeUser.find(
      (item) => item.taskId.toString() === this.props.taskID.toString()
    );

    let userDetails = activeDetails ? activeDetails.user : null;

    console.log(this.props)

    return (
      <Dialog
        className="customDrawer"
        open={this.props.showDetails}
        onClose={() => this.props.cancel(this.props.token, this.props.taskID)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          style={{
            backgroundColor: this.props.taskColor + "99",
          }}
        >
          <div style={{ display: "flex" }}>
            {this.props.permitted ? (
              <MenuList
                duplicate={this.props.handleDuplicate}
                removeTask={this.props.handleRemoveTask}
              />
            ) : null}
            <TextareaAutosize
              defaultValue={this.props.title ? this.props.title : "Untitled"}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  return this.props.changed && event.preventDefault();
                }
              }}
              onBlur={this.props.changed}
              disabled={this.props.permitted ? false : true}
            />
            <CloseIcon
              onClick={() =>
                this.props.cancel(this.props.token, this.props.taskID)
              }
              className="customDrawerClose"
            />
          </div>
        </DialogTitle>

        <DialogContent style={{ padding: 0 }}>
          <div className="sidebarControllerWrapper">
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <SlowMotionVideoIcon />
                </span>
                <span className="sidebarTitle">Status</span>
              </Col>
              <Col span={14}>
                <StatusAutoComplete
                  active={
                    this.props.taskStatusActive
                      ? this.props.taskStatusActive
                      : this.props.taskStatus
                      ? this.props.taskStatus.length
                        ? this.props.taskStatus[0]._id
                        : ""
                      : ""
                  }
                  allStatus={this.props.taskStatus}
                  statusChange={this.props.handleTaskStatus}
                  handleUpdate={this.props.handleUpdate}
                  disabled={this.props.permitted ? false : true}
                />
              </Col>
            </Row>
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <InboxIcon />
                </span>
                <span className="sidebarTitle">Project</span>
              </Col>
              <Col span={14}>
                <AutoComplete
                  active={this.props.projectId}
                  projectLists={this.props.projectLists}
                  onProjectChange={this.props.changeProjectId}
                  handleUpdate={this.props.handleUpdate}
                  disabled={this.props.permitted ? false : true}
                />
              </Col>
            </Row>
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <MoodIcon />
                </span>
                <span className="sidebarTitle">Person</span>
              </Col>
              <Col span={14}>
                <UserAutoComplete
                  active={this.props.userId}
                  handleUpdate={this.props.handleUpdate}
                  onUserChange={this.props.handleMember}
                  disabled={this.props.permitted ? false : true}
                />
              </Col>
            </Row>
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <EventAvailableIcon />
                </span>
                <span className="sidebarTitle">Start - End</span>
              </Col>
              <Col span={14}>
                <span>
                  <div style={{ display: "flex" }}>
                    <DatePicker
                      onChange={this.onChangeStart}
                      value={new Date(this.state.startDate)}
                      clearIcon={null}
                      calendarIcon={null}
                      format="MMM dd, y"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      dayPlaceholder="DD"
                      className="sidebarCustomCalender hoverBGColor"
                      calendarClassName="sidebarCustomCalenderView"
                      disabled={this.props.permitted ? false : true}
                    />
                    <div className="highPen">-</div>
                    <DatePicker
                      onChange={this.onChangeEnd}
                      value={new Date(this.state.endDate)}
                      minDate={new Date(this.state.startDate)}
                      clearIcon={null}
                      calendarIcon={null}
                      format="MMM dd, y"
                      monthPlaceholder="MM"
                      yearPlaceholder="YYYY"
                      dayPlaceholder="DD"
                      className="sidebarCustomCalender hoverBGColor"
                      calendarClassName="sidebarCustomCalenderView"
                      disabled={this.props.permitted ? false : true}
                    />
                  </div>
                </span>
              </Col>
            </Row>
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <ScheduleIcon />
                </span>
                <span className="sidebarTitle">Time per Day</span>
              </Col>
              <Col span={14}>
                <TextField
                  id="standard-number"
                  className="timePerDay editHoverBGColor"
                  type="number"
                  InputProps={{ inputProps: { min: 1, max: 10 } }}
                  value={this.state.hours}
                  onChange={this.onHourChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disableUnderline={true}
                  disabled={this.props.permitted ? false : true}
                />
                {this.state.hourMsg.length ? (
                  <div className="error_message" style={{ cursor: "alias" }}>
                    **{this.state.hourMsg}
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <MilestoneIcon />
                </span>
                <span className="sidebarTitle">Milestone</span>
              </Col>
              <Col span={14}>
                <Milstone
                  active={milesTask}
                  projectId={this.props.projectId}
                  onMilestoneChange={this.props.changeMilesId}
                  handleUpdate={this.props.handleUpdate}
                  disabled={this.props.permitted ? false : true}
                />
              </Col>
            </Row>
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <Col span={10}>
                <span className="sidebarIcon">
                  <LayersIcon />
                </span>
                <span className="sidebarTitle">Automatically Skip</span>
              </Col>
              <Col span={14}>
                <div style={{ display: "flex", marginTop: 5 }}>
                  {this.state.isSplit ? (
                    <div
                      className="weekendIcon"
                      onClick={() => this.onSplitChange()}
                    >
                      <CheckCircleIcon />
                    </div>
                  ) : (
                    <div
                      className="weekendIcon"
                      onClick={() => this.onSplitChange()}
                    >
                      <CheckCircleOutlineIcon />
                    </div>
                  )}
                  <div
                    className="weekendTitle"
                    onClick={() => this.onSplitChange()}
                  >
                    Weekends
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="sidebarDescriptionWrapper">
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <TextareaAutosize
                defaultValue={this.props.description}
                placeholder="Task description"
                value={this.props.value}
                onBlur={this.props.detailsText}
                // maxRows={10}
                minRows={5}
                disabled={this.props.permitted ? false : true}
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  fontFamily: "Sofia Pro",
                  fontSize: 14,
                  color: "#585858",
                }}
              />
            </Row>
          </div>
          <div className="sidebarSubtaskWrapper">
            <Row className="sidebarControllerRow" type="flex" align="middle">
              <SubTask
                taskId={this.props.taskID}
                userId={this.props.userId}
                permitted={this.props.permitted}
              />
            </Row>
          </div>
        </DialogContent>

        <DialogActions
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          {userDetails ? (
            userDetails.id !== this.props.loggedIn.id ? (
              <div style={{ width: "100%" }}>
                <div className="error_message" style={{ cursor: "alias" }}>
                  ***
                  {userDetails.firstName + " " + userDetails.lastName} is
                  currenty updating this card
                </div>
              </div>
            ) : null
          ) : null}
          {this.props.permitted ? (
            <div style={{ display: "flex", width: "100%", marginLeft: 12 }}>
              <div style={{ width: 8 }} />
              <div className="text_button" onClick={() => this.onFirstClick()}>
                Update
              </div>
              <div
                className="text_button"
                onClick={() =>
                  this.props.cancel(this.props.token, this.props.taskID)
                }
              >
                Discard
              </div>
              {this.state.isProgress === true ? createProgress : ""}
            </div>
          ) : null}
          {!isEmpty(this.props.history) ? (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 300,
                  fontFamily: "Sofia Pro",
                  paddingLeft: 16,
                }}
              >
                Last Updated by {this.props.history.name} -{" "}
                {moment(this.props.history.updatedAt).fromNow()}
              </div>
            </div>
          ) : null}
        </DialogActions>
      </Dialog>
    );
  }
}

export default ShowTaskDetails;
