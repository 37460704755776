import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import DuplicateIcon from '@material-ui/icons/FileCopyOutlined';
import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import AttachmentIcon from '@material-ui/icons/AttachmentOutlined';
import Typography from '@material-ui/core/Typography';

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleDuplicate = () => {
    props.duplicate()
    setAnchorEl(null);
  }

  const handleRemove = () => {
    props.removeTask()
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="more"
        aria-haspopup="true"
        onClick={handleClick}
        style={{ padding: 6, marginLeft: -10 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleDuplicate}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <DuplicateIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Duplicate</Typography>
        </MenuItem>
        <MenuItem onClick={handleRemove}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <ArchiveIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Archive</Typography>
        </MenuItem>
        {/* <MenuItem onClick={handleClose}>
          <ListItemIcon style={{ minWidth: 30 }}>
            <AttachmentIcon fontSize="small" />
          </ListItemIcon>
          <Typography>Copy Link</Typography>
        </MenuItem> */}
      </Menu>
    </>
  );
}

