import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { setTeamToStore } from "../../Actions/teamActions";

import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 100,
    marginLeft: -10,
  },
  icon: {
    display: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectDiv: {
    display: "flex",
    paddingLeft: 10,
  },
}));

let allTeamsLocal = JSON.parse(localStorage.getItem("ttkAllTeams"));

function SimpleSelect(props) {
  const classes = useStyles();
  let { active, teams, teamMembers } = props;

  let activeTeam = active;

  let [teamId, setTeamId] = React.useState(activeTeam);

  const handleChange = (event) => {
    setTeamId(event.target.value);
    props.setTeamToStore(event.target.value);
    socket.emit("switchTeam", event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <div className={classes.selectDiv}>
          <div
            id="demo-simple-select-helper-label"
            style={{
              color: "grey",
              marginTop: 4,
              marginRight: 10,
              fontSize: 12,
              fontWeight: 300,
              fontFamily: "Sofia Pro",
            }}
          >
            Team:
          </div>
          <div>
            <Select
              style={{
                color: "blue",
                fontSize: 12,
                fontWeight: 300,
                fontFamily: "Sofia Pro",
              }}
              disableUnderline
              inputProps={{ classes: { icon: classes.icon } }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={teamId}
              onChange={handleChange}
            >
              {Array.isArray(teams) &&
                teams.map((item, i) => {
                  if (item.teamStatus) {
                    let check = teamMembers.allTeamMember
                      ? teamMembers.allTeamMember.find(
                          (member) => member.userId == props.userId
                        )
                      : false;
                    if (check) {
                      return (
                        <MenuItem
                          style={{
                            color: "grey",
                            fontSize: 12,
                            fontWeight: 300,
                            fontFamily: "Sofia Pro",
                          }}
                          key={i}
                          value={item._id}
                        >
                          {item.teamTitle}
                        </MenuItem>
                      );
                    }
                  }
                })}
            </Select>
          </div>
        </div>
      </FormControl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    teams: state.teams.allTeams,
    teamMembers: state.teamMembers,
  };
}

export default connect(mapStateToProps, {
  setTeamToStore,
})(SimpleSelect);
