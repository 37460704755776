import {
  ADD_INITIAL_LOG,
  UPDATE_ESTIMATE,
  GET_WEEK_LOGS,
  UPDATE_DATE_USER,
  START_TIMER,
  PAUSE_TIMER,
  SET_RUNNING,
  UPDATE_SPENT
} from '../Constants/index'

let initialState = {}

let hourLogReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INITIAL_LOG:
      return {
        ...state,
        initLogs: action.data
      }

    case UPDATE_ESTIMATE:
      return {
        ...state,
        updatedLog: action.data
      }

    case UPDATE_SPENT:
      return {
        ...state,
        updatedLog: action.data
      }

    case GET_WEEK_LOGS:
      return {
        ...state,
        weekLogs: action.data
      }

    case UPDATE_DATE_USER:
      return {
        ...state,
        updatedLog: action.data
      }

    case START_TIMER:
      return {
        ...state,
        startLog: action.data
      }

    case PAUSE_TIMER:
      return {
        ...state,
        pauseLog: action.data
      }
    case SET_RUNNING:
      return {
        ...state,
        running: action.data
      }

    default:
      return state
  }
}

export default hourLogReducer