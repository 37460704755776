import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: '#5765B3',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 700
  },
});

let LeaveHistory = props => {
  const classes = useStyles();
  let { rowData } = props

  return (
    <div>
      <TableContainer align='center'>
        <div
          style={{
            fontSize: 18,
            textAlign: 'center',
            margin: '10px 0px'
          }}
        >
          Available Leaves
        </div>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">Type</StyledTableCell>
              <StyledTableCell align="center">Available</StyledTableCell>
              <StyledTableCell align="center">Total</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.length ? rowData.map((row, key) => (
              <StyledTableRow key={key}>
                <StyledTableCell align="center" scope="row">{row.title}</StyledTableCell>
                <StyledTableCell align="center">{row.available}</StyledTableCell>
                <StyledTableCell align="center">{row.total}</StyledTableCell>
              </StyledTableRow>
            )) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default LeaveHistory