import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { deepOrange, deepPurple } from '@material-ui/core/colors';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
// Material Tooltip
import Tooltip from '@material-ui/core/Tooltip';
// Material Icons
import IconButton from '@material-ui/core/IconButton';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import ReportOutlinedIcon from '@material-ui/icons/ReportOutlined';
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import WorkIcon from '@material-ui/icons/WorkOffOutlined';

// Redux
import { logOutUser } from '../../Actions/userActions';
import DropDown from './DropDown'
import TeamDropDown from './TeamDropDown'
import ProjectDropDown from './ProjectDropDown'
import { connect } from 'react-redux';

// React Router Link
import {
    Link,
    NavLink
} from "react-router-dom";

// Media Import
import Logo from '../../logo.svg';
import trello_ic from  '../../Assets/images/trello_ic.png'

// Appbar Style
import '../../Assets/CSS/MaterialAppbar.css';
import clsx from "clsx";

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    grow: {
        flexGrow: 1,
        height: 48
    },
    appBar: {
        backgroundColor: ' #fff !important',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    title: {
        display: 'none',
        marginLeft: -6,
        marginTop: -5,
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    sectionDesktop: {
        display: 'none',
        marginRight: -10,
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    orange: {
        color: theme.palette.getContrastText(deepOrange[500]),
        backgroundColor: deepOrange[500],
    },
    purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
        fontSize: 10
    },
}));

let teamData = JSON.parse(localStorage.getItem('teamTrekkingTeams'))

function PrimaryAppBar(props) {
    const classes = useStyles();
    // console.log('WorkspaceLists', props.workspaceList)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = event => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const handleLogOut = () => {
        props.logOutUser()
    }

    const [drawerState, handelDrawer] = useState(
        false
    );


    function drawerOpenHandler(event) {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.drawerOpenHandel(() => handelDrawer(!drawerState))
    }

    const loggid_in = props.user.token.length > 0 ? true : false;
    const menuId = 'primary-search-account-menu';

    // let workspaceTitle = props.user.token && props.settings.workspaceDetails ?
    //     props.settings.workspaceDetails[0].workspace : ''

    // const [workspaceName, setWorkspace] = React.useState(workspaceTitle)

    // let handleChange = value => {
    //     setWorkspace(value)
    // }

    // console.log('settings: ', props.settings)

    let teamLocal = teamData ? teamData.length : 0

    let permission = props.teamMembers ? 
        props.teamMembers.permission ? props.teamMembers.permission : null 
    : null

    let permitted = permission ? permission > 0 && permission <=2 : null

    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            {!loggid_in ?
                <span>
                    <MenuItem key={0} onClick={handleMenuClose}>
                        <Link to="/signin">Signin</Link>
                    </MenuItem>
                    <MenuItem key={1} onClick={handleMenuClose}>
                        <Link to="/signup">Signup</Link>
                    </MenuItem>
                </span>
                :
                <span>
                    <MenuItem key={0} onClick={handleMenuClose}>
                        <Link to="/userprofile">
                            {props.user.userDetails.firstName}
                        </Link>
                    </MenuItem>
                    <MenuItem key={1} onClick={handleLogOut}>
                        <Link to="/">Log Out</Link>
                    </MenuItem>
                </span>
            }

        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            {/* <MenuItem key={0}>
                <IconButton color="primary">
                    <DashboardOutlinedIcon />
                </IconButton>
                <p>Dashboard</p>
            </MenuItem> */}
            <NavLink exact to="/" activeClassName="active">
                <MenuItem key={1}>
                    <IconButton color="primary">
                        <TimelineIcon />
                    </IconButton>
                    <p>Timeline</p>
                </MenuItem>
            </NavLink>
            <NavLink exact to="hourlog" activeClassName="active">
                <MenuItem key={2}>
                    <IconButton color="primary">
                        <AvTimerIcon />
                    </IconButton>
                    <p>Hour log</p>
                </MenuItem>
            </NavLink>

            <NavLink exact to="status" activeClassName="active">
                <MenuItem key={3}>
                    <IconButton color="primary">
                        <img
                            style={{width: 20, height: 20}}
                            src={trello_ic}/>
                    </IconButton>
                    <p>Task status</p>
                </MenuItem>
            </NavLink>
            <NavLink exact to="leave" activeClassName="active">
                <MenuItem key={4}>
                    <IconButton color="primary">
                        <WorkIcon />
                    </IconButton>
                    <p>Leave</p>
                </MenuItem>
            </NavLink>

            <MenuItem key={5} onClick={handleProfileMenuOpen}>
                <IconButton
                    aria-label="account of current user"
                    aria-controls="primary-search-account-menu"
                    aria-haspopup="true"
                    color="inherit"
                >
                    <AccountCircle />
                </IconButton>
                <p>Profile</p>
            </MenuItem>
        </Menu>
    );

    let activeTeam = teamData ? teamData :
        (props.team.allTeams ? (props.team.allTeams.length ? props.team.allTeams[0]._id : null)
            : null)

    return (
        <div className={classes.grow}>
            <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: props.state,
                })}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={drawerOpenHandler}
                        edge="start"
                        disabled={permitted ? false : true}
                        className={clsx(classes.menuButton, props.state && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        <Link to="/">
                            <img src={Logo} alt="Team Trakking Logo" />
                        </Link>
                    </Typography>
                    {
                        props.user.token ?
                            <>
                                <DropDown
                                    active={props.settings.workspace ? props.settings.workspace[0] : props.workspace[0]}
                                    workspaces={props.workspaceList.allWorkspaces ? props.workspaceList.allWorkspaces : []}
                                    reload={props.refreshPage}
                                />
                                {
                                    props.team.allTeams ?
                                        <>
                                            <TeamDropDown
                                                active={activeTeam}
                                                reload={props.refreshPage}
                                                userId={props.user.userDetails.id}
                                                allMembers={props.teamMembers.allTeamMember ? props.teamMembers.allTeamMember : []}
                                            />
                                            <ProjectDropDown />
                                        </>
                                        : null

                                }
                            </> : null
                    }
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        {/*<Typography*/}
                        {/*    style={{*/}
                        {/*        color: 'blue',*/}
                        {/*        fontSize: 12,*/}
                        {/*        fontWeight: 300,*/}
                        {/*        fontFamily: 'Sofia Pro',*/}
                        {/*        marginTop: "3%",*/}
                        {/*        paddingRight: 2*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {props.user.userDetails.firstName + ' ' + props.user.userDetails.lastName}*/}
                        {/*</Typography>*/}
                        {/* <NavLink to="dashboard" activeClassName="active">
                            <Tooltip title="Dashboard">
                                <IconButton color="inherit">
                                    <DashboardOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </NavLink> */}

                        <NavLink exact to="/" activeClassName="active">
                            <Tooltip title="Timeline">
                                <IconButton color="inherit">
                                    <TimelineIcon />
                                </IconButton>
                            </Tooltip>
                        </NavLink>

                        <NavLink exact to="status" activeClassName="active">
                            <Tooltip title="Task status">
                                <IconButton color="inherit">
                                    <img
                                        style={{width: 20, height: 20}}
                                        src={trello_ic}/>
                                </IconButton>
                            </Tooltip>
                        </NavLink>

                        <NavLink exact to="hourlog" activeClassName="active">
                            <Tooltip title="Hour log">
                                <IconButton color="inherit">
                                    <AvTimerIcon />
                                </IconButton>
                            </Tooltip>
                        </NavLink>

                        <NavLink exact to="leave" activeClassName="active">
                            <Tooltip title="Leave">
                                <IconButton color="inherit">
                                    <WorkIcon />
                                </IconButton>
                            </Tooltip>
                        </NavLink>

                        {/* <NavLink to="report" activeClassName="active">
                            <Tooltip title="Reports">
                                <IconButton color="inherit">
                                    <ReportOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        </NavLink> */}


                        {/*<a href="/#" onClick={drawerOpenHandler}>*/}
                        {/*    <Tooltip title="Settings">*/}
                        {/*        <IconButton color="inherit">*/}
                        {/*            <SettingsApplicationsOutlinedIcon />*/}
                        {/*        </IconButton>*/}
                        {/*    </Tooltip>*/}
                        {/*</a>*/}

                        <Tooltip title="Account">
                            <IconButton
                                edge="end"
                                aria-label="account of current user"
                                aria-controls={menuId}
                                aria-haspopup="true"
                                onClick={handleProfileMenuOpen}
                                color="inherit"
                            >
                                {
                                    props.user.userDetails.avatar ? (
                                        <Avatar
                                            className={classes.purple}
                                            style={{
                                                height: 22,
                                                width: 22
                                            }}
                                            src={props.user.userDetails.avatar}
                                        />
                                    ) : (
                                            <Avatar
                                                className={classes.purple}
                                                style={{
                                                    height: 22,
                                                    width: 22,
                                                    paddingTop: 2
                                                }}
                                            >
                                                {props.user.userDetails.shortName}
                                            </Avatar>
                                        )
                                }
                                {/*<AccountCircle />*/}
                            </IconButton>
                        </Tooltip>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
        </div >
    );
}

function mapStateToProps(state) {
    return {
        user: state.user ? state.user : '',
        settings: state.settings ? state.settings : '',
        team: state.teams,
        teamMembers: state.teamMembers,
    };
}

export default connect(
    mapStateToProps,
    {
        logOutUser
    }
)(PrimaryAppBar)
