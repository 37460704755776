import React, { useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { rejectUserLeave } from "../../Actions/leaveActions";
import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import CancelDialog from "./CancelLeave";
import Pagination from "@material-ui/lab/Pagination";

let socket = socketIOClient(serverSocket);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#5765B3",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    maxWidth: 700,
  },
});

let LeavePending = (props) => {
  const classes = useStyles();
  let { rowData, rejectUserLeave, token } = props;

  let [rejectId, setReject] = useState("");
  let [open, setOpen] = useState(false);
  let [page, setPage] = useState(1);
  let [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  let openDialog = (id) => {
    setOpen(true);
    setReject(id);
  };

  let handleReject = () => {
    if (token) {
      let sendData = {
        _id: rejectId,
      };
      rejectUserLeave(sendData, token).then((res) => {
        socket.emit("updateLeaves", res.data);
        setOpen(false);
      });
    }
  };

  return (
    <div>
      <CancelDialog
        open={open}
        handleOpen={setOpen}
        handleReject={handleReject}
      />
      {rowData.length ? (
        <TableContainer align="center">
          <div
            style={{
              fontSize: 18,
              textAlign: "center",
              margin: "10px 0px",
            }}
          >
            Leaves pending to approval
          </div>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Date(s)</StyledTableCell>
                <StyledTableCell align="center">Type</StyledTableCell>
                <StyledTableCell align="center">Days</StyledTableCell>
                <StyledTableCell align="center">Cancel</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData.length
                ? (rowsPerPage > 0
                    ? rowData &&
                      rowData.slice(
                        (page - 1) * rowsPerPage,
                        (page - 1) * rowsPerPage + rowsPerPage
                      )
                    : rowData
                  ).map((row, key) => (
                    <StyledTableRow key={key}>
                      <StyledTableCell align="center" scope="row">
                        {row.dates.map((day) => {
                          return <div>{day}</div>;
                        })}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.type}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row.days}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <IconButton
                          color="secondary"
                          onClick={() => openDialog(row.id)}
                        >
                          <DeleteIcon className={classes.check} />
                        </IconButton>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : null}
            </TableBody>
            <div>
              <Pagination
                style={{ height: 48, paddingTop: 8 }}
                count={
                  rowData
                    ? rowData.length
                      ? Math.ceil(rowData.length / 10)
                      : 0
                    : 0
                }
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChange={handleChangePage}
              />
            </div>
          </Table>
        </TableContainer>
      ) : null}
    </div>
  );
};

export default connect(null, { rejectUserLeave })(LeavePending);
