import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import '.././Assets/CSS/jannatCss.css';

const ColorLinearProgress = withStyles({
    colorPrimary: {
        backgroundColor: '#b2dfdb',
    },
    barColorPrimary: {
        backgroundColor: 'rgba(27,40,105,0.65)',
    },
})(LinearProgress);

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

export default function ScrollerProgress() {
    const classes = useStyles();

    return (
        <div className= "scroller-progressbar">
            <div className={classes.root}>
                <ColorLinearProgress />
            </div>
        </div>

    );
}