import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

// Material icons
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import FilterNoneIcon from '@material-ui/icons/FilterNone';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import PersonAddDisabledOutlinedIcon from '@material-ui/icons/PersonAddDisabledOutlined';
import WorkSpaceIcon from '@material-ui/icons/WorkOutlineOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';

import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';

import { connect } from "react-redux";
import { addWorkspace, updateWorkspace, updateWorkspaceStatus } from '../../Actions/settingsActions'
import { logOutUser, inviteUser } from "../../Actions/userActions";
import { addProject, updateProject } from "../../Actions/projectActions";
import { addNewTeam, updateTeam } from "../../Actions/teamActions";
import { addNewTeamMember, findUserByMail, updateTeamMember } from "../../Actions/teamMemberActions";
import AddProjectForm from '../Workspace/AddProjectForm';
import AddTeamForm from '../Workspace/AddTeamForm';
import RemoveTeam from "../Workspace/RemoveTeam";
import AddTeamMember from "../Workspace/AddTeamMember";
import RemoveMember from "../Workspace/RemoveMember";
import AddWorkspaceForm from "../Workspace/AddWorkspaceForm"
import EditProjectForm from "../Workspace/EditProjectForm"
import EditProject from '../Workspace/EditProject'
import RemoveProjectFrom from "../Workspace/RemoveProject"
import UpdateWorkspaceStatus from '../Workspace/UpdateWorkspaceStatus'
import UpdateWorkspace from "../Workspace/UpdateWorkspace"

const useStyles = makeStyles(theme => ({
    list: {
        width: 230,
    },
    fullList: {
        width: 'auto',
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

function SettingsDrawer(props) {
    const classes = useStyles();

    let { open, setOpen } = props

    let [updateWorkspaceId, setUpdateWorkspace] = useState('')

    let handleUpdateWorkspace = (id) => {
        setUpdateWorkspace(id)
    }
    
    const [modalStatus, handleProjectModal] = useState(
        [
            {
                workspaceModal: false,
                updateWorkspaceModal: false,
                updateWorkspaceStatusModal: false,
                projectModal: false,
                updateProject: false,
                removeProject: false,
                addTeamModal: false,
                removeTeamModal: false,
                addTeamMemberModal: false,
                removeTeamMemberModal: false
            }
        ]
    );

    let [updateId, setUpdateProject] = useState('')
    let [updateDetails, setUpdateDetails] = useState(false)

    let handleUpdateProject = value => {
        setUpdateProject(value)
        if (value.length) {
            setUpdateDetails(true)
        }
    }

    let handleUpdateDetails = () => {
        setUpdateDetails(false)
    }

    const handleClickTeam = () => {
        let change = {
            team: !open.team,
            teamMember: false,
            project: false,
            workspace: false
        }
        setOpen(change);
    };

    const handleClickTeamMember = () => {
        let change = {
            team: false,
            teamMember: !open.teamMember,
            project: false,
            workspace: false
        }
        setOpen(change);
    };

    const handleClickProject = () => {
        let change = {
            team: false,
            teamMember: false,
            project: !open.project,
            workspace: false
        }
        setOpen(change)
    }

    const handleClickWorkspace = () => {
        let change = {
            team: false,
            teamMember: false,
            project: false,
            workspace: !open.workspace
        }
        setOpen(change)
    }

    // Project Modal Handler
    function showProjectModal() {
        handleProjectModal({
            projectModal: !modalStatus.projectModal
        })
    }

    function showUpdateProjectModal() {
        handleProjectModal({
            updateProject: !modalStatus.updateProject
        })
    }

    function showRemoveProjectModal() {
        handleProjectModal({
            removeProject: !modalStatus.removeProject
        })
    }

    // End of Project Modal Handler

    // Team Add modal handler
    function showAddTeamModal() {
        handleProjectModal({
            addTeamModal: !modalStatus.addTeamModal
        })
    }
    function showRemoveTeamModal() {
        handleProjectModal({
            removeTeamModal: !modalStatus.removeTeamModal
        })
    }

    // End of Team Add modal handler

    // Add Team Member modal handler
    function showTeamMemberModal() {
        handleProjectModal({
            addTeamMemberModal: !modalStatus.addTeamMemberModal
        })
    }
    // End of Add Team Member modal handler

    // Remove Team Member modal handler
    function showRemoveTeamMemberModal() {
        handleProjectModal({
            removeTeamMemberModal: !modalStatus.removeTeamMemberModal
        })
    }

    function showAddWorkspaceModal() {
        handleProjectModal({
            workspaceModal: !modalStatus.workspaceModal
        })
    }

    function showUpdateWorkspaceModal() {
        handleProjectModal({
            updateWorkspaceModal: !modalStatus.updateWorkspaceModal
        })
    }

    function showUpdateWorkspaceStatusModal() {
        handleProjectModal({
            updateWorkspaceStatusModal: !modalStatus.updateWorkspaceStatusModal
        })
    }

    let userId = props.user ? 
        props.user.userDetails ? props.user.userDetails.id : null 
        : null

    return (
        <div className={classes.list}>
            <List>
                <ListItem
                    button
                    onClick={handleClickWorkspace}
                >
                    <ListItemIcon>
                        <WorkSpaceIcon />
                    </ListItemIcon>
                    <ListItemText primary="Workspace" />
                    {open.workspace ? <ExpandLess /> : <ExpandMore />}
                </ListItem>

                <Collapse in={open.workspace} timeout='auto' unmountOnExit>
                    <List component='div'>
                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showAddWorkspaceModal}
                        >
                            <ListItemIcon>
                                <AddCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Workspace" />
                        </ListItem>
                        <AddWorkspaceForm
                            token={props.user.token}
                            onAdd={props.addWorkspace}
                            addTeam={props.addNewTeam}
                            handleClose={showAddWorkspaceModal}
                            open={modalStatus.workspaceModal}
                        />
                        {/* <ListItem
                            button
                            className={classes.nested}
                            onClick={showUpdateWorkspaceModal}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Update Workspace" />
                        </ListItem> */}
                        <UpdateWorkspace
                            token={props.user.token}
                            onUpdate={props.updateWorkspace}
                            handleClose={showUpdateWorkspaceModal}
                            open={modalStatus.updateWorkspaceModal}
                            allWorkspaces={props.settings.allWorkspaces}
                            updateWorkspaceId={updateWorkspaceId}
                        />
                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showUpdateWorkspaceStatusModal}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Workspace List" />
                        </ListItem>
                        <UpdateWorkspaceStatus
                            token={props.user.token}
                            onUpdate={props.updateWorkspaceStatus}
                            handleClose={showUpdateWorkspaceStatusModal}
                            open={modalStatus.updateWorkspaceStatusModal}
                            allWorkspaces={props.settings.allWorkspaces}
                            userId={userId}
                            showUpdateWorkspace={showUpdateWorkspaceModal}
                            handleWorkspace={handleUpdateWorkspace}
                            addWorkspace={showAddWorkspaceModal}
                            userId={userId}
                        />
                    </List>
                </Collapse>

                <ListItem button onClick={handleClickProject}>
                    <ListItemIcon>
                        <FilterNoneIcon />
                    </ListItemIcon>
                    <ListItemText primary="Project" />
                    {open.project ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open.project} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showProjectModal}
                        >
                            <ListItemIcon>
                                <AddCircleIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Project" />
                        </ListItem>
                        <AddProjectForm
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            onAdd={props.addProject}
                            createdBy={props.user.userDetails.id}
                            handleClose={showProjectModal}
                            open={modalStatus.projectModal}
                            members={props.teamMembers.allTeamMember ?
                                props.teamMembers.allTeamMember : []
                            }
                            activeTeam={props.team.activeTeam}
                        />

                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showUpdateProjectModal}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Project List" />
                        </ListItem>
                        <EditProjectForm
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            allProjects={props.projects.allProjects}
                            onUpdate={props.updateProject}
                            open={modalStatus.updateProject}
                            createClick={showProjectModal}
                            actionId={updateId}
                            handleProjectId={handleUpdateProject}
                            handleClose={showUpdateProjectModal}
                        />
                        {
                            updateId.length && updateDetails ? (
                                <EditProject
                                    token={props.user.token}
                                    allProjects={props.projects.allProjects}
                                    workspaceId={props.workspace[0]}
                                    onAdd={props.updateProject}
                                    updateId={updateId}
                                    createdBy={props.user.userDetails.id}
                                    handleClose={handleUpdateDetails}
                                    open={updateDetails}
                                    members={props.teamMembers.allTeamMember ?
                                        props.teamMembers.allTeamMember : []
                                    }
                                    activeTeam={props.team.activeTeam}
                                />
                            ) : null
                        }
                    </List>

                    {/*<ListItem*/}
                    {/*    button*/}
                    {/*    className={classes.nested}*/}
                    {/*    onClick={showRemoveProjectModal}*/}
                    {/*>*/}
                    {/*    <ListItemIcon>*/}
                    {/*        <DeleteIcon />*/}
                    {/*    </ListItemIcon>*/}
                    {/*    <ListItemText primary="Delete Project" />*/}
                    {/*</ListItem>*/}

                    {/*<RemoveProjectFrom*/}
                    {/*    token={props.user.token}*/}
                    {/*    workspaceId={props.workspace[0]}*/}
                    {/*    allProjects={props.projects.allProjects}*/}
                    {/*    onUpdate={props.updateProject}*/}
                    {/*    open={modalStatus.removeProject}*/}
                    {/*    handleClose={showRemoveProjectModal}*/}
                    {/*/>*/}
                </Collapse>

                <ListItem button onClick={handleClickTeam}>
                    <ListItemIcon>
                        <PeopleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Team" />
                    {open.team ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open.team} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showAddTeamModal}
                        >
                            <ListItemIcon>
                                <GroupAddOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Team" />
                        </ListItem>
                        <AddTeamForm
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            onAdd={props.addNewTeam}
                            createdBy={props.user.userDetails.id}
                            open={modalStatus.addTeamModal}
                            handleClose={showAddTeamModal}
                        />

                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showRemoveTeamModal}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Team List" />
                        </ListItem>
                        <RemoveTeam
                            allTeams={props.team.allTeams ? props.team.allTeams : []}
                            activeTeam={props.team.activeTeam}
                            allMembers={props.teamMembers.allTeamMember ? props.teamMembers.allTeamMember : []}
                            roles={
                                props.teamMembers ?
                                    props.teamMembers.roles ? props.teamMembers.roles : []
                                    : []
                            }
                            workspace={props.settings ?
                                props.settings.workspaceDetails ? props.settings.workspaceDetails : null
                                : null
                            }
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            onRemove={props.updateTeam}
                            addTeamModal={showAddTeamModal}
                            open={modalStatus.removeTeamModal}
                            handleClose={showRemoveTeamModal}
                        />
                    </List>
                </Collapse>

                <ListItem button onClick={handleClickTeamMember}>
                    <ListItemIcon>
                        <PersonOutlinedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Team Member" />
                    {open.teamMember ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={open.teamMember} timeout="auto" unmountOnExit>
                    <List component="div">
                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showTeamMemberModal}
                        >
                            <ListItemIcon>
                                <PersonAddOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary="Add Team Member" />
                        </ListItem>
                        <AddTeamMember
                            activeTeam={props.team.activeTeam}
                            allMembers={props.teamMembers ?
                                props.teamMembers.allTeamMember ? props.teamMembers.allTeamMember : []
                                : []
                            }
                            roles={
                                props.teamMembers ?
                                    props.teamMembers.roles ? props.teamMembers.roles : []
                                    : []
                            }
                            workspace={props.settings ?
                                props.settings.workspaceDetails ? props.settings.workspaceDetails : null
                                : null
                            }
                            allTeams={props.team.allTeams ? props.team.allTeams : []}
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            onAdd={props.addNewTeamMember}
                            search={props.findUserByMail}
                            open={modalStatus.addTeamMemberModal}
                            handleClose={showTeamMemberModal}
                            addInvited={props.inviteUser}
                        />

                        <ListItem
                            button
                            className={classes.nested}
                            onClick={showRemoveTeamMemberModal}
                        >
                            <ListItemIcon>
                                <EditIcon />
                            </ListItemIcon>
                            <ListItemText primary="Team Member List" />
                        </ListItem>
                        <RemoveMember
                            allTeams={props.team.allTeams ? props.team.allTeams : []}
                            activeTeam={props.team.activeTeam}
                            allMembers={props.teamMembers.allTeamMember ? props.teamMembers.allTeamMember : []}
                            roles={
                                props.teamMembers ?
                                    props.teamMembers.roles ? props.teamMembers.roles : []
                                    : []
                            }
                            workspace={props.settings ?
                                props.settings.workspaceDetails ? props.settings.workspaceDetails : null
                                : null
                            }
                            token={props.user.token}
                            workspaceId={props.workspace[0]}
                            onRemove={props.updateTeamMember}
                            open={modalStatus.removeTeamMemberModal}
                            handleClose={showRemoveTeamMemberModal}
                            addMemberModal={showTeamMemberModal}
                        />
                    </List>
                </Collapse>

            </List>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        user: state.user ? state.user : '',
        workspace: state.settings.workspace ? state.settings.workspace : '',
        team: state.teams,
        teamMembers: state.teamMembers,
        tasks: state.tasks,
        projects: state.projects,
        settings: state.settings ? state.settings : ''
    };
}

export default connect(
    mapStateToProps,
    {
        logOutUser,
        addProject,
        updateTeam,
        addNewTeam,
        addNewTeamMember,
        findUserByMail,
        updateTeamMember,
        addWorkspace,
        updateProject,
        updateWorkspace,
        inviteUser,
        updateWorkspaceStatus
    }
)(SettingsDrawer)
