/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

// Containers
import MaterialAppBar from "../Container/Header/MaterialAppBar";
import { SwipeableDrawer } from "@material-ui/core";

import Signup from "../Container/User/Signup";
import Signin from "../Container/User/Signin";
import Workspace from "../Container/Workspace/Dashboard";
import SettingsSidebar from "../Container/Settings/SettingsSidebar";
import HourlogNav from "../Container/Hourlog/HourlogNav";
import IndexStatus from "../Container/Status/index"
import LeaveNav from "../Container/LeaveModule/LeaveNav";
import ForgetPassword from "../Container/User/SetEmail";
import EmailVerify from "../Container/User/EmailVerify";
import UpdatePassword from "../Container/User/NewPassword";

// Redux
import { connect } from "react-redux";
import { logOutUser } from "../Actions/userActions";

// Socket
import socketIOClient from "socket.io-client";
import { getAllTeam } from "../Actions/teamActions";
import { findUserByMail } from "../Actions/teamMemberActions";
import { getWorkspace, getAllWorkspacesUser } from "../Actions/settingsActions";
import { getAllProjects } from "../Actions/projectActions";
import { getAllSubTasks } from "../Actions/subTaskActions";
import { findAllTeamMembers } from "../Actions/teamMemberActions";
import { getAllRoles } from "../Actions/roleActions";
import { getAllHoliday } from "../Actions/holidayActions";

// socketIO
import { serverSocket } from "../Actions/config";
import Verification from "../Container/User/Verification";
import PersistentDrawerLeft from "../Container/Settings/DrawerNav";
import PersistentDrawerLeftAnother from "../Container/AnotherView/AnotherDrawerNav";
import ResponsiveDrawer from "../Container/Settings/UserNav";

let socket = socketIOClient(serverSocket);
let userVerified = localStorage.getItem("userVerified");

function RouteController(props) {
  // console.log('props.user', props.user)

  // socket.on('teamData', function (data) {
  //     console.log('SOCKET', data)
  // })

  const loggedIn =
    props.user.token && props.workspace.length > 0
      ? props.user.token.length > 0
        ? true
        : false
      : false;

  let isVerified = props.user
    ? props.user.userDetails
      ? props.user.userDetails.status
      : null
    : userVerified;

  const [state, handelEvent] = useState(false);

  useEffect(() => {
    if (props.user.token) {
      let workspaceId = props.settings.workspace
        ? props.settings.workspace[0]
        : null;
      let data = { workspaceId: workspaceId };
      let token = props.user.token;
      if (workspaceId) {
        props.getWorkspace(data, token);
        props.getAllRoles(token);
        props.getAllTeam(data, token);
        props.findAllTeamMembers(data, token);
        props.getAllProjects(data, token);
        props.getAllWorkspacesUser(token);
        props.getAllSubTasks(data, token);
        props.getAllHoliday(data, token);
        socket.on("addWorkspace", (data) => {
          props.getAllWorkspacesUser(token);
        });
        socket.on("addTeam", (sockData) => {
          props.getAllTeam(data, token);
        });
        socket.on("addUserRole", (socketData) => {
          props.findAllTeamMembers(data, token);
        });
      }
    }
  }, []);

  function onOpenDrawer() {
    handelEvent(!state);
  }

  function refreshPage() {
    window.location.reload(false);
  }

  let userDetails = props.user
    ? props.user.userDetails
      ? props.user.userDetails
      : null
    : null;
  let teamMembers = props.teamMembers
    ? props.teamMembers.allTeamMember
      ? props.teamMembers.allTeamMember
      : []
    : [];
  let roles = props.teamMembers
    ? props.teamMembers.roles
      ? props.teamMembers.roles
      : []
    : [];
  let holidays = props.holidays
    ? props.holidays.allHolidays
      ? props.holidays.allHolidays
      : []
    : [];
  let loading = props.holidays ? props.holidays.holidayLoading : true;
  let workspaceOwner = props.workspaceDetails
    ? props.workspaceDetails[0]
      ? props.workspaceDetails[0].createdBy
      : null
    : null;

  let userId = userDetails ? userDetails.id : "";

  let activeTeam = props.activeTeam ? props.activeTeam : "";

  let userMember = teamMembers.length
    ? teamMembers.find(
        (item) => item.userId === userId && item.teamId === activeTeam
      )
    : null;

  let userRoleId = userMember ? userMember.userRole : null;

  let userRole = roles.length
    ? roles.find((item) => item._id === userRoleId)
    : null;

  let priority = userRole ? userRole.priority : 0;

  let permission = workspaceOwner === userId ? 1 : priority;

  return (
    <Router>
      <div style={{ overflow: "hidden" }}>
        {/*<>*/}
        {/*  {loggedIn ? (*/}
        {/*    <MaterialAppBar state={state}*/}
        {/*      workspace={props.workspace}*/}
        {/*      drawerOpenHandel={onOpenDrawer}*/}
        {/*      workspaceList={props.settings}*/}
        {/*      refreshPage={refreshPage}*/}
        {/*    />*/}
        {/*  ) : null}*/}
        {/*  <SwipeableDrawer*/}
        {/*    children={*/}
        {/*      <SettingsSidebar*/}
        {/*        // openModal={}*/}
        {/*        userData={props}*/}
        {/*      />*/}
        {/*    }*/}
        {/*    onBackdropClick={onOpenDrawer}*/}
        {/*    onEscapeKeyDown={onOpenDrawer}*/}
        {/*    // onOpen={onOpenDrawer}*/}
        {/*    open={state}*/}
        {/*    onClose={!state}*/}
        {/*  />*/}
        {/*   End of Resources add modal call */}
        {/*</>*/}
        <Switch>
          <Route exact path="/">
            {loggedIn ? (
              isVerified ? (
                //<Workspace status={props.workspace} />
                <PersistentDrawerLeft
                  loggedIn={loggedIn}
                  status={props.workspace}
                  userData={props}
                  onOpenDrawer={onOpenDrawer}
                  state={state}
                  refreshPage={refreshPage}
                  permission={permission}
                  holidays={holidays}
                  loading={loading}
                />
              ) : (
                <Redirect to="/verification" />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>
          <Route exact path="/another-view">
            {loggedIn ? (
              isVerified ? (
                <PersistentDrawerLeftAnother
                  loggedIn={loggedIn}
                  status={props.workspace}
                  userData={props}
                  onOpenDrawer={onOpenDrawer}
                  state={state}
                  refreshPage={refreshPage}
                  permission={permission}
                  holidays={holidays}
                  loading={loading}
                />
              ) : (
                <Redirect to="/verification" />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>
          <Route exact path="/userprofile">
            {loggedIn ? (
              isVerified ? (
                <ResponsiveDrawer />
              ) : (
                <Redirect to="/verification" />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>

          <Route exact path="/hourlog">
            {loggedIn ? (
              isVerified ? (
                <HourlogNav permission={permission} />
              ) : (
                <Redirect to="/verification" />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>

          <Route exact path="/status">
            {loggedIn ? (
              isVerified ? (
                <IndexStatus permission={permission} />
              ) : (
                <Redirect to="/verification" />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>

          <Route exact path="/leave">
            {loggedIn ? (
              isVerified ? (
                <LeaveNav />
              ) : (
                <Redirect to="/verification" />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>

          <Route exact path="/forget-password">
            <ForgetPassword />
          </Route>

          <Route exact path="/email-verify">
            <EmailVerify />
          </Route>

          <Route exact path="/submit-password">
            {isVerified ? (
              <UpdatePassword />
            ) : (
              <Redirect to="/forget-password" />
            )}
          </Route>

          <Route path="/signin">
            {loggedIn ? <Redirect to="/" /> : <Signin />}
          </Route>
          <Route path="/signup">
            <Signup />
          </Route>
          <Route path="/verification">
            {loggedIn ? (
              isVerified ? (
                <Redirect to="/" />
              ) : (
                <Verification />
              )
            ) : (
              <Redirect to="/signin" />
            )}
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : "",
    workspace: state.settings.workspace ? state.settings.workspace : "",
    workspaceDetails: state.settings
      ? state.settings.workspaceDetails
        ? state.settings.workspaceDetails
        : []
      : [],
    settings: state.settings ? state.settings : "",
    teamMembers: state.teamMembers ? state.teamMembers : null,
    holidays: state.holidays ? state.holidays : [],
    activeTeam: state.teams
      ? state.teams.activeTeam
        ? state.teams.activeTeam
        : null
      : null,
  };
}

export default connect(mapStateToProps, {
  logOutUser,
  findUserByMail,
  getWorkspace,
  getAllWorkspacesUser,
  getAllTeam,
  getAllProjects,
  getAllSubTasks,
  findAllTeamMembers,
  getAllRoles,
  getAllHoliday,
})(RouteController);
