import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { Scrollbars } from "react-custom-scrollbars";
import "../../Assets/CSS/Leave.css";
import TextareaAutosize from "react-textarea-autosize";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { connect } from "react-redux";

import {
  startTimer,
  pauseTimer,
  updateEstimate,
  updateSpent,
} from "../../Actions/hourLogActions";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 48,
    marginLeft: 32,
    marginRight: 32,
  },
  thisweek: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32,
  },
  lastweek: {
    marginTop: 32,
    marginLeft: 32,
    marginRight: 32,
    marginBottom: 64,
  },
  paper: { minWidth: "900px" },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  close: {
    position: "absolute",
    right: 5,
    top: 3,
  },
  check: {
    height: 28,
    width: 28,
  },
}));

let spentHour = (spent) => {
  let final = spent / (60 * 60 * 1000);
  return final.toFixed(2);
};

let msToTime = (duration) => {
  let milliseconds = parseInt((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds;
};

let weekTotal = (week) => {
  let total = 0;
  for (let i = 0; i < 7; i++) {
    let len = week[i].logs.length;
    let log = week[i].logs;
    for (let j = 0; j < len; j++) {
      total += log[j].spent;
    }
  }
  return total;
};

let dayTotal = (logs) => {
  let total = 0;
  for (let j = 0; j < logs.length; j++) {
    total += logs[j].spent;
  }
  return total;
};

function Hourlog(props) {
  let classes = useStyles();
  let {
    token,
    tasks,
    history,
    projects,
    dates,
    getDetails,
    getDates,
    addUserLeave,
    workspaceId,
    leaveId,
    handleLeaveId,
    setDetails,
    userPendings,
    ownerEmail,
    userEmail,
    open,
    handleOpen,
    setDescription,
    description,
    handleClose,
  } = props;

  let dateArray = getDates(dates.start, dates.end);

  let taskData = getDetails(tasks, history, projects, dateArray);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let onConfirm = () => {
    if (token) {
      if (userPendings.length) {
        alert("Previous leave requests are not yet reviewed by Management");
      } else {
        let sendData = {
          workspaceId: workspaceId,
          leaveId: leaveId,
          days: dateArray,
          ownerEmail: ownerEmail,
          userEmail: userEmail,
          description: description,
        };
        addUserLeave(sendData, token).then((res) => {
          if (res) {
            if (res.data) {
              handleLeaveId("");
              setDescription("");
              socket.emit("updateLeaves", res.data);
              handleOpen(false);
              setDetails(false);
            }
          }
        });
      }
    }
  };

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogContent>
        <Scrollbars
          className="scrollers"
          style={{ height: window.innerHeight }}
        >
          {/*Next Week*/}
          <div className="this-week">
            <div>Available Tasks:</div>
          </div>
          <Box>
            {taskData.length
              ? taskData.map((dayData, idx) => {
                  if (dayData.logs.length) {
                    return (
                      <Card key={idx} className={classes.thisweek}>
                        <CardContent>
                          <div className="date-name">
                            <span>
                              {moment(dayData.date).format("ddd, MMM D")}
                            </span>
                            <div className="spent">S</div>
                            <div className="estimate">E</div>
                          </div>
                          {dayData.logs.map((log, key) => {
                            return (
                              <div className="week-row">
                                <TextareaAutosize
                                  disabled
                                  value={
                                    log.taskName ? log.taskName : "Untitled"
                                  }
                                  placeholder="What are you working now?"
                                  onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                      return event.preventDefault();
                                    }
                                  }}
                                />
                                <div
                                  style={{ color: log.projectColor }}
                                  className="header-project"
                                >
                                  <FiberManualRecordIcon />
                                  <span>
                                    {log.projectTitle
                                      ? log.projectTitle
                                      : "Untitled"}
                                  </span>
                                </div>
                                <div className="header-estimate">
                                  <TextareaAutosize
                                    disabled
                                    value={spentHour(log.spent)}
                                    placeholder="S: 0"
                                    onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                        return event.preventDefault();
                                      }
                                    }}
                                  />
                                  <span>-</span>
                                  <TextareaAutosize
                                    disabled
                                    value={log.estimated}
                                    placeholder="E: 00:00"
                                    onKeyPress={(event) => {
                                      if (event.key === "Enter") {
                                        return event.preventDefault();
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </CardContent>
                      </Card>
                    );
                  }
                })
              : "No tasks Assigned"}
          </Box>
        </Scrollbars>
      </DialogContent>
      <DialogActions>
        <div className="week-total">
          <Button
            variant="outlined"
            style={{ color: "#4A9AFF", marginRight: 10 }}
            onClick={() => onConfirm()}
          >
            Confirm
          </Button>
          <Button
            variant="outlined"
            style={{ color: "#f50057" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export default connect(null, {
  startTimer,
  pauseTimer,
  updateEstimate,
  updateSpent,
})(Hourlog);
