/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";

const filter = createFilterOptions();

function FreeSoloCreateOption(props) {
  let { active, teamMembers, onUserChange, handleUpdate, activeTeam } = props;
  let member = teamMembers
    ? teamMembers.find((item) => item._id === active)
    : null;
  let valueTitle = member ? (member.name ? member.name : "") : "";
  const [value, setValue] = React.useState({ title: valueTitle });

  let handleChange = (resourceId, userId) => {
    onUserChange(resourceId, userId);
    if (handleUpdate) {
      handleUpdate(true);
    }
  };

  let filteredMembers = activeTeam
    ? teamMembers.filter(
        (member) => member.teamId.toString() === activeTeam.toString()
      )
    : [];

  return (
    <Autocomplete
      className="customSingleSelect editHoverBGColor"
      value={value ? value.title : value}
      disableClearable
      disableOpenOnFocus={false}
      forcePopupIcon={true}
      onChange={(event, newValue) => {
        if (newValue) {
          if (newValue._id && newValue.status) {
            handleChange(newValue._id, newValue.userId);
            if (newValue) {
              setValue({
                title: newValue.name,
              });
              return;
            }
            setValue(newValue);
          } else {
            setValue({
              title: newValue.inputValue,
            });
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      options={filteredMembers}
      disabled={props.disabled}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.name && option.name) {
          return option.name;
        }
        return option.name;
      }}
      renderOption={(option) => (
        <Typography
          style={{
            width: "100%",
            fontSize: 14,
            fontFamily: "Sofia Pro",
            fontWeight: 300,
          }}
        >
          {option.name}
        </Typography>
      )}
      style={{ width: "100%", marginRight: "1%" }}
      freeSolo
      renderInput={(params) => (
        <span className="sidebarProjectName">
          <TextField
            {...params}
            style={{ width: "100%", fontSize: 12 }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        </span>
      )}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user ? state.user : "",
    workspace: state.settings.workspace ? state.settings.workspace : "",
    settings: state.settings ? state.settings : "",
    teamMembers: state.teamMembers ? state.teamMembers.allTeamMember : [],
    activeTeam: state.teams ? state.teams.activeTeam : null,
  };
}

export default connect(mapStateToProps)(FreeSoloCreateOption);
