import React, { useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const TeamLists = (props) => {
    let {
        allTeams,
        handleTeam,
        userId
    } = props
    return (
        allTeams.map((item, index) => {
            if (item.teamStatus && userId == item.createdBy) {
                return (
                    <div 
                      style={{  
                        display: 'flex', 
                        alignItems: 'center',
                        height: 60, 
                        background: index % 2 == 0 ? '#f2f2f2' : '#f7f7f7' 
                      }}>
                        <div className="divLists">{item.teamTitle}</div>
                        <DeleteOutlinedIcon
                          style={{ marginLeft: 142 }}
                          className="deleteIcon"
                          onClick={() => handleTeam(item._id)}
                        />
                    </div>
                )
            } else return
        })
    );
}

export default TeamLists;