import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import { selectProject } from "../../Actions/projectActions";

import { connect } from "react-redux";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginLeft: -10,
  },
  icon: {
    display: "none",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectDiv: {
    display: "flex",
  },
}));

function SimpleSelect(props) {
  const classes = useStyles();
  const { projects, selectProject } = props;

  let [projectId, setProject] = React.useState(null);

  const handleChange = (event) => {
    setProject(event.target.value);
    selectProject(event.target.value);
    socket.emit("switchProject", []);
  };

  let sorted = projects
    ? projects.sort((a, b) =>
        a.projectTitle.toLowerCase() < b.projectTitle.toLowerCase() ? -1 : 1
      )
    : [];

  return (
    <div>
      <FormControl className={classes.formControl}>
        <div className={classes.selectDiv}>
          <div
            id="demo-simple-select-placeholder-label-label"
            style={{
              color: "grey",
              marginTop: 4,
              marginRight: 10,
              fontSize: 12,
              fontWeight: 300,
              fontFamily: "Sofia Pro",
            }}
          >
            Project:
          </div>
          <div>
            <Select
              style={{
                color: "blue",
                fontSize: 12,
                fontWeight: 300,
                fontFamily: "Sofia Pro",
              }}
              disableUnderline
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              inputProps={{ classes: { icon: classes.icon } }}
              value={projectId}
              onChange={handleChange}
              displayEmpty
            >
              <MenuItem
                style={{
                  color: "grey",
                  fontSize: 12,
                  fontWeight: 300,
                  fontFamily: "Sofia Pro",
                }}
                value={null}
              >
                All projects
              </MenuItem>
              {Array.isArray(sorted) &&
                sorted.map((item, i) => {
                  if (item.projectStatus) {
                    return (
                      <MenuItem
                        style={{
                          color: "grey",
                          fontSize: 12,
                          fontWeight: 300,
                          fontFamily: "Sofia Pro",
                        }}
                        key={i}
                        value={item._id}
                      >
                        {item.projectTitle}
                      </MenuItem>
                    );
                  } else return;
                })}
            </Select>
          </div>
        </div>
      </FormControl>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    projects: state.projects.allProjects,
  };
}

export default connect(mapStateToProps, {
  selectProject,
})(SimpleSelect);
