import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { userLogin } from "../../Actions/userActions";
import { connect } from "react-redux";
import ColorProgress from '../../Components/Signprogress';
import TimerMixin from 'react-timer-mixin';

// React Router Link
import {
    Link
} from "react-router-dom";

function Copyright() {
    return (
        <Typography style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Sofia Pro' }}
            variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <a href="https://dcastalia.com/">
                Team Trakking
            </a>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        fontFamily: 'Sofia Pro',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        fontFamily: 'Sofia Pro',
        fontSize: 14
    },
}));


function SignIn(props) {
    const classes = useStyles();

    let [userName, setUserName] = React.useState('')
    let [password, setPassword] = React.useState('')
    let [isProgress, setProgress] = useState(false);
    let [isUserName, setISUserName] = React.useState(false)
    let [isPassword, setISPassword] = React.useState(false)

    let handleUser = event => {
        setUserName(event.target.value)
    }

    let handlePassword = event => {
        setPassword(event.target.value)
    }

    let handleSubmit = (event) => {
        // console.log("Sign in", event);
        event.preventDefault()

        let data = {
            userName: userName,
            password: password
        }
        setProgress(true);
        TimerMixin.setTimeout(
            () => {
                props.userLogin(data).then(res => {
                    if (!res) {
                        setProgress(false)
                    }
                })
            },
            50
        );
    }

    let createProgress = (
        <ColorProgress />
    );
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography style={{ color: 'black', fontSize: 22, fontWeight: 300, fontFamily: 'Sofia Pro' }} component="h1" variant="h5">
                    Sign in
                </Typography>

                <form className={classes.form} onSubmit={handleSubmit}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={userName}
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        onChange={handleUser}
                    />
                    {/* {isUserName === true ? <div className="error_message">**The email or username that you've entered is incorrect. </div> : <div style={{ height: 5 }} />} */}
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={password}
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={handlePassword}
                    />
                    {props.user.loginError ? <div className="error_message">**{props.user.loginError} </div> : <div style={{ height: 5 }} />}
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}>
                        Sign In {isProgress === true ? createProgress : ''}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link to="forget-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>

                            <Link to="signup">
                                Don't have an account? Sign Up
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}


function mapStateToProps(state) {
    return {
        user: state.user,
    };
}

export default connect(mapStateToProps, { userLogin })(SignIn)
