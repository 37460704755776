import {
  SET_USER_DETAILS,
  SET_USER_TOKEN,
  SET_USER_LOCAL,
  REMOVE_TOKEN,
  SET_SIGNUP_ERROR,
  SET_LOGIN_ERROR,
  ACTIVE_TASK_USER,
} from "../Constants";

const initialState = {};
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.data,
      };

    case SET_USER_TOKEN:
      return {
        ...state,
        token: action.data,
        signupError: null,
        loginError: null,
      };

    case SET_USER_LOCAL:
      return {
        ...state,
        token: action.data.token,
        userDetails: action.data.userDetails,
      };

    case SET_SIGNUP_ERROR:
      return {
        ...state,
        signupError: action.data,
      };

    case SET_LOGIN_ERROR:
      return {
        ...state,
        loginError: action.data,
      };

    case REMOVE_TOKEN:
      return {
        ...state,
        token: action.data,
        userDetails: action.data,
        allTeams: [],
        allTeamMember: [],
      };

    case ACTIVE_TASK_USER:
      return {
        ...state,
        activeUser: action.data,
      };

    default:
      return state;
  }
};

export default userReducer;
