import React, { useState } from "react";
import { Input, List, ListItem, Checkbox } from "@material-ui/core";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Row from "antd/lib/row";
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import InfoIcon from '@material-ui/icons/Info';
import { connect } from 'react-redux'
import withStyles from "@material-ui/core/styles/withStyles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";

const GreenCheckbox = withStyles({
    root: {
        color: '#A0A0A0',
        '&$checked': {
            color: '#38F8C4',
        },
    },
    checked: {},
})((props) => <Checkbox color="default" {...props} />);


let Milestone = (props) => {
    let milestones = props.milestones
    let MilestoneDetails = props.MilestoneDetails
    let filtered = milestones.find(item =>
        item.projectId == props.projectId
    )

    let milestoneList = filtered ? filtered.milestoneList.map((item, key) => {
        let retData = {
            id: key,
            title: item.title,
            checked: item.checked,
            focus: true,
            dbId: item._id,
            percentage: item.percentage,
            tasks: item.tasks
        }

        return retData
    }) : []

    let len = milestoneList.length

    milestoneList.push({
        id: len,
        title: null,
        checked: false,
        focus: false,
        dbId: len,
        percentage: 5,
        tasks: []
    })

    // console.log('Subtask State', subTaskState)
    // console.log('TaskId', props.taskId)
    // console.log('Subtasks', subTasks)

    let [fields, setFields] = useState(milestoneList);
    // const [checked, changeCheckStatus] = useState(false);

    let [state, setState] = React.useState([])

    let handleChangeMap = (id) => {
        let nextState = fields.map((a, idx) => {
            if (idx === id) a.checked = !a.checked
            return a
        })
        setState(nextState)
        let sendList = nextState.filter(item => item.title !== null)
        props.milestoneState(sendList)
    }

    function handleChange(i, event) {
        let values = [...fields];
        values[i].title = event.target.value;
        let txt = event.target.value
        if (!txt.length) values[i].focus = false
        setFields(values);
        let sendList = values.filter(item => item.title !== null)
        props.milestoneState(sendList)
    }

    function handlePercentage(i, event) {
        let values = [...fields];
        let percentage = event.target.value
        values[i].percentage = parseInt(percentage);
        setFields(values);
        let sendList = values.filter(item => item.title !== null)
        props.milestoneState(sendList)
    }

    function handleAdd() {
        let values = [...fields];
        values.push({
            id: 0,
            title: null,
            checked: false,
            focus: false,
            percentage: 5,
            tasks: [],
            dbId: 0
        });
        setFields(values);
    }

    function handleAddOnEnter(event) {
        var code = event.keyCode || event.which;
        if (code === 13) { //13 is the enter keycode
            //Do stuff in here
            let values = [...fields];
            let lastItem = values.length - 1;
            values[lastItem].focus = true
            setFields(values)
            if (values[lastItem].title) {
                values.push({
                    id: lastItem + 1,
                    title: null,
                    checked: false,
                    focus: false,
                    dbId: lastItem + 1,
                    percentage: 5,
                    tasks: []
                });
                setFields(values);
            }
            let sendList = values.filter(item => item.title !== null)
            props.milestoneState(sendList)
        }
    }

    function handleRemove(i) {
        let values = [...fields];
        values.splice(i, 1);
        let sendList = values.filter(item => item.title !== null)
        props.milestoneState(sendList)
        setFields(values)
    }

    // console.log(fields.focus);

    // console.log('state====', fields)

    return (
        <>
            <div style={{ display: 'flex', height: 56, background: 'rgba(0,0,0,0.1)' }}>
                <div className="divMilestoneHeaders">Milestones</div>
                <div
                    className="createProject"
                    onClick={() => handleAdd()}
                >
                    <AddCircleIcon />
                    <div className="create">Create Milestone</div>
                </div>
                <div className="divMilestoneHeaders" style={{ paddingLeft: 128 }}>Tasks</div>
                <div className="divMilestoneHeaders" style={{ paddingLeft: 60 }}>Value</div>
            </div>
            <div className="sidebarSubtaskWrapper">
                <Row className="sidebarControllerRow" type="flex" align="middle">
                    <div className="subTaskWrapper">
                        {
                            (fields.length < 1) ? (
                                <button type="button" onClick={() => handleAdd()}>
                                    +
                                </button>
                            ) : ''
                        }


                        <List>
                            {fields.map((field, idx) => {
                                return (
                                    <ListItem key={`${field}-${idx}`}>
                                        <GreenCheckbox
                                            key={idx}
                                            className="subtaskCheckbox"
                                            // onChange={() => handleChangeCheck(idx,false)}
                                            onChange={() => handleChangeMap(idx)}
                                            checked={field.checked}
                                        />
                                        <Input
                                            placeholder={'+Type and press enter to add a new milestone'}
                                            autoFocus={idx + 1}
                                            value={field.title || ""}
                                            disabled={field.checked}
                                            className="subtaskInput"
                                            // disabled={state[idx].status}
                                            onChange={e => handleChange(idx, e)}
                                            onKeyPress={handleAddOnEnter.bind(this)}
                                        />

                                        {/*<InfoIcon*/}
                                        {/*    className="subtaskDetails"*/}
                                        {/*    onClick={() => MilestoneDetails(field.dbId)}*/}
                                        {/*/>*/}

                                        <div className="subtaskmax">
                                            {field.title ? field.tasks.length : 'Max value'}
                                        </div>
                                        <Input
                                            placeholder={'5%'}
                                            autoFocus={idx + 1}
                                            value={field.percentage ? field.percentage : ""}
                                            disabled={field.checked}
                                            className="subtaskvalue"
                                            // disabled={state[idx].status}
                                            onChange={e => handlePercentage(idx, e)}
                                            onKeyPress={handleAddOnEnter.bind(this)}
                                        />
                                        <RemoveCircleRoundedIcon
                                            className="subtaskRemove"
                                            onClick={() => handleRemove(idx)}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </div>
                </Row>
            </div>
        </>
    );
}

function mapStateToProps(state) {
    return {
        workspace: state.settings.workspace,
        user: state.user,
        subTasks: state.subTasks,
        milestones: state.projects ?
            state.projects.allMilestones ? state.projects.allMilestones : [] : []
    }
}

export default connect(
    mapStateToProps
)(Milestone);
