import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../Assets/CSS/ProjectDialog.css";
import socketIOClient from "socket.io-client";
import ConfirmDelete from "./ConfirmTeamDelete";
import TeamLists from "../../Components/TeamLists";
import { connect } from "react-redux";
import { search } from "ss-search";
import Search from "./ProjectSearch";
import { serverSocket } from "../../Actions/config";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Scrollbars } from "react-custom-scrollbars";
import AddCircleIcon from "@material-ui/icons/AddCircle";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 16,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function RemoveTeam(props) {
  const classes = useStyles();
  let {
    allTeams,
    onRemove,
    token,
    open,
    handleClose,
    user,
    addTeamModal,
  } = props;

  let [value, setValue] = useState("");
  let [msg, setMsg] = useState("");
  let [searchText, setSearchKey] = useState("");
  let [deletePop, setDeletePop] = useState(false);

  let handleDeletePop = () => {
    setDeletePop(true);
  };

  let closeDeletePop = () => {
    setDeletePop(false);
    setValue("");
  };

  let handleTeamChange = (teamId) => {
    setValue(teamId);
    handleDeletePop();
  };

  let onSubmit = () => {
    if (value) {
      let data = {
        teamId: value,
        teamStatus: false,
      };
      onRemove(data, token).then((res) => {
        // console.log('remove team', res.data);
        socket.emit("removeTeam", data);
        closeDeletePop();
      });
    } else {
      setMsg("Please Select a team to remove");
    }
  };

  let onClickCreate = () => {
    addTeamModal();
  };

  let searchKey = ["teamTitle"];

  let filtered = search(allTeams, searchKey, searchText);

  return (
    <React.Fragment>
      <Dialog
        className="customDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "left",
              }}
            >
              All Teams
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Search label={"Teams"} onSearch={setSearchKey} />
          <div style={{ marginTop: 12 }}>
            <div
              style={{
                display: "flex",
                height: 56,
                background: "rgba(0,0,0,0.1)",
              }}
            >
              <div className="divHeaders">Team name</div>
              <div className="divHeaders" style={{ paddingLeft: 350 }}>
                Action
              </div>
            </div>
            <Scrollbars style={{ height: (window.innerHeight / 5) * 3 }}>
              <div>
                {
                  <TeamLists
                    allTeams={filtered}
                    handleTeam={handleTeamChange}
                    userId={user.userDetails.id}
                  />
                }
              </div>
            </Scrollbars>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="createProject" onClick={onClickCreate}>
              <AddCircleIcon />
              <div className="create">Create Team</div>
            </div>
          </div>
        </DialogContent>
        <ConfirmDelete
          open={deletePop}
          deleteItem={value}
          confirm={onSubmit}
          cancel={closeDeletePop}
        />
      </Dialog>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(RemoveTeam);
