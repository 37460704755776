import {
  ADD_HOLIDAY,
  UPDATE_HOLIDAY,
  GET_ALL_HOLIDAY,
  SET_HOLIDAY_LOADING
} from '../Constants/index'

let initialState = {}

let holidayReducer = (state = initialState, action) => {
  switch(action.type) {
    case ADD_HOLIDAY:
      return {
        ...state,
        addHoliday: action.data
      }
    case UPDATE_HOLIDAY:
      return {
        ...state,
        updateHoliday: action.data
      }
    case GET_ALL_HOLIDAY:
      return {
        ...state,
        allHolidays: action.data
      }
    case SET_HOLIDAY_LOADING:
      return {
        ...state,
        holidayLoading: action.data
      }

    default:
      return state
  }
}

export default holidayReducer

