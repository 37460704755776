import React, { useState } from 'react'
import { withStyles, makeStyles } from '@material-ui/core/styles';
import LeavesTaken from './LeavesTaken'
import AvailableLeaves from './AvailableLeaves'
import LeavesPending from './LeavesPending'
import moment from 'moment'
import LeavesRejected from './LeavesRejected'

const useStyles = makeStyles({
  table: {
    maxWidth: 700
  },
});

let parseData = (leaves, history) => {
  let leaveDetails = []

  let len = history.length

  for (let i = 0; i < len; i++) {
    let dates = history[i].days.map(day => moment(day).format('DD/MM/YYYY'))
    let type = leaves.find(leave =>
      leave._id === history[i].leaveId
    )
    if (type) {
      let sendData = {
        dates: dates,
        type: type.title,
        days: dates.length,
        operatedBy: history[i].operatedBy.firstName
      }
      leaveDetails.push(sendData)
    }
  }
  return leaveDetails
}

let parsePending = (leaves, pending) => {
  let leaveDetails = []

  let len = pending.length

  for (let i = 0; i < len; i++) {
    let dates = pending[i].days.map(day => moment(day).format('DD/MM/YYYY'))
    let type = leaves.find(leave =>
      leave._id === pending[i].leaveId
    )
    if (type) {
      let sendData = {
        dates: dates,
        type: type.title,
        days: dates.length,
        id: pending[i]._id
      }
      leaveDetails.push(sendData)
    }
  }
  return leaveDetails
}

let availableLeaves = (allLeaves, history) => {
  let leaveDetails = []

  let len = allLeaves.length

  for (let i = 0; i < len; i++) {
    let leaveMain = allLeaves[i]

    let found = history.filter(item => item.leaveId === leaveMain._id)

    let leaveCount = 0

    let flen = found.length

    for (let j = 0; j < flen; j++) {
      leaveCount += found[j].days.length
    }

    let pushData = {
      title: allLeaves[i].title,
      id: allLeaves[i]._id,
      available: allLeaves[i].count - leaveCount ? allLeaves[i].count - leaveCount : 0,
      total: allLeaves[i].count
    }
    leaveDetails.push(pushData)
  }
  return leaveDetails
}

let LeaveHistory = props => {
  const classes = useStyles();
  let { allLeaves, history, userName, pending, token, rejected } = props

  let rowData = parseData(allLeaves, history)

  let available = availableLeaves(allLeaves, history)

  let pendings = parsePending(allLeaves, pending)

  pendings = pendings.reverse()
  rowData = rowData.reverse()
  rejected = rejected.reverse()

  return (
    <div>
      <AvailableLeaves
        rowData={available}
      />
      <LeavesPending
        rowData={pendings}
        token={token}
      />
      <LeavesTaken
        rowData={rowData}
        userName={userName}
        token={token}
      />
      <LeavesRejected
        rowData={rejected}
        userName={userName}
        token={token}
      />
    </div>
  );
}

export default LeaveHistory