import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import styled from "styled-components";
import {getAllReportTask} from "../../Actions/taskActions";
import moment from "moment";
import {TableContainer} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { downloadExcel } from 'react-export-table-to-excel';

function getDateArray(startDate, endDate) {
    let dateArray = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateArray.push(moment(new Date(currentDate)).format("YYYY-MM-DD") + "T00:00:00.000Z");
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
}

function getDetails(taskData, logData, projectList, days) {
    let dayLogs = [];
    for (let i = 0; i < days.length; i++) {
        let filteredTasks = logData.filter((log) => log.date === days[i]);

        let len = filteredTasks.length;
        let taskLogs = [];

        for (let j = 0; j < len; j++) {
            let found = taskData.find(
                (task) => task.taskId === filteredTasks[j].taskId
            );
            //console.log('found', found, filteredTasks[j].taskId)
            let projectData = filteredTasks[j].projectId
                ? projectList.find(
                    (project) => project._id === filteredTasks[j].projectId
                )
                : null;
            if (found) {
                let details = {
                    _id: filteredTasks[j]._id,
                    taskName: found.taskName,
                    taskId: found.taskId,
                    userId: filteredTasks[j].userId,
                    spent: filteredTasks[j].spent,
                    estimated: filteredTasks[j].estimated,
                    run: filteredTasks[j].run,
                    pause: filteredTasks[j].pause,
                    projectId: filteredTasks[j].projectId,
                    teamId: filteredTasks[j].teamId,
                    workspaceId: filteredTasks[j].workspaceId,
                    projectTitle: projectData ? projectData.projectTitle : "",
                    projectColor: projectData ? projectData.color : "",
                    startTime: filteredTasks[j].startTime,
                    endTime: filteredTasks[j].endTime,
                };
                taskLogs.push(details);
            }
        }

        let pushData = {
            date: days[i].slice(0, 10),
            logs: taskLogs,
        };

        dayLogs.push(pushData);
    }

    return dayLogs;
};

const LogReport = ({
                       user,
                       workspace,
                       workspaceDetails,
                       settings,
                       teamMembers,
                       activeTeam,
                       getAllReportTask,
                       hourLogs, tasks,
                       projects
                   }) => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [select, setSelect] = useState('');
    const [thisMonthData, setThisMonthData] = useState([]);

    const header = ["Date", "Card", "Project", "Estimate", "Spend"];
    let body = [];

    let setUserInput = (name, e) => {
        if (name === 'startDate') {
            setStartDate(e.target.value)
        } else if (name === 'endDate') {
            setEndDate(e.target.value)
        }
    }

    let onChangeSelect = (event) => {
        setSelect(event.target.value)
    }

    let handleSubmit = () => {
        if (startDate === '') {
            alert('Select start date')
        } else if (endDate === '') {
            alert('Select end date')
        } else if (select === '') {
            alert('Select an user')
        } else {
            let data = {
                start: startDate,
                end: endDate,
                userId: select
            }
            getAllReportTask(data, user?.token).then((res) => {
                //console.log(res)
            })
        }
    }

    let taskList = tasks ? (tasks.taskDetails ? tasks.taskDetails : []) : [];

    let msToTime = (duration) => {
        let milliseconds = parseInt((duration % 1000) / 100),
            seconds = Math.floor((duration / 1000) % 60),
            minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = hours < 10 ? "0" + hours : hours;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;

        return hours + ":" + minutes + ":" + seconds;
    };

    useEffect(() => {
        if (hourLogs.length > 0) {
            let thisMonth = getDateArray(new Date(startDate), new Date(endDate))
            let monthData = hourLogs
                ? getDetails(taskList, hourLogs, projects, thisMonth)
                : [];
            setThisMonthData(monthData)
        }
    }, [hourLogs])

    useEffect(()=>{
        if (thisMonthData.length > 0){
            thisMonthData.map((month)=>{
                if (month?.logs.length > 0){
                    month.logs.map((log)=>{
                        let data = {
                            Date: month?.date,
                            Card: log?.taskName,
                            Project: log?.projectTitle,
                            Estimate: log?.estimated,
                            Spend: msToTime(log?.spent)
                        }
                        body.push(data)
                    })
                }
            })
        }
    },[thisMonthData])

    function handleDownloadExcel() {
        downloadExcel({
            fileName: "react-export-table-to-excel -> downloadExcel method",
            sheet: "react-export-table-to-excel",
            tablePayload: {
                header,
                body: body,
            },
        });
    }

    return (
        <StyledMain>
            <div className="filter-option">
                <div>
                    <h4>
                        Select start date
                    </h4>
                    <input
                        type="date"
                        className="form-control"
                        placeholder="Select start date"
                        value={startDate}
                        onChange={setUserInput.bind(null, "startDate")}
                    />
                </div>

                <div>
                    <h4>
                        Select end date
                    </h4>
                    <input
                        type="date"
                        className="form-control"
                        placeholder="Select end date"
                        value={endDate}
                        onChange={setUserInput.bind(null, "endDate")}
                    />
                </div>

                <div>
                    <h4>
                        Select user
                    </h4>
                    <select
                        onChange={onChangeSelect}
                        value={select}
                        className="form-control-select">
                        <option>Select user</option>
                        {
                            teamMembers && teamMembers?.allTeamMember && teamMembers?.allTeamMember.length > 0 ?
                                teamMembers?.allTeamMember.map((member, key) =>
                                    <option key={key} value={member?.userId}>{member?.name}</option>
                                ) : null
                        }
                    </select>
                </div>

                <div>
                    <button onClick={handleSubmit}
                            className="styled-button"
                    >
                        Generate
                    </button>
                </div>
            </div>

            {
                thisMonthData && thisMonthData.length > 0 ?
                    <div className="table-wraps">
                        <button className="styled-button" onClick={handleDownloadExcel}>download excel</button>
                        <TableContainer component={Paper}>
                            <Table aria-label="custom pagination table">
                                <TableHead className="table-header">
                                    <TableRow>
                                        {
                                            header.map((item) =>{
                                                return(
                                                    <TableCell align="center">
                                                        {item}
                                                    </TableCell>
                                                )
                                            })
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {
                                        thisMonthData && thisMonthData.length > 0 ?
                                            thisMonthData.map((month, key) =>{
                                                return(
                                                    <>
                                                        {
                                                            month && month.logs && month.logs.length > 0 ?
                                                                month.logs.map((log, id)=>
                                                                    <TableRow key={id}>
                                                                        <TableCell
                                                                            className="cell-first"
                                                                            align="center"
                                                                        >
                                                                            {month?.date}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {log?.taskName}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {log?.projectTitle}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {log?.estimated}
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            {msToTime(log?.spent)}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ) : null
                                                        }
                                                    </>
                                                )
                                            }) : null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div> : null
            }

        </StyledMain>
    );
};

let StyledMain = styled.div`
  padding: 20px;

  .filter-option {
    display: flex;
  }

  .form-control {
    padding: 6px;
    margin-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .form-control-select {
    padding: 8px;
    margin-right: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }

  .styled-button {
    padding: 8px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background-color: #5765b3;
    color: #fff;
    border-radius: 5px;
    margin-top: 26px;
  }

  .main-list {
    width: 100%;
    margin-top: 20px;

    .title-box {
      border: 1px solid #0000001f;
      padding: 10px 20px;
      display: inline-block;
    }
  }
  
  .table-wraps {
    padding: 0px;
    margin-top: 20px;
    
    .MuiPaper-root {
      background: transparent !important;
    }

    .MuiPaper-rounded {
      border-radius: 0px;
    }

    .MuiTableRow-root:last-child {
      .MuiTableCell-root {
        border-bottom: 0px;
      }
    }

    .MuiTableCell-root {
      font-size: 14px;
      font-family: Roboto;
      padding-top: 13px;
      font-weight: 400;
      line-height: 19px;
      padding-bottom: 13px;
      white-space: nowrap;
      color: #242424;
      height: 55px;
      border-bottom: 1px solid #eeeeee;
    }

    .cell-first {
      padding-left: 30px;
      color: #ff5722;

      @media (max-width: 768px) {
        padding-left: 20px;
      }
    }

    .table-header {
      background-color: #f7f7f7;

      .cell-first-head {
        padding-left: 30px;
        @media (max-width: 768px) {
          padding-left: 20px;
        }
      }

      .MuiTableCell-root {
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 21px;
        color: #404040 !important;
        padding-top: 10px;
        padding-bottom: 11px;
        height: 40px;
        border-bottom: 1px solid #eeeeee;
      }
    }
  }
`

function mapStateToProps(state) {
    return {
        user: state.user ? state.user : "",
        workspace: state.settings.workspace ? state.settings.workspace : "",
        workspaceDetails: state.settings
            ? state.settings.workspaceDetails
                ? state.settings.workspaceDetails
                : []
            : [],
        settings: state.settings ? state.settings : "",
        teamMembers: state.teamMembers ? state.teamMembers : null,
        hourLogs: state.tasks ? state.tasks?.reportTask ? state.tasks?.reportTask : [] : [],
        activeTeam: state.teams
            ? state.teams.activeTeam
                ? state.teams.activeTeam
                : null
            : null,
        tasks: state.tasks ? state.tasks : [],
        projects: state.projects
            ? state.projects.allProjects
                ? state.projects.allProjects
                : []
            : []
    };
}

export default connect(mapStateToProps, {
    getAllReportTask
})(LogReport);