import {
  ADD_PROJECT,
  SET_UPDATED_PROJECT,
  GET_WORKSPACE_PROJECT,
  SET_FILTER_PROJECT,
  SET_PROJECT_TYPES,
  GET_ALL_MILESTONES,
  ADD_NEW_MILESTONE
} from '../Constants/index'

const initialState = {}

const projectReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_PROJECT:
      // console.log('+++++ADD PROJECT ACTION+++++', action)
      return {
        ...state,
        projectDetails: action.data
      }
    case SET_UPDATED_PROJECT:
      return {
        ...state,
        projectDetails: action.data
      }

    case GET_WORKSPACE_PROJECT:
      return {
        ...state,
        allProjects: action.data
      }

    case SET_FILTER_PROJECT:
      return {
        ...state,
        selectProject: action.data
      }

    case SET_PROJECT_TYPES:
      return {
        ...state,
        projectTypes: action.data
      }

    case ADD_NEW_MILESTONE:
      return {
        ...state,
        milestoneDetails: action.data
      }

    case GET_ALL_MILESTONES:
      return {
        ...state,
        allMilestones: action.data
      }

    default:
      return state;
  }
}

export default projectReducer;