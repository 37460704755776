/* eslint-disable no-use-before-define */
import React from "react";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

const filter = createFilterOptions();

function FreeSoloCreateOption(props) {
  let { leaves, handleChange, available, leaveId } = props;

  let valueMain = leaves ? leaves.find((item) => item._id === leaveId) : null;
  let valueTitle = valueMain ? valueMain.title : "";
  let [value, setValue] = React.useState({ title: valueTitle });

  return (
    <Autocomplete
      value={value ? value.title : value}
      disableClearable
      disableOpenOnFocus={false}
      forcePopupIcon={true}
      onChange={(event, newValue) => {
        if (newValue) {
          if (newValue._id) {
            handleChange(newValue._id);
            if (newValue) {
              setValue({
                title: newValue.title,
              });
              return;
            }
            setValue(newValue);
          }
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "") {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add "${params.inputValue}"`,
          });
        }

        return filtered;
      }}
      id="free-solo-with-text-demo"
      options={leaves}
      getOptionLabel={(option) => {
        // e.g value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        if (option.title) {
          return option.title;
        }
        return option.title;
      }}
      renderOption={(option) => (
        <Typography
          style={{
            width: "100%",
            fontSize: 14,
            fontFamily: "Sofia Pro",
            fontWeight: 300,
          }}
        >
          {option.title}
        </Typography>
      )}
      getOptionDisabled={(option) => {
        let value = available.find((item) => item.id === option._id);
        if (value && value.available) return false;
        else return true;
      }}
      style={{ width: 200 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          variant="outlined"
          label="Leave Type"
          id="outlined-size-small"
          size="small"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
}

export default FreeSoloCreateOption;
