import React, { useState } from 'react';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const MemberLists = (props) => {
    let {
        allMembers,
        handleMember,
        teamId
    } = props
    return (
      allMembers.map((item, index) => {
        if (item.status && item.teamId == teamId) {
            return (
                <div 
                  style={{  
                    display: 'flex', 
                    alignItems: 'center',
                    height: 60, 
                    background: index % 2 == 0 ? '#f2f2f2' : '#f7f7f7' 
                  }}>
                    <div className="divLists">{item.name}</div>
                    <div className="divLists">
                      {item.email}
                    </div>
                    <DeleteOutlinedIcon
                      className="deleteIcon"
                      style={{ marginRight: 14 }}
                      onClick={() => handleMember(item.userId)}
                    />
                </div>
            )
        } else return
      })
    );
}

export default MemberLists;