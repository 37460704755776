import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import {DialogActions} from "@material-ui/core";
import '../../Assets/CSS/Users.css';
import DialogContent from "@material-ui/core/DialogContent";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles({
    orange: {
        color: 'rgba(0,0,0,0.7)',
        backgroundColor: 'rgba(123,123,123,0.2)',
        marginTop: -6,
        fontSize: 14,
        fontFamily: 'Sofia Pro'
    },
});

function SimpleDialog(props) {
    const classes = useStyles();

    let OnDone = () =>{
        props.handleClose()
    }

    return (
        <Dialog
            className="profile-dialog"
            onClose={props.handleClose}
            open={props.open}
            aria-labelledby="simple-dialog-title"
        >
            <DialogTitle id="simple-dialog-title">Edit Display Picture & Initials</DialogTitle>
            <DialogContent>
                <div className="display-image-dia">
                    <Avatar className={classes.orange}>JM</Avatar>
                    <div className="edit-image"
                    >
                        Upload picture
                    </div>
                </div>
                <div className="edit-field-dia">
                    <TextField
                        label="Initials"
                        id="outlined-size-small"
                        fullWidth
                        defaultValue="JM"
                        variant="outlined"
                        size="small"
                    />
                </div>
            </DialogContent>
            <DialogActions>
                <div className="save-button-dia2" onClick={props.handleClose}>Cancel</div>
                <div className="save-button-dia" onClick={OnDone}>Done</div>
            </DialogActions>
        </Dialog>
    );
}

export default SimpleDialog;