import React, { useRef, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import ReactCodeInput from "react-verification-code-input";
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { verifyByMail } from "../../Actions/userActions";
import ColorProgress from '../../Components/Signprogress';
import TimerMixin from 'react-timer-mixin';
import { connect } from 'react-redux'
// React Router Link
import {
  Link,
  withRouter
} from "react-router-dom";

function Copyright() {
  return (
    <Typography style={{ color: 'grey', fontSize: 14, fontWeight: 300, fontFamily: 'Sofia Pro' }}
      variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <a href="https://dcastalia.com/">
        Team Trakking
      </a>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let emailLocal = localStorage.getItem('email')

const useStyles = makeStyles(theme => ({
  paper: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(20, 1, 1, 1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
    fontFamily: 'Sofia Pro',
  },
  submit: {
    margin: theme.spacing(4, 0, 2),
    fontFamily: 'Sofia Pro',
    fontSize: 14
  },
}));


function Verification(props) {
  let { verifyByMail, user } = props
  const classes = useStyles();
  let [isProgress, setProgress] = useState(false);
  const input = useRef(null);
  let [code, setCode] = useState('')

  let email = emailLocal ? emailLocal : user ? user.userDetails ? user.userDetails.email : '' : ''

  let handleChange = vals => {
    if (vals.length >= 6) {
      console.log('complete, ', vals);
      setCode(vals)
    } else if (vals.length === 0) {
      console.log('empty, ', vals);
    }
  };
  let handleSubmit = (event) => {
    event.preventDefault()

    setProgress(true);

    let data = {
      code: code,
      email: email
    }
    verifyByMail(data)
      .then(res => {
        console.log('Res', res)
        TimerMixin.setTimeout(
          () => {
            setProgress(false)
            props.history.push('/submit-password')
          },
          50
        );
      }).catch(err => {
        console.log('Error', err)
        setProgress(false)
      })
  }

  let createProgress = (
    <ColorProgress />
  );
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography
          style={{
            color: 'black',
            fontSize: 22,
            fontWeight: 300,
            fontFamily: 'Sofia Pro'
          }}
          component="h1"
          variant="h5"
        >
          Verification
        </Typography>
        <Typography
          style={{
            color: 'black',
            fontSize: 18,
            fontWeight: 300,
            fontFamily: 'Sofia Pro'
          }}
          component="h1"
          variant="h5"
        >
          A code has been sent to your email
        </Typography>

        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container>
            <Grid item xs>

            </Grid>
            <Grid item xs>
              <ReactCodeInput
                ref={input}
                className="custom-class"
                onChange={handleChange}
                onComplete={val => console.log('complete', val)}
              />
            </Grid>
            <Grid item xs>

            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Submit Code {isProgress === true ? createProgress : ''}
          </Button>
          <Grid container>
            <Grid item xs>
            </Grid>
            <Grid item>
              <Link to="forget-password">
                Don't have code? Resend
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

let mapStateToProps = state => {
  return {
    user: state.user
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    { verifyByMail }
  )(Verification)
)
