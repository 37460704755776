import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import clsx from "clsx";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
  },
}));

function ChangeWorkspace(props) {
  const classes = useStyles();
  let { onUpdate, workspaceId, token, allWorkspaces, handleClose } = props;

  let project = allWorkspaces.find((item) => item.workspaceId === workspaceId);

  let [name, setName] = useState(project.workspace);

  let handleName = (event) => {
    setName(event.target.value);
  };

  let onSubmit = (event) => {
    event.preventDefault();
    let data = {
      workspaceId: workspaceId,
      workspace: name,
    };
    onUpdate(data, token).then((res) => {
      socket.emit("addWorkspace", []);
      setName("");
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <form
        className={clsx(classes.form, classes.formControl)}
        noValidate
        onSubmit={onSubmit}
      >
        <TextField
          autoFocus
          value={name}
          onChange={handleName}
          margin="dense"
          id="name"
          label="Workspace Name"
          fullWidth
          autoComplete
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          className={classes.btn}
        >
          Submit
        </Button>
      </form>
    </React.Fragment>
  );
}

export default ChangeWorkspace;
