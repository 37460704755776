import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import "../../Assets/CSS/ProjectDialog.css";
import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  btn: {
    marginTop: theme.spacing(1),
  },
}));

let socket = socketIOClient(serverSocket);

function AddTeamForm(props) {
  const classes = useStyles();
  let { onAdd, workspaceId, token, open, handleClose } = props;

  let [name, setName] = React.useState("");

  let handleName = (event) => {
    setName(event.target.value);
  };

  let onSubmit = (e) => {
    e.preventDefault();
    let data = {
      teamTitle: name,
      workspaceId: workspaceId,
    };
    onAdd(data, token).then((res) => {
      socket.emit("addTeam", data);
      setName("");
      handleClose();
    });
  };

  return (
    <React.Fragment>
      <Dialog
        className="customMemberDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "center",
              }}
            >
              Add A Team
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <form
            className={clsx(classes.form, classes.formControl)}
            noValidate
            onSubmit={onSubmit}
          >
            <TextField
              autoFocus
              value={name}
              onChange={handleName}
              margin="dense"
              id="name"
              label="Team Name"
              autoComplete
              fullWidth
            />
            <Button
              type="submit"
              color="primary"
              variant="contained"
              className={classes.btn}
            >
              Add Team
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}

export default AddTeamForm;
