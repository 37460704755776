import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(theme => ({
  formControl: {
    marginRight: theme.spacing(2),
    minWidth: 120,
    marginLeft: 10,
  },
  icon: {
    display: 'none'
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectDiv: {
    display: 'flex',
    marginLeft: 44
  },
}));

let workspaceLocal = JSON.parse(localStorage.getItem('ttkWorkspaces'))

function SimpleSelect(props) {
  const classes = useStyles();
  let {
    active,
    workspaces,
    reload
  } = props

  const [workspaceId, setWorkspaceId] = React.useState(active);

  const handleChange = event => {
    setWorkspaceId(event.target.value);
    let teamTrekkingSettings = {
      workspace: [event.target.value]
    }
    localStorage.setItem('teamTrekkingSettings', JSON.stringify(teamTrekkingSettings))
    localStorage.setItem('teamTrekkingTeams', JSON.stringify(''))
    localStorage.removeItem('ttkAllTeams')
    localStorage.removeItem('ttkTeamMembers')
    localStorage.removeItem('ttkAllTask')
    localStorage.removeItem('ttkAllProjects')
    reload()
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <div className={classes.selectDiv}>
          <div id="demo-simple-select-helper-label" style={{ color: 'grey', marginTop: 4, marginRight: 10, fontSize: 12, fontWeight: 300, fontFamily: 'Sofia Pro' }}>Workspace:</div>
          <div>
            <Select
              style={{ color: 'blue', fontSize: 12, fontWeight: 300, fontFamily: 'Sofia Pro' }}
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              inputProps={{ classes: { icon: classes.icon } }}
              value={workspaceId}
              onChange={handleChange}
              disableUnderline
            >
              {Array.isArray(workspaces) &&
                workspaces.map((item, i) => {
                  return <MenuItem style={{ color: 'grey', fontSize: 12, fontWeight: 300, fontFamily: 'Sofia Pro' }} key={i} value={item.workspaceId}>{item.workspace}</MenuItem>
                })
              }
            </Select>
          </div>
        </div>
      </FormControl>
    </div>
  );
}

export default SimpleSelect
