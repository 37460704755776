import {
  GET_ALL_SUBTASKS,
  ADD_SINGLE_SUBTASK,
  ADD_MULTIPLE_SUBTASK,
  UPDATE_SUBTASK,
  SET_SUBTASK_STORE,
  GET_ALL_LITTLE_SUBTASKS
} from '../Constants/index'

const initialState = {}

const subTaskReducers = (state = initialState, action) => {
  switch (action.type) {
    case ADD_SINGLE_SUBTASK:
      return {
        ...state,
        newSubTask: action.data
      }

    case ADD_MULTIPLE_SUBTASK:
      return {
        ...state,
        newMultipleSubtasks: action.data
      }

    case UPDATE_SUBTASK:
      return {
        ...state,
        updatedSubtask: action.data
      }

    case GET_ALL_SUBTASKS:
      return {
        ...state,
        allSubTasks: action.data
      }
    case GET_ALL_LITTLE_SUBTASKS:
      return {
        ...state,
        allLittleSubTasks: action.data
      }

    case SET_SUBTASK_STORE:
      return {
        ...state,
        setSubtasks: action.data
      }

    default:
      return state
  }
}

export default subTaskReducers