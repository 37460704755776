import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Scrollbars } from "react-custom-scrollbars";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import { withRouter } from "react-router-dom";

import { addNewRoleUser } from "../Actions/roleActions";

import Roles from "../Container/Header/RoleAutoComplete";

import { connect } from "react-redux";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../Actions/config";
import TimerMixin from "react-timer-mixin";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  orange: {
    color: "rgba(0,0,0,0.7)",
    backgroundColor: "rgba(123,123,123,0.2)",
    marginTop: 16,
    fontSize: 14,
    fontFamily: "Sofia Pro",
  },
}));

function Users(props) {
  let {
    user,
    activeTeam,
    teamMembers,
    teams,
    workspaceDetails,
    handleMember,
    memberId,
    addNewRoleUser,
  } = props;
  const classes = useStyles();

  let [roleId, setRole] = useState("");

  let users = activeTeam
    ? teamMembers.length
      ? teamMembers.filter(
          (member) => member.teamId.toString() === activeTeam.toString()
        )
      : []
    : [];

  let member = users.length
    ? users.find((user) => user._id === memberId)
    : null;

  function handleSubmit() {
    let roleData = {
      userId: member.userId,
      teamId: member.teamId,
      workspaceId: member.workspaceId,
      roleId: roleId,
      memberId: memberId,
    };
    if (user.token) {
      addNewRoleUser(roleData, user.token).then((res) => {
        socket.emit("addUserRole", res.data);
      });
    }
    TimerMixin.setTimeout(() => {
      handleMember(null);
    }, 1500);
  }

  let workspaceOwner = workspaceDetails ? workspaceDetails.createdBy : "";

  let block = member
    ? workspaceOwner === member.userId
      ? true
      : false
    : false;

  let handleChange = (value) => {
    setRole(value);
  };

  return member ? (
    <Scrollbars
      style={{
        height: window.innerHeight - 100,
        backgroundColor: "rgba(0,0,0,0)",
      }}
    >
      <div className="body">
        <div className="display-top">
          <div className="title-text">Users</div>
          <div className="save-button" onClick={() => handleSubmit()}>
            Save & Close
          </div>
        </div>
        <div className="display-image">
          {member.avatar ? (
            <Avatar
              className={classes.orange}
              src={member.avatar}
              alt="Display Picture"
            />
          ) : (
            <Avatar className={classes.orange}>{member.shortName}</Avatar>
          )}
        </div>
        <div className="display-field">
          <div className="edit-field">
            <TextField
              label="Name"
              id="outlined-size-small"
              fullWidth
              defaultValue={member.name}
              variant="outlined"
              disabled
              size="small"
            />
          </div>
          {/*<div className="edit-field2">*/}
          {/*    <TextField*/}
          {/*        label="Last Name"*/}
          {/*        id="outlined-size-small"*/}
          {/*        fullWidth*/}
          {/*        defaultValue={member.name}*/}
          {/*        variant="outlined"*/}
          {/*        disabled*/}
          {/*        size="small"*/}
          {/*    />*/}
          {/*</div>*/}
        </div>
        <div className="display-field">
          <div className="edit-field">
            <TextField
              label="Email"
              id="outlined-size-small"
              fullWidth
              disabled
              defaultValue={member.email}
              variant="outlined"
              size="small"
            />
          </div>
          <div className="edit-field2">
            <Roles
              handleChange={handleChange}
              activeRole={member.userRole}
              block={block}
            />
          </div>
        </div>
      </div>
    </Scrollbars>
  ) : null;
}

function mapStateToProps(state) {
  return {
    workspace: state.settings.workspace,
    workspaceDetails: state.settings.workspaceDetails[0],
    user: state.user,
    teams: state.teams ? state.teams.allTeams : [],
    activeTeam: state.teams ? state.teams.activeTeam : null,
    teamMembers: state.teamMembers ? state.teamMembers.allTeamMember : [],
  };
}

export default withRouter(connect(mapStateToProps, { addNewRoleUser })(Users));
