import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import TextareaAutosize from "react-textarea-autosize";
import Timer from "react-compound-timer";
import { makeStyles } from "@material-ui/core/styles";
// import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import StopOutlinedIcon from "@material-ui/icons/StopOutlined";

import { connect } from "react-redux";

import { pauseTimer } from "../../Actions/hourLogActions";

import socketIOClient from "socket.io-client";
import { serverSocket } from "../../Actions/config";
import moment from "moment";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles({
  root: {
    marginTop: 48,
    marginLeft: 32,
    marginRight: 32,
  },
});

let spentHour = (spent) => {
  let final = spent / (60 * 60 * 1000);
  return final.toPrecision(2);
};

function HeaderHour(props) {
  let classes = useStyles();

  let {
    runningTask,
    handleStop,
    pauseTimer,
    token,
    resetFlag,
    stopFlag,
  } = props;

  let active = runningTask ? runningTask.run : false;

  let startArr = runningTask
    ? runningTask.startTime
      ? runningTask.startTime
      : []
    : [];

  let len = startArr.length;

  let handleEnd = (log) => {
    handleStop({});
    if (token) {
      let stopData = {
        _id: log._id,
        endTime: new Date(),
      };
      pauseTimer(stopData, token).then((res) => {
        if (res) {
          socket.emit("notify", token);
          socket.emit("getLogs", res);
        }
      });
    }
  };

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className="header-body">
          <TextareaAutosize
            defaultValue={runningTask.taskName ? runningTask.taskName : ""}
            placeholder="What are you working now?"
            disabled
          />
          <div
            style={{
              color: runningTask.projectColor ? runningTask.projectColor : "",
            }}
            className="header-project"
          >
            <span>
              {runningTask.projectTitle ? runningTask.projectTitle : "Project"}
            </span>
          </div>

          <div className="header-estimate">
            <TextareaAutosize
              defaultValue={
                runningTask.spent ? spentHour(runningTask.spent) : ""
              }
              placeholder="S: 0"
              disabled
            />
            <span>-</span>
            <TextareaAutosize
              defaultValue={runningTask.estimated ? runningTask.estimated : ""}
              placeholder="E: 0"
              disabled
            />
          </div>

          <div>
            <Timer
              initialTime={
                active
                  ? moment(new Date()).valueOf() -
                    moment(new Date(startArr[len - 1])).valueOf()
                  : 0
              }
              startImmediately={false}
              formatValue={(value) => `${value < 10 ? `0${value}` : value}`}
            >
              {({ start, resume, pause, stop, reset, timerState }) => (
                <React.Fragment>
                  {runningTask._id ? start() : null}
                  {resetFlag ? reset() : null}
                  {stopFlag ? stop() : null}
                  <div className="header-timer">
                    <div>
                      <Timer.Hours />:
                      <Timer.Minutes />:
                      <Timer.Seconds />
                    </div>
                    {runningTask._id ? (
                      <div
                        className="play-pause"
                        onClick={() => {
                          stop();
                          reset();
                          handleEnd(runningTask);
                        }}
                      >
                        <StopOutlinedIcon />
                      </div>
                    ) : (
                      <div className="play-pause" onClick={start}>
                        {/* <PlayCircleOutlineIcon /> */}
                      </div>
                    )}
                  </div>
                </React.Fragment>
              )}
            </Timer>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default connect(null, {
  pauseTimer,
})(HeaderHour);
