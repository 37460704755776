import axios from 'axios'

import {
  ADD_PROJECT,
  SET_UPDATED_PROJECT,
  GET_WORKSPACE_PROJECT,
  SET_FILTER_PROJECT,
  SET_PROJECT_TYPES,
  GET_ALL_MILESTONES,
  ADD_NEW_MILESTONE
} from '../Constants'
// import { func } from 'prop-types';
import { serverURI } from './config';
//const serverURI = "http://192.168.2.3:5001/api/";
//Must add 'JWT ' string before the token

export function addProject(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let addProject = await axios.post(serverURI + 'post/add-new-project', data)

      try {
        if (addProject.status === 200) {
          response.status = addProject.status
          response.token = token
          response.data = addProject.data
          dispatch(setProject(addProject.data))

          let sendMilestone = {
            workspaceId: addProject.data.workspaceId,
            projectId: addProject.data._id,
            milestoneList: data.milestone
          }

          let addMilestone = await axios.post(serverURI + 'post/add-new-milestone', sendMilestone)

          if (addMilestone.status === 200) {
            dispatch(newMilestone(addMilestone.data))
          }

          response.message = 'Project Successfully Added'
        } else {
          response.status = 302
          response.message = addProject.data.message
        }

        return response
      } catch (err) {
        response.status = 500;
        response.message = err;
        return response;
      }
    }
  }
}

export function updateProject(data, token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updateProject = await axios.post(serverURI + 'post/update-project', data)

      try {
        if (updateProject.status === 200) {
          response.status = updateProject.status
          response.token = token
          dispatch(setUpdate(updateProject.data))

          response.message = 'Project Successfully Added'
        } else {
          response.status = 302
          response.message = updateProject.data.message
        }
        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function updateMilestone(data, token) {
  let response = {
    status: '',
    message: '',
    token: ''
  }

  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updateMilestone = await axios.post(serverURI + 'post/update-milestone', data)

      try {
        if (updateMilestone.status === 200) {
          dispatch(newMilestone(updateMilestone.data))
          response.message = 'Milestone Successfully Updated'
        } else {
          response.status = 302
          response.message = updateMilestone.data.message
        }
        return response
      } catch (err) {
        response.status = 400
        response.message = err
        return response
      }
    }
  }
}

export function getAllProjects(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    projects: ''
  }

  if (token) {
    try {
      return async dispatch => {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let allProjects = await axios.post(serverURI + 'post/get-projects-by-workspace', data)
        if (allProjects.status === 200) {
          response.status = allProjects.status
          response.token = token
          response.projects = allProjects.data
          // console.log('Projects', res.data)
          dispatch(setAllProjects(allProjects.data))

          let allTypes = await axios.post(serverURI + 'post/fetch-all-project-type', data)

          if (allTypes.status === 200) {
            dispatch(setAllProjectTypes(allTypes.data))
          }

          let allMilestones = await axios.post(serverURI + 'post/fetch-milestones', data)

          if (allMilestones.status === 200) {
            dispatch(setMilestone(allMilestones.data))
          }

          response.message = 'Project Successfully Fetched'
        } else {
          response.status = 302
          response.message = allProjects.data.message
        }
        return response
      }
    } catch (err) {
      response.status = 400
      response.message = err
      return response
    }
  }
}


export function addProjectType(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    try {
      return async () => {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let addType = await axios.post(serverURI + 'post/add-new-project-type', data)
        if (addType.status === 200) {
          response.status = 200
          response.token = token
          response.data = addType.data
          response.message = 'Project Type has been added Successfully'
        } else {
          response.status = addType.status
          response.message = 'Error Occured'
        }

        return response
      }
    } catch (err) {
      response.status = 500
      response.message = 'Internal Server Error'

      return response
    }
  }
}

export function addTaskToMilestone(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  if (token) {
    try {
      return async dispatch => {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token

        let add = await axios.post(serverURI + 'post/add-new-item', data)

        if (add.status === 200) {
          response.status = 200
          response.token = token
          response.data = add.data
          dispatch(newMilestone(add.data))

          response.message = 'Task added to milestone'
        } else {
          response.status = add.status
          response.message = 'Task adding to milestone is not successful'
        }

        return response
      }
    } catch (err) {
      response.status = 500
      response.message = 'Internal Server Error'

      return response
    }
  }
}

export function getAllProjectTypes(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  }
  if (token) {
    try {
      return async dispatch => {
        axios.defaults.headers.common['Authorization'] = 'JWT ' + token
        let getAllType = await axios.post(serverURI + 'post/fetch-all-project-type', data)

        if (getAllType.status === 200) {
          response.status = 200
          response.token = token
          response.data = getAllType.data
          dispatch(setAllProjectTypes(getAllType.data))
          response.message = 'Project Types fetched successfully'
        } else {
          response.status = getAllType.status
          response.message = 'All Projects Fetching Error'
        }

        return response
      }
    } catch (err) {
      response.status = 500
      response.message = 'Internal Server Error'

      return response
    }
  }
}

export function selectProject(data) {
  return dispatch => {
    dispatch(setFiltered(data))
  }
}

export function setProject(projectData) {
  return {
    type: ADD_PROJECT,
    data: projectData
  }
}

export function setMilestone(data) {
  return {
    type: GET_ALL_MILESTONES,
    data: data
  }
}

export function newMilestone(data) {
  return {
    type: ADD_NEW_MILESTONE,
    data: data
  }
}

export function setFiltered(projectData) {
  return {
    type: SET_FILTER_PROJECT,
    data: projectData
  }
}

export function setUpdate(projectData) {
  return {
    type: SET_UPDATED_PROJECT,
    data: projectData
  }
}

export function setAllProjects(projectData) {
  localStorage.setItem('ttkAllProjects', JSON.stringify(projectData))
  return {
    type: GET_WORKSPACE_PROJECT,
    data: projectData
  }
}

export function setAllProjectTypes(data) {
  localStorage.setItem('ttkProjectTypes', JSON.stringify(data))
  return {
    type: SET_PROJECT_TYPES,
    data: data
  }
}
