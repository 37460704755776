import React from 'react';
import './index.css';

import { render } from 'react-dom';

import { Provider } from 'react-redux'
import Store from "./Store/index";
import App from './App'
import * as serviceWorker from './serviceWorker';
import { checkUser } from './Actions/userActions'
import { setSettingsLocal } from './Actions/settingsActions'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
      primary: { 500: '#638CFE'}
    }
  });

const AppStore = Store()

let loggedIn = localStorage.getItem('loggedIn')

function onAppInit(dispatch) {
    dispatch(checkUser());
    dispatch(setSettingsLocal());
}

render(
    <MuiThemeProvider theme={theme}>
        <Provider store={AppStore}>
            <App onLoad={onAppInit(AppStore.dispatch)} loggedIn={loggedIn} />
        </Provider>
    </MuiThemeProvider>,
    document.getElementById('root')
)
serviceWorker.unregister();
