/* eslint-disable no-use-before-define */
import React from 'react';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { makeStyles } from '@material-ui/core/styles';
import styled from "styled-components";

const useStyles = makeStyles(theme => ({
    label: {
        display: 'block',
    },
    input: {
        width: 200,
    },
    listbox: {
        width: 200,
        margin: 0,
        padding: 0,
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        backgroundColor: theme.palette.background.paper,
        overflow: 'auto',
        maxHeight: 200,
        border: '1px solid rgba(0,0,0,.25)',
        '& li[data-focus="true"]': {
            backgroundColor: '#4a8df6',
            color: 'white',
            cursor: 'pointer',
        },
        '& li:active': {
            backgroundColor: '#2977f5',
            color: 'white',
        },
    },
}));

const Listbox = styled('ul')`
  width: 280px;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: #fff;
  font-family: 'Sofia Pro';
  font-weight: 300;
  overflow: auto;
  max-height: 250px;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: #fafafa;
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li[data-focus='true'] {
    background-color: #e6f7ff;
    cursor: pointer;

    & svg {
      color: #000;
    }
  }
`;

export default function UseAutocomplete(props) {
    const classes = useStyles();
    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions
    } = useAutocomplete({
        id: 'customized-single-select-hook',
        defaultValue: props.defaultValue,
        options: props.values,
        getOptionLabel: option => option.title,
    });

    // if (props.onProjectChange) props.onProjectChange(value)


    return (
        <>
            <div className="customSingleSelect editHoverBGColor" {...getRootProps()}>
                {
                    props.bgColor ? (
                        <span className="sidebarProjectColor" style={{
                            backgroundColor: props.bgColor
                        }}>

                        </span>
                    ) : ''
                }
                <span className="sidebarProjectName">
                    <input
                        className={classes.input}
                        value={props.defaultValue.title}
                        {...getInputProps()}
                    />
                </span>
            </div>
            {groupedOptions.length > 0 ? (
                <Listbox {...getListboxProps()}>
                    {groupedOptions.map((option, index) => (
                        <li {...getOptionProps({ option, index })}>{option.title}</li>
                    ))}
                </Listbox>
            ) : null}
        </>
    );
}

