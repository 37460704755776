import {
  ADD_LEAVE_LIST,
  UPDATE_LEAVE_LIST,
  GET_LEAVE_LIST,
  INSERT_USER_LEAVE,
  UPDATE_USER_LEAVE,
  CONFIRM_USER_LEAVE,
  REJECT_USER_LEAVE,
  GET_PENDING_LEAVES,
  GET_CONFIRMED_LEAVES,
  GET_USER_HISTORY,
  GET_USER_PENDING,
  GET_USER_REJECTED
} from '../Constants/index'

import axios from 'axios'
import { serverURI } from './config'

export function addLeave(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let addNew = await axios.post(serverURI + 'post/insert-leave', inputData)

      if (addNew.status === 200) {
        response.status = 200
        response.token = token
        response.data = addNew.data
        dispatch(setNewLeave(addNew.data))
        response.message = "New Leave added"
      } else {
        response.status = addNew.status
        response.message = "Error from add new leave"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function updateLeave(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let update = await axios.post(serverURI + 'post/update-leave', inputData)

      if (update.status === 200) {
        response.status = 200
        response.token = token

        response.data = update.data
        dispatch(setUpdatedLeave(update.data))
        response.message = "Leave updated"
      } else {
        response.status = update.status
        response.message = "Error from add new leave"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getAllLeaves(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let allLeaves = await axios.post(serverURI + 'post/get-leave-list', inputData)

      if (allLeaves.status === 200) {
        response.status = 200
        response.token = token

        response.data = allLeaves.data
        dispatch(setAllLeaves(allLeaves.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = allLeaves.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function addUserLeave(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let apply = await axios.post(serverURI + 'post/insert-user-leave', inputData)

      if (apply.status === 200) {
        response.status = 200
        response.token = token

        response.data = apply.data
        dispatch(setAppliedLeave(apply.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = apply.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function updateUserLeave(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let update = await axios.post(serverURI + 'post/update-user-leave', inputData)

      if (update.status === 200) {
        response.status = 200
        response.token = token

        response.data = update.data
        dispatch(setUpdatedUserLeave(update.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = update.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function confirmUserLeave(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let confirm = await axios.post(serverURI + 'post/confirm-user-leave', inputData)

      if (confirm.status === 200) {
        response.status = 200
        response.token = token

        response.data = confirm.data
        dispatch(setConfirmed(confirm.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = confirm.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function rejectUserLeave(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let reject = await axios.post(serverURI + 'post/reject-user-leave', inputData)

      if (reject.status === 200) {
        response.status = 200
        response.token = token

        response.data = reject.data
        dispatch(setReject(reject.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = reject.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getPendingLeaves(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let pendings = await axios.post(serverURI + 'post/get-pending-leaves', inputData)

      if (pendings.status === 200) {
        response.status = 200
        response.token = token

        response.data = pendings.data
        dispatch(setPendings(pendings.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = pendings.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getUserPending(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let pendings = await axios.post(serverURI + 'post/get-user-pending', inputData)

      if (pendings.status === 200) {
        response.status = 200
        response.token = token

        response.data = pendings.data
        dispatch(setUserPendings(pendings.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = pendings.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getConfirmedLeaves(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let confirmed = await axios.post(serverURI + 'post/get-confirmed-leaves', inputData)

      if (confirmed.status === 200) {
        response.status = 200
        response.token = token

        response.data = confirmed.data
        dispatch(setConfirmedList(confirmed.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = confirmed.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getUserLeaveHistory(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let confirmed = await axios.post(serverURI + 'post/get-user-history', inputData)

      if (confirmed.status === 200) {
        response.status = 200
        response.token = token

        response.data = confirmed.data
        dispatch(setUserHistory(confirmed.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = confirmed.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export function getUserRejectedHistory(inputData, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  return async dispatch => {
    try {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let confirmed = await axios.post(serverURI + 'post/get-user-rejected', inputData)

      if (confirmed.status === 200) {
        response.status = 200
        response.token = token

        response.data = confirmed.data
        dispatch(setUserRejected(confirmed.data))
        response.message = "All Leaves fetched"
      } else {
        response.status = confirmed.status
        response.message = "Error from leave fetching"
      }

      return response
    } catch (err) {
      response.message = err
      response.status = 500

      return response
    }
  }
}

export let setNewLeave = data => {
  return {
    type: ADD_LEAVE_LIST,
    data: data
  }
}

export let setUpdatedLeave = data => {
  return {
    type: UPDATE_LEAVE_LIST,
    data: data
  }
}

export const setAllLeaves = data => {
  return {
    type: GET_LEAVE_LIST,
    data: data
  }
}

export const setAppliedLeave = data => {
  return {
    type: INSERT_USER_LEAVE,
    data: data
  }
}

export const setUpdatedUserLeave = data => {
  return {
    type: UPDATE_USER_LEAVE,
    data: data
  }
}

export const setConfirmed = data => {
  return {
    type: CONFIRM_USER_LEAVE,
    data: data
  }
}

export const setReject = data => {
  return {
    type: REJECT_USER_LEAVE,
    data: data
  }
}

export const setPendings = data => {
  return {
    type: GET_PENDING_LEAVES,
    data: data
  }
}

export const setUserPendings = data => {
  return {
    type: GET_USER_PENDING,
    data: data
  }
}

export const setConfirmedList = data => {
  return {
    type: GET_CONFIRMED_LEAVES,
    data: data
  }
}

export const setUserHistory = data => {
  return {
    type: GET_USER_HISTORY,
    data: data
  }
}

export const setUserRejected = data => {
  return {
    type: GET_USER_REJECTED,
    data: data
  }
}