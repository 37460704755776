import React, { useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const WorkspaceLists = (props) => {
  let {
    allWorkspaces,
    editWorkspace,
    deleteWorkspace,
    userId
  } = props
  return (
    allWorkspaces.map((item, index) => {
      if (item.status) {
        return (
          <div 
            style={{ 
              alignItems: 'center', 
              display: 'flex', 
              height: 60, 
              background: index % 2 == 0 ? '#f2f2f2' : '#f7f7f7' 
            }}
          >
            <div className="divLists">{item.workspace}</div>
            <EditOutlinedIcon
              className="editIcon"
              style={{ marginLeft: 168 }}
              onClick={userId === item.createdBy ? () => editWorkspace(item.workspaceId) : () => {}}
            />
            <DeleteOutlinedIcon
              className="deleteIcon"
              onClick={userId === item.createdBy ? () => deleteWorkspace(item.workspaceId) : () => {}}
            />
          </div>
        )
      } else return
    })
  );
}

export default WorkspaceLists;