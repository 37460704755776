import {
  ADD_NEW_ACTIVITY_NAME,
  GET_ALL_ACTIVITY_NAME,
  ADD_NEW_ACTIVITY,
  GET_ALL_ACTIVITIES,
  ADD_NEW_MILESTONE_HISTORY,
  GET_ALL_MILESTONE_HISTORY
} from '../Constants/index'

let initialState = {}

let milestoneDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NEW_ACTIVITY_NAME:
      return {
        ...state,
        newActivityName: action.data
      }
    case GET_ALL_ACTIVITY_NAME:
      return {
        ...state,
        allActivitiesName: action.data
      }
    case ADD_NEW_ACTIVITY:
      return {
        ...state,
        newActivity: action.data
      }
    case GET_ALL_ACTIVITIES:
      return {
        ...state,
        allActivities: action.data
      }
    case ADD_NEW_MILESTONE_HISTORY:
      return {
        ...state,
        newMilestoneHistory: action.data
      }
    case GET_ALL_MILESTONE_HISTORY:
      return {
        ...state,
        allMilestoneHistory: action.data
      }

    default:
      return state
  }
}

export default milestoneDetailsReducer