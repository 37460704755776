import axios from 'axios'

import { GET_ALL_STATUS } from '../Constants';

import { serverURI } from './config';

export function getAllStatus(token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  };
  if (token) {
    return async (dispatch) => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const getStatus = await axios.get(serverURI + 'get/get-all-status')
      try {
        if (getStatus.status === 200) {
          response.status = getStatus.status
          response.token = token
          response.data = getStatus.data
          dispatch(allTaskStatusSet(getStatus.data))
          response.message = "Task Status fetched successfully"
        } else {
          response.status = 302
          response.message = getStatus.data.message
        }
        return response
      } catch (err) {
        response.status = 400;
        response.message = err;
        return response;
      }
    }
  }
}

export function allTaskStatusSet(taskStatus) {
  localStorage.setItem('ttkTaskStatus', JSON.stringify(taskStatus))
  return {
    type: GET_ALL_STATUS,
    data: taskStatus
  }
}