import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MemberList from "../../Components/MemberList";
import "../../Assets/CSS/ProjectDialog.css";
import { Scrollbars } from "react-custom-scrollbars";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import socketIOClient from "socket.io-client";
import { connect } from "react-redux";
import { serverSocket } from "../../Actions/config";
import CloseIcon from "@material-ui/icons/Close";
import ConfirmDelete from "./ConfirmMemberDelete";
import { search } from "ss-search";
import Search from "./ProjectSearch";
import AddCircleIcon from "@material-ui/icons/AddCircle";

let socket = socketIOClient(serverSocket);

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 480,
    paddingBottom: 10,
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
}));

function RemoveTeamMember(props) {
  const classes = useStyles();
  let {
    open,
    handleClose,
    allTeams,
    allMembers,
    onRemove,
    token,
    user,
    roles,
    activeTeam,
    workspace,
    addMemberModal,
  } = props;

  let userDetails = user ? (user.userDetails ? user.userDetails : null) : null;

  let mainId = userDetails ? userDetails.id : null;

  let teamMembers = allMembers.filter((item) => item.teamId === activeTeam);

  let member = teamMembers.find((item) => item.userId === mainId);

  let roleId = member
    ? roles.find((role) => role._id === member.userRole)
    : null;

  let workspaceOwner = workspace ? workspace.createdBy === mainId : false;

  let team = allTeams ? allTeams.find((item) => item._id === activeTeam) : null;

  let permission = roleId
    ? roleId.priority
    : team
    ? team.createdBy === mainId
      ? 1
      : 3
    : workspaceOwner
    ? 1
    : 3;

  let [value, setValue] = useState(activeTeam ? activeTeam : "");
  let [selected, setUser] = useState("");
  let [msg, setMsg] = useState("");
  let [searchText, setSearchKey] = useState("");
  let [deletePop, setDeletePop] = useState(false);

  let handleDeletePop = () => {
    setDeletePop(true);
  };

  let closeDeletePop = () => {
    setDeletePop(false);
  };

  let handleUser = (userId) => {
    setUser(userId);
    handleDeletePop();
  };

  let onClickCreate = () => {
    addMemberModal();
  };

  let onSubmit = () => {
    if (permission < 3) {
      let data = {
        teamId: value,
        userId: selected,
      };
      // console.log('data from remove member', data)
      if (value && data.userId) {
        onRemove(data, token).then((res) => {
          // console.log('Res.Data', res.data, userId)
          let sendData = {
            teamId: value,
            userId: data.userId,
            id: res.data._id,
          };
          socket.emit("addUserRole", sendData);
          socket.emit("removeMember", sendData);
          setValue("");
          setUser("");
          closeDeletePop();
        });
      } else {
        setMsg("Please select values from dropdown");
      }
    } else {
      setMsg("You are not permitted to do the action");
    }
  };

  let searchKey = ["name", "email"];

  let filtered = search(teamMembers, searchKey, searchText);

  return (
    <React.Fragment>
      <Dialog
        className="customDialog"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ background: "rgba(73,121,255,0.85)" }}
        >
          <div style={{ display: "flex" }}>
            <text
              style={{
                color: "white",
                width: "100%",
                fontFamily: "Sofia Pro",
                textAlign: "left",
              }}
            >
              All Team Members
            </text>
            <CloseIcon className="dialogDrawerClose" onClick={handleClose} />
          </div>
        </DialogTitle>
        <DialogContent>
          <Search label={"Team Member by name/email"} onSearch={setSearchKey} />
          <div style={{ marginTop: 12 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                height: 56,
                background: "rgba(0,0,0,0.1)",
              }}
            >
              <div className="divHeaders">Team Members</div>
              <div className="divHeaders">Email</div>
              <div className="divHeaders" style={{ marginRight: 12 }}>
                Action
              </div>
            </div>
            <Scrollbars style={{ height: (window.innerHeight / 5) * 3 }}>
              <div>
                {
                  <MemberList
                    allMembers={filtered}
                    handleMember={handleUser}
                    teamId={activeTeam}
                  />
                }
              </div>
            </Scrollbars>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <div className="createProject" onClick={onClickCreate}>
              <AddCircleIcon />
              <div className="create" style={{ marginTop: "-2%" }}>
                Add Team Member
              </div>
            </div>
          </div>
        </DialogContent>
        <ConfirmDelete
          open={deletePop}
          deleteItem={selected}
          confirm={onSubmit}
          allMembers={filtered}
          cancel={closeDeletePop}
        />
      </Dialog>
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(RemoveTeamMember);
