import axios from 'axios'
import {
  GET_ALL_SUBTASKS,
  ADD_SINGLE_SUBTASK,
  ADD_MULTIPLE_SUBTASK,
  UPDATE_SUBTASK,
  SET_SUBTASK_STORE, GET_ALL_LITTLE_SUBTASKS
} from '../Constants'

import { serverURI } from './config'

export function addSubTaskMultiple(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  };
  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const subTasks = await axios.post(serverURI + 'post/add-many-subtasks', data)
      try {
        if (subTasks.status === 200) {
          response.status = 200
          response.token = token
          response.data = subTasks.data
          dispatch(setMultiple(subTasks.data))
          response.message = 'Subtasks added successfully'
        } else {
          response.status = subTasks.status
          response.message = "Errors from add subtask"
        }
        return response
      } catch (err) {
        response.status = 500
        response.message = 'Server error'

        return response
      }
    }
  }
}

export function addLittleSubTaskMultiple(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  };
  if (token) {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      const subTasks = await axios.post(serverURI + 'post/add-many-little-subtasks', data)
      try {
        if (subTasks.status === 200) {
          response.status = 200
          response.token = token
          response.data = subTasks.data
          dispatch(setMultiple(subTasks.data))
          response.message = 'Subtasks added successfully'
        } else {
          response.status = subTasks.status
          response.message = "Errors from add subtask"
        }
        return response
      } catch (err) {
        response.status = 500
        response.message = 'Server error'

        return response
      }
    }
  }
}

export function addSingleSubtask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let subTask = await axios.post(serverURI + 'post/add-one-sub-task', data)

      if (subTask.status === 200) {
        response.token = token
        response.status = 200
        response.data = subTask.data

        dispatch(setSingleSubtask(subTask.data))
        response.message = 'Subtask added successfully'
      } else {
        response.status = subTask.status
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function addSingleSubLittletask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token
      let subTask = await axios.post(serverURI + 'post/add-one-sub-little-task', data)

      if (subTask.status === 200) {
        response.token = token
        response.status = 200
        response.data = subTask.data

        dispatch(setSingleSubtask(subTask.data))
        response.message = 'Subtask added successfully'
      } else {
        response.status = subTask.status
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function getAllSubTasks(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let allSubtasks = await axios.post(serverURI + 'post/fetch-all-subtasks', data)

      if (allSubtasks.status === 200) {
        response.status = 200
        response.token = token
        response.data = allSubtasks.data
        dispatch(setAllSubtasks(allSubtasks.data))
        response.message = 'Subtask fetched'
      } else {
        response.status = allSubtasks.status
        response.message = 'Error from subtask fetching'
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function getAllLittleSubTasks(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: []
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let allSubtasks = await axios.post(serverURI + 'post/fetch-all-little-subtasks', data)

      if (allSubtasks.status === 200) {
        response.status = 200
        response.token = token
        response.data = allSubtasks.data
        dispatch(setAllLittleSubtasks(allSubtasks.data))
        response.message = 'Subtask fetched'
      } else {
        response.status = allSubtasks.status
        response.message = 'Error from subtask fetching'
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function updateSubtask(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updatedTask = await axios.post(serverURI + 'post/update-sub-task', data)

      if (updatedTask.status === 200) {
        response.status = 200
        response.token = token
        response.data = updatedTask.data
        dispatch(setUpdatedTask(updatedTask.data))
        response.message = 'Subtask updated successfully'
      } else {
        response.status = updatedTask.status
        response.message = 'Error from updating subtask'
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function updateSubtaskLittle(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updatedTask = await axios.post(serverURI + 'post/update-sub-little-task', data)

      if (updatedTask.status === 200) {
        response.status = 200
        response.token = token
        response.data = updatedTask.data
        dispatch(setUpdatedTask(updatedTask.data))
        response.message = 'Subtask updated successfully'
      } else {
        response.status = updatedTask.status
        response.message = 'Error from updating subtask'
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function deleteSubtaskLittle(data, token) {
  let response = {
    status: '',
    message: '',
    token: '',
    data: {}
  }
  try {
    return async dispatch => {
      axios.defaults.headers.common['Authorization'] = 'JWT ' + token

      let updatedTask = await axios.post(serverURI + 'post/delete-sub-little-task', data)

      if (updatedTask.status === 200) {
        response.status = 200
        response.token = token
        response.data = updatedTask.data
        dispatch(setUpdatedTask(updatedTask.data))
        response.message = 'Subtask updated successfully'
      } else {
        response.status = updatedTask.status
        response.message = 'Error from updating subtask'
      }

      return response
    }
  } catch (err) {
    response.status = 500
    response.message = 'Server error'

    return response
  }
}

export function setToState(data) {
  return dispatch => {
    dispatch(setSubtaskStore(data))
  }
}

export function setSubtaskStore(data) {
  return {
    type: SET_SUBTASK_STORE,
    data: data
  }
}

export function setMultiple(multipleSubtasks) {
  return {
    type: ADD_MULTIPLE_SUBTASK,
    data: multipleSubtasks
  }
}

export function setSingleSubtask(data) {
  return {
    type: ADD_SINGLE_SUBTASK,
    data: data
  }
}

export function setAllSubtasks(data) {
  localStorage.setItem('ttkSubtasks', JSON.stringify(data))
  return {
    type: GET_ALL_SUBTASKS,
    data: data
  }
}

export function setAllLittleSubtasks(data) {
  return {
    type: GET_ALL_LITTLE_SUBTASKS,
    data: data
  }
}

export function setUpdatedTask(data) {
  return {
    type: UPDATE_SUBTASK,
    data: data
  }
}