import React, { useState } from 'react';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

const ProjectLists = (props) => {
    let {
        allProjects,
        editProject,
        deleteProject,
        taskStatus
    } = props
    return (
        allProjects.map((item, index) => {
            if (item.projectStatus) {
                let status = taskStatus.length && item.phase ?
                    taskStatus.find(
                        phase => phase._id.toString() === item.phase.toString()
                    ) : null
                return (
                    <div style={{ alignItems: 'center', display: 'flex', height: 60, background: index % 2 == 0 ? '#f2f2f2' : '#f7f7f7' }}>
                        <div className="divColor" style={{ background: item.color + '80' }} />
                        <div className="divLists">{item.projectTitle}</div>
                        <div className="divListStatus">
                            {status ? status.statusTitle : '---'}
                        </div>
                        <EditOutlinedIcon
                            className="editIcon"
                            onClick={() => editProject(item._id)}
                        />
                        <DeleteOutlinedIcon
                            className="deleteIcon"
                            onClick={() => deleteProject(item)}
                        />
                    </div>
                )
            } else return
        })
    );
}

export default ProjectLists;